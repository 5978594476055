import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { useContentContext } from "../providers/ContentContext";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import {
  CustomPaginationCom,
  EditSuburb,
  NewSuburb,
  StatusChange,
  ViewSuburb,
} from "../components";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import {
  getAllSuburbWithPagination,
  tableSuburbStatusChange,
} from "../helpers";

const columns = [
  {
    title: "Country",
    dataIndex: "country",
    key: "0",
    sorter: (a, b) => {},
    defaultSortOrder: "ascend",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "1",
    sorter: (a, b) => {},
    defaultSortOrder: "ascend",
  },
  {
    title: "Suburb",
    dataIndex: "suburb",
    key: "2",
    sorter: (a, b) => {},
    defaultSortOrder: "ascend",
  },
  {
    title: "Postal Code",
    dataIndex: "postalCode",
    key: "3",
    sorter: (a, b) => {},
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "4",
    sorter: (a, b) => {},
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "5",
  },
];

const Suburb = () => {
  let { openNotification, handleError } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [tableSuburbData, setTableSuburbData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [isOpenNewSuburbDrawer, setIsOpenNewSuburbDrawer] = useState(false);
  const [isOpenVewSuburbDrawer, setIsOpenViewSuburbDrawer] = useState(false);
  const [selectedViewSuburbData, setSelectedViewSuburbData] = useState();
  const [isOpenEditSuburbDrawer, setIsOpenEditSuburbDrawer] = useState(false);
  const [selectedEditSuburbData, setSelectedEditSuburbData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState();
  const [searchText, setSearchText] = useState(null);
  const [orderType, setOrderType] = useState(1);
  const [orderBy, setOrderBy] = useState(1);

  useEffect(() => {
    fetchData(1, 1, null);
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async (ordertype, orderby, text) => {
    try {
      setDataLoading(true);
      await getAllSuburbWithPagination({
        status: -1,
        page: currentPage,
        limit: 10,
        order_type: ordertype,
        order_by: orderby,
        search: text,
      })
        .then((response) => {
          if (response?.data?.success) {
            setData(
              response?.data?.output?.suburbs
                ? response?.data?.output?.suburbs
                : []
            );
            setTotalPage(response.data.output.pagination.totalPages);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (suburbs) => {
    if (suburbs !== 0) {
      var tableData = [];

      suburbs?.forEach((suburb, x) => {
        let data = {
          key: x,
          country: suburb?.country,
          state: suburb.state,
          suburb: suburb.suberb,
          postalCode: suburb.postal_code,
          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                suburb?.is_active === 1 ? "bg-primaryColor" : "bg-primaryLight"
              } `}
              onClick={() => {
                setChangeStatus(suburb?.is_active);
                openChangeStatus(suburb);
              }}
            >
              {suburb?.is_active === 1 ? "Active" : "Inactive"}
            </Button>
          ),
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(18) ? (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditSuburbDrawer(suburb);
                  }}
                />
              ) : (
                <></>
              )}

              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewSuburbDrawerOpenChange(suburb);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableSuburbData(tableData);
    } else {
      setTableSuburbData([]);
    }
    setDataLoading(false);
  };

  const handleNewSuburbDrawerOpenChange = () => {
    setIsOpenNewSuburbDrawer(!isOpenNewSuburbDrawer);
  };
  const handleViewSuburbDrawerOpenChange = (suburb) => {
    setSelectedViewSuburbData(suburb);
    setIsOpenViewSuburbDrawer(!isOpenVewSuburbDrawer);
  };
  const openEditSuburbDrawer = (suburb) => {
    setIsOpenEditSuburbDrawer(true);
    setSelectedEditSuburbData(suburb);
  };
  const closeEditSuburbDrawer = () => {
    setIsOpenEditSuburbDrawer(false);
  };

  const openChangeStatus = (suburb) => {
    setIsModalOpen(true);
    setSelectedData(suburb);
  };
  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };
  // state status change in table
  const handleStatus = async (status) => {
    const data = {
      suberb_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    await tableSuburbStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const search = (text) => {
    fetchData(orderType, orderBy, text);
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5 pl-6">
        SUBURB
      </div>

      <Content className="rounded-lg mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Suburb..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(17) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                onClick={handleNewSuburbDrawerOpenChange}
                icon={<MdAdd className="text-2xl font-bold" />}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD SUBURB
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={tableSuburbData}
            columns={columns}
            showSorterTooltip={false}
            pagination={false}
            loading={dataLoading}
            scroll={{ x: "100%" }}
            onChange={(pagination, filters, sorter) => {
              setOrderBy(
                sorter?.order === "ascend"
                  ? 1
                  : sorter?.order === "descend"
                  ? 2
                  : 1
              );
              setOrderType(
                sorter?.field === "suburb"
                  ? 1
                  : sorter?.field === "state"
                  ? 2
                  : sorter?.field === "country"
                  ? 3
                  : sorter?.field === "status"
                  ? 4
                  : 1
              );

              fetchData(
                sorter?.order === "ascend"
                  ? 1
                  : sorter?.order === "descend"
                  ? 2
                  : 1,
                sorter?.field === "suburb"
                  ? 1
                  : sorter?.field === "state"
                  ? 2
                  : sorter?.field === "country"
                  ? 3
                  : sorter?.field === "status"
                  ? 4
                  : 5,
                searchText
              );
            }}
          />
          <div className="flex justify-end">
            <CustomPaginationCom
              current={currentPage}
              total={totalPages}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </Content>

      {isOpenNewSuburbDrawer ? (
        <NewSuburb
          open={isOpenNewSuburbDrawer}
          onClose={handleNewSuburbDrawerOpenChange}
          reloadSuburbData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenVewSuburbDrawer ? (
        <ViewSuburb
          open={isOpenVewSuburbDrawer}
          onClose={handleViewSuburbDrawerOpenChange}
          suburb={selectedViewSuburbData}
        />
      ) : (
        <></>
      )}

      {isOpenEditSuburbDrawer ? (
        <EditSuburb
          open={isOpenEditSuburbDrawer}
          onClose={closeEditSuburbDrawer}
          suburb={selectedEditSuburbData}
          reloadSuburbData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isModalOpen && (
        <StatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default Suburb;
