import React from "react";
import { Modal, Row, Col, Button } from "antd";

const ApproveRejectStatusChange = ({
  open,
  onCancel,
  changeStatus,
  handleStatus,
}) => {
  return (
    <Modal open={open} onCancel={onCancel}>
      <p className="text-[24px] font-medium text-center">
        Are you sure you want to change the status?
      </p>

      <p className="text-[16px] font-normal text-secondaryColor text-center pt-5">
        {`This action will ${
          changeStatus === 1 ? "reject" : "approve"
        } the promotion campaign. Confirm to
        proceed`}
      </p>

      <Row className="flex flex-row justify-between px-10 pt-5">
        <Col className="tradie-block-button">
          <Button
            onClick={() => {
              handleStatus(changeStatus === 1 ? 0 : 1);
            }}
            className="bg-black text-white"
            size="large"
          >
            {changeStatus === 1 ? "REJECT" : "APPROVE"}
          </Button>
        </Col>
        <Col className="outline-button">
          <Button size="large" onClick={onCancel}>
            CANCEL
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ApproveRejectStatusChange;
