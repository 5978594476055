import axios from "axios";
import { config, formconfig, url } from "./header";

//blog data
export async function getAllBlogs(data) {
  return await axios.post(url + "blog/all", data, config);
}

//add blog
export async function addBlog(data) {
  return await axios.post(url + "blog/add", data, formconfig);
}

//view blog
export async function viewBlog(data) {
  return await axios.post(url + "blog/data", data, config);
}
//edit blog
export async function editBlog(data) {
  return await axios.post(url + "blog/edit", data, formconfig);
}

//image add
export async function addImage(data) {
  return await axios.post(url + "blog/image/add", data, formconfig);
}
