import React, { useState } from "react";
import { CollapsedLogo, LoginBg, LoginLogo } from "../assets";
import { Col, ConfigProvider, Row } from "antd";
import {
  ResetEmail,
  ResetNewPassword,
  ResetPwdSuccess,
  SetOTP,
} from "../components";

const ForgotPassword = () => {
  const [formChanger, setFormChanger] = useState(0);
  const [resetemail, setResetEmail] = useState();
  const [isOTPsend, setIsOTPsend] = useState(false);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins",
            colorPrimary: "var(--primary-light)"
          },
        }}
      >
        <Row>
          <Col
            xs={24}
            md={15}
            className="login-form-mobile flex flex-col md:hidden w-full h-screen justify-center items-center"
            style={{
              backgroundImage: `url(${LoginBg})`,
              backgroundSize: "cover",
            }}
          >
            <div className="absolute inset-0 bg-black opacity-20"></div>
            <div>
              <img src={CollapsedLogo} alt="Logo" />
            </div>
            <>
              {formChanger === 0 ? (
                <ResetEmail
                  setFormChanger={setFormChanger}
                  setResetEmail={setResetEmail}
                  setIsOTPsend={setIsOTPsend}
                />
              ) : formChanger === 1 ? (
                <SetOTP
                  setFormChanger={setFormChanger}
                  resetemail={resetemail}
                  isOTPsend={isOTPsend}
                />
              ) : formChanger === 2 ? (
                <ResetNewPassword
                  resetemail={resetemail}
                  setFormChanger={setFormChanger}
                />
              ) : formChanger === 3 ? (
                <ResetPwdSuccess />
              ) : (
                <></>
              )}
            </>
          </Col>

          <Col
            xs={24}
            md={15}
            className="login-form-desktop hidden md:flex flex-col w-full h-screen justify-center items-center bg-[#484848]"
          >
            <>
              {formChanger === 0 ? (
                <ResetEmail
                  setFormChanger={setFormChanger}
                  setResetEmail={setResetEmail}
                  setIsOTPsend={setIsOTPsend}
                />
              ) : formChanger === 1 ? (
                <SetOTP
                  setFormChanger={setFormChanger}
                  resetemail={resetemail}
                  isOTPsend={isOTPsend}
                />
              ) : formChanger === 2 ? (
                <ResetNewPassword
                  resetemail={resetemail}
                  setFormChanger={setFormChanger}
                />
              ) : formChanger === 3 ? (
                <ResetPwdSuccess />
              ) : (
                <></>
              )}
            </>
          </Col>

          <Col
            md={9}
            className="hidden md:flex flex-col bg-cover bg-center items-center justify-center"
            style={{
              backgroundImage: `url(${LoginBg})`,
            }}
          >
            <div>
              <img src={LoginLogo} width={250} alt="Logo" />
            </div>
          </Col>
        </Row>
      </ConfigProvider>
    </>
  );
};

export default ForgotPassword;
