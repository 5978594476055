import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, url } from "./header";

const userId = getLocalStoragedata("userId");

export async function getAllForm1GraphData(data) {
  return await axios.post(url + "graph/form1/data", data, config);
}

export async function getAllForm2GraphData(data) {
  return await axios.post(url + "graph/form2/data", data, config);
}

export async function getAllForm3GraphData(data) {
  return await axios.post(url + "graph/form3/data", data, config);
}

export async function getAllForm4GraphData(data) {
  return await axios.post(url + "graph/form4/data", data, config);
}
