import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Switch,
} from "antd";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { addAppData } from "../../helpers/apiHelpers/appDownloadHelpers";
import moment from "moment";
import AppDownloadSubmitButton from "../submitButton/AppDownloadSubmitButton";
import { getLocalStoragedata } from "../../helpers/StorageHelper";

const AddAppDownload = ({ onClose, open, reloadTable }) => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");

  const [form] = Form.useForm();

  const [viewUserData, setViewUserData] = useState(1);

  const [dateString, setDateString] = useState(true);
  const [formattedDateString, setFormattedDateString] = useState(true);

  const handleDate = (date, dateString) => {
    const formattedDate = moment(dateString, "DD MMM YYYY").format(
      "YYYY-MM-DD"
    );
    setDateString(dateString);
    setFormattedDateString(formattedDate);
  };

  const handleUSerCount = (e) => {
    e == true ? setViewUserData(0) : setViewUserData(1);
  };

  const onFinish = (formData) => {
    const data = {
      day: formattedDateString,
      day_str: dateString,
      user_id: userId,
      au_ios_client_download: formData?.au_ios_c,
      au_ios_tasker_download: formData?.au_ios_t,
      sl_ios_client_download: formData?.sl_ios_c,
      sl_ios_tasker_download: formData?.sl_ios_t,
      au_android_client_download: formData?.au_and_c,
      au_android_tasker_download: formData?.au_and_t,
      sl_android_client_download: formData?.sl_and_c,
      sl_android_tasker_download: formData?.sl_and_t,
      is_auto: viewUserData,
      sl_client_register: formData?.sl_c_ru,
      sl_tasker_register: formData?.sl_t_ru,
      au_client_register: formData?.au_c_ru,
      au_tasker_register: formData?.au_t_ru,
    };

    addAppData(data)
      .then((response) => {
        if (response.data.success) {
          reloadTable();
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  const validateInputField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please ente only numbers"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            form={form}
            name="addAppDownload"
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}
          >
            <div className="relative bg-secondaryWhite p-7">
              <div className="w-full flex flex-row justify-between pb-5">
                <div className="text-[16px] font-medium text-primaryLight">
                  Add App Download and Register Data
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <div className="flex">
                <Form.Item
                  name="date"
                  label="Date"
                  className="w-[50%]"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    className="w-[50%] h-9"
                    onChange={handleDate}
                    format="DD MMM YYYY"
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="au_ios_c"
                  label="AU IOS client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    maxLength={20}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="au_ios_t"
                  label="AU IOS tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="au_and_c"
                  label="AU Android client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name="au_and_t"
                  label="AU Android tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
              </div>
              <Divider className="mb-4 mt-2" />
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="sl_ios_c"
                  label="SL IOS client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name="sl_ios_t"
                  label="SL IOS tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="sl_and_c"
                  label="SL Android client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  name="sl_and_t"
                  label="SL Android tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex font-medium text-base">User Count</div>

                <div className="flex text-center">
                  <Form.Item name="switch" initialValue={false}>
                    <Switch className="" onChange={handleUSerCount} />
                  </Form.Item>
                </div>
                {viewUserData == 0 ? (
                  <>
                    <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                      <Form.Item
                        name="au_c_ru"
                        label="AU client register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Register count"
                          size="large"
                          maxLength={20}
                        />
                      </Form.Item>
                      <Form.Item
                        name="au_t_ru"
                        label="AU tasker register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Register count"
                          size="large"
                          maxLength={20}
                        />
                      </Form.Item>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                      <Form.Item
                        name="sl_c_ru"
                        label="SL client register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Register count"
                          size="large"
                          maxLength={20}
                        />
                      </Form.Item>
                      <Form.Item
                        name="sl_t_ru"
                        label="SL tasker register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Register count"
                          size="large"
                          maxLength={20}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full pt-2 sm:pt-6">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <AppDownloadSubmitButton
                        form={form}
                        isEditButtonEnable={false}
                        type={"ADD"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default AddAppDownload;
