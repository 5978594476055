import React from "react";
import { Button, Col, Divider, Drawer, Form, Row } from "antd";
import { MdClose } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { decryptSecureData } from "../../services/EncryptionService";

const ViewAdmin = ({ open, onClose, selectedDrawerData }) => {
  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] admin-page">
        <Form layout="vertical" className="w-full view-form">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between">
              <div className="text-[18px] font-medium text-primaryLight pb-3">
                Admin Details
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row>
              <Col xs={24} sm={8} lg={8}>
                <Form.Item label="First Name">
                  <p className="text-[16px] font-medium break-words">
                    {decryptSecureData(selectedDrawerData?.first_name)}
                  </p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} lg={8}>
                <Form.Item label="Last Name">
                  <p className="text-[16px] font-medium break-words">
                    {decryptSecureData(selectedDrawerData?.last_name)}
                  </p>
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <div className="text-[16px] font-medium text-primaryLight pb-3">
              Address
            </div>
            <Row>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Street Address 1">
                  <p className="text-[16px] font-medium">
                    {decryptSecureData(selectedDrawerData?.street_address)}
                  </p>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Street Address 2">
                  <p className="text-[16px] font-medium">
                    {decryptSecureData(selectedDrawerData?.apt)}
                  </p>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item
                  label={
                    selectedDrawerData?.country_id === 2 ? "City" : "Suburb"
                  }
                >
                  <p className="text-[16px] font-medium">
                    {selectedDrawerData?.suberb}
                  </p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item
                  label={
                    selectedDrawerData?.country_id === 2 ? "District" : "State"
                  }
                >
                  <p className="text-[16px] font-medium">
                    {selectedDrawerData?.state}
                  </p>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Postal Code">
                  <p className="text-[16px] font-medium">
                    {selectedDrawerData?.postal_code}
                  </p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label="Country">
                  <p className="text-[16px] font-medium">
                    {selectedDrawerData?.country}
                  </p>
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <div className="text-[16px] font-medium text-primaryLight pb-3">
              Contact
            </div>
            <Row>
              <Col xs={24} md={12} className="pb-3">
                <Row className="flex flex-row">
                  <Col xs={5}>
                    <FiPhoneCall className="text-[30px] text-[primaryLight] mt-3" />
                  </Col>
                  <Col xs={18}>
                    <p className="text-[16px] font-normal">Telephone</p>
                    <p className="text-[16px] font-medium">
                      {decryptSecureData(selectedDrawerData?.mobile_number)}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row className="flex flex-row">
                  <Col xs={5}>
                    <MdOutlineMailOutline className="text-[35px] text-[primaryLight] mt-2" />
                  </Col>
                  <Col xs={18}>
                    <p className="text-[16px] font-normal">Mail</p>
                    <p className="text-[16px] font-medium">
                      {selectedDrawerData?.email_address}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />
            <div>
              <div className="text-[16px] font-medium text-primaryLight pb-3">
                Admin Status
              </div>
              <p className="text-[16px] font-medium">
                {selectedDrawerData?.is_active === 1 ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewAdmin;
