import { Button, Col, ConfigProvider, Drawer, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../../providers/ContentContext";
import { decryptSecureData } from "../../services/EncryptionService";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile Number",
    dataIndex: "number",
  },
];

const AddAudience = ({ open, onClose }) => {
  let { promoSmsUserList, selectedPromoList, setSelectedPromoList } =
    useContentContext();

  const [tableUserList, setTableUserList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIdArray, setSelectedIdArray] = useState([]);

  useEffect(() => {
    setData(promoSmsUserList);
  }, [promoSmsUserList]);

  const setData = (dataList) => {
    if (dataList?.length !== 0) {
      var tableData = [];
      dataList?.forEach((list, x) => {
        let data = {
          key: x,
          name: decryptSecureData(list?.full_name),
          number: decryptSecureData(list?.mobile_number),
          user_id: list?.user_id,
        };
        tableData.push(data);
        setTableUserList(tableData);
      });
    } else {
    }
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedIdArray(selectedRows.map((row) => row));
  };

  const handleSaveAudianceList = () => {
    const combinedArray = [...selectedPromoList, ...selectedIdArray];

    const uniqueData = Array.from(
      new Set(combinedArray.map((item) => item.user_id))
    ).map((user_id) => {
      return combinedArray.find((item) => item.user_id === user_id);
    });

    setSelectedPromoList(uniqueData);
    onClose();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px]">
        <div className="relative bg-secondaryWhite p-7">
          <div className="w-full flex flex-row justify-between pb-5">
            {/* <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Client..."
              // onChange={(e) => {
              //   setSearchText(e.target.value);
              //   search(e.target.value);
              // }}
              // value={searchText}
              maxLength={50}
            /> */}

            <Row className="flex flex-row gap-2 w-full justify-end">
              <Col span={6}>
                <Button
                  size="large"
                  type="primary"
                  className="w-full"
                  onClick={() => {
                    handleSaveAudianceList();
                  }}
                >
                  SAVE
                </Button>
              </Col>

              <Col span={6}>
                <Button
                  className="w-full"
                  onClick={() => {
                    onClose();
                  }}
                  size="large"
                >
                  CANCEL
                </Button>
              </Col>
            </Row>
          </div>

          <ConfigProvider
            theme={{
              components: {
                Table: {
                  rowSelectedBg: "rgb(214, 211, 213)",
                  rowSelectedHoverBg: "rgb(214, 211, 213)",
                },
              },
            }}
          >
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={tableUserList}
            />
          </ConfigProvider>
        </div>
      </div>
    </Drawer>
  );
};

export default AddAudience;
