import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, url } from "./header";

const userId = getLocalStoragedata("userId");

//tradie
export async function getTradieList(
  type,
  status,
  country_id,
  state_id,
  suburb_id,
  from,
  to
) {
  const data = {
    user_id: userId,
    date_type: type,
    status: status,
    country_id: country_id,
    state_id: state_id,
    suburb_id: suburb_id,
    from_date: from,
    to_date: to,
  };

  return await axios.post(url + "verification/list", data, config);
}

export async function getTradieProfileDetails(data) {
  return await axios.post(url + "trady/profile", data, config);
}

export async function getAllRejectReasonList() {
  const data = {
    user_id: userId,
    status: 1,
  };
  return await axios.post(url + "verification/reject/reason", data, config);
}

export async function tradieVerificationChange(data) {
  return await axios.post(url + "verification/admin", data, config);
}
