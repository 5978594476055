import React, { useState } from "react";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  Typography,
  Upload,
} from "antd";
import { IoMdCloudUpload } from "react-icons/io";
import { MdAdd, MdClose } from "react-icons/md";
import TextArea from "antd/es/input/TextArea";
import AddContent from "./AddContent";
import { editBlog } from "../../helpers/apiHelpers/cmsHelpers";
import BlogSubmitButton from "../submitButton/BlogSubmitButton";
import _ from "lodash";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e?.fileList;
};

const EditBlog = ({
  onClose,
  open,
  reloadTableData,
  blogData,
  setSearchText,
}) => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");
  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isEditButtonEnable, setIsEditButonEnable] = useState(true);
  const [isContentUpdate, setIsContentUpdate] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [fileList, setFileList] = useState(
    blogData?.image === null
      ? []
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            originFileObj: blogData?.image,
            url: blogData?.image,
          },
        ]
  );

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isOpenAddContentDrawer, setIsOpenAddContentDrawer] = useState(false);
  const [blogContent, setBlogContent] = useState(blogData?.content);

  const initialPrimaryImage =
    blogData?.image === null
      ? []
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            originFileObj: blogData?.image,
            url: blogData?.image,
          },
        ];

  const OpenAddContentDrawer = () => {
    setIsOpenAddContentDrawer(!isOpenAddContentDrawer);
  };
  const CloseAddContentDrawer = () => {
    setIsOpenAddContentDrawer(!isOpenAddContentDrawer);
  };

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  const handleCancel = () => setPreviewOpen(false); //for primary image
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  }; //for primary image
  const handleChange = ({ fileList: newFileList }) => {
    setIsEditButonEnable(false);
    setFileList(newFileList);
  }; // for primary image

  const formInitialState = {
    title: blogData?.title,
    description: blogData?.description,
    switch: blogData?.is_active === 1 ? true : false,
  };

  const onFieldsChange = () => {
    let formState = form.getFieldsValue();

    if (
      formInitialState.title !== formState.title ||
      formInitialState.description !== formState.description ||
      !_.isEqual(formState.upload, initialPrimaryImage) ||
      formState.switch !== formInitialState.switch
    ) {
      setIsEditButonEnable(false);
    } else {
      setIsEditButonEnable(true);
    }
  };

  const uploadButton = (
    <div className="flex flex-col p-3 items-center text-primaryLightDisable italic text-[14px] font-normal">
      <p>Drop files here to upload</p>
      <p>OR</p>
      <IoMdCloudUpload className="text-[66px]" />
      <p className="underline">Click Here to Upload</p>
    </div>
  );

  const onFinish = async (e) => {
    setSearchText();

    const data = new FormData();
    data.append("title", e.title);
    data.append("description", e.description);
    data.append(
      "extension",
      fileList.length === 1 ? fileList[0]?.name.split(".")[1] : null
    );
    data.append("blog_image", fileList[0] ? fileList[0]?.originFileObj : null);
    data.append("updated_by", userId);
    data.append("status", e.switch === true ? 1 : 0);
    data.append("content", blogContent);
    data.append("blog_id", blogData.id);

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);

      await editBlog(data)
        .then((response) => {
          if (response.data.success) {
            openNotification("success", response.data.message);
          } else {
            openNotification("error", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      setIsUploading(false);
      reloadTableData(2);
      onClose();
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}
            fields={[{ name: ["upload"], value: fileList }]}
            initialValues={{
              title: blogData?.title,
              description: blogData?.description,
              switch: blogData?.is_active === 1 ? true : false,
            }}
            onFieldsChange={onFieldsChange}
          >
            <div className="relative bg-secondaryWhite p-7">
              <div className="flex flex-row justify-between w-full pb-5">
                <div className="text-[16px] font-medium text-primaryLight">
                  Edit Blog
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    // setFileList([]);
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Row className="flex flex-col" gutter={8}>
                <Col xs={24} md={23}>
                  <Form.Item
                    name="title"
                    className="text-[16px]"
                    label="Title"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="Title"
                      className="!bg-secondaryWhite"
                      size="large"
                      minLength={3}
                      maxLength={50}
                    />
                  </Form.Item>

                  {errormsg === true ? (
                    <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px]">
                      Title already exist
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={24} md={23}>
                  <Form.Item
                    name="description"
                    className="text-[16px]"
                    label="Description (Max 30 Words)"
                    rules={[{ required: true }]}
                  >
                    <TextArea
                      placeholder="Description"
                      className="!bg-secondaryWhite"
                      size="large"
                      minLength={3}
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={10}>
                  <Form.Item
                    name="upload"
                    label="Image"
                    rules={[{ required: true }]}
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      customRequest={({ onSuccess }) => onSuccess("ok")}
                      listType="picture-card"
                      className="md:col-span-5"
                      onPreview={handlePreview}
                      onChange={handleChange}
                      fileList={fileList}
                      accept=".png, .jpg, .jpeg"
                    >
                      {fileList?.length === 1 ? null : uploadButton}
                    </Upload>

                    <Modal
                      className="z-[100000]"
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <Row>
                <Col xs={24} sm={12}>
                  <Typography className="text-[16px]">Blog contents</Typography>
                  <Button
                    size="large"
                    className="flex flex-row mt-2 font-medium"
                    icon={<MdAdd className="text-2xl font-bold" />}
                    onClick={OpenAddContentDrawer}
                  >
                    <p className="text-center">ADD CONTENT</p>
                  </Button>
                </Col>
              </Row>
              {isOpenAddContentDrawer ? (
                <AddContent
                  onClose={CloseAddContentDrawer}
                  open={isOpenAddContentDrawer}
                  setContent={blogContent}
                  getContent={setBlogContent}
                  setIsContentUpdate={setIsContentUpdate}
                />
              ) : (
                <></>
              )}
              {/* status */}
              <Divider />
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col text-base font-normal">
                    Blog Status
                  </div>

                  <div className="flex flex-row items-baseline mt-2 text-center">
                    <span
                      className={`${
                        isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                      }`}
                    >
                      Inactive
                    </span>
                    <Form.Item name="switch" valuePropName="checked">
                      <Switch className="mx-2" onChange={handleSwitchChange} />
                    </Form.Item>
                    <span
                      className={`${
                        isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                      }`}
                    >
                      Active
                    </span>
                  </div>
                </div>
              </div>

              {/* button */}
              <div className="w-full pt-2">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <BlogSubmitButton
                        form={form}
                        isUploading={isUploading}
                        isEditButtonEnable={isEditButtonEnable}
                        type={"SAVE"}
                        isContentUpdate={isContentUpdate}
                        blogContent={blogContent}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="w-full font-medium "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default EditBlog;
