import React, { useState } from "react";
import { Button, Form, Modal, Radio } from "antd";
import { MdClose } from "react-icons/md";
import TextForm from "./inputFieldForms/TextForm";
import DropdownForm from "./inputFieldForms/DropdownForm";
import DateTimeForm from "./inputFieldForms/DateTimeForm";
import { useContentContext } from "../../providers/ContentContext";

const AddInputField = ({ open, onCancel, pageTitleId }) => {
  let { pageTitleCount, setPageTitleCount } = useContentContext();
  const [value, setValue] = useState(1);
  const formType = "add";
  const [form] = Form.useForm();

  const radioButtonChange = (e) => {
    setValue(e.target.value);
    form.resetFields();
  };

  const onFinish = async (e) => {
    const newQuestionData = {
      title: e.title,
      input_type: e.inputType,
      require: e.require === true ? 1 : 0,
      hint: e.hint,
      answer: e?.dropdown ? e?.dropdown : [],
      field_type: value,
      status: 1,
    };

    const updatedQuestionCount = pageTitleCount.map((pageTitle) => {
      if (pageTitle.page_title_id === pageTitleId) {
        return {
          ...pageTitle,
          question_data: [...pageTitle?.question_data, newQuestionData],
        };
      }
      return pageTitle;
    });

    setPageTitleCount(updatedQuestionCount);
    onCancel();
  };

  return (
    <Modal placement="right" open={open} onCancel={onCancel} width={650}>
      <div>
        <Form
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          initialValues={{ require: false, fieldStatus: false }}
          form={form}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[18px] font-medium text-primaryLight pb-3">
                Add Question
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onCancel();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div className="text-[16px] font-normal  pb-3">Field Type</div>
            <Radio.Group
              onChange={radioButtonChange}
              value={value}
              className="job-type-question"
            >
              <Radio value={1}>Text</Radio>
              <Radio value={2}>Dropdown</Radio>
              <Radio value={3}>Date & Time</Radio>
            </Radio.Group>

            {value === 1 ? (
              <TextForm
                pageTitleId={pageTitleId}
                formType={formType}
                onCancel={onCancel}
                fieldStatus={false}
              />
            ) : value === 2 ? (
              <DropdownForm
                pageTitleId={pageTitleId}
                formType={formType}
                onCancel={onCancel}
                fieldStatus={false}
              />
            ) : value === 3 ? (
              <DateTimeForm
                pageTitleId={pageTitleId}
                formType={formType}
                onCancel={onCancel}
                fieldStatus={false}
              />
            ) : (
              <></>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddInputField;
