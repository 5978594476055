import React, { useState } from "react";
import { Menu } from "antd";
import { AppLogo, CollapsedLogo } from "../assets";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { BiSolidDashboard } from "react-icons/bi";
import { FaDatabase, FaUser } from "react-icons/fa";
import { BsBriefcaseFill, BsClipboardDataFill } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { MdSupportAgent } from "react-icons/md";
import { MdVerified } from "react-icons/md";
import { HiSpeakerWave } from "react-icons/hi2";
import { TbReportSearch } from "react-icons/tb";
import { BiSolidMessage } from "react-icons/bi";

const Sidebar = ({ collapsed }) => {
  const permission = getLocalStoragedata("permission");
  // const permission = [
  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  //   22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  //   41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  //   60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
  //   79, 80, 81, 82, 83, 84,
  // ];

  const [openKeys, setOpenKeys] = useState(["1"]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(<Link to="/dashboard">DASHBOARD</Link>, "1", <BiSolidDashboard />),

    //JOB
    permission?.includes(24) ||
    permission?.includes(28) ||
    permission?.includes(32)
      ? getItem("JOB", "sub1", <BsBriefcaseFill />, [
          permission?.includes(24)
            ? getItem(<Link to="/category">Category</Link>, "2", <GoDotFill />)
            : "",

          permission?.includes(28)
            ? getItem(<Link to="/job-type">Job Type</Link>, "4", <GoDotFill />)
            : "",
          permission?.includes(32)
            ? getItem(
                <Link to="/jobs-overview">Jobs Overview</Link>,
                "3",
                <GoDotFill />
              )
            : "",
        ])
      : "",

    //USERS
    permission?.includes(8) ||
    permission?.includes(56) ||
    permission?.includes(60)
      ? getItem("USERS", "sub2", <FaUser />, [
          permission?.includes(8)
            ? getItem(<Link to="/client">Client</Link>, "5", <GoDotFill />)
            : "",
          permission?.includes(56)
            ? getItem(<Link to="/tasker">Worker</Link>, "6", <GoDotFill />)
            : "",
          permission?.includes(60)
            ? getItem(<Link to="/admin">Admin</Link>, "7", <GoDotFill />)
            : "",
        ])
      : "",

    //VERI-CENTER
    permission?.includes(48)
      ? getItem(
          <Link to="/verfication-center">VERI-CENTER</Link>,
          "8",
          <MdVerified />
        )
      : "",

    //MASTER DATA
    // permission?.includes(12) ||
    // permission?.includes(16) ||
    // permission?.includes(20) ||
    // permission?.includes(36) ||
    permission?.includes(4)
      ? getItem("MASTER DATA", "sub3", <FaDatabase />, [
          // permission?.includes(12)
          //   ? getItem(<Link to="/country">Country</Link>, "9", <GoDotFill />)
          //   : "",

          // permission?.includes(16)
          //   ? getItem(<Link to="/states">States</Link>, "10", <GoDotFill />)
          //   : "",

          // permission?.includes(20)
          //   ? getItem(<Link to="/suburb">Suburbs</Link>, "11", <GoDotFill />)
          //   : "",

          // permission?.includes(36)
          //   ? getItem(<Link to="/units">Units</Link>, "12", <GoDotFill />)
          //   : "",

          permission?.includes(4)
            ? getItem(
                <Link to="/admin-roles">Admin Roles</Link>,
                "13",
                <GoDotFill />
              )
            : "",
        ])
      : "",

    //CMS
    permission?.includes(64) || permission?.includes(68)
      ? getItem("CMS", "sub6", <HiSpeakerWave />, [
          permission?.includes(64)
            ? getItem(<Link to="/blog">Blog</Link>, "19", <GoDotFill />)
            : "",
          permission?.includes(68)
            ? getItem(
                <Link to="/promo-sms">Promo SMS</Link>,
                "20",
                <GoDotFill />
              )
            : "",
        ])
      : "",

    //NOTIFICATION
    getItem("NOTIFICATION", "sub9", <BiSolidMessage />, [
      getItem(
        <Link to="/topic-notification">Topic</Link>,
        "30",
        <GoDotFill />
      ),
      getItem(
        <Link to="/tasker-notification">Worker</Link>,
        "26",
        <GoDotFill />
      ),
      getItem(
        <Link to="/client-notification">Client</Link>,
        "27",
        <GoDotFill />
      ),

    
    ]),

     //EMAIL NOTIFICATION
     getItem("EMAIL NOTIFICATION", "sub10", <BiSolidMessage />, [
      getItem(
        <Link to="/tasker-email-notification">Worker</Link>,
        "28",
        <GoDotFill />
      ),
      getItem(
        <Link to="/client-email-notification">Client</Link>,
        "29",
        <GoDotFill />
      ),
    ]),

    //REPORT
    permission?.includes(72)
      ? getItem("REPORT", "sub7", <TbReportSearch />, [
          getItem(<Link to="/job-count">Job Count</Link>, "21", <GoDotFill />),
          getItem(
            <Link to="/user-count-job-wise">User Count-Category Wise</Link>,
            "24",
            <GoDotFill />
          ),
          getItem(
            <Link to="/user-count-area-wise">User Count-Area Wise</Link>,
            "25",
            <GoDotFill />
          ),
        ])
      : "",

    //GRAPH
    permission?.includes(76)
      ? getItem("GRAPH", "sub8", <BsClipboardDataFill />, [
          getItem(
            <Link to="/app-download-graph">App Download</Link>,
            "22",
            <GoDotFill />
          ),
          getItem(
            <Link to="/app-register-graph">App Register</Link>,
            "23",
            <GoDotFill />
          ),
        ])
      : "",

    //SUPPORT
    permission?.includes(44)
      ? getItem(
          "SUPPORT",
          "sub4",
          <MdSupportAgent />,
          [
            getItem(
              <Link to="/support-client">Client</Link>,
              "14",
              <GoDotFill />
            ),

            getItem(
              <Link to="/support-tasker">Tasker</Link>,
              "15",
              <GoDotFill />
            ),
          ],
          "menu-item-settings"
        )
      : "",

    //SETTINGS
    permission?.includes(80) ||
    permission?.includes(40) ||
    permission?.includes(52) ||
    permission?.includes(84)
      ? getItem(
          "SETTINGS",
          "sub5",
          <AiFillSetting />,
          [
            permission?.includes(80)
              ? getItem(
                  <Link to="/reset-password">Reset Password</Link>,
                  "16",
                  <GoDotFill />
                )
              : "",
            // getItem(
            //   <Link to="/package-configuration">Package Configuration</Link>,
            //   "23",
            //   <GoDotFill />
            // ),
            permission?.includes(40)
              ? getItem(
                  <Link to="/configuration">Configuration</Link>,
                  "17",
                  <GoDotFill />
                )
              : "",
            permission?.includes(52)
              ? getItem(
                  <Link to="/terms">Terms & Con.</Link>,
                  "18",
                  <GoDotFill />
                )
              : "",
            permission?.includes(84)
              ? getItem(
                  <Link to="/app-download">App Download</Link>,
                  "22",
                  <GoDotFill />
                )
              : "",
          ],
          "menu-item-settings"
        )
      : "",
  ];

  const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6"];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full p-5 bg-secondaryWhite sticky top-0 z-10">
        {collapsed ? (
          <img src={CollapsedLogo} alt="logo" width={40} />
        ) : (
          <img src={AppLogo} alt="logo" width={190} />
        )}
      </div>
      <Menu
        id="toggleButton"
        className="text-base text-[16px] font-semibold bg-secondaryWhite"
        mode="inline"
        items={items}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </>
  );
};

export default Sidebar;
