import { Button, Col, DatePicker, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import AddAppDownload from "../components/appDownload/AddAppDownload";
import { MdAdd } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import EditAppDownload from "../components/appDownload/EditAppDownload";
import ViewAppDownload from "../components/appDownload/ViewAppDownload";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { getAllAppData } from "../helpers/apiHelpers/appDownloadHelpers";
import moment from "moment";
const { RangePicker } = DatePicker;

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "0",
    sorter: (a, b) => a.date?.localeCompare(b.date),
    defaultSortOrder: "ascend",
  },
  {
    title: "AU ICD",
    dataIndex: "au_ios_client",
    key: "1",
    sorter: (a, b) => a.au_ios_client - b.au_ios_client,
    defaultSortOrder: "ascend",
  },
  {
    title: "AU ITD",
    dataIndex: "au_ios_tasker",
    key: "2",
    sorter: (a, b) => a.au_ios_tasker - b.au_ios_tasker,
    defaultSortOrder: "ascend",
  },
  {
    title: "AU ACD",
    dataIndex: "au_android_client",
    key: "3",
    sorter: (a, b) => a.au_android_client - b.au_android_client,
    defaultSortOrder: "ascend",
  },
  {
    title: "AU ATD",
    dataIndex: "au_android_tasker",
    key: "4",
    sorter: (a, b) => a.au_android_tasker - b.au_android_tasker,
    defaultSortOrder: "ascend",
  },
  {
    title: "SL ICD",
    dataIndex: "sl_ios_client",
    key: "5",
    sorter: (a, b) => a.sl_ios_client - b.sl_ios_client,
    defaultSortOrder: "ascend",
  },
  {
    title: "SL ITD",
    dataIndex: "sl_ios_tasker",
    key: "6",
    sorter: (a, b) => a.sl_ios_tasker - b.sl_ios_tasker,
    defaultSortOrder: "ascend",
  },
  {
    title: "SL ACD",
    dataIndex: "sl_android_client",
    key: "7",
    sorter: (a, b) => a.sl_android_client - b.sl_android_client,
    defaultSortOrder: "ascend",
  },
  {
    title: "SL ATD",
    dataIndex: "sl_android_tasker",
    key: "8",
    sorter: (a, b) => a.sl_android_tasker - b.sl_android_tasker,
    defaultSortOrder: "ascend",
  },
  {
    title: "AU CRU",
    dataIndex: "au_client_users",
    key: "9",
    sorter: (a, b) => a.au_client_users - b.au_client_users,
    defaultSortOrder: "ascend",
  },
  {
    title: "AU TRU",
    dataIndex: "au_tasker_users",
    key: "10",
    sorter: (a, b) => a.au_tasker_users - b.au_tasker_users,
    defaultSortOrder: "ascend",
  },
  {
    title: "SL CRU",
    dataIndex: "sl_client_users",
    key: "11",
    sorter: (a, b) => a.sl_client_users - b.sl_client_users,
    defaultSortOrder: "ascend",
  },
  {
    title: "SL TRU",
    dataIndex: "sl_tasker_users",
    key: "12",
    sorter: (a, b) => a.sl_tasker_users - b.sl_tasker_users,
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "13",
  },
];

const AppDownload = () => {
  let { openNotification, handleError } = useContentContext();
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const defaultStartDate = moment().startOf("month").format("YYYY-MM-DD");
  const defaultEndDate = moment().format("YYYY-MM-DD");

  const [selectedStartDate, setSelectedStartDate] = useState(defaultStartDate);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);

  const defaultRange = [moment("2024-01-01"), moment("2024-01-10")];

  useEffect(() => {
    handleDefaultDateRangeTable();
  }, []);

  const handleDefaultDateRangeTable = () => {
    fetchTableData([defaultStartDate, defaultEndDate]);
  };
  const handleSelectedDateRangeTable = () => {
    fetchTableData([selectedStartDate, selectedEndDate]);
  };

  const handleDateRange = (date, dateString) => {
    setSelectedStartDate(dateString[0] !== "" && dateString[0]);
    setSelectedEndDate(dateString[1] !== "" && dateString[1]);
    dateString[0] !== "" && dateString[1] !== "" && fetchTableData(dateString);
  };

  const handleAddDrawerOpen = () => {
    setIsAddDrawerOpen(true);
  };
  const handleAddDrawerClose = () => {
    setIsAddDrawerOpen(false);
  };
  const handleEditDrawerOpen = (dataSet) => {
    setSelectedData(dataSet);
    setIsEditDrawerOpen(true);
  };
  const handleEditDrawerClose = () => {
    setIsEditDrawerOpen(false);
  };
  const handleViewDrawerOpen = (dataSet) => {
    setSelectedData(dataSet);
    setIsViewDrawerOpen(true);
  };
  const handleViewDrawerClose = () => {
    setIsViewDrawerOpen(false);
  };

  const fetchTableData = async (dateString) => {
    setDataLoading(true);
    try {
      await getAllAppData({
        from_date: dateString[0],
        to_date: dateString[1],
        status: 1,
      })
        .then((response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (items) => {
    if (items?.length !== 0) {
      var tableData = [];

      items?.forEach((item, x) => {
        let data = {
          key: x,
          date: item?.day_str,
          au_ios_client: item?.au_ios_client_download,
          au_ios_tasker: item?.au_ios_tasker_download,
          au_android_client: item?.au_android_client_download,
          au_android_tasker: item?.au_android_tasker_download,
          sl_ios_client: item?.sl_ios_client_download,
          sl_ios_tasker: item?.sl_ios_tasker_download,
          sl_android_client: item?.sl_android_client_download,
          sl_android_tasker: item?.sl_android_tasker_download,
          au_client_users: item?.au_client_register,
          au_tasker_users: item?.au_tasker_register,
          sl_client_users: item?.sl_client_register,
          sl_tasker_users: item?.sl_tasker_register,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <BiEdit
                className="cursor-pointer"
                onClick={() => {
                  handleEditDrawerOpen(item);
                }}
              />

              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewDrawerOpen(item);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        APP Download
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex justify-between pt-0 pb-6">
          <Col>
            <RangePicker
              format="YYYY-MM-DD"
              className="flex"
              size="large"
              onChange={handleDateRange}
              //value={defaultRange}
            />
          </Col>
          <Col>
            <Button
              className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
              type="primary"
              size="large"
              onClick={() => {
                handleAddDrawerOpen();
              }}
              icon={<MdAdd className="text-2xl font-bold" />}
            >
              <p className=" text-secondaryWhite text-[18px] font-semibold">
                ADD COUNT
              </p>
            </Button>
          </Col>
        </Row>

        <div className="relative bg-secondaryWhite p-7">
          <div className="flex w-full flex-col sm:gap-8 sm:flex-row ">
            <div className="flex flex-col">
              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU ICD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU IOS client download"}
                </p>
              </div>

              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU ITD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU IOS tasker download"}
                </p>
              </div>

              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU ACD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU Android client download"}
                </p>
              </div>
              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU ATD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU Android tasker download"}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL ICD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL IOS client download"}
                </p>
              </div>

              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL ITD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL IOS tasker download"}
                </p>
              </div>

              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL ACD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL Android client download"}
                </p>
              </div>
              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL ATD ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL Android tasker download"}
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU CRU ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU client register user"}
                </p>
              </div>

              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU TRU ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"AU tasker register user"}
                </p>
              </div>

              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL CRU ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL client register user"}
                </p>
              </div>
              <div className="flex flex-row">
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL TRU ="}
                </p>
                <p className="text-[12px] lg:text-[14px] font-medium break-words">
                  {"SL tasker register user"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="table-status-button">
          <Table
            dataSource={tableData}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>

        {isAddDrawerOpen && (
          <AddAppDownload
            open={isAddDrawerOpen}
            onClose={handleAddDrawerClose}
            reloadTable={handleSelectedDateRangeTable}
          />
        )}
        {isEditDrawerOpen && (
          <EditAppDownload
            open={isEditDrawerOpen}
            onClose={handleEditDrawerClose}
            dataSet={selectedData}
            reloadTable={handleSelectedDateRangeTable}
          />
        )}
        {isViewDrawerOpen && (
          <ViewAppDownload
            open={isViewDrawerOpen}
            onClose={handleViewDrawerClose}
            dataSet={selectedData}
          />
        )}
      </Content>
    </>
  );
};

export default AppDownload;
