import React from "react";
import { Pagination } from "antd";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const CustomPaginationCom = ({ current, total, onChange }) => {
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row items-center">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row items-center">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <Pagination
      current={current}
      total={total}
      onChange={onChange}
      itemRender={itemRender}
    />
  );
};

export default CustomPaginationCom;
