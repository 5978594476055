import axios from "axios";
import { authConfig, url } from "./header";

export async function userLogin(data) {
  return await axios.post(url + "auth/admin/login", data, authConfig);
}

export async function allAuthUserData(data, config) {
  return await axios.post(url + "auth/user", data, config);
}

export async function allAuthUserPermission(data, config) {
  return await axios.post(url + "permission/has", data, config);
}

export async function userLogOut(data) {
  return await axios.post(url + "logout", data, authConfig);
}
