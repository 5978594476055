import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Divider, Row, Col, Spin } from "antd";
import { MdClose } from "react-icons/md";
import { IoMailOpenOutline } from "react-icons/io5";
import { useContentContext } from "../../providers/ContentContext";
import { getAllSupportCustomerTradieProfileDetails } from "../../helpers";
import { decryptSecureData } from "../../services/EncryptionService";

const ViewSupportCustomer = ({ open, onClose, customer }) => {
  let { openNotification, handleError } = useContentContext();

  const [customerProfileData, setCustomerProfileData] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setDataLoading(true);
    try {
      await getAllSupportCustomerTradieProfileDetails({
        support_id: customer?.id,
      })
        .then((response) => {
          if (response?.data.success) {
            setCustomerProfileData(response?.data.output[0]);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setDataLoading(false);
  };
  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px]">
        {dataLoading ? (
          <div className="flex justify-center pt-[350px]">
            <Spin className="!text-[primaryLight]" />
          </div>
        ) : (
          <Form layout="vertical" className="w-full view-form">
            <div className="relative bg-secondaryWhite p-7 ">
              <div className="w-full flex flex-row  justify-between">
                <p className="text-[16px] font-medium pb-3">Client Details</p>

                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Form.Item label="Client Name">
                <p className="text-[16px] font-medium">
                  {decryptSecureData(customerProfileData?.user_name)}
                </p>
              </Form.Item>
              <Divider />

              <p className="text-[16px] font-semibold pb-3">Address</p>

              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Address">
                    <p className="text-[16px] font-medium">
                      {customerProfileData?.address
                        ? decryptSecureData(customerProfileData?.address.trim())
                        : "-"}
                    </p>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Postal Code">
                    <p className="text-[16px] font-medium">
                      {customerProfileData?.postal_code
                        ? customerProfileData?.postal_code
                        : "-"}
                    </p>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Suburbs">
                    <p className="text-[16px] font-medium">
                      {customerProfileData?.suberb
                        ? customerProfileData?.suberb
                        : "-"}
                    </p>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="State">
                    <p className="text-[16px] font-medium">
                      {customerProfileData?.state
                        ? customerProfileData?.state
                        : "-"}
                    </p>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Country">
                    <p className="text-[16px] font-medium">
                      {customerProfileData?.suberb
                        ? customerProfileData?.suberb
                        : "-"}
                    </p>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />

              <p className="text-[16px] font-semibold pb-3">Contact</p>
              <Row className="flex flex-row">
                <Col xs={5}>
                  <IoMailOpenOutline className="text-[40px] text-[primaryLight] mt-3" />
                </Col>
                <Col xs={18}>
                  <p className="text-[16px] font-normal">Email Address</p>
                  <p className="text-[16px] font-medium">
                    {decryptSecureData(customerProfileData?.email_address)}
                  </p>
                </Col>
              </Row>
              <Divider />

              <p className="text-[16px] font-semibold pb-3"> Support</p>
              <Form.Item label="Problem">
                <p className="text-[16px] font-medium">
                  {customerProfileData?.topic}
                </p>
              </Form.Item>
              <Form.Item label="Description">
                <p className="text-[16px] font-medium">
                  {customerProfileData?.description}
                </p>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
    </Drawer>
  );
};

export default ViewSupportCustomer;
