import React from "react";
import { Button, Drawer, Form, Input, Row, Col, InputNumber } from "antd";
import { MdClose } from "react-icons/md";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { appCountUpdate } from "../../helpers/apiHelpers/dashboard";
import { useContentContext } from "../../providers/ContentContext";

const AppSummeryUpdate = ({ onClose, open, values }) => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");
  const [form] = Form.useForm();

  const onFinish = async (e) => {
    const data = {
      user_id: userId,
      client_ios: e.clientIOS,
      client_android: e.clientAndroid,
      tasker_ios: e.taskerIOS,
      tasker_android: e.taskerAndroid,
    };

    await appCountUpdate(data)
      .then((response) => {
        if (response.data.success) {
          onClose();
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"default"}
      className="drawer-form"
    >
      <div className="w-full md:w-[400px]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          initialValues={{
            clientAndroid: values.client_android,
            taskerAndroid: values.tasker_android,
            clientIOS: values.client_ios,
            taskerIOS: values.tasker_ios,
          }}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-lg font-bold text-primaryLight">
                Update App Summery
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row className="flex flex-col mt-8">
              <div className="text-base font-bold text-primaryLight">
                ANDROID APP
              </div>
            </Row>

            <Row className="flex flex-col mt-2">
              <Col>
                <Form.Item
                  name="clientAndroid"
                  className="text-[16px] w-full"
                  label="Client App Users"
                  rules={[
                    {
                      required: true,
                      message: "Client App User count required",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Client App Users"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="taskerAndroid"
                  className="text-[16px]"
                  label="Tasker App Users"
                  rules={[
                    {
                      required: true,
                      message: "Tasker App User count required",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Tasker App Users"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="flex flex-col mt-8">
              <div className="text-base font-bold text-primaryLight">
                IOS APP
              </div>
            </Row>
            <Row className="flex flex-col mt-2">
              <Col>
                <Form.Item
                  name="clientIOS"
                  className="text-[16px]"
                  label="Client App Users"
                  rules={[
                    {
                      required: true,
                      message: "Client App User count required",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Client App Users"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="taskerIOS"
                  className="text-[16px]"
                  label="Tasker App Users"
                  rules={[
                    {
                      required: true,
                      message: "Tasker App User count required",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Tasker App Users"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="w-full pt-8">
              <Row className="flex flex-col justify-between" gutter={8}>
                <Col>
                  <Form.Item>
                    <FormSubmitButton
                      form={form}
                      isEditButtonEnable={false}
                      type={"Update"}
                    />
                  </Form.Item>
                </Col>
                <Col className="outline-button">
                  <Button
                    size="large"
                    className="font-medium w-full "
                    onClick={onClose}
                  >
                    <p className="text-center">CANCEL</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default AppSummeryUpdate;
