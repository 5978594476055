import axios from "axios";
import { config, formconfig, url } from "./header";

//view app data
export async function getAllAppData(data) {
  return await axios.post(url + "graph/form/view", data, config);
}

//add app data
export async function addAppData(data) {
  return await axios.post(url + "graph/form/fill", data, config);
}

//edit app data
export async function editAppData(data) {
  return await axios.post(url + "graph/form/fill", data, config);
}

//delete app data
export async function deleteAppData(data) {
  return await axios.post(url + "graph/form/delete", data, config);
}
