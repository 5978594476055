import axios from "axios";
import { config, url } from "./header";

export async function resetEmail(data) {
  return await axios.post(url + "auth/admin/otp", data, config);
}

export async function getResedOTP(data) {
  return await axios.post(url + "auth/admin/otp", data, config);
}

export async function verifyOTP(data) {
  return await axios.post(url + "admin/otp/verify", data, config);
}

export async function resetLoginPassword(data) {
  return await axios.post(url + "admin/password/reset", data, config);
}
