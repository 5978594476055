import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  Row,
  Col,
} from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { PiWarningCircleThin } from "react-icons/pi";
import {
  checkSuburbName,
  deleteSuburb,
  editSuburb,
  getAllCountries,
  getAllStates,
} from "../../helpers";
import _ from "lodash";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import SuburbDelete from "../deleteComponents/SuburbDelete";
import { commonFieldValidateField } from "../../commonFun";

const EditSuburb = ({
  open,
  onClose,
  suburb,
  reloadSuburbData,
  setSearchText,
}) => {
  const userId = getLocalStoragedata("userId");
  let { openNotification, handleError } = useContentContext();
  const [form] = Form.useForm();
  const permission = getLocalStoragedata("permission");

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isEditButtonEnable, setIsEditButonEnavle] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [stateSelectorData, setStateSelectorData] = useState([]);
  const [stateSelectorValue, setStateSelectorValue] = useState();
  const [existCountryId, setExistCountryId] = useState();
  const [isSwitchOn, setIsSwitchOn] = useState(
    suburb?.is_active === 1 ? true : false
  );

  const formInitialState = {
    country: existCountryId,
    suburb: suburb?.suberb,
    switch: suburb?.is_active === 1 ? true : false,
    postalcode: suburb?.postal_code,
    state: suburb?.state_id,
  };

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            //check country active or inactive
            const existCountry = response?.data.output.find(
              (country) => country.id === suburb?.country_id
            );
            if (existCountry) {
              setExistCountryId(suburb?.country_id);
            } else {
              setExistCountryId(undefined);
            }
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      await getAllStates({ country_id: suburb?.country_id, status: 1 })
        .then((response) => {
          if (response?.data.success) {
            //check state active or inactive
            const existState = response?.data.output.find(
              (state) => state.id === suburb?.state_id
            );
            if (existState) {
              setStateSelectorValue(suburb?.state_id);
            } else {
              setStateSelectorValue(undefined);
            }
            setStateData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      setIsLoading(false);
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchState = async (e) => {
    setStateSelectorValue();
    try {
      await getAllStates({ country_id: e, status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setStateData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country.id,
        label: country.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const setStateData = (states) => {
    var object = [];
    states?.forEach((state) => {
      let data = {
        value: state.id,
        label: state.state,
      };
      object.push(data);
    });
    setStateSelectorData(object);
  };

  const onFinish = async (e) => {
    setSearchText();

    const checkData = {
      state_id: e.state,
      suberb: e.suburb,
      suberb_id: suburb?.id,
    };

    const response = await checkSuburbName(checkData)
      .then((response) => {
        if (response.data.success) {
          if (suburb?.suberb === e.suburb && suburb?.state_id === e.state) {
            return 0;
          } else {
            return response.data.output.has_suberb;
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });

    if (response === 0) {
      const data = {
        country_id: e.country,
        suberb_id: suburb?.id,
        state_id: e.state,
        suberb: e.suburb,
        status: e.switch,
        postal_code: e.postalcode,
        updated_by: userId,
      };

      if (isUploading) {
        openNotification("warning", "Please wait Upload on progress");
      } else {
        setIsUploading(true);
        editSuburb(data)
          .then((response) => {
            if (response.data.success) {
              reloadSuburbData();
              openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
            onClose();
            setIsUploading(false);
          })
          .catch((error) => {
            handleError(error);
          });
      }
    } else {
      setErrormsg(true);
    }
  };

  const validatePostalCodeField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please enter only numbers"));
    }
    return Promise.resolve();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onFieldsChange = () => {
    if (_.isEqual(form.getFieldsValue(), formInitialState)) {
      setIsEditButonEnavle(true);
    } else {
      setIsEditButonEnavle(false);
    }
  };

  const deleteModalOpen = () => {
    setIsOpenDeleteModal(true);
  };
  const deleteModalClose = () => {
    setIsOpenDeleteModal(false);
  };
  const deleteCategoryPermanently = () => {
    const data = {
      suberb_id: suburb?.id,
      updated_by: userId,
    };

    deleteSuburb(data)
      .then((response) => {
        if (response.data.success) {
          reloadSuburbData();
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
        setIsOpenDeleteModal(false);
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        width="large"
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          {isLoading ? (
            <div className="flex justify-center pt-[350px]">
              <Spin className="!text-[primaryLight]" />
            </div>
          ) : (
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              className="w-full edit-form"
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
              initialValues={{
                country: existCountryId,
                suburb: suburb?.suberb,
                switch: suburb?.is_active === 1 ? true : false,
                postalcode: suburb?.postal_code,
              }}
              fields={[{ name: ["state"], value: stateSelectorValue }]}
            >
              <div className="relative bg-secondaryWhite p-7 ">
                <div className="w-full flex flex-row  justify-between">
                  <div className="text-[16px] font-medium text-primaryLight pb-4">
                    Edit Suburb
                  </div>
                  <Button
                    className="border-none"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <MdClose className="text-[20px]" />
                  </Button>
                </div>

                <Row className="flex flex-row justify-between" gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      className="text-[16px]"
                      label="Country Name"
                      rules={[
                        {
                          required: true,
                          message: "Please select the country name!",
                        },
                      ]}
                      name="country"
                    >
                      <Select
                        name="country"
                        placeholder="Country"
                        required
                        className="!bg-secondaryWhite"
                        size="large"
                        options={countrySelectorData}
                        onSelect={(e) => {
                          fetchState(e);
                          setStateSelectorValue();
                          setErrormsg(false);
                        }}
                        filterOption={filterOption}
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      className="text-[16px]"
                      label="State Name"
                      rules={[
                        {
                          required: true,
                          message: "Please select the state name!",
                        },
                      ]}
                      name="state"
                    >
                      <Select
                        name="state"
                        placeholder="State"
                        required
                        className="!bg-secondaryWhite"
                        size="large"
                        options={stateSelectorData}
                        onSelect={(e) => {
                          setStateSelectorValue(e);
                          setErrormsg(false);
                        }}
                        filterOption={filterOption}
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="flex flex-row justify-between" gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      className="text-[16px]"
                      label="Suburb Name"
                      required
                      rules={[{ validator: commonFieldValidateField }]}
                      name="suburb"
                    >
                      <Input
                        placeholder="Suburb"
                        className="!bg-secondaryWhite"
                        size="large"
                        onChange={() => {
                          setErrormsg(false);
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </Form.Item>

                    {errormsg ? (
                      <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-3">
                        Suburb name already exist
                      </p>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="postalcode"
                      className="text-[16px]"
                      label="Postal Code"
                      required
                      rules={[{ validator: validatePostalCodeField }]}
                    >
                      <Input
                        placeholder="Postal Code"
                        className="!bg-secondaryWhite"
                        size="large"
                        maxLength={6}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col font-medium text-base">
                      Suburb Status
                    </div>
                    <div className="flex flex-row mt-2 text-center items-baseline">
                      <span
                        className={`${
                          isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                        }`}
                      >
                        Inactive
                      </span>
                      <Form.Item name="switch" valuePropName="checked">
                        <Switch
                          className="mx-2"
                          onChange={handleSwitchChange}
                        />
                      </Form.Item>
                      <span
                        className={`${
                          isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                        }`}
                      >
                        Active
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                    <p className="text-[12px] text-secondaryColor">
                      Changing the suburb status will impact the available jobs
                      within the suburb
                    </p>
                  </div>
                </div>

                {/* delete */}
                {permission?.includes(19) && (
                  <>
                    <Divider />
                    <div>
                      <p className="font-medium text-base pb-5">
                        Delete Suburb
                      </p>

                      <div className="flex flex-row pb-3">
                        <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                        <p className="text-[12px] text-secondaryColor">
                          Before deleting a Suburb, make sure there are no
                          active transaction within the Suburb
                        </p>
                      </div>
                      <Button
                        size="large"
                        className="!border-primaryRed text-primaryRed hover:!text-secondaryWhite hover:!bg-primaryRed hover:!border-primaryRed"
                        onClick={deleteModalOpen}
                      >
                        DELETE
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="w-full pl-7 pb-5">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <FormSubmitButton
                        form={form}
                        errormsg={errormsg}
                        isUploading={isUploading}
                        isEditButtonEnable={isEditButtonEnable}
                        type={"SAVE"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </div>
      </Drawer>
      {isOpenDeleteModal && (
        <SuburbDelete
          open={isOpenDeleteModal}
          onCancel={deleteModalClose}
          deleteData={suburb}
          deletePermanently={deleteCategoryPermanently}
        />
      )}
    </>
  );
};

export default EditSuburb;
