import { Button, Drawer, Image, Typography } from "antd";
import { MdClose } from "react-icons/md";

const ViewContent = ({ open, onClose, blog }) => {
  return (
    <Drawer placement="right" open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] justify-center flex flex-col my-5 mx-2 px-4 border-[1px]">
        <div className="flex justify-end w-full mt-4">
          <Button
            className="border-none"
            onClick={() => {
              onClose();
            }}
          >
            <MdClose className="text-[20px]" />
          </Button>
        </div>
        <div className="flex flex-col items-center justify-center w-full gap-1">
          <h1 className="text-2xl font-semibold">{blog?.title}</h1>
          <br />
          <h3 className="text-lg font-medium">{blog?.description}</h3>
          <Image
            src={blog?.image}
            alt="No Image Found"
            className="object-cover w-full h-full mt-2"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        </div>
        <div className="flex items-center justify-center w-full">
          <Typography
            dangerouslySetInnerHTML={{ __html: blog?.content }}
          ></Typography>
        </div>
      </div>
    </Drawer>
  );
};

export default ViewContent;
