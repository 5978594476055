import axios from "axios";
import { config, url } from "./header";

export async function getAllPromoSms(data) {
  return await axios.post(url + "promotiom/sms/list", data, config);
}

export async function getAllPromoSmsDetails(data) {
  return await axios.post(url + "promotiom/sms/view", data, config);
}

export async function tableSmsStatusChange(data) {
  return await axios.post(url + "promotiom/sms/status", data, config);
}

export async function getAllPromoSmsCountries(data) {
  return await axios.post(url + "country/list", data, config);
}

export async function getAllLocationLevel1Data(data) {
  return await axios.post(url + "location/level1", data, config);
}

export async function getAllLocationLevel2Data(data) {
  return await axios.post(url + "location/level2", data, config);
}

export async function getAllCategoryData(data) {
  return await axios.post(url + "job/category/list", data, config);
}

export async function getAllJobTypeData(data) {
  return await axios.post(url + "job/type/list", data, config);
}

export async function getPromotionUserListData(data) {
  return await axios.post(url + "promotiom/tasker/list", data, config);
}

export async function addPromoSms(data) {
  return await axios.post(url + "promotiom/sms/request", data, config);
}
