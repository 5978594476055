import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Row,
  Col,
} from "antd";
import { MdClose } from "react-icons/md";
import { PiWarningCircleThin } from "react-icons/pi";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { useContentContext } from "../../providers/ContentContext";
import {
  checkCountryName,
  deleteCountry,
  editCountry,
  getAllTimeZone,
} from "../../helpers";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import _ from "lodash";
import CountryDelete from "../deleteComponents/CountryDelete";
import { commonFieldValidateField } from "../../commonFun";

const EditCountry = ({
  open,
  onClose,
  country,
  reloadCountryData,
  setSearchText,
}) => {
  let { handleError, openNotification } = useContentContext();
  const userId = getLocalStoragedata("userId");
  const permission = getLocalStoragedata("permission");

  const [form] = Form.useForm();
  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isEditButtonEnable, setIsEditButonEnable] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(
    country?.is_active === 1 ? true : false
  );
  const [timeZoneSelectorData, setTimeZoneSelectorData] = useState([]);
  const [selectedTimeZoneValue, setSelectTimeZoneVale] = useState(
    country?.timezone
  );

  const formInitialState = {
    country: country?.country,
    countryCode: country?.country_code,
    timezoneType: country?.timezone_type,
    timeZone: country?.timezone,
    switch: country?.is_active === 1 ? true : false,
    currency: country?.currency,
    currencyLabel: country?.currency_label,
    postalCodeLenght: country?.postal_code_length,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getAllTimeZone({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setTimeZoneData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setTimeZoneData = (timeZones) => {
    var timeZoneObject = [];
    timeZones?.forEach((timeZone) => {
      let data = {
        value: timeZone.timezone,
        label: timeZone.timezone,
      };
      timeZoneObject.push(data);
    });
    setTimeZoneSelectorData(timeZoneObject);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getTimeZoneValue = (e) => {
    timeZoneSelectorData.forEach((item) => {
      if (item?.value === e) {
        setSelectTimeZoneVale(item.label);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  const onFinish = (e) => {
    setSearchText();

    const data = {
      country_id: country?.id,
      country: e?.country,
      country_code: e?.countryCode,
      timezone: selectedTimeZoneValue,
      status: e?.switch,
      timezone_type: e?.timezoneType,
      updated_by: userId,
      currency: e?.currency,
      currency_label: e?.currencyLabel,
      postal_code_length: parseInt(e?.postalCodeLenght),
    };

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);
      editCountry(data)
        .then((response) => {
          if (response.data.success) {
            reloadCountryData();
            openNotification("success", response.data.message);
          } else {
            openNotification("error", response.data.message);
          }
          onClose();
          setIsUploading(false);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const onFieldsChange = (e) => {
    if (_.isEqual(form.getFieldsValue(), formInitialState)) {
      setIsEditButonEnable(true);
    } else {
      setIsEditButonEnable(false);
    }
  };

  const checkCountry = (e) => {
    const data = {
      country: e,
      country_id: country.id,
    };

    checkCountryName(data)
      .then((response) => {
        if (response.data.success) {
          if (country?.country !== e) {
            setErrormsg(response.data.output.has_country === 1 ? true : false);
          } else {
            setErrormsg(false);
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const validateCountryCodeField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the text"));
    }
    if (!/^[A-Za-z]+$/.test(value)) {
      return Promise.reject(new Error("Please enter a valid text"));
    }
    return Promise.resolve();
  };

  const validatePostalCodeField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please ente only numbers"));
    }
    return Promise.resolve();
  };

  const deleteModalOpen = () => {
    setIsOpenDeleteModal(true);
  };
  const deleteModalClose = () => {
    setIsOpenDeleteModal(false);
  };
  const deleteCategoryPermanently = () => {
    const data = {
      country_id: country.id,
      updated_by: userId,
    };
    deleteCountry(data)
      .then((response) => {
        if (response.data.success) {
          reloadCountryData();
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
        setIsOpenDeleteModal(false);
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        width="large"
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full edit-form"
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            initialValues={{
              country: country?.country,
              countryCode: country?.country_code,
              timezoneType: country?.timezone_type,
              timeZone: country?.timezone,
              switch: country?.is_active === 1 ? true : false,
              currency: country?.currency,
              currencyLabel: country?.currency_label,
              postalCodeLenght: country?.postal_code_length,
            }}
          >
            <div className="relative bg-secondaryWhite p-7 ">
              <div className="w-full flex flex-row  justify-between">
                <div className="text-[16px] font-medium text-primaryLight pb-4">
                  Edit Country
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Row className="flex flex-row justify-between" gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    className="text-[16px]"
                    label="Country Name"
                    required
                    rules={[{ validator: commonFieldValidateField }]}
                    name="country"
                  >
                    <Input
                      placeholder="Country"
                      className="!bg-secondaryWhite"
                      size="large"
                      onChange={(e) => {
                        checkCountry(e.target.value);
                      }}
                      minLength={3}
                      maxLength={50}
                    />
                  </Form.Item>
                  {errormsg === true ? (
                    <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-3">
                      Country name already exist
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="countryCode"
                    className="text-[16px]"
                    label="Country Code"
                    required
                    rules={[{ validator: validateCountryCodeField }]}
                  >
                    <Input
                      placeholder="Country Code"
                      className="!bg-secondaryWhite"
                      size="large"
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="flex flex-row justify-between" gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="timezoneType"
                    className="text-[16px]"
                    label="Time Zone Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select time zone type",
                      },
                    ]}
                  >
                    <Select
                      name="timezoneType"
                      placeholder="Time Zone Type"
                      required
                      className="!bg-secondaryWhite"
                      size="large"
                      options={[
                        {
                          value: 1,
                          label: "Country Wise",
                        },
                        {
                          value: 2,
                          label: "State Wise",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    name="timeZone"
                    className="text-[16px]"
                    label="Time Zone"
                    rules={[
                      { required: true, message: "Please select Time Zone" },
                    ]}
                  >
                    <Select
                      name="timeZone"
                      placeholder="Time Zone"
                      required
                      className="!bg-secondaryWhite"
                      size="large"
                      options={timeZoneSelectorData}
                      onSelect={(e) => {
                        getTimeZoneValue(e);
                      }}
                      filterOption={filterOption}
                      showSearch
                    />
                  </Form.Item>
                  <p className="text-[12px] font-normal">
                    Please input the correct timezone for the selected country.
                    This ensures accurate time-related functionalities within
                    the system
                  </p>
                </Col>
              </Row>

              {/* country currency */}
              <Divider />
              <Row className="flex flex-row justify-between" gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="currency"
                    className="text-[16px]"
                    label="Currency"
                    rules={[
                      { required: true, message: "Please enter currency" },
                    ]}
                  >
                    <Input
                      placeholder="Currency"
                      className="!bg-secondaryWhite"
                      size="large"
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="currencyLabel"
                    className="text-[16px]"
                    label="Currency Label"
                    rules={[
                      {
                        required: true,
                        message: "Please enter currency Label",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Currency Label"
                      className="!bg-secondaryWhite"
                      size="large"
                      maxLength={50}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* postal code length */}
              <Divider />
              <Row className="flex flex-row justify-between" gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="postalCodeLenght"
                    className="text-[16px]"
                    label="Postal Code Lenght"
                    rules={[{ validator: validatePostalCodeField }]}
                  >
                    <Input
                      placeholder="Postal Code Lenght"
                      className="!bg-secondaryWhite"
                      size="large"
                      maxLength={5}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col font-medium text-base">
                    Country Status
                  </div>
                  <div className="flex flex-row mt-2 text-center items-baseline">
                    <span
                      className={`${
                        isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                      }`}
                    >
                      Inactive
                    </span>
                    <Form.Item name="switch" valuePropName="checked">
                      <Switch className="mx-2" onChange={handleSwitchChange} />
                    </Form.Item>
                    <span
                      className={`${
                        isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                      }`}
                    >
                      Active
                    </span>
                  </div>
                </div>

                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Changing the country status will impact the states and
                    suburbs within the country
                  </p>
                </div>
              </div>

              {/* delete */}
              {permission?.includes(11) && (
                <>
                  <Divider />
                  <div>
                    <p className="font-medium text-base pb-5">Delete Country</p>

                    <div className="flex flex-row pb-3">
                      <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                      <p className="text-[12px] text-secondaryColor">
                        Before deleting a country, make sure there are no active
                        state,suburb or active transaction within the job
                        country
                      </p>
                    </div>
                    <Button
                      size="large"
                      className="!border-primaryRed text-primaryRed hover:!text-secondaryWhite hover:!bg-primaryRed hover:!border-primaryRed"
                      onClick={deleteModalOpen}
                    >
                      DELETE
                    </Button>
                  </div>
                </>
              )}
            </div>

            <div className="w-full pl-7 pb-5">
              <Row gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <FormSubmitButton
                      form={form}
                      errormsg={errormsg}
                      isUploading={isUploading}
                      isEditButtonEnable={isEditButtonEnable}
                      type={"SAVE"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="outline-button">
                  <Button
                    size="large"
                    className="font-medium w-full "
                    onClick={onClose}
                  >
                    <p className="text-center">CANCEL</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Drawer>

      {isOpenDeleteModal && (
        <CountryDelete
          open={isOpenDeleteModal}
          onCancel={deleteModalClose}
          deleteData={country}
          deletePermanently={deleteCategoryPermanently}
        />
      )}
    </>
  );
};

export default EditCountry;
