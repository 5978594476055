import axios from "axios";
import { config, url } from "./header";

//Country
export async function getAllCountries(data) {
  return await axios.post(url + "country/all", data, config);
}

export async function tableCountryStatusChange(data) {
  return await axios.post(url + "country/status", data, config);
}

export async function addCountry(data) {
  return await axios.post(url + "country/add", data, config);
}

export async function editCountry(data) {
  return await axios.post(url + "country/edit", data, config);
}

export async function deleteCountry(data) {
  return await axios.post(url + "country/delete", data, config);
}

export async function checkCountryName(data) {
  return await axios.post(url + "country/check", data, config);
}

export async function getAllTimeZone(data) {
  return await axios.post(url + "timezone/all", data, config);
}

//state
export async function getAllStates(data) {
  return await axios.post(url + "state/all", data, config);
}

export async function tableStateStatusChange(data) {
  return await axios.post(url + "state/status", data, config);
}

export async function addState(data) {
  return await axios.post(url + "state/add", data, config);
}

export async function editState(data) {
  return await axios.post(url + "state/edit", data, config);
}

export async function deleteState(data) {
  return await axios.post(url + "state/delete", data, config);
}

export async function checkStateName(data) {
  return await axios.post(url + "state/check", data, config);
}

//suburb
export async function getAllSuburb(data) {
  return await axios.post(url + "suberb/all", data, config);
}

export async function getAllSuburbWithPagination(data) {
  return await axios.post(url + "suberb/page", data, config);
}

export async function getAllSuburbFilter(data) {
  return await axios.post(url + "suberb/data", data, config);
}

export async function tableSuburbStatusChange(data) {
  return await axios.post(url + "suberb/status", data, config);
}

export async function addSuburb(data) {
  return await axios.post(url + "suberb/add", data, config);
}

export async function editSuburb(data) {
  return await axios.post(url + "suberb/edit", data, config);
}

export async function deleteSuburb(data) {
  return await axios.post(url + "suberb/delete", data, config);
}

export async function checkSuburbName(data) {
  return await axios.post(url + "suberb/check", data, config);
}

export async function getActiveStatesWithCountry(country_id) {
  let data = {
    country_id: country_id,
    status: 1,
  };
  return await axios.post(url + "state/all", data, config);
}

export async function getActiveSuburbsWithState(state_id) {
  let data = {
    state_id: state_id,
    status: 1,
  };
  return await axios.post(url + "suberb/data", data, config);
}

//units
export async function getAllUnits(data) {
  return await axios.post(url + "uom/all", data, config);
}
export async function addUnit(data) {
  return await axios.post(url + "uom/add", data, config);
}
export async function editUnit(data) {
  return await axios.post(url + "uom/edit", data, config);
}
export async function checkUnit(data) {
  return await axios.post(url + "uom/check", data, config);
}

//admin roles
export async function addPermissions(data) {
  return await axios.post(url + "user/type/permission/add", data, config);
}

export async function editPermissions(data) {
  return await axios.post(url + "user/type/permission/edit", data, config);
}

export async function adminRoleTableStatusChange(data) {
  return await axios.post(url + "user/type/status", data, config);
}
