import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useContentContext } from "../../providers/ContentContext";
import { resetLoginPassword } from "../../helpers";

const ResetNewPassword = ({ setFormChanger, resetemail }) => {
  let { openNotification, handleError } = useContentContext();
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errormsg, setErrormsg] = useState("");

  const onSubmitNewPassword = (e) => {
    setIsButtonDisable(true);
    if (
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/.test(
        e.newPassword
      )
    ) {
      if (e.newPassword === e.confirmPassword) {
        if (e.newPassword !== "oldPassword@1") {
          const data = {
            email_address: resetemail,
            password: e.newPassword,
            confirm_password: e.confirmPassword,
          };

          resetLoginPassword(data)
            .then((response) => {
              if (response.data.success) {
                openNotification("success", response.data.message);
                setFormChanger(3);
              } else {
                setErrormsg(response.data.message);
              }
            })
            .catch((error) => {
              handleError(error);
            });
        } else {
          setErrormsg("cannot reuse old password");
          setIsButtonDisable(false);
        }
      } else {
        setErrormsg("Password didn’t match");
        setIsButtonDisable(false);
      }
    } else {
      setErrormsg(
        "Your password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one digit, and one special character"
      );
      setIsButtonDisable(false);
    }
  };
  return (
    <>
      <div className=" text-[40px] font-semibold text-center md:text-secondaryWhite">
        Reset Your Password
      </div>
      <div className="reset-password flex flex-col justify-center items-center mt-4 md:bg-[#484848] p-6 sm:px-8 px-4 max-sm:mx-2">
        <Form
          name="login"
          className="login-form flex flex-col sm:w-[400px] w-[300px] mt-2 font-normal "
          layout="vertical"
          size="large"
          autoComplete="off"
          onFinish={onSubmitNewPassword}
        >
          <Form.Item
            name="newPassword"
            label="New Password"
            className="mb-4 login-password"
            rules={[{ required: true }]}
          >
            <Input.Password
              type="Password"
              placeholder="New Password"
              className="login-page-password border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
              onChange={(e) => {
                setErrormsg("");
                setIsButtonDisable(true);
                setResetPassword({
                  ...resetPassword,
                  password: e.target.value,
                });
              }}
              maxLength={20}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            className="mb-4 login-password"
            rules={[{ required: true }]}
          >
            <Input.Password
              type="password"
              placeholder="Password"
              className="login-page-password border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
              onChange={(e) => {
                setErrormsg("");
                setIsButtonDisable(true);
                setResetPassword({
                  ...resetPassword,
                  confirmPassword: e.target.value,
                });
              }}
              maxLength={20}
            />
          </Form.Item>

          {errormsg && (
            <p className="text-[14px] font-normal text-[#a77802]">{errormsg}</p>
          )}

          <Form.Item>
            <Button
              htmlType="submit"
              className={`w-full mt-7 !text-[18px] text-semibold !text-white ${
                isButtonDisable === false ||
                resetPassword.password === "" ||
                resetPassword.confirmPassword === ""
                  ? "!bg-primaryLightDisable !border-primaryLightDisable"
                  : "!bg-primaryLight !border-primaryLight"
              }`}
              style={{ height: "50px" }}
              disabled={
                isButtonDisable === false ||
                resetPassword.password === "" ||
                resetPassword.confirmPassword === ""
                  ? true
                  : false
              }
            >
              CONFIRM
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ResetNewPassword;
