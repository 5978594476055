import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Drawer,
  Row,
  Select,
  Table,
} from "antd";
import {
  getAllCategoryData,
  getAllJobTypeData,
  getAllLocationLevel1Data,
  getAllLocationLevel2Data,
  getAllPromoSmsCountries,
} from "../../helpers";
import { useContentContext } from "../../providers/ContentContext";
import { getAllNotificationUserList } from "../../helpers/apiHelpers/notificationHelpers";
import { decryptSecureData } from "../../services/EncryptionService";
import { CustomPagination } from "../../commonFun";

const ClientNotificationAudience = ({ open, onClose }) => {
  let {
    openNotification,
    handleError,
    // setNotificationCountry,
    setNotificationArray,
    filterValues,
    setFilterValues,
  } = useContentContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIdArray, setSelectedIdArray] = useState([]);
  const [tableUserList, setTableUserList] = useState([]);

  const [countrySelectorData, setCountrySelectorData] = useState();
  const [location1SelectorData, setLocation1SelectorData] = useState();
  const [location2SelectorData, setLocation2SelectorData] = useState();
  const [categorySelectorData, setCategorySelectorData] = useState();
  const [jObTypeSelectorData, setJObTypeSelectorData] = useState();

  useEffect(() => {
    fetchCountryData();
    fetchLocationLevel1Data(1);
    fetchCategoryData(1);
    setTableUserList([]);
    // setNotificationCountry(1);
    setFilterValues({
      selectedCountry: 1,
      location1SelectedData: null,
      location2SelectedData: null,
      selectedCategoryData: null,
      selectedJobTypeData: null,
    });
  }, []);

  const fetchCountryData = async () => {
    try {
      await getAllPromoSmsCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  const fetchLocationLevel2Data = async (data) => {
    try {
      await getAllLocationLevel2Data({
        country_id: filterValues?.selectedCountry,
        level1_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel2Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel2Data = (locations2) => {
    var locationLevel2Object = [];
    locations2?.forEach((location2) => {
      let data = {
        value: location2?.level2_id,
        label: location2?.level2_location,
      };
      locationLevel2Object.push(data);
    });
    setLocation2SelectorData(locationLevel2Object);
  };

  const fetchCategoryData = async (data) => {
    try {
      await getAllCategoryData({
        country_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setCategoryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCategoryData = (category) => {
    var categoryObject = [];
    category?.forEach((category) => {
      let data = {
        value: category?.job_category_id,
        label: category?.job_category,
      };
      categoryObject.push(data);
    });
    setCategorySelectorData(categoryObject);
  };

  const fetchJobTypeData = async (data) => {
    try {
      await getAllJobTypeData({
        job_category_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setJObTypeData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setJObTypeData = (jobType) => {
    var jobTypeObject = [];
    jobType?.forEach((jobType) => {
      let data = {
        value: jobType?.job_type_id,
        label: jobType?.job_type,
      };
      jobTypeObject.push(data);
    });
    setJObTypeSelectorData(jobTypeObject);
  };

  //fetch user list
  const handleFetchUserList = async () => {
    try {
      await getAllNotificationUserList({
        user_type_id: 2,
        country_id: filterValues?.selectedCountry,
        location_level1: filterValues?.location1SelectedData,
        location_level2: filterValues?.location2SelectedData,
        job_category_id: filterValues?.selectedCategoryData,
        job_type_id: filterValues?.selectedJobTypeData,
      })
        .then((response) => {
          if (response?.data.success) {
            setData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (dataList) => {
    setTableUserList([]);
    if (dataList?.length !== 0) {
      var tableData = [];
      dataList?.forEach((list, x) => {
        let data = {
          key: x,
          name: decryptSecureData(list?.full_name),
          number: decryptSecureData(list?.mobile_number),
          id: list?.id,
        };
        tableData.push(data);
        setTableUserList(tableData);
      });
    } else {
    }
  };

  // get selected audiance array
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedIdArray(selectedRows.map((row) => row?.id));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSaveAudianceList = () => {
    setNotificationArray(selectedIdArray);
    onClose();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="flex justify-end">
              <div className="flex flex-row items-center gap-3">
                <Button
                  type="primary"
                  className="w-32"
                  onClick={() => {
                    handleSaveAudianceList();
                  }}
                  disabled={selectedIdArray?.length === 0}
                >
                  SAVE
                </Button>

                <Button
                  className="w-32"
                  onClick={() => {
                    onClose();
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </div>
            <Divider />

            <div>
              <Row className="w-full" gutter={8}>
                <Col
                  span={24}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  className="w-full pb-3"
                >
                  <p className="text-base font-normal pb-1">Country</p>
                  <Select
                    showSearch
                    filterOption={filterOption}
                    // allowClear
                    placeholder="Country"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    value={filterValues?.selectedCountry}
                    options={countrySelectorData}
                    onSelect={(e) => {
                      // setNotificationCountry(e);
                      fetchLocationLevel1Data(e);
                      fetchCategoryData(e);
                      setFilterValues({
                        ...filterValues,
                        selectedCountry: e,
                        location1SelectedData: null,
                        location2SelectedData: null,
                      });
                    }}
                  />
                </Col>

                <Col
                  span={24}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  className="w-full pb-3"
                >
                  <p className="text-base font-normal pb-1">
                    {filterValues?.selectedCountry === 1 ? "State" : "District"}
                  </p>
                  <Select
                    showSearch
                    filterOption={filterOption}
                    value={filterValues?.location1SelectedData}
                    placeholder={
                      filterValues?.selectedCountry === 1 ? "State" : "District"
                    }
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    options={location1SelectorData}
                    onSelect={(e) => {
                      fetchLocationLevel2Data(e);
                      setFilterValues({
                        ...filterValues,
                        location1SelectedData: e,
                        location2SelectedData: null,
                      });
                    }}
                  />
                </Col>

                <Col
                  span={24}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  className="w-full pb-3"
                >
                  <p className="text-base font-normal pb-1">
                    {filterValues?.selectedCountry === 1 ? "Suburb" : "City"}
                  </p>
                  <Select
                    showSearch
                    filterOption={filterOption}
                    value={filterValues?.location2SelectedData}
                    placeholder={
                      filterValues?.selectedCountry === 1 ? "Suburb" : "City"
                    }
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    options={location2SelectorData}
                    onSelect={(e) => {
                      setFilterValues({
                        ...filterValues,
                        location2SelectedData: e,
                      });
                    }}
                  />
                </Col>

                <Col
                  span={24}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  className="w-full pb-3"
                >
                  <p className="text-base font-normal pb-1">Job Category</p>
                  <Select
                    showSearch
                    filterOption={filterOption}
                    value={filterValues?.selectedCategoryData}
                    placeholder="Job Category"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    options={categorySelectorData}
                    onSelect={(e) => {
                      fetchJobTypeData(e);
                      setFilterValues({
                        ...filterValues,
                        selectedCategoryData: e,
                        selectedJobTypeData: null,
                      });
                    }}
                  />
                </Col>

                <Col
                  span={24}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  className="w-full pb-3"
                >
                  <p className="text-base font-normal pb-1">Job Type</p>
                  <Select
                    showSearch
                    filterOption={filterOption}
                    value={filterValues?.selectedJobTypeData}
                    placeholder="Job Type"
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    options={jObTypeSelectorData}
                    onChange={(e) => {
                      setFilterValues({
                        ...filterValues,
                        selectedJobTypeData: e,
                      });
                    }}
                  />
                </Col>

                <Col
                  span={24}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  className="w-full pb-3 pt-7"
                >
                  <Button
                    size="large"
                    className="w-full"
                    onClick={handleFetchUserList}
                    disabled={filterValues?.selectedCountry === null}
                  >
                    SHOW RESULTS
                  </Button>
                </Col>
              </Row>
            </div>

            <div className="pt-5">
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      rowSelectedBg: "rgb(214, 211, 213)",
                      rowSelectedHoverBg: "rgb(214, 211, 213)",
                    },
                  },
                }}
              >
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={tableUserList}
                  pagination={{
                    size: "large",
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "100", "1000", "10000"],
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ClientNotificationAudience;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile Number",
    dataIndex: "number",
  },
];
