import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Row,
  Checkbox,
  Col,
  TimePicker,
  DatePicker,
} from "antd";
import { MdClose, MdOutlineAddBox } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { PiWarningCircleThin } from "react-icons/pi";
import {
  addJobType,
  checkJobTypeName,
  getAllCategories,
  getAllUom,
  jobTypeQuestionAdd,
} from "../../helpers";
import { MdAdd } from "react-icons/md";
import AddInputField from "./AddInputField";
import { BiEdit } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import EditInputField from "./EditInputField";
import JobTypeFormSubmitButton from "../submitButton/JobTypeFormSubmitButton";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const dateFormat = "YYYY/MM/DD";
const format = "HH:mm";

const NewJobType = ({
  open,
  onClose,
  reloadJobTypeData,
  setSearchText,
  topCount,
}) => {
  const userId = getLocalStoragedata("userId");
  let { openNotification, handleError, pageTitleCount, setPageTitleCount } =
    useContentContext();
  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);

  const [categorySelectorData, setCategorySelectorData] = useState();
  const [uomSelectorData, setUOMSelectorData] = useState();

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const [isOpenInputFieldAddModal, setIsOpenInputFieldAddModal] =
    useState(false);
  const [isOpenInputFieldEditModal, setIsOpenInputFieldEditModal] =
    useState(false);
  const [pageTitleId, setPageTitleId] = useState();
  const [selectedQuestionData, setSelectedQuestionData] = useState();

  let questionErrorCount = 0;

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
    setPageTitleCount([
      {
        page_title_id: 1,
        page_title: "",
        question_data: [],
        status: 1,
      },
    ]);
  }, []);

  const fetchData = async () => {
    try {
      await getAllCategories({ is_top: 0, status: -1 })
        .then((response) => {
          if (response?.data.success) {
            setCategoryList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      await getAllUom({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setUOMList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCategoryList = (categories) => {
    var categoryObj = [];
    categories?.forEach((category) => {
      let data = {
        value: category.id,
        label: category.job_category,
      };
      categoryObj.push(data);
    });
    setCategorySelectorData(categoryObj);
  };

  const setUOMList = (uoms) => {
    var uomObj = [];
    uoms?.forEach((uom) => {
      let data = {
        value: uom.id,
        label: uom.uom,
      };
      uomObj.push(data);
    });
    setUOMSelectorData(uomObj);
  };

  const onFinish = async (e) => {
    setSearchText();

    const checkData = {
      job_category_id: e.category,
      job_type: e.jobType,
      job_type_id: 0,
    };

    const response = await checkJobTypeName(checkData)
      .then((response) => {
        if (response.data.success) {
          return response.data.output.has_job_type;
        }
      })
      .catch((error) => {
        handleError(error);
      });

    if (response === 0) {
      const data = new FormData();
      data.append("job_category_id", e.category);
      data.append("job_type", e.jobType);
      data.append("uom_id", e.umo);
      data.append("status", e.switch === true ? 1 : 0);
      data.append("file_extension2", null);
      data.append("urgent_image", null);
      data.append("is_urgent", 0);
      data.append("created_by", userId);
      data.append("is_remote", e.remote === true ? 1 : 0);

      if (isUploading) {
        openNotification("warning", "Please wait Upload on progress !");
      } else {
        setIsUploading(true);
        const jobTypeResponse = await addJobType(data)
          .then((response) => {
            if (response.data.success) {
              openNotification("success", response.data.message);
              return response;
            } else {
              openNotification("error", response.data.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });

        if (
          jobTypeResponse?.data.success === true &&
          pageTitleCount[0].question_data.length !== 0
        ) {
          const questionData = {
            job_category_id: e.category,
            job_type_id: jobTypeResponse?.data?.output?.id,
            question: pageTitleCount,
          };
          jobTypeQuestionAdd(questionData)
            .then((response) => {
              if (response.data.success) {
                openNotification("success", response.data.message);
              } else {
                openNotification("error", response.data.message);
              }
              setIsUploading(false);
              setErrormsg(false);
            })
            .catch((error) => {
              handleError(error);
            });
        }
      }
      reloadJobTypeData(0);
      onClose();
      setPageTitleCount([
        {
          page_title_id: 1,
          page_title: "",
          question_data: [],
          status: 1,
        },
      ]);
    } else {
      setErrormsg(true);
    }
  };

  const validatePageTitleDuplicate = (_, value) => {
    // Count occurrences of the given question value
    const count = pageTitleCount.reduce(
      (acc, item, currentIndex) => (item.page_title === value ? acc + 1 : acc),
      0
    );

    if (count >= 2) {
      return Promise.reject(new Error("Duplicate page title !"));
    } else {
      return Promise.resolve();
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleOpenInputFieldAddModal = (e) => {
    setIsOpenInputFieldAddModal(!isOpenInputFieldAddModal);
    setPageTitleId(e);
  };

  const handleOpenInputFieldEditModal = (
    pageTitleData,
    questionIndex,
    questionData
  ) => {
    setIsOpenInputFieldEditModal(true);
    setSelectedQuestionData({
      pageTitleData: pageTitleData,
      questionIndex: questionIndex,
      questionData: questionData,
    });
  };

  const handleCloseInputFieldEditModal = () => {
    setIsOpenInputFieldEditModal(false);
  };

  const handleRemovePageTitleData = (pageTitleIdToRemove) => {
    setPageTitleCount((prevPageTitleCount) =>
      prevPageTitleCount.filter(
        (pageTitle) => pageTitle.page_title_id !== pageTitleIdToRemove
      )
    );
  };

  const handlePageTitleChange = (index, e) => {
    const updatedPageTitleCount = [...pageTitleCount];
    updatedPageTitleCount[index].page_title = e.target.value;
    setPageTitleCount(updatedPageTitleCount);
  };

  const deleteQuestion = (pageTitleId, questionIndex) => {
    setPageTitleCount((prevPageTitleCount) => {
      const updatedPageTitleCount = prevPageTitleCount.map((pageTitle) => {
        if (pageTitle.page_title_id === pageTitleId) {
          const updatedQuestions = pageTitle.question_data.filter(
            (_, index) => index !== questionIndex
          );
          return { ...pageTitle, question_data: updatedQuestions };
        }
        return pageTitle;
      });

      return updatedPageTitleCount;
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className=" w-full md:w-[768px]">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full add-form"
            initialValues={{ switch: false }}
            onFinish={onFinish}
          >
            <div className="relative bg-secondaryWhite p-7 ">
              <div className="w-full flex flex-row  justify-between">
                <div className="text-[16px] font-medium text-primaryLight">
                  Add New Job Type
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();

                    setPageTitleCount([
                      {
                        page_title_id: 1,
                        page_title: "",
                        question_data: [],
                        status: 1,
                      },
                    ]);
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>
              {/* select */}
              <Row className="flex flex-row" gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Select Category"
                    name="category"
                    rules={[
                      { required: true, message: "Please select category !" },
                    ]}
                  >
                    <Select
                      name="category"
                      placeholder="Select category"
                      required
                      className="!bg-secondaryWhite"
                      filterOption={filterOption}
                      showSearch
                      size="large"
                      options={categorySelectorData}
                      onSelect={() => {
                        setErrormsg(false);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Job Type"
                    name="jobType"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="Job type"
                      className="!bg-secondaryWhite"
                      size="large"
                      onChange={() => {
                        setErrormsg(false);
                      }}
                      minLength={3}
                      maxLength={50}
                    />
                  </Form.Item>
                  {errormsg && (
                    <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-3">
                      JobType name already exist !
                    </p>
                  )}
                </Col>
              </Row>
              {/* select */}
              <Row className="flex flex-row" gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label=" Unit Of Measure"
                    name="umo"
                    rules={[
                      {
                        required: true,
                        message: "Please select unit of measure !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Unit Of Measure"
                      required
                      className="!bg-secondaryWhite"
                      size="large"
                      options={uomSelectorData}
                      filterOption={filterOption}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* remote */}
              <div className="w-full bg-secondaryLight p-3 rounded-[20px]">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row gap-3">
                    <Form.Item name="remote" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <p className="text-[16px] font-normal">Remote Job</p>
                  </div>
                </div>
                <div className="mt-[-10px]">
                  <p className="text-[12px] text-secondaryColor">
                    hint text - When enabled, this job type allows employees to
                    work from any location, eliminating the requirement to visit
                    a specific place
                  </p>
                </div>
              </div>

              {/* dynamic question */}
              <Divider />
              <div className="w-full bg-secondaryLight p-3 rounded-[20px]">
                <Row className="flex flex-row justify-between" gutter={8}>
                  <Col xs={24} sm={12}>
                    <p className="text-[16px] font-normal">Dynamic Questions</p>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    className="flex justify-end outline-button"
                  >
                    <Button
                      className="flex flex-row gap-2"
                      onClick={() =>
                        setPageTitleCount([
                          ...pageTitleCount,
                          {
                            page_title_id: pageTitleCount.length + 1,
                            page_title: "",
                            question_data: [],
                            status: 1,
                          },
                        ])
                      }
                    >
                      <p className="text-[16px] font-normal">Add More</p>
                      <MdAdd className="text-[22px]" />
                    </Button>
                  </Col>
                </Row>
                <p className="text-[14px] font-normal">
                  Add dynamic questions related to the relevant job type
                </p>

                {pageTitleCount?.map((pageTitle, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Divider />
                      {/* page title */}
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col w-full">
                          <Form.Item
                            label="Page Title"
                            name={`pageTitle${index}`}
                            className="pt-5 w-full"
                            rules={[
                              {
                                required:
                                  pageTitleCount.length === 1 &&
                                  pageTitleCount[0].question_data.length ===
                                    0 &&
                                  pageTitleCount[0].page_title === ""
                                    ? false
                                    : true,
                              },
                              { validator: validatePageTitleDuplicate },
                            ]}
                          >
                            <Input
                              placeholder="Type page title here"
                              className="!bg-secondaryWhite"
                              size="large"
                              onChange={(e) => handlePageTitleChange(index, e)}
                              maxLength={255}
                            />
                          </Form.Item>
                        </div>

                        {index !== 0 && (
                          <MdClose
                            className="text-[20px]"
                            onClick={() =>
                              handleRemovePageTitleData(pageTitle.page_title_id)
                            }
                          />
                        )}
                      </div>

                      {/* button */}
                      <Button
                        className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9 mb-2"
                        type="primary"
                        size="large"
                        icon={
                          <MdOutlineAddBox className="text-2xl font-bold" />
                        }
                        onClick={() => {
                          handleOpenInputFieldAddModal(
                            pageTitle?.page_title_id
                          );
                        }}
                      >
                        <p className=" text-secondaryWhite text-[18px] font-medium">
                          ADD QUESTION
                        </p>
                      </Button>

                      {(pageTitleCount.length !== 1 &&
                        pageTitleCount[index].question_data.length === 0) ||
                      (index === 0 &&
                        pageTitle.page_title !== "" &&
                        pageTitle.question_data.length === 0) ? (
                        <>
                          <p className="hidden">{(questionErrorCount += 1)}</p>

                          <p className="text-[#ff4d4f]">
                            Please add question !
                          </p>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* questions */}
                      {pageTitle?.question_data.length !== 0 && (
                        <div className="py-5">
                          <div className="rounded-xl pb-5 bg-secondaryDarkshade">
                            {pageTitle?.question_data.map((question, index) => (
                              <React.Fragment key={index}>
                                <div className=" px-8 py-5">
                                  <p className="text-[16px] font-normal pb-2">
                                    {`${question?.title} ${
                                      question?.require === 1 ? "*" : ""
                                    }`}
                                  </p>
                                  <Row gutter={16}>
                                    <Col xs={24} sm={14} className="w-full">
                                      {question?.field_type === 2 &&
                                      question?.input_type === 1 ? (
                                        <Select
                                          placeholder={question?.hint}
                                          required
                                          className="font-medium text-[16px] w-full"
                                          size="large"
                                          options={question?.answer}
                                        />
                                      ) : question?.field_type === 2 &&
                                        question?.input_type === 2 ? (
                                        <Select
                                          mode="multiple"
                                          className="font-medium text-[16px] w-full"
                                          allowClear
                                          placeholder={question?.hint}
                                          options={question?.answer}
                                          size="large"
                                        />
                                      ) : question?.field_type === 3 &&
                                        question?.input_type === 1 ? (
                                        <DatePicker
                                          className="font-medium text-[16px] w-full"
                                          size="large"
                                          format={dateFormat}
                                          placeholder={question?.hint}
                                        />
                                      ) : question?.field_type === 3 &&
                                        question?.input_type === 2 ? (
                                        <TimePicker
                                          className="font-medium text-[16px] w-full"
                                          size="large"
                                          format={format}
                                          placeholder={question?.hint}
                                        />
                                      ) : question?.field_type === 3 &&
                                        question?.input_type === 3 ? (
                                        <DatePicker
                                          className="font-medium text-[16px] w-full"
                                          size="large"
                                          format="YYYY-MM-DD HH:mm:ss"
                                          disabledDate={disabledDate}
                                          disabledTime={disabledDateTime}
                                          placeholder={question?.hint}
                                          showTime={{
                                            defaultValue: dayjs(
                                              "00:00:00",
                                              "HH:mm:ss"
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <Input
                                          className="font-medium text-[16px]"
                                          size="large"
                                          placeholder={question?.hint}
                                        />
                                      )}
                                    </Col>
                                    <Col
                                      xs={24}
                                      sm={10}
                                      className="flex flex-row w-full text-[28px] justify-end gap-3 table-status-button"
                                    >
                                      <BiEdit
                                        className="cursor-pointer"
                                        onClick={() => {
                                          handleOpenInputFieldEditModal(
                                            pageTitle,
                                            index,
                                            question
                                          );
                                        }}
                                      />
                                      <IoTrashOutline
                                        className="text-secondaryRed cursor-pointer"
                                        onClick={() => {
                                          deleteQuestion(
                                            pageTitle.page_title_id,
                                            index
                                          );
                                        }}
                                      />
                                      <p className="text-[20px]">|</p>
                                      <Button
                                        className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                                          question?.status === 1
                                            ? "bg-primaryColor"
                                            : "bg-primaryLight"
                                        } `}
                                      >
                                        <p>
                                          {question?.status === 1
                                            ? "Active"
                                            : "Inactive"}
                                        </p>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              <Divider />
              {/* status */}
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col font-medium text-base text-[16px] text-primaryLight">
                    Job Type Status
                  </div>
                  <div className="flex flex-row  text-center items-baseline">
                    <span
                      className={`${
                        isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                      }`}
                    >
                      Inactive
                    </span>
                    <Form.Item name="switch" valuePropName="checked">
                      <Switch className="mx-2" onChange={handleSwitchChange} />
                    </Form.Item>
                    <span
                      className={`${
                        isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                      }`}
                    >
                      Active
                    </span>
                  </div>
                </div>
                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Changing the job type status will impact the available job
                    types within the job type
                  </p>
                </div>
              </div>
              {/* button */}
              <div className="w-full pt-8">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <JobTypeFormSubmitButton
                        form={form}
                        errormsg={errormsg}
                        isUploading={isUploading}
                        isEditButtonEnable={false}
                        type={"ADD"}
                        pageTitleStatus={
                          (pageTitleCount.length > 1 &&
                            pageTitleCount[0].page_title === "") ||
                          (pageTitleCount.length === 1 &&
                            pageTitleCount[0].page_title === "" &&
                            pageTitleCount[0].question_data.length > 0)
                            ? true
                            : false
                        }
                        questionStatus={questionErrorCount > 0 ? true : false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full"
                      onClick={() => {
                        onClose();
                        setPageTitleCount([
                          {
                            page_title_id: 1,
                            page_title: "",
                            question_data: [],
                            status: 1,
                          },
                        ]);
                      }}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
      {isOpenInputFieldAddModal && (
        <AddInputField
          open={isOpenInputFieldAddModal}
          onCancel={handleOpenInputFieldAddModal}
          pageTitleId={pageTitleId}
        />
      )}

      {isOpenInputFieldEditModal && (
        <EditInputField
          open={isOpenInputFieldEditModal}
          onCancel={handleCloseInputFieldEditModal}
          selectedQuestionData={selectedQuestionData}
        />
      )}
    </>
  );
};

export default NewJobType;
