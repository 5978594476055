import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { BsEye } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useContentContext } from "../providers/ContentContext";
import { ViewSupportTradie } from "../components";
import { getAllSupportCustomerTradieList } from "../helpers";
import { decryptSecureData } from "../services/EncryptionService";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "0",
    sorter: (a, b) => a.name?.localeCompare(b.name),
    defaultSortOrder: "ascend",
  },

  {
    title: "Request No",
    dataIndex: "request",
    key: "1",
    sorter: (a, b) => a.request - b.request,
    defaultSortOrder: "ascend",
  },
  {
    title: "Topic",
    dataIndex: "topic",
    key: "2",
    sorter: (a, b) => a.topic?.localeCompare(b.topic),
    defaultSortOrder: "ascend",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "3",
    sorter: (a, b) => a.description?.localeCompare(b.description),
    defaultSortOrder: "ascend",
  },

  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];

const SupportTradie = () => {
  let { openNotification, handleError } = useContentContext();

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState([]);

  const [isOpenVewDrawer, setIsOpenViewDrawer] = useState(false);
  const [selectedViewData, setSelectedViewData] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setDataLoading(true);
      await getAllSupportCustomerTradieList({ user_type_id: 6, status: 0 })
        .then((response) => {
          if (response?.data.success) {
            setData(response?.data.output ? response?.data.output : []);
            setDataForSearch(response?.data.output);
          } else {
            openNotification("warning", response?.data.message);
            setDataLoading(false);
          }
        })
        .catch((error) => {
          handleError(error);
          setDataLoading(false);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const search = (text) => {
    let filtered = dataForSearch.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );
    setData(filtered);
  };

  const setData = (tradies) => {
    if (tradies.length !== 0) {
      var tableData = [];

      tradies?.forEach((tradie, x) => {
        let data = {
          key: x,
          name: decryptSecureData(tradie?.user_name),
          request: tradie?.id,
          topic: tradie?.topic,
          description: tradie?.description,
          action: (
            <BsEye
              className="cursor-pointer text-2xl text-primaryLight"
              onClick={() => {
                handleViewDrawerOpenChange(tradie);
              }}
            />
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
      setDataLoading(false);
    } else {
      setTableData([]);
      setDataLoading(false);
    }
    setDataLoading(false);
  };

  const handleViewDrawerOpenChange = (tradie) => {
    setSelectedViewData(tradie);
    setIsOpenViewDrawer(!isOpenVewDrawer);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };
  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5 pl-6">
        TASKER SUPPORT
      </div>
      <Content className="rounded-lg mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search tasker..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
        </Row>

        <Table
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          loading={dataLoading}
          pagination={{
            size: "small",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            showQuickJumper: true,
            itemRender: CustomPagination,
          }}
        />
      </Content>

      {isOpenVewDrawer && (
        <ViewSupportTradie
          open={isOpenVewDrawer}
          onClose={handleViewDrawerOpenChange}
          tradie={selectedViewData}
        />
      )}
    </>
  );
};

export default SupportTradie;
