import { Button, Drawer, Table } from "antd";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { getAllNotificationAudiensList } from "../../../helpers/apiHelpers/emailNotificationHelpers";
import { useContentContext } from "../../../providers/ContentContext";
import { decryptSecureData } from "../../../services/EncryptionService";


const ViewEmailTaskerNotificationAudience = ({ open, onClose, notificationID }) => {
  let { openNotification, handleError } = useContentContext();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchAllNotificationAudiensList();
  }, []);

  const fetchAllNotificationAudiensList = async () => {
    try {
      await getAllNotificationAudiensList({ notification_id: notificationID })
        .then((response) => {
          if (response?.data.success) {
            setData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (items) => {
    if (items.length !== 0) {
      var tableData = [];
      items?.forEach((item, x) => {
        let data = {
          key: x,
          name: decryptSecureData(item?.user_name),
          number: decryptSecureData(item?.mobile_number),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
  };
  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                View Audience
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div className="pt-5">
              <Table columns={columns} dataSource={tableData} />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ViewEmailTaskerNotificationAudience;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile Number",
    dataIndex: "number",
  },
];
