import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, url } from "./header";

const userId = getLocalStoragedata("userId");

export async function getCommonConfigurationData() {
  let data = {
    user_id: userId,
  };
  return await axios.post(url + "setting/view", data, config);
}

export async function updateCommonConfigurationData(data) {
  return await axios.post(url + "setting/manage", data, config);
}

export async function resetPassword(data) {
  Object.assign(data, { user_id: userId });

  return await axios.post(url + "auth/password/change", data, config);
}

export async function getTermsAndConditions() {
  let data = {
    status: -1,
  };
  return await axios.post(url + "terms/all", data, config);
}

export async function addNewTermsAndConditions(data, uploadConfig) {
  return await axios.post(url + "terms/add", data, uploadConfig);
}

export async function updateTermsAndConditions(data, uploadConfig) {
  return await axios.post(url + "terms/edit", data, uploadConfig);
}
