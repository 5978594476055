import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { useContentContext } from "../providers/ContentContext";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { NewJobData, ViewJobData } from "../components";
import EditJobData from "../components/jobData/EditJobData";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { getAllUnits } from "../helpers";

const columns = [
  {
    title: "Measuring Units",
    dataIndex: "measureUnit",
    key: "0",
    sorter: (a, b) => a.measureUnit?.localeCompare(b.measureUnit),
    defaultSortOrder: "ascend",
  },
  {
    title: "Short Name",
    dataIndex: "shortname",
    key: "1",
    sorter: (a, b) => a.shortname?.localeCompare(b.shortname),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "2",
  },
];

const JobData = () => {
  const { handleError, openNotification } = useContentContext();
  const permission = getLocalStoragedata("permission");

  const [dataLoading, setDataLoading] = useState(false);
  const [tableJobData, setTableJobData] = useState();

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState();

  const [isOpenNewJobDataDrawer, setIsOpenNewJobDataDrawer] = useState(false);
  const [isOpenViewJobDataDrawer, setIsOpenViewJobDataDrawer] = useState(false);
  const [selectedViewJobData, setSelectedViewJobData] = useState();
  const [isOpenEditJobDataDrawer, setIsOpenEditJobDataDrawer] = useState(false);
  const [selectedEditJobData, setSelectedEditJobData] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setDataLoading(true);
      await getAllUnits({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
            setDataForSearch(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (jobDatas) => {
    if (jobDatas?.length !== 0) {
      var tableData = [];

      jobDatas?.forEach((jobData, x) => {
        let data = {
          key: x,
          measureUnit: jobData?.uom,
          shortname: jobData?.uom_label,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(34) && (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditJobDataDrawer(jobData);
                  }}
                />
              )}
              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewJobDataDrawerOpenChange(jobData);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableJobData(tableData);
    } else {
      setTableJobData([]);
    }
    setDataLoading(false);
  };

  const search = (text) => {
    let filtered = dataForSearch.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );

    setData(filtered);
  };

  const handleNewJobDataDrawerOpenChange = () => {
    setIsOpenNewJobDataDrawer(!isOpenNewJobDataDrawer);
  };

  const handleViewJobDataDrawerOpenChange = (jobData) => {
    setSelectedViewJobData(jobData);
    setIsOpenViewJobDataDrawer(!isOpenViewJobDataDrawer);
  };

  const openEditJobDataDrawer = (jobData) => {
    setSelectedEditJobData(jobData);
    setIsOpenEditJobDataDrawer(true);
  };
  const closeEditJobDataDrawer = () => {
    setIsOpenEditJobDataDrawer(false);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };
  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5 pl-6 ">
        MEASURING UNIT
      </div>

      <Content className="rounded-lg mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Measuring Unit..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(33) && (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                icon={<MdAdd className="text-2xl font-bold" />}
                onClick={handleNewJobDataDrawerOpenChange}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD MEASURING UNIT
                </p>
              </Button>
            )}
          </Col>
        </Row>

        <Table
          columns={columns}
          showSorterTooltip={false}
          dataSource={tableJobData}
          loading={dataLoading}
          pagination={{
            size: "small",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            showQuickJumper: true,
            itemRender: CustomPagination,
          }}
        />
      </Content>
      {isOpenNewJobDataDrawer ? (
        <NewJobData
          open={isOpenNewJobDataDrawer}
          onClose={handleNewJobDataDrawerOpenChange}
          reloadJobData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenViewJobDataDrawer ? (
        <ViewJobData
          open={isOpenViewJobDataDrawer}
          onClose={handleViewJobDataDrawerOpenChange}
          jobData={selectedViewJobData}
        />
      ) : (
        <></>
      )}

      {isOpenEditJobDataDrawer ? (
        <EditJobData
          open={isOpenEditJobDataDrawer}
          onClose={closeEditJobDataDrawer}
          jobData={selectedEditJobData}
          reloadJobData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default JobData;
