import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button, Select, Form, Input } from "antd";
import { useContentContext } from "../../providers/ContentContext";
import {
  getAllRejectReasonList,
  tradieVerificationChange,
} from "../../helpers";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
const { TextArea } = Input;

const VerificationChange = ({
  open,
  onCancel,
  tradie,
  VerificatStatus,
  getTradieData,
  drawerClose,
}) => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");

  const [rejectReasonList, setRejectReasonList] = useState([]);
  const [rejectListItemId, setRejectListItemId] = useState(0);

  useEffect(() => {
    if (VerificatStatus === 4) {
      getRejectReasonList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (e) => {
    adminApproved(e?.reason, e?.description);
  };

  const getRejectReasonList = async () => {
    await getAllRejectReasonList()
      .then((response) => {
        if (response.data.success) {
          var rejectList = [];
          response.data.output?.forEach((reject) => {
            let data = {
              value: reject.id,
              label: reject.reason,
            };
            rejectList.push(data);
          });
          setRejectReasonList(rejectList);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const adminApproved = async (reasonId, reason) => {
    const data = {
      user_id: userId,
      trady_id: tradie?.trady_id,
      verification_id: tradie?.id,
      status: VerificatStatus,
      reason_id: reasonId,
      reason: reason,
    };

    await tradieVerificationChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          getTradieData(1, 1);
        } else {
          openNotification("error", response.data.message);
        }
        onCancel();
        drawerClose();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <Modal open={open} onCancel={onCancel}>
      {VerificatStatus === 2 ? (
        <>
          <p className="text-[24px] font-medium text-center">
            Are you sure you want to verify this tasker?
          </p>

          <p className="text-[16px] font-normal text-primaryLight text-center pt-5">
            Before proceeding, please note that, you have to conduct a
            verification call to ensure accuracy
          </p>

          <Row className="flex flex-row justify-between px-10 pt-5" gutter={64}>
            <Col xs={12} className="tradie-block-button w-full">
              <Button
                type="primary"
                size="large"
                className="w-full"
                onClick={() => {
                  adminApproved(null, null);
                }}
              >
                VERIFY
              </Button>
            </Col>
            <Col xs={12} className="w-full outline-button">
              <Button size="large" onClick={onCancel} className="w-full">
                CANCEL
              </Button>
            </Col>
          </Row>
        </>
      ) : VerificatStatus === 4 ? (
        <>
          <p className="text-[24px] font-medium text-center">
            Are you sure you want to reject the verification for this tasker?
          </p>

          <p className="text-[16px] font-normal text-primaryLight text-center pt-5">
            Before proceeding, please note that, you have to conduct a
            verification call to ensure accuracy
          </p>

          <Form
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}
          >
            <div className="py-10">
              <Form.Item
                name="reason"
                className="text-[16px]"
                label="Select Reason"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select reason"
                  required
                  className="!bg-secondaryWhite w-full"
                  size="large"
                  options={rejectReasonList && rejectReasonList}
                  onSelect={(e) => {
                    setRejectListItemId(e);
                  }}
                />
              </Form.Item>
              {rejectListItemId === 13 && (
                <Form.Item
                  name="description"
                  className="text-[16px]"
                  label="Describe your reason"
                  rules={[{ required: true }]}
                >
                  <TextArea
                    showCount
                    maxLength={100}
                    placeholder="disable resize"
                    style={{
                      height: 120,
                      resize: "none",
                    }}
                  />
                </Form.Item>
              )}
            </div>
            <Row
              className="flex flex-row justify-between px-10 pt-5"
              gutter={64}
            >
              <Col xs={12} className="tradie-block-button w-full">
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="large"
                    className="bg-primaryRed text-white w-full border-none"
                  >
                    REJECT
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={12} className="w-full outline-button">
                <Button size="large" onClick={onCancel} className="w-full">
                  CANCEL
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        // VerificatStatus === 3
        <>
          <p className="text-[24px] font-medium text-center">
            Are you sure you want to hold this tasker request?
          </p>

          <p className="text-[16px] font-normal text-primaryLight text-center pt-5">
            Before proceeding, please note that, you have to conduct a
            verification call to ensure accuracy
          </p>

          <Row className="flex flex-row justify-between px-10 pt-5" gutter={64}>
            <Col xs={12} className="tradie-block-button w-full">
              <Button
                type="primary"
                size="large"
                className="w-full"
                onClick={() => {
                  adminApproved(null, null);
                }}
              >
                HOLD
              </Button>
            </Col>
            <Col xs={12} className="w-full outline-button">
              <Button size="large" onClick={onCancel} className="w-full">
                CANCEL
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default VerificationChange;
