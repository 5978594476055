import React, { useRef } from "react";
import { Button, Drawer } from "antd";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageConvertor from "./ImageConvertor";

const AddContent = ({
  onClose,
  open,
  getContent,
  setContent,
  setIsContentUpdate,
}) => {
  const quillRef = useRef(null);

  window.Quill = Quill;
  Quill.register("modules/imageResize", ImageResize);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ header: 1 }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
  ];

  function imageHandler() {
    if (!quillRef.current) return;
    const value = prompt("Please enter the image URL");
    const editor = quillRef.current.getEditor();
    if (value) {
      const range = editor.getSelection();
      const cursorPosition = range ? range.index + range.length : 0;
      editor.insertEmbed(cursorPosition, "image", value, "user");
      editor.setSelection(cursorPosition + 1);
    }
  }

  const modules = {
    toolbar: {
      container: toolbarOptions,
      handlers: {
        image: imageHandler,
      },
    },
    imageResize: {
      parchment: Quill.import("parchment"),
    },
  };

  const onSave = () => {
    const content = quillRef.current.getEditor().root.innerHTML;
    getContent(content);
    onClose();
  };

  return (
    <Drawer placement="right" open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] justify-center items-center flex flex-col">
        <div className="flex justify-end w-full mt-4">
          <div className="flex flex-row gap-4">
            <Button
              size="large"
              type="primary"
              className="w-[150px] bg-primaryLight text-white hover:text-white"
              onClick={() => {
                setIsContentUpdate(true);
                onSave();
              }}
            >
              SAVE
            </Button>
            <Button size="large" className="w-[150px]" onClick={onClose}>
              CANCEL
            </Button>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full">
          <div className="flex w-full px-2 py-4">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={setContent}
              ref={quillRef}
              className="h-80"
            />
          </div>
          <ImageConvertor />
        </div>
      </div>
    </Drawer>
  );
};

export default AddContent;
