import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { BsEye } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useContentContext } from "../providers/ContentContext";
import { NewAdmin, StatusChangeDiff, ViewAdmin } from "../components";
import { MdAdd } from "react-icons/md";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { getAdminUsers, userTableStatusChange } from "../helpers";
import { customStatusSorterDiff } from "../commonFun";
import { decryptSecureData } from "../services/EncryptionService";

const columns = [
  {
    title: "Full Name",
    dataIndex: "name",
    key: "0",
    sorter: (a, b) => a.name?.localeCompare(b.name),
    defaultSortOrder: "ascend",
  },
  {
    title: "Role",
    dataIndex: "userType",
    key: "1",
    sorter: (a, b) => a.userType?.localeCompare(b.userType),
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "2",
    sorter: (a, b) => customStatusSorterDiff(a, b),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "3",
  },
];

const Admin = () => {
  let { openNotification, handleError } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState([]);

  const [isOpenVewDrawer, setIsOpenViewDrawer] = useState(false);
  const [selectedDrawerData, setSelectedDrawerData] = useState();
  const [isOpenNewDrawer, setIsOpenNewDrawer] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    setDataLoading(true);
    await getAdminUsers()
      .then(async (response) => {
        if (response.data.success) {
          setData(response.data.output);
          const searchArray = await response.data.output.map((item) => {
            return {
              ...item,
              first_name: decryptSecureData(item.first_name),
              last_name: decryptSecureData(item.last_name),
            };
          });
          setDataForSearch(searchArray);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const search = (text) => {
    let filtered = dataForSearch.filter(
      (o) =>
        o.first_name.toLowerCase().includes(text.toLowerCase()) ||
        o.last_name.toLowerCase().includes(text.toLowerCase()) ||
        o.mobile_number.toLowerCase().includes(text.toLowerCase()) ||
        o.email_address.toLowerCase().includes(text.toLowerCase()) ||
        (o.first_name.toLowerCase() + " " + o.last_name.toLowerCase()).includes(
          text.toLowerCase()
        )
    );

    setData(filtered);
  };

  const setData = (admins) => {
    if (admins.length !== 0) {
      var tableData = [];

      admins?.forEach((admin, x) => {
        let data = {
          key: x,
          name:
            decryptSecureData(admin.first_name) +
            " " +
            decryptSecureData(admin.last_name),
          userType: admin.user_type,
          status: (
            <div>
              <Button
                className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                  admin?.is_active === 1
                    ? "bg-activeStatus"
                    : "bg-inactiveStatus"
                } `}
                onClick={() => {
                  setChangeStatus(admin);
                  openChangeStatus(admin);
                }}
              >
                <p>{admin?.is_active === 1 ? "Active" : "Inactive"}</p>
              </Button>
            </div>
          ),
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <BsEye
                className="cursor-pointer text-2xl text-primaryLight"
                onClick={() => {
                  handleViewDrawerOpenChange(admin);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  const handleNewDrawerOpenChange = () => {
    setIsOpenNewDrawer(!isOpenNewDrawer);
  };

  const handleViewDrawerOpenChange = (admin) => {
    setSelectedDrawerData(admin);
    setIsOpenViewDrawer(!isOpenVewDrawer);
  };

  const openChangeStatus = (admin) => {
    setIsModalOpen(true);
    setSelectedData(admin);
  };

  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };

  const handleStatus = async (status) => {
    const data = {
      user_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    userTableStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        ADMIN
      </div>
      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start  lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Admin..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(57) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                onClick={handleNewDrawerOpenChange}
                icon={<MdAdd className="text-2xl font-bold" />}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD ADMIN
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <div className="table-status-button">
          <Table
            dataSource={tableData}
            columns={columns}
            showSorterTooltip={false}
            loading={dataLoading}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>

      {isModalOpen === true && (
        <StatusChangeDiff
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
        />
      )}

      {isOpenNewDrawer === true && (
        <NewAdmin
          open={isOpenNewDrawer}
          onClose={handleNewDrawerOpenChange}
          setSearchText={setSearchText}
          reloadTableData={fetchData}
        />
      )}

      {isOpenVewDrawer ? (
        <ViewAdmin
          open={isOpenVewDrawer}
          onClose={handleViewDrawerOpenChange}
          selectedDrawerData={selectedDrawerData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Admin;
