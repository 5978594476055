import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loading = ({ isLoading }) => {
  return (
    <div className="flex flex-col w-full h-screen justify-center items-center">
      <ClipLoader
        className="text-primaryLight"
        color="1da1f2"
        loading={isLoading}
        size={50}
      />
    </div>
  );
};

export default Loading;
