import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import {
  adminRegister,
  getActiveStatesWithCountry,
  getActiveSuburbsWithState,
  getAdminBasicData,
  getAllLocationLevel1Data,
  getAllLocationLevel2Data,
} from "../../helpers";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { PiWarningCircleThin } from "react-icons/pi";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import { commonFieldValidateField } from "../../commonFun";

const NewAdmin = ({ open, onClose, setSearchText, reloadTableData }) => {
  let { openNotification, handleError } = useContentContext();
  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [userTypeSelectorData, setUserTypeSelectorData] = useState();

  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [stateSelectorData, setStateSelectorData] = useState([]);
  const [suburbSelectorData, setSuburbSelectorData] = useState([]);

  const [stateSelectorValue, setStateSelectorValue] = useState();
  const [suburbSelectorValue, setSuburbSelectorValue] = useState();
  const [allCountryList, setAllCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState();

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState();

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await getAdminBasicData()
      .then((response) => {
        if (response.data.success) {
          setUserTypeData(response.data.output.user_types);
          setAllCountryList(response.data.output.countries);
          setCountryData(response.data.output.countries);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const fetchState = async (e) => {
    setStateSelectorValue();
    setSuburbSelectorValue();

    await getAllLocationLevel1Data({ country_id: e })
      .then((response) => {
        if (response.data.success) {
          setStateData(response.data.output);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const fetchSuburb = async (e) => {
    setSuburbSelectorValue();

    await getAllLocationLevel2Data({
      country_id: selectedCountry,
      level1_id: e,
    })
      .then((response) => {
        if (response.data.success) {
          setSuburbData(response.data.output);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const setUserTypeData = (userTypes) => {
    var useTypeObject = [];
    userTypes?.forEach((userType) => {
      let data = {
        value: userType.id,
        label: userType.user_type,
      };
      useTypeObject.push(data);
    });
    setUserTypeSelectorData(useTypeObject);
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country.id,
        label: country.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const setStateData = (states) => {
    var object = [];
    states?.forEach((state) => {
      let data = {
        value: state?.level1_id,
        label: state?.level1_location,
      };
      object.push(data);
    });
    setStateSelectorData(object);
  };

  const setSuburbData = (suburbs) => {
    var object = [];
    suburbs?.forEach((suburb) => {
      let data = {
        // value: suburb.id,
        // label: suburb?.suberb + " - " + suburb?.postal_code,
        value: suburb?.level2_id,
        label: suburb?.level2_location,
      };
      object.push(data);
    });
    setSuburbSelectorData(object);
  };

  const onFinish = async (e) => {
    setSearchText();
    const data = {
      user_type_id: e.userType,
      first_name: e.firstName,
      last_name: e.lastName,
      country_code: countryCode,
      mobile_number: e.mobile,
      email_address: e.email,
      postal_code: e.postalCode,
      address_1: e.streetAddress,
      suberb_id: e.suburb,
      state_id: e.state,
      country_id: e.country,
      address_2: e.apt,
      push_id: "QwdiuhSDfEUhfsidfuheuIUfisueffdth",
      os_type: 1,
      device_id: "SDgogssgtyrodfiuger935ihau",
      status: e.switch === true ? 1 : 0,
    };

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);
      await adminRegister(data)
        .then((response) => {
          if (response.data.success) {
            openNotification("success", response.data.message);
            reloadTableData();
          } else {
            openNotification("error", response.data.message);
          }
          onClose();
          setIsUploading(false);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const validateField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a value"));
    }

    if (!/^[0-9+]+$/.test(value)) {
      return Promise.reject(new Error("Please enter a valid value"));
    }
    return Promise.resolve();
  };

  const validatePostalCodeField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please enter only numbers"));
    }
    return Promise.resolve();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          fields={[
            { name: ["state"], value: stateSelectorValue },
            { name: ["suburb"], value: suburbSelectorValue },
          ]}
          initialValues={{ switch: false }}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium text-primaryLight">
                Add New Admin
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form.Item
              name="userType"
              className="text-[16px]"
              label="User Type"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="User Type"
                className="!bg-secondaryWhite"
                size="large"
                options={userTypeSelectorData}
                filterOption={filterOption}
                showSearch
              />
            </Form.Item>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="firstName"
                  className="text-[16px]"
                  label="First Name"
                  required
                  rules={[{ validator: commonFieldValidateField }]}
                >
                  <Input
                    placeholder="First Name"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="lastName"
                  className="text-[16px]"
                  label="Last Name"
                  required
                  rules={[{ validator: commonFieldValidateField }]}
                >
                  <Input
                    placeholder="Last Name"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="streetAddress"
                  className="text-[16px]"
                  label="Street Address 1"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Street Address"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="apt"
                  className="text-[16px]"
                  label="Street Address 2"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="Street Address"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {/* country */}
              <Col xs={24} md={12}>
                <Form.Item
                  name="country"
                  className="text-[16px]"
                  label="Country"
                  rules={[{ required: true }]}
                >
                  <Select
                    name="country"
                    placeholder="Country"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={countrySelectorData}
                    onSelect={(e) => {
                      fetchState(e);
                      setSelectedCountry(e);
                      allCountryList?.forEach((country) => {
                        if (country.id === e) {
                          setCountryCode(country?.country_code);
                        }
                      });
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                {/* state */}
                <Form.Item
                  name="state"
                  className="text-[16px]"
                  label={selectedCountry === 2 ? "District" : "State"}
                  rules={[{ required: true }]}
                >
                  <Select
                    name="state"
                    placeholder={selectedCountry === 2 ? "District" : "State"}
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={stateSelectorData}
                    onSelect={(e) => {
                      setStateSelectorValue(e);
                      fetchSuburb(e);
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                {/* suburb */}
                <Form.Item
                  name="suburb"
                  className="text-[16px]"
                  label={selectedCountry === 2 ? "City" : "Suburb"}
                  rules={[{ required: true }]}
                >
                  <Select
                    name="suburb"
                    placeholder={selectedCountry === 2 ? "City" : "Suburb"}
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={suburbSelectorData}
                    filterOption={filterOption}
                    showSearch
                    onSelect={(e) => {
                      setSuburbSelectorValue(e);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="postalCode"
                  className="text-[16px]"
                  label="Postal Code"
                  required
                  rules={[{ validator: validatePostalCodeField }]}
                >
                  <Input
                    placeholder="Postal Code"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={16}>
              <Col xs={2}>
                <FiPhoneCall className="text-[30px] text-[primaryLight] mt-8" />
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="mobile"
                  className="text-[16px]"
                  label="Mobile Number"
                  required
                  rules={[{ validator: validateField }]}
                >
                  <Input
                    placeholder="Mobile Number"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={2}>
                <MdOutlineMailOutline className="text-[35px] text-[primaryLight] mt-8" />
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="email"
                  className="text-[16px]"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email Address"
                    className="!bg-secondaryWhite"
                    size="large"
                    type="email"
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* status */}
            <Divider />
            <div className="pb-10">
              <div className="flex flex-col gap-2">
                <div className="flex flex-col font-medium text-base">
                  Admin Status
                </div>

                <div className="flex flex-row mt-2 text-center items-baseline">
                  <span
                    className={`${
                      isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                    }`}
                  >
                    Inactive
                  </span>
                  <Form.Item name="switch" valuePropName="checked">
                    <Switch className="mx-2" onChange={handleSwitchChange} />
                  </Form.Item>
                  <span
                    className={`${
                      isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                    }`}
                  >
                    Active
                  </span>
                </div>
              </div>

              <div className="flex flex-row">
                <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                <p className="text-[12px] text-secondaryColor">
                  Changing the admin status will impact the available job types
                  within the admin
                </p>
              </div>
            </div>
            {/* button */}
            <div className="w-full">
              <Row gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <FormSubmitButton
                      form={form}
                      errormsg={false}
                      isUploading={isUploading}
                      isEditButtonEnable={false}
                      type={"ADD"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="outline-button">
                  <Button
                    size="large"
                    className="font-medium w-full "
                    onClick={onClose}
                  >
                    <p className="text-center">CANCEL</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default NewAdmin;
