import React from "react";
import { Button, Drawer, Form, Row, Col } from "antd";
import { MdClose } from "react-icons/md";
import { Divider } from "antd/es";

const ViewSuburb = ({ open, onClose, suburb }) => {
  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] view-suburb">
        <Form layout="vertical" className="w-full view-form">
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium pb-3">View Suburb</div>

              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Country Name</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{suburb?.country}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">State Name</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{suburb?.state}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Suburb Name</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{suburb?.suberb}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-5">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Postal Code</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{suburb?.postal_code}</p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10} className="pb-5">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Suburb Status</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {suburb?.is_active === 1 ? "Active" : "Inactive"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{suburb?.created_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {suburb?.created_user ? suburb.created_user : "_"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{suburb?.updated_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {suburb?.updated_user ? suburb.updated_user : "_"}
                </p>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewSuburb;
