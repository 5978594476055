import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import {
  EditCategory,
  NewCategory,
  StatusChange,
  ViewCategory,
} from "../components";
import { useContentContext } from "../providers/ContentContext";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { customStatusSorter } from "../commonFun";
import { getAllCategories, tableCategoryStatusChange } from "../helpers";

const columns = [
  {
    title: "Category",
    dataIndex: "category",
    key: "0",
    sorter: (a, b) => a.category?.localeCompare(b.category),
    defaultSortOrder: "ascend",
  },
  {
    title: "No.All Job Types",
    dataIndex: "all",
    key: "1",
    sorter: (a, b) => a.all - b.all,
    defaultSortOrder: "ascend",
  },
  {
    title: "No.Active Job Types",
    dataIndex: "jobTypes",
    key: "2",
    sorter: (a, b) => a.jobTypes - b.jobTypes,
    defaultSortOrder: "ascend",
  },
  {
    title: "No.Inactive Job Type",
    dataIndex: "inactive",
    key: "3",
    sorter: (a, b) => a.inactive - b.inactive,
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "4",
    sorter: (a, b) => customStatusSorter(a, b),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "5",
  },
];

const Category = () => {
  let { openNotification, handleError } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [jobCategories, setJobCategories] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [isOpenNewCategoryDrawer, setIsOpenNewCategoryDrawer] = useState(false);
  const [isOpenViewCategoryDrawer, setIsOpenViewCategoryDrawer] =
    useState(false);
  const [isOpenEditCategoryDrawer, setIsOpenEditCategoryDrawer] =
    useState(false);
  const [selectedData, setSelectedData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();

  const [searchText, setSearchText] = useState();
  const [categoryData, setCategoryData] = useState([]);

  const [topCount, setTopCount] = useState(0);
  const [activeTableFilterButton, setActiveTableFilteButton] = useState(0);

  useEffect(() => {
    fetchData(0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async (type) => {
    try {
      setActiveTableFilteButton(type);
      setDataLoading(true);

      await getAllCategories({ is_top: type, status: -1 })
        .then((response) => {
          if (response?.data.success) {
            setTopCount(response?.data.output[0].popular_count);
            setData(response.data.output ? response.data.output : []);
            setCategoryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const openChangeStatus = (category) => {
    setIsModalOpen(true);
    setSelectedData(category);
  };

  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };

  const setData = (categories) => {
    if (categories.length !== 0) {
      var tableData = [];
      categories.forEach((category, x) => {
        let data = {
          key: x,
          category: category?.job_category,
          jobTypes: category?.job_type.active,
          all: category?.job_type.all,
          inactive: category?.job_type.inactive,
          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                category?.is_active === 1
                  ? "bg-primaryColor"
                  : "bg-primaryLight"
              } `}
              onClick={() => {
                setChangeStatus(category?.is_active);
                openChangeStatus(category);
              }}
              disabled={!permission.includes(22)}
            >
              {category?.is_active === 1 ? "Active" : "Inactive"}
            </Button>
          ),

          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(22) && (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditCategoryDrawer(category);
                  }}
                />
              )}
              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewCategoryDrawerOpenChange(category);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
        setJobCategories(tableData);
      });
    } else {
      setJobCategories([]);
    }
    setDataLoading(false);
  };

  const search = (text) => {
    let filtered = categoryData?.filter((o) => {
      return o.job_category.toLowerCase().includes(text.toLowerCase());
    });
    setData(filtered);
  };

  const handleCategoryDrawerOpenChange = () => {
    setIsOpenNewCategoryDrawer(!isOpenNewCategoryDrawer);
  };
  const handleViewCategoryDrawerOpenChange = (category) => {
    setSelectedData(category);
    setIsOpenViewCategoryDrawer(!isOpenViewCategoryDrawer);
  };
  const openEditCategoryDrawer = (category) => {
    setSelectedData(category);
    setIsOpenEditCategoryDrawer(true);
  };
  const closeEditCategoryDrawer = () => {
    setIsOpenEditCategoryDrawer(false);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  // category status change in table
  const handleStatus = async (status) => {
    const data = {
      job_category_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    tableCategoryStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData(0);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        CATEGORY
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Category..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(21) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                onClick={handleCategoryDrawerOpenChange}
                icon={<MdAdd className="text-2xl font-bold" />}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD CATEGORY
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div className="flex items-end justify-end pb-5">
          <div className="btn-group">
            <button
              className={`font-semibold ${
                activeTableFilterButton === 0 && "bg-primaryLight text-white"
              } text-[18px] border-r-2 border-gray-300 target:!bg-primaryLight active:!bg-primaryLight focus:!bg-primaryLight  target:!text-white focus:!text-white active:!text-white px-7 py-1 rounded-l-[5px] bg-[#F3F2F2]`}
              onClick={() => {
                fetchData(0);
              }}
            >
              ALL
            </button>
            <button
              className={`font-semibold ${
                activeTableFilterButton === 1 && "bg-primaryLight text-white"
              } text-[18px] target:!bg-primaryLight active:!bg-primaryLight focus:!bg-primaryLight  target:!text-white focus:!text-white active:!text-white px-7 py-1 rounded-r-[5px] bg-[#F3F2F2]`}
              onClick={() => {
                fetchData(1);
              }}
            >
              TOP CATEGORY
            </button>
          </div>
        </div>

        <div className="table-status-button">
          <Table
            dataSource={jobCategories}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>

      {isOpenNewCategoryDrawer ? (
        <NewCategory
          onClose={handleCategoryDrawerOpenChange}
          reloadCategoryData={fetchData}
          open={isOpenNewCategoryDrawer}
          setSearchText={setSearchText}
          topCount={topCount}
        />
      ) : (
        <></>
      )}

      {isOpenViewCategoryDrawer ? (
        <ViewCategory
          open={isOpenViewCategoryDrawer}
          onClose={handleViewCategoryDrawerOpenChange}
          category={selectedData}
        />
      ) : (
        <></>
      )}

      {isOpenEditCategoryDrawer ? (
        <EditCategory
          open={isOpenEditCategoryDrawer}
          onClose={closeEditCategoryDrawer}
          category={selectedData}
          reloadCategoryData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isModalOpen && (
        <StatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default Category;
