import { GrFormNext, GrFormPrevious } from "react-icons/gr";

//table status sorting function
export const customStatusSorter = (a, b) => {
  const getStatusValue = (item) => {
    const buttonText = item.status.props.children;
    if (buttonText === "Active") {
      return 1;
    } else if (buttonText === "Inactive") {
      return 2;
    } else {
      return 0;
    }
  };
  return getStatusValue(a) - getStatusValue(b);
};

//table status sorting function for job type and admin
export const customStatusSorterDiff = (a, b) => {
  const getStatusValue = (item) => {
    const buttonText = item.status.props.children.props.children.props.children;
    if (buttonText === "Active") {
      return 1;
    } else if (buttonText === "Inactive") {
      return 2;
    } else {
      return 0;
    }
  };
  return getStatusValue(a) - getStatusValue(b);
};

//validation function
export const commonFieldValidateField = (_, value) => {
  if (!value) {
    return Promise.reject(new Error("Please enter the text"));
  }

  if (value.length > 50) {
    return Promise.reject(new Error("Text should not exceed 50 characters"));
  }

  if (value.length < 3) {
    return Promise.reject(new Error("Text should be at least 3 characters"));
  }

  if (!/^[a-zA-Z\s]+$/.test(value)) {
    return Promise.reject(
      new Error("Text should not include numbers or special characters")
    );
  }

  return Promise.resolve();
};

//table pagination
export const CustomPagination = (current, type, originalElement) => {
  if (type === "prev") {
    return (
      <div className="flex flex-row">
        <GrFormPrevious className="text-2xl" />
        <span className="text-[14px] font-medium">Previous | </span>
      </div>
    );
  }
  if (type === "next") {
    return (
      <div className="flex flex-row">
        <span className="text-[14px] font-medium"> | Next</span>
        <GrFormNext className="text-2xl" />
      </div>
    );
  }
  return originalElement;
};
