import axios from "axios";
import { config, url } from "./header";

//cart data
export async function getAllCartSummary(data) {
  return await axios.post(url + "admin/dashboard", data, config);
}

export async function getSuburbSummary(data) {
  return await axios.post(url + "admin/job/suburb", data, config);
}

export async function appCountUpdate(data) {
  return await axios.post(url + "app/count/update", data, config);
}