import { Button, Col, Divider, Drawer, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { useContentContext } from "../../providers/ContentContext";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  getJobData,
  getJobStatusColor,
  getJobStatusLabel,
  getJobUrgencyLabel,
} from "../../helpers";
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import { CollapsedLogo } from "../../assets";
import { decryptSecureData } from "../../services/EncryptionService";
import TaskerNotificationForm from "./TaskerNotificationForm";

const ViewJob = ({ open, onClose, job }) => {
  let { openNotification, handleError } = useContentContext();
  const [isLoading, setIsLoading] = useState(false);
  const [jobData, setJobData] = useState();
  const [activeTab, setActiveTab] = useState(1);

  const [openCloseNotificationDrawer, setOpenCloseNotificationDrawer] =
    useState(false);
  const [tasterData, setTaskerData] = useState();

  const handleOpenCloseNotificationDrawer = () => {
    setOpenCloseNotificationDrawer(false);
  };

  useEffect(() => {
    getJobsData();
  }, []);

  const getJobsData = async () => {
    setIsLoading(true);
    await getJobData(job.id)
      .then((response) => {
        if (response.data.success) {
          setJobData(response.data.output);
          setIsLoading(false);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[1028px]">
          {isLoading ? (
            <div className="flex justify-center pt-[350px]">
              <Spin className="!text-[primaryLight]" />
            </div>
          ) : (
            <div className="relative bg-secondaryWhite p-4 mt-2">
              <div className="w-full flex flex-row justify-between pb-5">
                <div className="flex flex-col">
                  <div className="text-2xl font-semibold text-primaryLight">
                    {jobData?.category}
                  </div>
                  <div className="text-lg font-medium text-primaryLight">
                    {jobData?.type}
                  </div>
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Row>
                <label className="p-1 text-base">Job Status : </label>
                <label
                  className={`pt-[4px] px-3 ml-2 ${getJobStatusColor(
                    jobData?.status
                  )} rounded-lg text-white`}
                >
                  {getJobStatusLabel(jobData?.status)}
                </label>
              </Row>

              <Row className="flex flex-row bg-[#eeeeee] mt-5 rounded-md w-full justify-between">
                <Col className="flex flex-col flex-1 bg-white rounded-md m-4 p-2 w-full">
                  <h2 className="flex text-lg font-semibold">
                    Job Description
                  </h2>
                  <p className="flex mt-2">{jobData?.description}</p>
                  <div className="flex mt-2 player">
                    {jobData?.audio !== null ? (
                      <audio controls>
                        <source src={jobData?.audio} type="audio/mpeg" />
                      </audio>
                    ) : (
                      <p className="mt-2 text-gray-500">No Audio Found</p>
                    )}
                  </div>
                </Col>
                <Col className="flex flex-col m-4 w-full md:w-1/4">
                  <Row className="flex flex-col mb-2 bg-white rounded-md p-2">
                    <p className="flex text-base font-normal">Client Name :</p>
                    <p className="flex text-base font-semibold">
                      {decryptSecureData(jobData?.created_by?.full_name)}
                    </p>
                    <p className="flex text-base font-normal mt-4">Address :</p>
                    <p className="flex text-base font-semibold">
                      {jobData?.created_by?.postal_code},{" "}
                      {jobData?.created_by?.suburb},{" "}
                      {jobData?.created_by?.state}
                    </p>
                  </Row>
                  <Row className="flex flex-col mb-2 bg-white rounded-md p-2">
                    <p className="flex text-base font-normal">Job ID :</p>
                    <p className="flex text-base font-semibold">
                      {jobData?.job_number}
                    </p>
                    <p className="flex text-base font-normal mt-4">
                      Job Urgency :
                    </p>
                    <p className="flex text-base font-semibold text-red-600">
                      {getJobUrgencyLabel(jobData?.duration_id)}
                    </p>
                    <p className="flex text-base font-normal mt-4">
                      Location :
                    </p>
                    <p className="flex text-base font-semibold">
                      {jobData?.postal_code}, {jobData?.suburb},{" "}
                      {jobData?.state}
                    </p>
                    <p className="flex text-base font-normal mt-4">
                      Posted on :
                    </p>
                    <p className="flex text-base font-semibold">
                      {jobData?.created_date}
                    </p>
                  </Row>
                </Col>
              </Row>

              <Divider className="bg-[#eeeeee] h-[2px]" />

              <Row className="flex flex-col">
                <h2 className="flex text-lg font-semibold">
                  Dynamic Questions
                </h2>
              </Row>

              <Row className="flex flex-col mt-2">
                {jobData?.question?.length === 0 ? (
                  <>No Questions</>
                ) : (
                  <>
                    {jobData?.question?.map((question, x) => {
                      return (
                        <div id={x}>
                          <div className="flex flex-row items-baseline text-center">
                            <p className="flex text-base font-semibold w-5">
                              {x + 1}.
                            </p>
                            <p className="flex text-lg font-semibold">
                              {question?.question}
                            </p>
                          </div>
                          <h2 className="flex text-base font-normal mt-2">
                            Questions
                          </h2>
                          {question?.fields?.map((field, y) => {
                            return (
                              <Row className="flex flex-col mt-2 ml-2">
                                <div className="flex flex-row items-baseline text-center">
                                  <p className="flex text-base font-semibold w-5">
                                    {y + 1}.
                                  </p>
                                  <p className="flex text-base font-semibold">
                                    {field?.title}
                                  </p>
                                </div>
                                {/* questions */}
                                <Row className="w-full">
                                  <Col className="py-5">
                                    {(field?.field_type === 2 &&
                                      field?.input_type === 1) ||
                                    (field?.field_type === 2 &&
                                      field?.input_type === 2) ? (
                                      <p className="font-medium text-[16px] bg-secondaryLight p-2 rounded">
                                        {field?.answer
                                          ?.filter(
                                            (item) => item?.is_selected === 1
                                          )
                                          .map((item) => item?.value)
                                          .join(", ") || "No Answer"}
                                      </p>
                                    ) : (
                                      <p className="font-medium text-[16px] bg-secondaryLight p-2 rounded">
                                        {field?.field_value
                                          ? field?.field_value
                                          : "No Answer"}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Row>
                            );
                          })}

                          <hr className="mb-4" />
                        </div>
                      );
                    })}
                  </>
                )}
              </Row>

              <Divider className="bg-[#eeeeee] h-[2px]" />

              <Row className="flex flex-row mt-4">
                <Col className="flex flex-col w-full md:w-1/2">
                  <h2 className="flex text-lg font-semibold">Timeline</h2>
                  {jobData?.log?.length === 0 ? (
                    <>No Logs</>
                  ) : (
                    <>
                      {jobData?.log?.map((log) => {
                        return (
                          <>
                            <Row className="flex flex-col mt-2">
                              <div className="flex text-base font-semibold">
                                {log?.created_at}
                              </div>
                              <div className="flex text-base font-normal">
                                {log?.description}
                              </div>
                            </Row>
                            <hr />
                          </>
                        );
                      })}
                    </>
                  )}
                </Col>
                <Col className="flex flex-col w-full mt-5 md:mt-0 md:w-1/2 bg-[#eeeeee] rounded-md">
                  <Row className="flex flex-row gap-4 m-2">
                    <div
                      className={`flex flex-row gap-1 cursor-pointer px-2 py-1 rounded-md ${
                        activeTab === 1 ? "bg-white shadow-xl" : ""
                      }`}
                      onClick={() => {
                        setActiveTab(1);
                      }}
                    >
                      <FaUser
                        className={`w-5 h-5 cursor-pointer ${
                          activeTab === 1 ? "text-orange-400" : "text-[#8C8C8C]"
                        }`}
                      />
                      <label
                        className={`flex text-base cursor-pointer font-normal ${
                          activeTab === 1 ? "text-black" : "text-[#8C8C8C]"
                        }`}
                      >
                        Related Taskers
                      </label>
                    </div>

                    <div
                      className={`flex flex-row gap-1 cursor-pointer px-2 py-1 rounded-md ${
                        activeTab === 2 ? "bg-white shadow-xl" : ""
                      }`}
                      onClick={() => {
                        setActiveTab(2);
                      }}
                    >
                      <BsFillHandThumbsUpFill
                        className={`w-5 h-5 cursor-pointer ${
                          activeTab === 2 ? "text-orange-400" : "text-[#8C8C8C]"
                        }`}
                      />
                      <label
                        className={`flex text-base cursor-pointer font-normal ${
                          activeTab === 2 ? "text-black" : "text-[#8C8C8C]"
                        }`}
                      >
                        Interested
                      </label>
                    </div>

                    <div
                      className={`flex flex-row gap-1 cursor-pointer px-2 py-1 rounded-md ${
                        activeTab === 3 ? "bg-white shadow-xl" : ""
                      }`}
                      onClick={() => {
                        setActiveTab(3);
                      }}
                    >
                      <BsCheckCircleFill
                        className={`w-5 h-5 cursor-pointer ${
                          activeTab === 3 ? "text-orange-400" : "text-[#8C8C8C]"
                        }`}
                      />
                      <label
                        className={`flex text-base cursor-pointer font-normal ${
                          activeTab === 3 ? "text-black" : "text-[#8C8C8C]"
                        }`}
                      >
                        Selected
                      </label>
                    </div>
                  </Row>

                  {activeTab === 1 ? (
                    <>
                      {jobData?.interst_tradie?.length === 0 ? (
                        <div className="flex flex-col w-full text-center justify-center">
                          <div className="text-base font-bold text-[#8C8C8C]">
                            No interested taskers
                          </div>
                          <div className="text-base text-[#8C8C8C]">
                            Taskers who are interested in the job will appear
                            here.
                          </div>
                        </div>
                      ) : (
                        <>
                          {jobData?.interst_tradie?.map((log) => {
                            return (
                              <>
                                <Row className="flex flex-row justify-between px-2 py-1 gap-2 text-[#8C8C8C]">
                                  <Col className="flex w-[60%] text-base font-semibold">
                                    Taskers
                                  </Col>
                                  <Col className="flex w-[35%] items-center text-base font-semibold">
                                    Responded at
                                  </Col>
                                </Row>

                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setOpenCloseNotificationDrawer(true);
                                    setTaskerData(log);
                                  }}
                                >
                                  <Row
                                    className={`flex flex-row justify-between items-center px-2 py-1 gap-2 rounded-lg ${
                                      log?.is_view === 1
                                        ? "bg-primaryColor"
                                        : "bg-white"
                                    } shadow-md m-2`}
                                  >
                                    <Col className="flex w-[50%]">
                                      <div className="flex flex-row">
                                        <img
                                          className="w-16 h-16 rounded-full"
                                          src={
                                            log?.profile_picture === null
                                              ? CollapsedLogo
                                              : log?.profile_picture
                                          }
                                        />
                                        <div className="flex flex-col gap-1">
                                          <p className="break-words  font-semibold text-sm">
                                            {decryptSecureData(log?.trady_name)}
                                          </p>
                                          <div className="flex flex-row gap-1">
                                            <FaStar className="text-[#E4AD1E]" />
                                            <div className="text-black">
                                              {log?.rate}
                                            </div>
                                            <div className="text-[#8C8C8C]">
                                              ({log?.review_count})
                                            </div>
                                          </div>
                                          <div className="flex flex-row gap-1">
                                            <div className="text-black">
                                              {log?.recommended_count}
                                            </div>
                                            <div className="text-[#8C8C8C]">
                                              recommended
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col className="flex w-[35%] font-semibold text-sm">
                                      {log?.created_at}
                                    </Col>
                                    <Col className="flex w-[10%]">
                                      <IoMdNotificationsOutline className="text-[25px]" />
                                    </Col>
                                  </Row>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : activeTab === 2 ? (
                    <>
                      {jobData?.shortlist_tradie?.length === 0 ? (
                        <div className="flex flex-col w-full text-center justify-center">
                          <div className="text-base font-bold text-[#8C8C8C]">
                            Client not shortlisted taskers yet
                          </div>
                          <div className="text-base text-[#8C8C8C]">
                            shortlisted taskers will appear here
                          </div>
                        </div>
                      ) : (
                        <>
                          {jobData?.shortlist_tradie?.map((log) => {
                            return (
                              <>
                                <Row className="flex flex-row justify-between px-2 py-1 gap-2 text-[#8C8C8C]">
                                  <Col className="flex w-[60%] text-base font-semibold">
                                    Taskers
                                  </Col>
                                  <Col className="flex w-[35%] items-center text-base font-semibold">
                                    Responded at
                                  </Col>
                                </Row>
                                <Row className="flex flex-row justify-between px-2 py-1 gap-2 bg-white rounded-lg shadow-md m-2">
                                  <Col className="flex w-[60%]">
                                    <div className="flex flex-row">
                                      <img
                                        className="w-16 h-16 rounded-full"
                                        src={
                                          log.profile_picture === null
                                            ? CollapsedLogo
                                            : log?.profile_picture
                                        }
                                      />
                                      <div className="flex flex-col gap-1">
                                        <p className="break-words  font-semibold text-base">
                                          {decryptSecureData(log?.trady_name)}
                                        </p>
                                        <div className="flex flex-row gap-1">
                                          <FaStar className="text-[#E4AD1E]" />
                                          <div className="text-black">
                                            {log?.rate}
                                          </div>
                                          <div className="text-[#8C8C8C]">
                                            ({log?.review_count})
                                          </div>
                                        </div>
                                        <div className="flex flex-row gap-1">
                                          <div className="text-black">
                                            {log?.recommended_count}
                                          </div>
                                          <div className="text-[#8C8C8C]">
                                            recommended
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col className="flex w-[35%] font-semibold text-base">
                                    {log?.created_at}
                                  </Col>
                                </Row>
                                <hr />
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {jobData?.award_tradie?.length === 0 ? (
                        <div className="flex flex-col w-full text-center justify-center">
                          <div className="text-base font-bold text-[#8C8C8C]">
                            Client not awarded the job to a tasker yet
                          </div>
                          <div className="text-base text-[#8C8C8C]">
                            The tasker who got awarded the job will appear here
                          </div>
                        </div>
                      ) : (
                        <>
                          {jobData?.award_tradie?.map((log) => {
                            return (
                              <>
                                <Row className="flex flex-row justify-between px-2 py-1 gap-2 text-[#8C8C8C]">
                                  <Col className="flex w-[60%] text-base font-semibold">
                                    Taskers
                                  </Col>
                                  <Col className="flex w-[35%] items-center text-base font-semibold">
                                    Responded at
                                  </Col>
                                </Row>
                                <Row className="flex flex-row justify-between px-2 py-1 gap-2 bg-white rounded-lg shadow-md m-2">
                                  <Col className="flex w-[60%]">
                                    <div className="flex flex-row">
                                      <img
                                        className="w-16 h-16 rounded-full"
                                        src={
                                          log.profile_picture === null
                                            ? CollapsedLogo
                                            : log?.profile_picture
                                        }
                                      />
                                      <div className="flex flex-col gap-1">
                                        <p className="break-words  font-semibold text-base">
                                          {decryptSecureData(log?.trady_name)}
                                        </p>
                                        <div className="flex flex-row gap-1">
                                          <FaStar className="text-[#E4AD1E]" />
                                          <div className="text-black">
                                            {log?.rate}
                                          </div>
                                          <div className="text-[#8C8C8C]">
                                            ({log?.review_count})
                                          </div>
                                        </div>
                                        <div className="flex flex-row gap-1">
                                          <div className="text-black">
                                            {log?.recommended_count}
                                          </div>
                                          <div className="text-[#8C8C8C]">
                                            recommended
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col className="flex w-[35%] font-semibold text-base">
                                    {log?.created_at}
                                  </Col>
                                </Row>
                                <hr />
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                  <Row className="flex flex-col mt-2"></Row>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Drawer>

      {openCloseNotificationDrawer && (
        <TaskerNotificationForm
          open={openCloseNotificationDrawer}
          onClose={handleOpenCloseNotificationDrawer}
          jobData={job}
          tasterData={tasterData}
        />
      )}
    </>
  );
};

export default ViewJob;
