import { Button, Col, Modal, Row } from "antd";
import React from "react";

const AppDownloadDelete = ({ open, onCancel, deletePermanently }) => {
  return (
    <Modal open={open} onCancel={onCancel}>
      <p className="text-[24px] font-medium text-center">
        Are you sure you want to delete this record?
      </p>

      <p className="text-[16px] font-normal text-secondaryRed text-center pt-5">
        Deleting it will permanently remove this record.
      </p>

      <Row className="flex flex-row justify-between px-10 pt-5" gutter={64}>
        <Col xs={12} className="tradie-block-button w-full">
          <Button
            className="bg-primaryRed text-white w-full border-none"
            size="large"
            onClick={() => {
              deletePermanently();
            }}
          >
            DELETE
          </Button>
        </Col>
        <Col xs={12} className="w-full outline-button">
          <Button
            size="large"
            onClick={() => {
              onCancel();
            }}
            className="w-full"
          >
            CANCEL
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default AppDownloadDelete;
