import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Drawer, Form, Row, Spin } from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import axios from "axios";

const ViewAdminRole = ({ open, onClose, selectedAdminRole }) => {
  let { loadAllPermissionList, handleError, loadAdminRolePermissions } =
    useContentContext();
  const [data, setData] = useState([]);
  const [initialPermission, setInitialPermission] = useState();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
    fetchExistingPermissstionData();
  }, []);

  //Permission list(admin roles)
  const fetchData = async () => {
    try {
      const permissionList = await loadAllPermissionList();
      setData(permissionList);
    } catch (error) {
      handleError(error);
    }
  };

  //fetch exixtting permission data
  const fetchExistingPermissstionData = async () => {
    try {
      const exixtingPermissstionData = await loadAdminRolePermissions();
      await exixtingPermissstionData.map((permissionData) => {
        if (permissionData.id === selectedAdminRole?.id) {
          if (permissionData.is_set_permission === 1) {
            const initialPermissionList = {};
            for (let i = 0; i < permissionData.permissions.length; i++) {
              initialPermissionList[permissionData.permissions[i].permission] =
                permissionData.permissions[i].has_permission === 1
                  ? true
                  : false;
            }
            setInitialPermission(initialPermissionList);
          } else {
            setInitialPermission({
              user_type_add: false,
              user_type_edit: false,
              user_type_delete: false,
              user_type_view: false,
              user_add: false,
              user_edit: false,
              user_delete: false,
              user_view: false,
              // country_add: false,
              // country_edit: false,
              // country_delete: false,
              // country_view: false,
              // state_add: false,
              // state_delete: false,
              // state_edit: false,
              // state_view: false,
              // suberb_add: false,
              // suberb_delete: false,
              // suberb_view: false,
              // suberb_edit: false,
              job_category_edit: false,
              job_category_add: false,
              job_category_delete: false,
              job_category_view: false,
              job_type_add: false,
              job_type_edit: false,
              job_type_delete: false,
              job_type_view: false,
              job_add: false,
              job_edit: false,
              job_delete: false,
              job_view: false,
              // unit_add: false,
              // unit_edit: false,
              // unit_delete: false,
              // unit_view: false,
              configuration_add: false,
              configuration_edit: false,
              configuration_delete: false,
              configuration_view: false,
              support_add: false,
              support_edit: false,
              support_delete: false,
              support_view: false,
              verification_add: false,
              verification_edit: false,
              verification_delete: false,
              verification_view: false,
              terms_add: false,
              terms_edit: false,
              terms_delete: false,
              terms_view: false,
            });
          }
          setIsLoading(false);
        }
      });
    } catch (error) {
      handleError(error);
    }
  };

  const renderColumns = (rowData, rowIndex) => {
    const columns = [];
    const actions =
      rowIndex === 0 ? ["Add", "Edit", "Delete", "View"] : ["", "", "", ""];

    for (let i = 0; i < rowData.length; i++) {
      const actionIndex = i % actions.length;
      columns.push(
        <Col span={4} key={rowData[i].id} className="permission-column">
          <Form.Item
            name={rowData[i].permission}
            label={`${actions[actionIndex]}`}
            valuePropName="checked"
          >
            <Checkbox className="pointer-events-none" />
          </Form.Item>
        </Col>
      );
    }
    return columns;
  };

  const renderRows = () => {
    const rows = [];
    const userTypes = [
      "Category",
      "JobType",
      "Job Overview",

      "Client",
      "Tasker",
      "Admin",

      "Veri-center",

      // "Country",
      // "State",
      // "Suburb",
      // "Unit",
      "Admin Roles",

      "Blog",
      "Promo SMS",

      "Report",

      "Graph",

      "Support",

      "Reset password",
      "Configuration",
      "Terms & con",
      "App Count",
    ];
    for (let i = 0; i < data.length; i += 4) {
      const userType = userTypes[i / 4];
      rows.push(
        <div key={i}>
          <Row gutter={16}>
            <Col span={6}>
              {i === 0 ? (
                <p className="text-[16px] pb-2 text-primaryLight">Permission</p>
              ) : (
                <></>
              )}
              <p className="text-[14px] font-medium pb-3">{userType}:</p>
            </Col>

            {renderColumns(data.slice(i, i + 4), i)}
          </Row>
          <Divider className="!m-0" />
        </div>
      );
    }
    return rows;
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px] add-permission">
        {isLoading ? (
          <div className="flex justify-center pt-[350px]">
            <Spin className="!text-[primaryLight]" />
          </div>
        ) : (
          <Form
            layout="vertical"
            className="w-full add-form"
            initialValues={initialPermission}
          >
            <div className="relative bg-secondaryWhite p-7 ">
              <div className="w-full flex flex-row  justify-between">
                <div className="text-[16px] font-medium text-primaryLight pb-5">
                  Admin Details
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Form.Item label="Role Name">
                <p className="pb-5 text-[16px] font-medium">
                  {selectedAdminRole?.user_type}
                </p>
              </Form.Item>

              <Divider />

              <div className="pb-8">{renderRows()}</div>

              <Form.Item label="Role Status">
                <p className="pb-5 text-[16px] font-medium">
                  {selectedAdminRole?.is_active === 1 ? "Active" : "Inactive"}
                </p>
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
    </Drawer>
  );
};

export default ViewAdminRole;
