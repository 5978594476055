import React, { useState } from "react";
import { Button, Drawer, Form, Input, Row, Col } from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import _ from "lodash";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import { commonFieldValidateField } from "../../commonFun";
import { checkUnit, editUnit } from "../../helpers";

const EditJobData = ({
  open,
  onClose,
  jobData,
  reloadJobData,
  setSearchText,
}) => {
  let { handleError, openNotification } = useContentContext();
  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isEditButtonEnable, setIsEditButonEnable] = useState(true);
  const [form] = Form.useForm();
  const userId = getLocalStoragedata("userId");

  const onFinish = async (e) => {
    setSearchText();

    const checkData = {
      uom: e.uom,
    };

    const response = await checkUnit(checkData)
      .then((response) => {
        if (response.data.success) {
          if (jobData?.uom === e.uom) {
            return 0;
          } else {
            return response.data.output.has_uom;
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });

    if (response === 0) {
      const data = {
        uom_id: jobData?.id,
        uom: e.uom,
        uom_label: e.shortName,
        updated_by: userId,
      };

      if (isUploading) {
        openNotification("warning", "Please wait Upload on progress !");
      } else {
        setIsUploading(true);
        editUnit(data)
          .then((response) => {
            if (response.data.success) {
              reloadJobData();
              openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
            onClose();
            setIsUploading(false);
          })
          .catch((error) => {
            handleError(error);
          });
      }
    } else {
      setErrormsg(true);
    }
  };

  const formInitialState = {
    uom: jobData?.uom,
    shortName: jobData?.uom_label,
  };

  const onFieldsChange = (e) => {
    if (_.isEqual(form.getFieldsValue(), formInitialState)) {
      setIsEditButonEnable(true);
    } else {
      setIsEditButonEnable(false);
    }
  };

  const validateField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the text"));
    }
    if (!/^[a-zA-Z\s]+$/.test(value)) {
      return Promise.reject(new Error("Please enter a valid text"));
    }
    return Promise.resolve();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          className="w-full edit-form"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          initialValues={{
            uom: jobData?.uom,
            shortName: jobData?.uom_label,
          }}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium text-primaryLight pb-4">
                Edit Unit
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="uom"
                  label="Unit Of Measure"
                  required
                  rules={[{ validator: commonFieldValidateField }]}
                >
                  <Input
                    placeholder="Unit Of Measure"
                    className="!bg-secondaryWhite"
                    size="large"
                    onChange={() => {
                      setErrormsg(false);
                    }}
                    maxLength={20}
                  />
                </Form.Item>

                {errormsg ? (
                  <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px]">
                    UOM already exist
                  </p>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="shortName"
                  label="Short Name"
                  required
                  rules={[{ validator: validateField }]}
                >
                  <Input
                    placeholder="Short Name"
                    className="!bg-secondaryWhite"
                    size="large"
                    onChange={(e) => {
                      setErrormsg(false);
                    }}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="w-full pl-7 pb-5">
            <Row gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item>
                  <FormSubmitButton
                    form={form}
                    errormsg={errormsg}
                    isUploading={isUploading}
                    isEditButtonEnable={isEditButtonEnable}
                    type={"SAVE"}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="outline-button">
                <Button
                  size="large"
                  className="font-medium w-full "
                  onClick={onClose}
                >
                  <p className="text-center">CANCEL</p>
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default EditJobData;
