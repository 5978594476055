import {
  Col,
  Input,
  Row,
  Segmented,
  Table,
  Select,
  Button,
  Popover,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye, BsFilter } from "react-icons/bs";
import {
  getActiveStatesWithCountry,
  getActiveSuburbsWithState,
  getAllCategories,
  getAllCountries,
  getAllJobStatuses,
  getAllJobTypes,
  getAllJobs,
  getAllLocationLevel1Data,
  getAllLocationLevel2Data,
  getAllPromoSmsCountries,
  getJobStatusLabel,
} from "../helpers";
import { ViewJob } from "../components";
import { useContentContext } from "../providers/ContentContext";
import moment from "moment/moment";

const columns = [
  {
    title: "Job Number",
    dataIndex: "id",
    key: "0",
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: "ascend",
  },
  {
    title: "Job Category",
    dataIndex: "category",
    key: "1",
    sorter: (a, b) => a.category?.localeCompare(b.category),
    defaultSortOrder: "ascend",
  },
  {
    title: "Job Type",
    dataIndex: "type",
    key: "2",
    sorter: (a, b) => a.type?.localeCompare(b.type),
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "jobStatus",
    key: "3",
    sorter: (a, b) => a.jobStatus?.localeCompare(b.jobStatus),
    defaultSortOrder: "ascend",
  },
  {
    title: "Posted Date",
    dataIndex: "date",
    key: "4",
    sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "5",
  },
];

const JobOverview = () => {
  let { openNotification, handleError } = useContentContext();

  const [segment, setSegment] = useState("Last 3 Months");
  const [loading, setLoading] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [viewJob, setViewJob] = useState(false);
  const [selectedJob, setSelectedUJob] = useState();
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [suburbList, setSuburbList] = useState();

  //filters
  const [searchText, setSearchText] = useState();
  const [dataSegment, setDataSegment] = useState(4);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedSuburb, setSelectedSuburb] = useState();
  const [categoryList, setCategoryList] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [jobTypeList, setJobTypeList] = useState();
  const [selectedJobType, setSelectedJobType] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusList, setStatusList] = useState();

  const [selectedFilteredcategory, setSelectFilteredcategory] = useState();
  const [selectFilteredJobType, setSelectFilteredJobType] = useState();
  const [selectFilteredJobStatus, setSelectFilteredJobStatus] = useState();
  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    cleaFilters();
    setStatusList(getAllJobStatuses());
    getJobData(4);
    getCountryList();
    getCategoryList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryList = async () => {
    await getAllCategories({ is_top: -1, status: 1 })
      .then((response) => {
        if (response?.data.success) {
          var categories = [];
          response.data.output?.forEach((category) => {
            let data = {
              value: category.id,
              label: category.job_category,
            };
            categories.push(data);
          });
          setCategoryList(categories);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getJobTypes = async (e) => {
    await getAllJobTypes({ job_category_id: e, status: 1, is_urgent: -1 })
      .then((response) => {
        if (response?.data.success) {
          var types = [];
          response.data.output?.forEach((type) => {
            let data = {
              value: type.id,
              label: type.job_type,
            };
            types.push(data);
          });
          setJobTypeList(types);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getJobData = async (
    dataType,
    country,
    state,
    suburb,
    status,
    category_id,
    job_type_id,
    from,
    to
  ) => {
    setLoading(true);

    await getAllJobs(
      dataType,
      country,
      state,
      suburb,
      category_id,
      job_type_id,
      status
    )
      .then((response) => {
        if (response.data.success) {
          setAllJobs(response.data.output);
          mapToTable(response.data.output);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  };

  // const getCountryList = async () => {
  //   await getAllCountries({ status: 1 })
  //     .then((response) => {
  //       if (response.data.success) {
  //         var countries = [];
  //         response.data.output?.forEach((country) => {
  //           let data = {
  //             value: country.id,
  //             label: country.country,
  //           };
  //           countries.push(data);
  //         });
  //         setCountryList(countries);
  //       } else {
  //         openNotification(
  //           "warning",
  //           "Data Loading Failed!",
  //           response.data.message
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       handleError(error);
  //     });
  // };

  const getCountryList = async () => {
    await getAllPromoSmsCountries({ status: 1 })
      .then((response) => {
        if (response.data.success) {
          var countries = [];
          response.data.output?.forEach((country) => {
            let data = {
              value: country?.country_id,
              label: country?.country,
            };
            countries.push(data);
          });
          setCountryList(countries);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getStateList = async (e) => {
    await getAllLocationLevel1Data({ country_id: e })
      .then((response) => {
        if (response.data.success) {
          var states = [];
          response.data.output?.forEach((state) => {
            let data = {
              value: state?.level1_id,
              label: state?.level1_location,
            };
            states.push(data);
          });
          setStateList(states);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const getSuburbList = async (e) => {
    await getAllLocationLevel2Data({
      country_id: selectedCountry,
      level1_id: e,
    })
      .then((response) => {
        if (response.data.success) {
          var suburbs = [];
          response.data.output?.forEach((suburb) => {
            let data = {
              // value: suburb.id,
              // label: suburb?.suberb + " - " + suburb?.postal_code,
              value: suburb?.level2_id,
              label: suburb?.level2_location,
            };
            suburbs.push(data);
          });
          setSuburbList(suburbs);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const mapToTable = (data) => {
    if (data?.length !== 0) {
      var tableData = [];

      data?.forEach((job, x) => {
        let data = {
          key: x,
          id: job.job_number,
          category: job?.job_category,
          type: job.job_type,
          jobStatus: getJobStatusLabel(job.status_id),
          date: job.posted_date,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  viewJobDetails(job);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setJobs(tableData);
    } else {
      setJobs([]);
    }
    setLoading(false);
  };

  const viewJobDetails = (job) => {
    setSelectedUJob(job);
    setViewJob(true);
  };

  const closeJobDetailsView = () => {
    setViewJob(false);
  };

  const setSegmentFilter = (segment) => {
    setSearchText();
    setSegment(segment);
    if (segment === "Today") {
      setDataSegment(1);
      getJobData(
        1,
        selectedCountry,
        selectedState,
        selectedSuburb,
        selectedStatus,
        selectedCategory,
        selectedJobType
      );
    } else if (segment === "Last 7 Days") {
      setDataSegment(2);
      getJobData(
        2,
        selectedCountry,
        selectedState,
        selectedSuburb,
        selectedStatus,
        selectedCategory,
        selectedJobType
      );
    } else if (segment === "This Month") {
      setDataSegment(3);
      getJobData(
        3,
        selectedCountry,
        selectedState,
        selectedSuburb,
        selectedStatus,
        selectedCategory,
        selectedJobType
      );
    } else {
      setDataSegment(4);
      getJobData(
        4,
        selectedCountry,
        selectedState,
        selectedSuburb,
        selectedStatus,
        selectedCategory,
        selectedJobType
      );
    }
  };

  const cleaFilters = () => {
    setSelectedCountry();
    setSelectedState();
    setSelectedSuburb();
    setSelectedCategory();
    setSelectedJobType();
    setSelectedStatus();
    setSearchText();
    setSegment("Last 3 Months");
    getJobData(4);
    setSelectFilteredJobStatus("");
    setSelectFilteredJobType("");
    setSelectFilteredcategory("");
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  const search = (text) => {
    let filtered = allJobs.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );
    mapToTable(filtered);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterContents = (
    <div className="flex flex-col w-96 gap-4">
      <div className="w-full gap-1">
        <p className="flex text-base font-normal">Catogory</p>
        <Select
          name="category"
          placeholder="Category"
          className="!bg-secondaryWhite w-full"
          size="large"
          options={categoryList}
          value={selectedCategory}
          filterOption={filterOption}
          onSelect={(e) => {
            setSelectedJobType();
            setSelectedCategory(e);
            getJobTypes(e);
            getJobData(
              dataSegment,
              selectedCountry,
              selectedState,
              selectedSuburb,
              selectedStatus,
              e,
              selectedJobType
            );
            filteredCategory(e);
          }}
          showSearch
        />
      </div>
      <div className="w-full gap-1">
        <p className="flex text-base font-normal">Job Type</p>
        <Select
          name="jobType"
          placeholder="Job Type"
          className="!bg-secondaryWhite w-full"
          size="large"
          options={jobTypeList}
          onSelect={(e) => {
            setSelectedJobType(e);
            getJobData(
              dataSegment,
              selectedCountry,
              selectedState,
              selectedSuburb,
              selectedStatus,
              selectedCategory,
              e
            );
            filteredJobType(e);
          }}
          value={selectedJobType}
          filterOption={filterOption}
          showSearch
          disabled={selectedCategory === undefined}
        />
      </div>
      <hr />
      <div className="w-full gap-1">
        <p className="flex text-base font-normal">Job Status</p>
        <Select
          name="status"
          placeholder="Job Status"
          className="!bg-secondaryWhite w-full"
          size="large"
          options={statusList}
          onSelect={(e) => {
            setSelectedStatus(e);
            getJobData(
              dataSegment,
              selectedCountry,
              selectedState,
              selectedSuburb,
              e,
              selectedCategory,
              selectedJobType
            );
            filteredJobStatus(e);
          }}
          value={selectedStatus}
          filterOption={filterOption}
          showSearch
        />
      </div>
      <div className="w-full">
        <Button
          className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9 w-full"
          type="primary"
          size="large"
          onClick={() => {
            setOpenPopover(false);
          }}
        >
          <p className="text-secondaryWhite text-[18px] font-semibold w-full text-center">
            APPLY
          </p>
        </Button>
      </div>
    </div>
  );

  const filteredCategory = (e) => {
    categoryList.forEach((item) => {
      if (item?.value === e) {
        setSelectFilteredcategory(item.label);
      }
    });
  };
  const filteredJobType = (e) => {
    jobTypeList.forEach((item) => {
      if (item?.value === e) {
        setSelectFilteredJobType(item.label);
      }
    });
  };
  const filteredJobStatus = (e) => {
    statusList.forEach((item) => {
      if (item?.value === e) {
        setSelectFilteredJobStatus(item.label);
      }
    });
  };
  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };
  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        JOBS OVERVIEW
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start sm:items-end lg:flex-row lg:justify-between pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Jobs..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            <Segmented
              options={["Today", "Last 7 Days", "This Month", "Last 3 Months"]}
              value={segment}
              onChange={(e) => {
                setSegmentFilter(e);
              }}
            />
          </Col>
        </Row>
        <Row className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pb-3">
          <div className="w-full">
            <p>Country</p>
            <Select
              name="country"
              placeholder="Country"
              className="!bg-secondaryWhite w-full"
              size="large"
              options={countryList}
              onSelect={(e) => {
                setSelectedState();
                setSelectedSuburb();
                setSelectedCountry(e);
                getStateList(e);
                getJobData(
                  dataSegment,
                  e,
                  selectedState,
                  selectedSuburb,
                  selectedStatus,
                  selectedCategory,
                  selectedJobType
                );
              }}
              value={selectedCountry}
              filterOption={filterOption}
              showSearch
            />
          </div>
          <div className="w-full">
            <p>{selectedCountry === 2 ? "District" : "State"}</p>
            <Select
              name="state"
              placeholder={selectedCountry === 2 ? "District" : "State"}
              className="!bg-secondaryWhite w-full"
              size="large"
              options={stateList}
              onSelect={(e) => {
                setSelectedSuburb();
                setSelectedState(e);
                getSuburbList(e);
                getJobData(
                  dataSegment,
                  selectedCountry,
                  e,
                  selectedSuburb,
                  selectedStatus,
                  selectedCategory,
                  selectedJobType
                );
              }}
              value={selectedState}
              filterOption={filterOption}
              showSearch
              disabled={selectedCountry === undefined}
            />
          </div>
          <div className="w-full">
            <p>{selectedCountry === 2 ? "City" : "Suburb"}</p>
            <Select
              name="suburb"
              placeholder={selectedCountry === 2 ? "City" : "Suburb"}
              className="!bg-secondaryWhite w-full"
              size="large"
              options={suburbList}
              onSelect={(e) => {
                setSelectedSuburb(e);
                getJobData(
                  dataSegment,
                  selectedCountry,
                  selectedState,
                  e,
                  selectedStatus,
                  selectedCategory,
                  selectedJobType
                );
              }}
              value={selectedSuburb}
              filterOption={filterOption}
              showSearch
              disabled={selectedState === undefined}
            />
          </div>
          <div className="w-full">
            <p>&nbsp;</p>
            <Button
              size="large"
              className="font-medium hover:!border-primaryLight hover:!text-primaryLight"
              onClick={cleaFilters}
            >
              <p className="text-center">CLEAR</p>
            </Button>
          </div>
        </Row>
        <Row className="pt-6 pb-6">
          <Popover
            placement="bottomLeft"
            content={filterContents}
            open={openPopover}
            onOpenChange={handleOpenChange}
          >
            <div className="flex flex-row gap-1">
              <BsFilter className="w-6 h-6" />
              <p className="flex text-black font-semibold text-base">Filter</p>
            </div>
          </Popover>
          <div className="ml-6">
            {(selectedFilteredcategory !== undefined ||
              selectFilteredJobType !== undefined ||
              selectFilteredJobStatus !== undefined) && (
              <div className="flex flex-row">
                {selectedFilteredcategory !== "" && (
                  <p className="mr-6 px-2 bg-secondaryDarkshade rounded-xl">
                    {selectedFilteredcategory}
                  </p>
                )}
                {selectFilteredJobType !== "" && (
                  <p className="mr-6 px-2 bg-secondaryDarkshade rounded-xl">
                    {selectFilteredJobType}
                  </p>
                )}
                {selectFilteredJobStatus !== "" && (
                  <p className="mr-6 px-2 bg-secondaryDarkshade rounded-xl">
                    {selectFilteredJobStatus}
                  </p>
                )}
              </div>
            )}
          </div>
        </Row>
        <Row className="flex flex-col">
          <Table
            dataSource={jobs}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
            loading={loading}
            scroll={{ x: "100%" }}
          />
        </Row>
      </Content>

      {viewJob && (
        <ViewJob
          onClose={closeJobDetailsView}
          open={viewJob}
          job={selectedJob}
        />
      )}
    </>
  );
};

export default JobOverview;
