import React from "react";
import { Button, Divider, Drawer, Form, Col, Row } from "antd";
import { MdClose } from "react-icons/md";

const ViewState = ({ open, onClose, state }) => {
  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px]  view-state">
        <Form layout="vertical" className="w-full view-form">
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium pb-3">View State</div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Country Name</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{state?.country}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">State Name</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{state?.state}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-5">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Time Zone</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{state?.timezone}</p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10} className="pb-5">
              <Col xs={12}>
                <p className="text-[16px] font-medium">State Status</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {state?.is_active === 1 ? "Active" : "Inactive"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{state?.created_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {state?.created_user ? state.created_user : "_"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{state?.updated_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {state?.updated_user ? state.updated_user : "_"}
                </p>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewState;
