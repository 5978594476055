import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { useContentContext } from "../providers/ContentContext";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import NewBlog from "../components/blogs/NewBlog";
import { getAllBlogs, viewBlog } from "../helpers/apiHelpers/cmsHelpers";
import EditBlog from "../components/blogs/EditBlog";
import ViewBlog from "../components/blogs/ViewBlog";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "0",
    sorter: (a, b) => a.title?.localeCompare(b.title),
    defaultSortOrder: "ascend",
  },
  {
    title: "Created Date",
    dataIndex: "created_Date",
    key: "1",
    sorter: (a, b) => a.created_Date - b.created_Date,
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "2",
  },
];

const Blog = () => {
  let { openNotification, handleError } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [dataLoading, setDataLoading] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);

  const [isOpenNewBlogDrawer, setIsOpenNewBlogDrawer] = useState(false);
  const [isOpenEditBlogDrawer, setIsOpenEditBlogDrawer] = useState(false);
  const [isOpenViewBlogDrawer, setIsOpenViewBlogDrawer] = useState(false);
  const [searchText, setSearchText] = useState();

  const [selectedData, setSelectedData] = useState();
  const [allTableData, setAllTableData] = useState([]);
  const [viewData, setViewData] = useState();
  const [activeTableFilterButton, setActiveTableFilteButton] = useState(0);

  useEffect(() => {
    fetchData(2);
  }, []);

  const openAddBlogDrawer = () => {
    setIsOpenNewBlogDrawer(!isOpenNewBlogDrawer);
  };
  const closeAddBlogDrawer = () => {
    setIsOpenNewBlogDrawer(!isOpenNewBlogDrawer);
  };

  const openEditBlogDrawer = (blogData) => {
    setSelectedData(blogData);
    setIsOpenEditBlogDrawer(true);
  };
  const closeEditBlogDrawer = () => {
    setIsOpenEditBlogDrawer(false);
  };

  const search = (text) => {
    setDataLoading(true);
    let filtered = allTableData?.filter((o) => {
      return (
        o.title.toLowerCase().includes(text.toLowerCase()) ||
        o.created_date.toLowerCase().includes(text.toLowerCase())
      );
    });
    setData(filtered);
  };

  const handleViewBlogDrawer = (id) => {
    fetchViewBlogData(id);
  };

  const closeViewBlogDrawer = () => {
    setIsOpenViewBlogDrawer(!isOpenViewBlogDrawer);
  };

  const fetchViewBlogData = async (id) => {
    try {
      await viewBlog({ blog_id: id })
        .then((response) => {
          if (response?.data.success) {
            setViewData(response.data.output ? response.data.output : "");
            setIsOpenViewBlogDrawer(true);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchData = async (id) => {
    try {
      setActiveTableFilteButton(id);
      setDataLoading(true);

      await getAllBlogs({ status: id })
        .then((response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
            setAllTableData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (blogs) => {
    if (blogs.length !== 0) {
      var tableData = [];
      blogs.forEach((blog, x) => {
        let data = {
          key: x,
          title: blog?.title,
          created_Date: blog?.created_date,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <BiEdit
                className="cursor-pointer"
                onClick={() => {
                  fetchEditData(blog.id);
                }}
              />

              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewBlogDrawer(blog.id);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
        setAllBlogs(tableData);
      });
    } else {
      setAllBlogs([]);
    }
    setDataLoading(false);
  };

  const fetchEditData = async (id) => {
    try {
      setDataLoading(true);
      await viewBlog({ blog_id: id })
        .then((response) => {
          if (response?.data.success) {
            openEditBlogDrawer(response?.data.output);
            setDataLoading(false);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    setDataLoading(false);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        Blogs
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start pt-0 pb-8 sm:items-start lg:flex-row lg:justify-between">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Blog..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            <Button
              className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
              type="primary"
              size="large"
              onClick={openAddBlogDrawer}
              icon={<MdAdd className="text-2xl font-bold" />}
            >
              <p className=" text-secondaryWhite text-[18px] font-semibold">
                ADD NEW BLOG
              </p>
            </Button>
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={allBlogs}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>

      {isOpenNewBlogDrawer ? (
        <NewBlog
          onClose={closeAddBlogDrawer}
          open={isOpenNewBlogDrawer}
          tableReload={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenEditBlogDrawer ? (
        <EditBlog
          open={isOpenEditBlogDrawer}
          onClose={closeEditBlogDrawer}
          blogData={selectedData}
          reloadTableData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenViewBlogDrawer ? (
        <ViewBlog
          open={isOpenViewBlogDrawer}
          onClose={closeViewBlogDrawer}
          blog={viewData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Blog;
