import { Button, Col, Divider, Drawer, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import AddAudience from "./AddAudience";
import { useContentContext } from "../../providers/ContentContext";
import {
  getAllCategoryData,
  getAllJobTypeData,
  getAllLocationLevel1Data,
  getAllLocationLevel2Data,
  getAllPromoSmsCountries,
  getPromotionUserListData,
} from "../../helpers";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { addPromoSms } from "../../helpers/apiHelpers/promoSmsHelpers";
const { TextArea } = Input;

const NewPromoSms = ({ onClose, open, fetchData }) => {
  let {
    openNotification,
    handleError,
    setPromoSmsUserList,
    selectedPromoList,
    setSelectedPromoList,
  } = useContentContext();
  const userId = getLocalStoragedata("userId");

  const [openAudienceDrawer, setOpenAudienceDrawer] = useState(false);

  const [promoTitle, setPromoTitle] = useState();
  const [promoDiscription, setPromoDiscription] = useState();

  const [useType, setUserType] = useState(6);
  const [selectedCountry, setSelectedCountry] = useState();
  const [location1SelectedData, setLocation1SelectedData] = useState();
  const [location2SelectedData, setLocation2SelectedData] = useState();
  const [selectedCategoryData, setSelectedCategoryData] = useState();
  const [selectedJobTypeData, setSelectedJobTypeData] = useState();

  const [countrySelectorData, setCountrySelectorData] = useState();
  const [location1SelectorData, setLocation1SelectorData] = useState();
  const [location2SelectorData, setLocation2SelectorData] = useState();
  const [categorySelectorData, setCategorySelectorData] = useState();
  const [jObTypeSelectorData, setJObTypeSelectorData] = useState();

  const handleOpenCloseAudianceDrawer = async () => {
    setOpenAudienceDrawer(!openAudienceDrawer);
    try {
      await getPromotionUserListData({
        country_id: selectedCountry,
        user_type_id: useType,
        level1_id: location1SelectedData,
        level2_id: location2SelectedData,
        job_category_id: selectedCategoryData,
        job_type_id: selectedJobTypeData,
      })
        .then((response) => {
          if (response?.data.success) {
            setPromoSmsUserList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const fetchCountryData = async () => {
    try {
      await getAllPromoSmsCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  const fetchLocationLevel2Data = async (data) => {
    try {
      await getAllLocationLevel2Data({
        country_id: selectedCountry,
        level1_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel2Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel2Data = (locations2) => {
    var locationLevel2Object = [];
    locations2?.forEach((location2) => {
      let data = {
        value: location2?.level2_id,
        label: location2?.level2_location,
      };
      locationLevel2Object.push(data);
    });
    setLocation2SelectorData(locationLevel2Object);
  };

  const fetchCategoryData = async (data) => {
    try {
      await getAllCategoryData({
        country_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setCategoryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCategoryData = (category) => {
    var categoryObject = [];
    category?.forEach((category) => {
      let data = {
        value: category?.job_category_id,
        label: category?.job_category,
      };
      categoryObject.push(data);
    });
    setCategorySelectorData(categoryObject);
  };

  const fetchJobTypeData = async (data) => {
    try {
      await getAllJobTypeData({
        job_category_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setJObTypeData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setJObTypeData = (jobType) => {
    var jobTypeObject = [];
    jobType?.forEach((jobType) => {
      let data = {
        value: jobType?.job_type_id,
        label: jobType?.job_type,
      };
      jobTypeObject.push(data);
    });
    setJObTypeSelectorData(jobTypeObject);
  };

  const onFinish = (e) => {
    //  setSearchText();
    const data = {
      country_id: selectedCountry,
      user_type_id: 6,
      title: e?.title,
      message: e?.message,
      created_by: userId,
      users: selectedPromoList.map((user) => user.user_id),
    };

    addPromoSms(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onClose();
    setSelectedPromoList([]);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            //   form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}

            //   initialValues={{
            //     switch: false,
            //     popular: checkBoxStatus,
            //   }}
          >
            <div className="relative bg-secondaryWhite p-7">
              <div className="w-full flex flex-row justify-between pb-5">
                <div className="text-[16px] font-medium text-primaryLight">
                  Add New Promo SMS
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                    setSelectedPromoList([]);
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Title"
                  size="large"
                  onChange={(e) => {
                    setPromoTitle(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="message"
                label="Message"
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder="Type message here"
                  size="large"
                  rows={4}
                  onChange={(e) => {
                    setPromoDiscription(e.target.value);
                  }}
                />
              </Form.Item>

              <Divider />

              <p className="text-[16px] pb-5">Select Audience</p>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="userType" className="text-[16px]">
                    <Select
                      name="userType"
                      placeholder="Select user type"
                      required
                      className="!bg-secondaryWhite w-full"
                      size="large"
                      value={useType}
                      options={[
                        {
                          value: 6,
                          label: "Tasker",
                        },
                        {
                          value: 2,
                          label: "Client",
                        },
                      ]}
                      onSelect={(e) => {
                        setUserType(e);
                        fetchCountryData();
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="country" className="text-[16px]">
                    <Select
                      name="country"
                      placeholder="Select Country"
                      required
                      className="!bg-secondaryWhite w-full"
                      size="large"
                      options={countrySelectorData}
                      value={selectedCountry}
                      onSelect={(e) => {
                        setSelectedCountry(e);
                        fetchLocationLevel1Data(e);
                        fetchCategoryData(e);
                        setLocation1SelectedData();
                        setLocation2SelectedData();
                        setSelectedCategoryData();
                        setSelectedJobTypeData();
                      }}
                      filterOption={filterOption}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="pb-5">
                <Col span={12}>
                  <Select
                    placeholder={selectedCountry === 1 ? "State" : "District"}
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    options={location1SelectorData}
                    value={location1SelectedData}
                    onSelect={(e) => {
                      setLocation2SelectedData();
                      setLocation1SelectedData(e);
                      fetchLocationLevel2Data(e);
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Col>

                <Col span={12}>
                  <Select
                    placeholder={selectedCountry === 1 ? "Suburb" : "City"}
                    className="!bg-secondaryWhite w-full"
                    size="large"
                    options={location2SelectorData}
                    value={location2SelectedData}
                    onSelect={(e) => {
                      setLocation2SelectedData(e);
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Col>
              </Row>

              {useType === 6 && (
                <Row gutter={16} className="pb-5">
                  <Col span={12}>
                    <Select
                      placeholder="Category"
                      className="!bg-secondaryWhite w-full"
                      size="large"
                      options={categorySelectorData}
                      value={selectedCategoryData}
                      onSelect={(e) => {
                        fetchJobTypeData(e);
                        setSelectedCategoryData(e);
                        setSelectedJobTypeData();
                      }}
                      filterOption={filterOption}
                      showSearch
                    />
                  </Col>

                  <Col span={12}>
                    <Select
                      placeholder="Job Type"
                      className="!bg-secondaryWhite w-full"
                      size="large"
                      options={jObTypeSelectorData}
                      value={selectedJobTypeData}
                      onSelect={(e) => {
                        setSelectedJobTypeData(e);
                      }}
                      filterOption={filterOption}
                      showSearch
                    />
                  </Col>
                </Row>
              )}

              <Row gutter={16} className="pb-5">
                <Col span={8}>
                  <Button
                    type="primary"
                    className="w-full h-[36px]"
                    onClick={() => {
                      setLocation1SelectedData();
                      setLocation2SelectedData();
                      setSelectedCategoryData();
                      setSelectedJobTypeData();

                      setLocation1SelectorData();
                      setLocation2SelectorData();
                      setCategorySelectorData();
                      setJObTypeSelectorData();
                    }}
                  >
                    CLEAR FILTERS
                  </Button>
                </Col>

                <Col span={16}>
                  <Button
                    className="text-sm font-semibold w-full"
                    size="large"
                    onClick={() => {
                      handleOpenCloseAudianceDrawer();
                    }}
                  >
                    ADD AUDIENCE
                  </Button>
                </Col>
              </Row>

              <Divider />
              <p className="text-[16px] pb-5">Selected Audience</p>
              <div className="h-24 overflow-y-scroll">
                {selectedPromoList?.length === 0 ? (
                  <p className="text-center">-- No Audience Data --</p>
                ) : (
                  <>
                    {selectedPromoList.map((item) => {
                      return (
                        <Row className="flex flex-row text-[14px]" gutter={8}>
                          <Col span={12} className="w-full">
                            <p>{item?.name}</p>
                          </Col>
                          <Col span={12}>
                            <p>{item?.number}</p>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                )}
              </div>

              <Divider />

              <div className="w-full">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        size="large"
                        className="font-medium w-full"
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !promoTitle ||
                          !promoDiscription ||
                          selectedPromoList?.length === 0
                        }
                      >
                        SAVE
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>

      {openAudienceDrawer && (
        <AddAudience
          open={openAudienceDrawer}
          onClose={handleOpenCloseAudianceDrawer}
          useType={useType}
          selectedCountry={selectedCountry}
        />
      )}
    </>
  );
};

export default NewPromoSms;
