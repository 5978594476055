import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Row,
  Col,
} from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { PiWarningCircleThin } from "react-icons/pi";
import {
  addState,
  checkStateName,
  getAllCountries,
  getAllTimeZone,
} from "../../helpers";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import { commonFieldValidateField } from "../../commonFun";

const NewState = ({ open, onClose, reloadStateData, setSearchText }) => {
  const userId = getLocalStoragedata("userId");
  let { openNotification, handleError } = useContentContext();
  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [countrySelectorData, setCountrySelectorData] = useState([]);

  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const [timeZoneSelectorData, setTimeZoneSelectorData] = useState([]);
  const [selectedTimeZoneValue, setSelectTimeZoneVale] = useState();

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }

    try {
      await getAllTimeZone({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setTimeZoneData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country.id,
        label: country.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const setTimeZoneData = (timeZones) => {
    var timeZoneObject = [];
    timeZones?.forEach((timeZone) => {
      let data = {
        value: timeZone.id,
        label: timeZone.timezone,
      };
      timeZoneObject.push(data);
    });
    setTimeZoneSelectorData(timeZoneObject);
  };

  const getTimeZoneValue = (e) => {
    timeZoneSelectorData.forEach((item) => {
      if (item?.value == e) {
        setSelectTimeZoneVale(item.label);
      }
    });
  };

  const onFinish = async (e) => {
    setSearchText();

    const checkData = {
      country_id: e.country,
      state: e.state,
      state_id: 0,
    };

    const response = await checkStateName(checkData)
      .then((response) => {
        if (response.data.success) {
          return response.data.output.has_state;
        }
      })
      .catch((error) => {
        handleError(error);
      });

    if (response === 0) {
      const data = {
        country_id: e?.country,
        state: e?.state,
        status: e?.switch,
        timezone: selectedTimeZoneValue,
        created_by: userId,
      };

      if (isUploading) {
        openNotification("warning", "Please wait Upload on progress !");
      } else {
        setIsUploading(true);
        addState(data)
          .then((response) => {
            if (response.data.success) {
              reloadStateData();
              openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
            onClose();
            setIsUploading(false);
          })
          .catch((error) => {
            handleError(error);
          });
      }
    } else {
      setErrormsg(true);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          initialValues={{ switch: false }}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium text-primaryLight">
                Add New State
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="country"
                  className="text-[16px]"
                  label="Country Name"
                  rules={[
                    { required: true, message: "Please select country name" },
                  ]}
                >
                  <Select
                    name="country"
                    placeholder="Country"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={countrySelectorData}
                    onSelect={() => {
                      setErrormsg(false);
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="state"
                  className="text-[16px]"
                  label="State Name"
                  required
                  rules={[{ validator: commonFieldValidateField }]}
                >
                  <Input
                    placeholder="State"
                    className="!bg-secondaryWhite"
                    size="large"
                    onChange={() => {
                      setErrormsg(false);
                    }}
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>
                {errormsg && (
                  <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px]">
                    State name already exist
                  </p>
                )}
              </Col>
            </Row>

            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="timeZone"
                  className="text-[16px]"
                  label="Time Zone"
                  rules={[
                    { required: true, message: "Please select Time Zone" },
                  ]}
                >
                  <Select
                    name="timeZone"
                    placeholder="Time Zone"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={timeZoneSelectorData}
                    onSelect={(e) => {
                      getTimeZoneValue(e);
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col font-medium text-base">
                  State Status
                </div>
                <div className="flex flex-row mt-2 text-center items-baseline">
                  <span
                    className={`${
                      isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                    }`}
                  >
                    Inactive
                  </span>
                  <Form.Item name="switch" valuePropName="checked">
                    <Switch className="mx-2" onChange={handleSwitchChange} />
                  </Form.Item>
                  <span
                    className={`${
                      isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                    }`}
                  >
                    Active
                  </span>
                </div>
              </div>

              <div className="flex flex-row">
                <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                <p className="text-[12px] text-secondaryColor">
                  Changing the state status will impact the suburbs within the
                  states
                </p>
              </div>
            </div>

            <div className="w-full pt-12">
              <Row gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <FormSubmitButton
                      form={form}
                      errormsg={errormsg}
                      isUploading={isUploading}
                      isEditButtonEnable={false}
                      type={"ADD"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="outline-button">
                  <Button
                    size="large"
                    className="font-medium w-full "
                    onClick={onClose}
                  >
                    <p className="text-center">CANCEL</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default NewState;
