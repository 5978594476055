import CryptoJS from "crypto-js";

export function encrypt(text) {
  return CryptoJS.AES.encrypt(JSON.stringify(text), "123456789").toString();
}

export function decrypt(data) {
  const bytes = CryptoJS.AES.decrypt(data, "123456789");
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return null;
  }
}

export function encryptSecureData(text) {
  const key = CryptoJS.enc.Utf8.parse("1245714587458888"); // Use UTF-8 encoding
  const iv = CryptoJS.enc.Utf8.parse("e16ce888a20dadb8");

  try {
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  } catch (error) {
    return text;
  }
}

export function decryptSecureData(encryptedText) {
  const key = CryptoJS.enc.Utf8.parse("1245714587458888"); // Use UTF-8 encoding
  const iv = CryptoJS.enc.Utf8.parse("e16ce888a20dadb8");

  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    if (decrypted.toString(CryptoJS.enc.Utf8) === "") {
      return encryptedText;
    } else {
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  } catch (e) {
    return encryptedText;
  }
}
