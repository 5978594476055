import React, { useState } from "react";
import { Modal, Row, Col, Button } from "antd";
import { useContentContext } from "../../providers/ContentContext";
import {
  deleteJobTypePageTitle,
  getAllJobTypeQuestionList,
} from "../../helpers";

const PageTitleDelete = ({
  open,
  onCancel,
  deletePageTitleData,
  setAllQuestionsList,
}) => {
  let { openNotification, handleError } = useContentContext();
  const [responseLoading, setResponseLoading] = useState(false);
  const [responseDetails, setResponseDetails] = useState({
    msg: "",
    status: 0,
  });

  const deletePageTitlePermanently = (pageTitleId) => {
    const data = {
      job_question_id: pageTitleId,
      status: 0,
    };

    deleteJobTypePageTitle(data)
      .then((response) => {
        if (response?.data.success) {
          setResponseDetails({
            msg: response?.data.message,
            status: response?.data.output?.has_job,
          });
          setResponseLoading(true);
        } else {
          setResponseDetails({
            msg: response?.data.message,
            status: response?.data.output?.has_job,
          });
          setResponseLoading(true);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleOk = () => {
    getAllJobTypeQuestionList({
      job_category_id: deletePageTitleData?.categoryId,
      job_type_id: deletePageTitleData?.jobTypeId,
    })
      .then((res) => {
        if (res?.data.success) {
          setAllQuestionsList(res?.data.output);
        } else {
          openNotification("warning", res.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCancel();
  };

  return (
    <Modal open={open} onCancel={onCancel}>
      <p className="text-[24px] font-medium text-center">
        Are you sure you want to delete this Page Title?
      </p>

      {responseLoading ? (
        <div className="pt-5 flex justify-center items-center flex-col">
          <p className="text-[15px] font-medium text-center text-primaryRed pb-5">
            {responseDetails?.msg}
          </p>

          <Button
            size="large"
            onClick={() => {
              handleOk();
            }}
            className="bg-primaryLight text-white"
          >
            <p className="px-[50px]">OK</p>
          </Button>
        </div>
      ) : (
        <Row className="flex flex-row justify-between px-10 pt-5" gutter={64}>
          <Col xs={12} className="tradie-block-button w-full">
            <Button
              className="bg-primaryRed text-white w-full border-none"
              size="large"
              onClick={() => {
                deletePageTitlePermanently(deletePageTitleData.pageTitleId);
              }}
            >
              DELETE
            </Button>
          </Col>
          <Col xs={12} className="w-full outline-button">
            <Button size="large" onClick={onCancel} className="w-full">
              CANCEL
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default PageTitleDelete;
