import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Divider,
  Spin,
  Switch,
} from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { PiWarningCircleThin } from "react-icons/pi";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import _ from "lodash";
import { commonFieldValidateField } from "../../commonFun";
import { editPermissions } from "../../helpers";

const EditAdminRole = ({
  open,
  onClose,
  selectedAdminRole,
  setSearchText,
  reloadTabelData,
}) => {
  const userId = getLocalStoragedata("userId");
  let {
    loadAllPermissionList,
    openNotification,
    handleError,
    loadAdminRolePermissions,
  } = useContentContext();
  const [data, setData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [initialPermission, setInitialPermission] = useState();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [isEditButtonEnable, setIsEditButonEnavle] = useState(true);

  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
    fetchExixtingPermissstionData();
  }, []);

  //Permission list(admin roles)
  const fetchData = async () => {
    try {
      const permissionList = await loadAllPermissionList();
      setData(permissionList);
    } catch (error) {
      handleError(error);
    }
  };

  //fetch exixtting permission data
  const fetchExixtingPermissstionData = async () => {
    try {
      const exixtingPermissstionData = await loadAdminRolePermissions();
      await exixtingPermissstionData.map((permissionData) => {
        if (permissionData.id === selectedAdminRole?.id) {
          if (permissionData.is_set_permission === 1) {
            const initialPermissionList = {};
            for (let i = 0; i < permissionData.permissions.length; i++) {
              initialPermissionList[permissionData.permissions[i].permission] =
                permissionData.permissions[i].has_permission === 1
                  ? true
                  : false;
            }
            setInitialPermission(initialPermissionList);
          } else {
            setInitialPermission({
              user_type_add: false,
              user_type_edit: false,
              user_type_delete: false,
              user_type_view: false,
              user_add: false,
              user_edit: false,
              user_delete: false,
              user_view: false,
              // country_add: false,
              // country_edit: false,
              // country_delete: false,
              // country_view: false,
              // state_add: false,
              // state_delete: false,
              // state_edit: false,
              // state_view: false,
              // suberb_add: false,
              // suberb_delete: false,
              // suberb_view: false,
              // suberb_edit: false,
              job_category_edit: false,
              job_category_add: false,
              job_category_delete: false,
              job_category_view: false,
              job_type_add: false,
              job_type_edit: false,
              job_type_delete: false,
              job_type_view: false,

              job_add: false,
              job_edit: false,
              job_delete: false,
              job_view: false,

              // unit_add: false,
              // unit_edit: false,
              // unit_delete: false,
              // unit_view: false,

              configuration_add: false,
              configuration_edit: false,
              configuration_delete: false,
              configuration_view: false,

              support_add: false,
              support_edit: false,
              support_delete: false,
              support_view: false,

              verification_add: false,
              verification_edit: false,
              verification_delete: false,
              verification_view: false,

              terms_add: false,
              terms_edit: false,
              terms_delete: false,
              terms_view: false,
            });
          }
          setIsLoading(false);
        }
      });
    } catch (error) {
      handleError(error);
    }
  };

  const renderColumns = (rowData, rowIndex) => {
    const columns = [];
    const actions =
      rowIndex === 0 ? ["Add", "Edit", "Delete", "View"] : ["", "", "", ""];

    const disablePermissions = [
      29, 30, 31, 3, 5, 6, 7, 53, 54, 55, 58, 59, 45, 46, 47, 63, 66, 67, 69,
      70, 71, 73, 74, 75, 41, 42, 43, 77, 78, 79, 37, 38, 39, 51,
    ];

    for (let i = 0; i < rowData.length; i++) {
      const actionIndex = i % actions.length;
      columns.push(
        <Col span={4} key={rowData[i].id} className="permission-column">
          {disablePermissions.includes(rowData[i].id) ? (
            <Form.Item
              name={rowData[i].permission}
              label={`${actions[actionIndex]}`}
              valuePropName="checked"
            >
              <Checkbox disabled />
            </Form.Item>
          ) : (
            <Form.Item
              name={rowData[i].permission}
              label={`${actions[actionIndex]}`}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          )}
        </Col>
      );
    }
    return columns;
  };

  const renderRows = () => {
    const rows = [];
    const userTypes = [
      "Category",
      "JobType",
      "Job Overview",

      "Client",
      "Tasker",
      "Admin",

      "Veri-center",

      // "Country",
      // "State",
      // "Suburb",
      // "Unit",
      "Admin Roles",

      "Blog",
      "Promo SMS",

      "Report",

      "Graph",

      "Support",

      "Reset password",
      "Configuration",
      "Terms & con",
      "App Count",
    ];
    for (let i = 0; i < data.length; i += 4) {
      const userType = userTypes[i / 4];
      rows.push(
        <div key={i}>
          <Row gutter={16}>
            <Col span={6}>
              {i === 0 ? (
                <p className="text-[16px] pb-2 text-primaryLight">Permission</p>
              ) : (
                <></>
              )}
              <p className="text-[14px] font-medium pb-3">{userType}:</p>
            </Col>

            {renderColumns(data.slice(i, i + 4), i)}
          </Row>
          <Divider className="!m-0" />
        </div>
      );
    }
    return rows;
  };

  const onFinish = async (e) => {
    setSearchText();

    const data = {
      user_type_id: selectedAdminRole?.id,
      user_type: e.adminRole,
      status: e.switch === true ? 1 : false,
      updated_by: userId,
      permissions: [
        {
          id: 1,
          has_permission: e.user_type_add === true ? 1 : 0,
        },
        {
          id: 2,
          has_permission: e.user_type_edit === true ? 1 : 0,
        },
        {
          id: 3,
          has_permission: 0,
        },
        {
          id: 4,
          has_permission: e.user_type_view === true ? 1 : 0,
        },
        {
          id: 5,
          has_permission: 0,
        },
        {
          id: 6,
          has_permission: 0,
        },
        {
          id: 7,
          has_permission: 0,
        },
        {
          id: 8,
          has_permission: e.client_view === true ? 1 : 0,
        },
        // {
        //   id: 9,
        //   has_permission: e.country_add === true ? 1 : 0,
        // },
        // {
        //   id: 10,
        //   has_permission: e.country_edit === true ? 1 : 0,
        // },
        // {
        //   id: 11,
        //   has_permission: e.country_delete === true ? 1 : 0,
        // },
        // {
        //   id: 12,
        //   has_permission: e.country_view === true ? 1 : 0,
        // },
        // {
        //   id: 13,
        //   has_permission: e.state_add === true ? 1 : 0,
        // },
        // {
        //   id: 14,
        //   has_permission: e.state_edit === true ? 1 : 0,
        // },
        // {
        //   id: 15,
        //   has_permission: e.state_delete === true ? 1 : 0,
        // },
        // {
        //   id: 16,
        //   has_permission: e.state_view === true ? 1 : 0,
        // },
        // {
        //   id: 17,
        //   has_permission: e.suberb_add === true ? 1 : 0,
        // },
        // {
        //   id: 18,
        //   has_permission: e.suberb_edit === true ? 1 : 0,
        // },
        // {
        //   id: 19,
        //   has_permission: e.suberb_delete === true ? 1 : 0,
        // },
        // {
        //   id: 20,
        //   has_permission: e.suberb_view === true ? 1 : 0,
        // },
        {
          id: 21,
          has_permission: e.job_category_add === true ? 1 : 0,
        },
        {
          id: 22,
          has_permission: e.job_category_edit === true ? 1 : 0,
        },
        {
          id: 23,
          has_permission: e.job_category_delete === true ? 1 : 0,
        },
        {
          id: 24,
          has_permission: e.job_category_view === true ? 1 : 0,
        },
        {
          id: 25,
          has_permission: e.job_type_add === true ? 1 : 0,
        },
        {
          id: 26,
          has_permission: e.job_type_edit === true ? 1 : 0,
        },
        {
          id: 27,
          has_permission: e.job_type_delete === true ? 1 : 0,
        },
        {
          id: 28,
          has_permission: e.job_type_view === true ? 1 : 0,
        },

        {
          id: 29,
          has_permission: 0,
        },
        {
          id: 30,
          has_permission: 0,
        },
        {
          id: 31,
          has_permission: 0,
        },
        {
          id: 32,
          has_permission: e.job_view === true ? 1 : 0,
        },

        // {
        //   id: 33,
        //   has_permission: e.unit_add === true ? 1 : 0,
        // },
        // {
        //   id: 34,
        //   has_permission: e.unit_edit === true ? 1 : 0,
        // },
        // {
        //   id: 35,
        //   has_permission: e.unit_delete === true ? 1 : 0,
        // },
        // {
        //   id: 36,
        //   has_permission: e.unit_view === true ? 1 : 0,
        // },

        {
          id: 37,
          has_permission: 0,
        },
        {
          id: 38,
          has_permission: 0,
        },
        {
          id: 39,
          has_permission: 0,
        },
        {
          id: 40,
          has_permission: e.configuration_view === true ? 1 : 0,
        },

        {
          id: 41,
          has_permission: 0,
        },
        {
          id: 42,
          has_permission: 0,
        },
        {
          id: 43,
          has_permission: 0,
        },
        {
          id: 44,
          has_permission: e.support_view === true ? 1 : 0,
        },

        {
          id: 45,
          has_permission: 0,
        },
        {
          id: 46,
          has_permission: 0,
        },
        {
          id: 47,
          has_permission: 0,
        },
        {
          id: 48,
          has_permission: e.verification_view === true ? 1 : 0,
        },
        {
          id: 49,
          has_permission: e.terms_add === true ? 1 : 0,
        },
        {
          id: 50,
          has_permission: e.terms_edit === true ? 1 : 0,
        },
        {
          id: 51,
          has_permission: 0,
        },
        {
          id: 52,
          has_permission: e.terms_view === true ? 1 : 0,
        },

        {
          id: 53,
          has_permission: 0,
        },
        {
          id: 54,
          has_permission: 0,
        },
        {
          id: 55,
          has_permission: 0,
        },
        {
          id: 56,
          has_permission: e.tasker_view === true ? 1 : 0,
        },

        {
          id: 57,
          has_permission: e.admin_add === true ? 1 : 0,
        },
        {
          id: 58,
          has_permission: 0,
        },
        {
          id: 59,
          has_permission: 0,
        },
        {
          id: 60,
          has_permission: e.admin_view === true ? 1 : 0,
        },

        {
          id: 61,
          has_permission: e.blog_add === true ? 1 : 0,
        },
        {
          id: 62,
          has_permission: e.blog_edit === true ? 1 : 0,
        },
        {
          id: 63,
          has_permission: 0,
        },
        {
          id: 64,
          has_permission: e.blog_view === true ? 1 : 0,
        },

        {
          id: 65,
          has_permission: e.promo_sms_add === true ? 1 : 0,
        },
        {
          id: 66,
          has_permission: 0,
        },
        {
          id: 67,
          has_permission: 0,
        },
        {
          id: 68,
          has_permission: e.promo_sms_view === true ? 1 : 0,
        },

        {
          id: 69,
          has_permission: 0,
        },
        {
          id: 70,
          has_permission: 0,
        },
        {
          id: 71,
          has_permission: 0,
        },
        {
          id: 72,
          has_permission: e.report_view === true ? 1 : 0,
        },

        {
          id: 73,
          has_permission: 0,
        },
        {
          id: 74,
          has_permission: 0,
        },
        {
          id: 75,
          has_permission: 0,
        },
        {
          id: 76,
          has_permission: e.graph_view === true ? 1 : 0,
        },

        {
          id: 77,
          has_permission: 0,
        },
        {
          id: 78,
          has_permission: 0,
        },
        {
          id: 79,
          has_permission: 0,
        },
        {
          id: 80,
          has_permission: e.password_view === true ? 1 : 0,
        },

        {
          id: 81,
          has_permission: e.app_count_add === true ? 1 : 0,
        },
        {
          id: 82,
          has_permission: e.app_count_edit === true ? 1 : 0,
        },
        {
          id: 83,
          has_permission: e.app_count_delete === true ? 1 : 0,
        },
        {
          id: 84,
          has_permission: e.app_count_view === true ? 1 : 0,
        },
      ],
    };

    if (isUploading) {
      openNotification("warning", "Please wait Change on progress !");
    } else {
      setIsUploading(true);

      await editPermissions(data)
        .then((response) => {
          if (response.data.success) {
            reloadTabelData();
            openNotification("success", response.data.message);
          } else {
            openNotification("error", response.data.message);
          }
          onClose();
          setIsUploading(false);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const formInitialState = {
    ...initialPermission,
    adminRole: selectedAdminRole?.user_type,
    switch: selectedAdminRole?.is_active === 1 ? true : false,
  };

  const onFieldsChange = (e) => {
    if (_.isEqual(form.getFieldsValue(), formInitialState)) {
      setIsEditButonEnavle(true);
    } else {
      setIsEditButonEnavle(false);
    }
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px] add-permission">
        {isLoading ? (
          <div className="flex justify-center pt-[350px]">
            <Spin className="!text-[primaryLight]" />
          </div>
        ) : (
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}
            initialValues={{
              ...initialPermission,
              adminRole: selectedAdminRole?.user_type,
              switch: selectedAdminRole?.is_active === 1 ? true : false,
            }}
            onFieldsChange={onFieldsChange}
          >
            <div className="relative bg-secondaryWhite p-7 ">
              <div className="w-full flex flex-row justify-between">
                <div className="text-[16px] font-medium text-primaryLight pb-5">
                  Edit Admmin Role
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <Form.Item
                className="text-[16px]"
                name="adminRole"
                label="Admin Role"
                required
                rules={[{ validator: commonFieldValidateField }]}
              >
                <Input
                  placeholder="Enter the Admin Role"
                  className="!bg-white"
                  size="large"
                  minLength={3}
                  maxLength={50}
                />
              </Form.Item>

              <div>{renderRows()}</div>

              {/* status */}
              <div className="pt-10">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col font-medium text-base">
                    Admin Status
                  </div>

                  <div className="flex flex-row mt-2 text-center items-baseline">
                    <span
                      className={`${
                        isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                      }`}
                    >
                      Inactive
                    </span>
                    <Form.Item name="switch" valuePropName="checked">
                      <Switch className="mx-2" onChange={handleSwitchChange} />
                    </Form.Item>
                    <span
                      className={`${
                        isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                      }`}
                    >
                      Active
                    </span>
                  </div>
                </div>

                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Changing the admin status will impact the available admin
                    type within the admin roles
                  </p>
                </div>
              </div>

              <div className="w-full pt-8">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <FormSubmitButton
                        form={form}
                        errormsg={false}
                        isUploading={isUploading}
                        isEditButtonEnable={isEditButtonEnable}
                        type={"SAVE"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full"
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        )}
      </div>
    </Drawer>
  );
};

export default EditAdminRole;
