import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { PiWarningCircleThin } from "react-icons/pi";

function EditPackage({ open, onClose }) {
  const [isDeletConfirmationOpen, setIsDeletConfirmationOpen] = useState(false);

  const handleDeletConfirmationOpen = () => {
    setIsDeletConfirmationOpen(true);
  };
  const handleDeletConfirmationClose = () => {
    setIsDeletConfirmationOpen(false);
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            //form={form}
            name="editPackage"
            layout="vertical"
            className="w-full add-form"
            // onFinish={onFinish}
          >
            <div className="relative bg-secondaryWhite p-7">
              <div className="w-full flex flex-row  justify-between">
                <div className="text-[16px] font-medium text-primaryLight">
                  Edit Package
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>
              <Row gutter={5} className="mt-2">
                <Col xs={24} sm={8}>
                  <p className="text-[14px] font-normal">Last Edited Date</p>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item>
                    <p className="text-[14px] font-normal">
                      {"2024-05-30 12:10"}
                    </p>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={5} className="-mt-4">
                <Col xs={24} sm={8}>
                  <p className="text-[14px] font-normal">Created Date</p>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item>
                    <p className="text-[14px] font-normal">
                      {"2024-05-29 01:10"}
                    </p>
                  </Form.Item>
                </Col>
              </Row>
              <Divider className="mt-0" />

              <Row className="flex flex-row justify-between" gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item name="country" label="Select Country" required>
                    <Select
                      name="country"
                      placeholder="Country"
                      required
                      className="!bg-secondaryWhite"
                      size="large"
                      options={[
                        {
                          value: 1,
                          label: "Country 1",
                        },
                        {
                          value: 2,
                          label: "Country 2",
                        },
                      ]}
                    />
                  </Form.Item>
                  <p className="text-xs font-normal text-[#9F9F9F]">
                    Select the country, where you want the packages to be
                    accessible
                  </p>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item name="packageName" label="Package Name" required>
                    <Input
                      placeholder="Package Name"
                      className="!bg-secondaryWhite"
                      size="large"
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />

              <Row className="flex flex-row justify-between" gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="price"
                    label="Price Of The Package(AUD)"
                    required
                  >
                    <Input
                      placeholder="Price"
                      className="!bg-secondaryWhite"
                      size="large"
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <p className="text-base font-normal py-1">
                    Validity Period{" "}
                    <span className="text-red-500 text-[16px] mt-1">*</span>
                  </p>
                  <div className="flex flex-row gap-2">
                    <Form.Item name="month" required>
                      <DatePicker picker="month" size="large" />
                    </Form.Item>
                    <Form.Item name="period">
                      <Input
                        placeholder="Type the period count"
                        className="!bg-secondaryWhite"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Divider />

              <div className="flex flex-col gap-5">
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal">New Member Discount</p>
                  <Switch></Switch>
                </div>
                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Change the status to enable or disable the new member
                    discount
                  </p>
                </div>

                <Row className="flex flex-row justify-between" gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="price"
                      label="Discount Price(AUD)"
                      required
                    >
                      <Input
                        placeholder="Price"
                        className="!bg-secondaryWhite"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <p className="text-base font-normal py-1">
                      Discount Period{" "}
                      <span className="text-red-500 text-[16px] mt-1">*</span>
                    </p>
                    <div className="flex flex-row gap-2">
                      <Form.Item name="month" required>
                        <DatePicker picker="month" size="large" />
                      </Form.Item>
                      <Form.Item name="period">
                        <Input
                          placeholder="Type the period count"
                          className="!bg-secondaryWhite"
                          size="large"
                          maxLength={10}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
              <Divider />

              <div className="flex flex-col gap-5">
                <div className="flex flex-row gap-3">
                  <p className="text-base font-normal">Promotional Offers</p>
                  <Switch></Switch>
                </div>
                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Change the status to enable or disable the promotional
                    offers
                  </p>
                </div>

                <Row className="flex flex-row justify-between" gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="price"
                      label="Promotion Price(AUD)"
                      required
                    >
                      <Input
                        placeholder="Price"
                        className="!bg-secondaryWhite"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Form.Item name="period" label="Coupon code">
                      <Input
                        placeholder="Type the period count"
                        className="!bg-secondaryWhite"
                        size="large"
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider />

              <div className="pb-4">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col font-medium text-base">
                    Package Status *
                  </div>

                  <div className="flex flex-row mt-2 text-center items-baseline">
                    <span>Inactive</span>
                    <Form.Item name="switch" valuePropName="checked">
                      <Switch className="mx-2" />
                    </Form.Item>
                    <span>Active</span>
                  </div>
                </div>

                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Changing the package status will impact the users who
                    already been using the package.
                    <br /> Current users with the deactivated package will
                    remain with the benefits of that package
                    <br /> until the conditions of the package expires for that
                    user.
                  </p>
                </div>
              </div>
              <Divider />

              <div className="pb-4">
                <div className="flex flex-col font-medium text-base">
                  Delete Package
                </div>

                <div className="flex flex-row mt-4">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Deactivate the package to enable the delete option.
                    <br /> Before deleting a package, make sure there are no
                    active users or active transaction
                    <br /> within the package.
                  </p>
                </div>
                <div className="flex mt-2">
                  <Button
                    disabled={false}
                    onClick={() => {
                      handleDeletConfirmationOpen();
                    }}
                    size="large"
                    className="!border-primaryRed text-primaryRed hover:!text-secondaryWhite hover:!bg-primaryRed hover:!border-primaryRed"
                  >
                    <span className="font-semibold text-base text-primaryRed">
                      DELETE
                    </span>
                  </Button>
                </div>
              </div>
              <Divider />

              <div className="w-full">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="w-full"
                      >
                        SAVE
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button size="large" onClick={onClose} className="w-full">
                      CANCEL
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
          {isDeletConfirmationOpen && (
            <Modal
              className="z-[100000]"
              open={isDeletConfirmationOpen}
              title={
                <Typography className="font-medium text-2xl text-center">
                  Are you sure you want to delete the “package name”?
                </Typography>
              }
              footer={null}
              onCancel={handleDeletConfirmationClose}
            >
              <div className="flex flex-col gap-4 items-center justify-center">
                <div className="flex items-center justify-center">
                  <span className="text-center">
                    This action is irreversible and will permanently remove the
                    package from the system. If there are current users
                    subscribed to this package, ensure they are notified about
                    the change and consider providing an alternative or an
                    upgrade option.
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="flex flex-row gap-6">
                    <Button className="w-40 h-10 md:w-56 md:h-12 items-center justify-center rounded-xl font-semibold text-lg bg-secondaryRed text-secondaryWhite">
                      Delete
                    </Button>
                    <Button
                      className="w-40 h-10 md:w-56 md:h-12 items-center justify-center font-semibold text-lg rounded-xl"
                      onClick={handleDeletConfirmationClose}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default EditPackage;
