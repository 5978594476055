import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, url } from "./header";

const userId = getLocalStoragedata("userId");

//admin
export async function adminRegister(data) {
  return await axios.post(url + "auth/admin/register", data, config);
}

export async function getAdminUsers() {
  let data = {
    user_id: userId,
    status: -1,
  };
  return await axios.post(url + "admin/users", data, config);
}

export async function getAdminBasicData() {
  let data = {
    user_id: userId,
  };
  return await axios.post(url + "auth/data", data, config);
}

export async function userTableStatusChange(data) {
  return await axios.post(url + "user/status", data, config);
}

//customer
export async function getAllCustomerUsers(data) {
  return await axios.post(url + "customer/users", data, config);
}

//tradie
export async function getAllTradieUsers(data) {
  return await axios.post(url + "trady/users", data, config);
}
