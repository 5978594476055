import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import NewPromoSms from "../components/promoSms/NewPromoSms";
import { useContentContext } from "../providers/ContentContext";
import { getAllPromoSms, tableSmsStatusChange } from "../helpers";
import ViewPromoSms from "../components/promoSms/ViewPromoSms";
import { formatDateWithTime } from "../helpers/apiHelpers/dateHelpers";
import { customStatusSorter } from "../commonFun";
import ApproveRejectStatusChange from "../components/statusChange/ApproveRejectStatusChange";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "0",
    sorter: (a, b) => a.title?.localeCompare(b.title),
    defaultSortOrder: "ascend",
  },

  {
    title: "Created Date",
    dataIndex: "date",
    key: "1",
    sorter: (a, b) => a.date?.localeCompare(b.date),
    defaultSortOrder: "ascend",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "2",
    sorter: (a, b) => customStatusSorter(a, b),
    defaultSortOrder: "ascend",
  },

  {
    title: "Action",
    dataIndex: "action",
    key: "3",
  },
];

const PromoSms = () => {
  const [isOpenNewPromoSmsDrawer, setIsOpenNewPromoSmsDrawer] = useState(false);
  let { openNotification, handleError } = useContentContext();
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedPromoSms, setSelectedPromoSms] = useState();
  const [openViewPromoSmsDrawer, setOpenViewPromoSmsDrawer] = useState(false);

  const [promoSms, setPromoSms] = useState();
  const [searchText, setSearchText] = useState();
  const [promoSmsSearchData, setPromoSmsSearchData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  const handlePromoSmsDrawerOpenChange = () => {
    setIsOpenNewPromoSmsDrawer(!isOpenNewPromoSmsDrawer);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setDataLoading(true);
    try {
      await getAllPromoSms({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
            setPromoSmsSearchData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (sms) => {
    if (sms.length !== 0) {
      var tableData = [];
      sms.forEach((item, x) => {
        let data = {
          key: x,
          title: item?.title,
          date: formatDateWithTime(item?.created_at),

          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                item?.status === 1 ? "bg-primaryColor" : "bg-primaryLight"
              } `}
              onClick={() => {
                setChangeStatus(item?.status);
                openChangeStatus(item);
              }}
            >
              {item?.status === 1 ? "Approve" : "Reject"}
            </Button>
          ),

          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewPromoSmsrOpenChange(item);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
        setPromoSms(tableData);
      });
    } else {
      setPromoSms([]);
    }
    setDataLoading(false);
  };

  const handleViewPromoSmsrOpenChange = (item) => {
    setOpenViewPromoSmsDrawer(!selectedPromoSms);
    setSelectedPromoSms(item);
  };

  const search = (text) => {
    let filtered = promoSmsSearchData?.filter((o) => {
      return o.title.toLowerCase().includes(text.toLowerCase());
    });
    setData(filtered);
  };

  const openChangeStatus = (item) => {
    setIsModalOpen(true);
    setSelectedData(item.promotion_id);
  };

  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };

  const handleStatus = async (status) => {
    const data = {
      promotion_id: selectedData,
      status: status,
    };

    tableSmsStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        PROMO SMS
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Promo SMS..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            <Button
              className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
              type="primary"
              size="large"
              onClick={handlePromoSmsDrawerOpenChange}
              icon={<MdAdd className="text-2xl font-bold" />}
            >
              <p className=" text-secondaryWhite text-[18px] font-semibold">
                ADD NEW PROMO SMS
              </p>
            </Button>
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={promoSms}
            columns={columns}
            loading={dataLoading}
            // showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>

      {isOpenNewPromoSmsDrawer && (
        <NewPromoSms
          onClose={handlePromoSmsDrawerOpenChange}
          open={isOpenNewPromoSmsDrawer}
          fetchData={fetchData}
        />
      )}

      {openViewPromoSmsDrawer && (
        <ViewPromoSms
          open={openViewPromoSmsDrawer}
          onClose={handleViewPromoSmsrOpenChange}
          selectedPromoSms={selectedPromoSms}
        />
      )}

      {isModalOpen && (
        <ApproveRejectStatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default PromoSms;
