import React from "react";
import { Form, Button } from "antd";

const ResetPwdSuccess = () => {
  return (
    <>
      <div className=" text-[40px] font-semibold text-center md:text-secondaryWhite">
        Password Reset Success
      </div>
      <div className="flex flex-col justify-center items-center mt-4 md:bg-[#484848] p-6 sm:px-8 px-4 max-sm:mx-2">
        <Form
          name="login"
          className="login-form flex flex-col sm:w-[400px] w-[300px] mt-2 font-normal "
          layout="vertical"
          size="large"
          autoComplete="off"
          onFinish={() => {
            window.location.replace("/login");
          }}
        >
          <p className="pb-3 text-[18px] font-normal text-[#a77802] text-center">
            Login using your newly reset password
          </p>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full mt-7 !text-[18px] font-semibold"
              style={{ height: "50px" }}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ResetPwdSuccess;
