import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, formconfig, url } from "./header";

const userId = getLocalStoragedata("userId");

//user List
export async function getAllTopicList(data) {
  return await axios.post(url + "topic/list", data, config);
}



export async function postNewNotificationTopic(data) {
  return await axios.post(url + "topic/notification/send", data, config);
}

export async function getAllTopicTableData(data) {
  return await axios.post(url + "topic/notification/list", data, config);
}

export async function getAllTopicMoreDetails(data) {
  return await axios.post(url + "topic/notification/data", data, config);
}

export async function getAllNotificationAudiensList(data) {
  return await axios.post(url + "notification/user/list", data, config);
}
