import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Row,
  Col,
} from "antd";
import { MdClose } from "react-icons/md";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { useContentContext } from "../../providers/ContentContext";
import { PiWarningCircleThin } from "react-icons/pi";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import { checkCountryName, addCountry, getAllTimeZone } from "../../helpers";
import { commonFieldValidateField } from "../../commonFun";

const NewCountry = ({ onClose, reloadCountryData, open, setSearchText }) => {
  const userId = getLocalStoragedata("userId");
  let { openNotification, handleError } = useContentContext();
  const [form] = Form.useForm();
  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [timeZoneSelectorData, setTimeZoneSelectorData] = useState([]);
  const [selectedTimeZoneValue, setSelectTimeZoneVale] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getAllTimeZone({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setTimeZoneData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setTimeZoneData = (timeZones) => {
    var timeZoneObject = [];
    timeZones?.forEach((timeZone) => {
      let data = {
        value: timeZone.id,
        label: timeZone.timezone,
      };
      timeZoneObject.push(data);
    });
    setTimeZoneSelectorData(timeZoneObject);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getTimeZoneValue = (e) => {
    timeZoneSelectorData.forEach((item) => {
      if (item?.value == e) {
        setSelectTimeZoneVale(item.label);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  const onFinish = (e) => {
    setSearchText();

    const data = {
      country: e?.country,
      country_code: e?.countryCode,
      timezone: selectedTimeZoneValue,
      status: e?.switch,
      timezone_type: e?.timezoneType,
      created_by: userId,
      currency: e?.currency,
      currency_label: e?.currencyLabel,
      postal_code_length: parseInt(e?.postalCodeLenght),
    };

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);
      addCountry(data)
        .then((response) => {
          if (response.data.success) {
            reloadCountryData();
            openNotification("success", response.data.message);
          } else {
            openNotification("error", response.data.message);
          }
          onClose();
          setIsUploading(false);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const checkCountry = (e) => {
    const data = {
      country: e,
      country_id: 0,
    };

    checkCountryName(data)
      .then((response) => {
        if (response.data.success) {
          setErrormsg(response.data.output.has_country === 1 ? true : false);
        } else {
          setErrormsg(false);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const validateCountryCodeField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the text"));
    }
    if (!/^[A-Za-z]+$/.test(value)) {
      return Promise.reject(new Error("Please enter a valid text"));
    }
    return Promise.resolve();
  };

  const validatePostalCodeField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please ente only numbers"));
    }
    return Promise.resolve();
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          initialValues={{ switch: false }}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium text-primaryLight">
                Add New Country
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="country"
                  className="text-[16px]"
                  label="Country Name"
                  required
                  rules={[{ validator: commonFieldValidateField }]}
                >
                  <Input
                    placeholder="Country"
                    className="!bg-secondaryWhite"
                    size="large"
                    onKeyUp={(e) => {
                      checkCountry(e.target.value);
                    }}
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>
                {errormsg === true ? (
                  <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-3">
                    Country name already exist
                  </p>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="countryCode"
                  className="text-[16px]"
                  label="Country Code"
                  required
                  rules={[{ validator: validateCountryCodeField }]}
                >
                  <Input
                    placeholder="Country Code"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="timezoneType"
                  className="text-[16px]"
                  label="Time Zone Type"
                  rules={[
                    { required: true, message: "Please select time zone type" },
                  ]}
                >
                  <Select
                    name="timezoneType"
                    placeholder="Time Zone Type"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={[
                      {
                        value: 1,
                        label: "Country Wise",
                      },
                      {
                        value: 2,
                        label: "State Wise",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="timeZone"
                  className="text-[16px]"
                  label="Time Zone"
                  rules={[
                    { required: true, message: "Please select Time Zone" },
                  ]}
                >
                  <Select
                    name="timeZone"
                    placeholder="Time Zone"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={timeZoneSelectorData}
                    onSelect={(e) => {
                      getTimeZoneValue(e);
                    }}
                    filterOption={filterOption}
                    showSearch
                  />
                </Form.Item>
                <p className="text-[12px] font-normal">
                  Please input the correct timezone for the selected country.
                  This ensures accurate time-related functionalities within the
                  system
                </p>
              </Col>
            </Row>

            {/* country currency */}
            <Divider />
            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="currency"
                  className="text-[16px]"
                  label="Currency"
                  rules={[{ required: true, message: "Please enter currency" }]}
                >
                  <Input
                    placeholder="Currency"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="currencyLabel"
                  className="text-[16px]"
                  label="Currency Label"
                  rules={[
                    { required: true, message: "Please enter currency Label" },
                  ]}
                >
                  <Input
                    placeholder="Currency Label"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* postal code length */}
            <Divider />
            <Row className="flex flex-row justify-between" gutter={8}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="postalCodeLenght"
                  className="text-[16px]"
                  label="Postal Code Lenght"
                  rules={[{ validator: validatePostalCodeField }]}
                >
                  <Input
                    placeholder="Postal Code Lenght"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* country status */}
            <Divider />
            <div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col font-medium text-base">
                  Country Status
                </div>
                <div className="flex flex-row mt-2 text-center items-baseline">
                  <span
                    className={`${
                      isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                    }`}
                  >
                    Inactive
                  </span>
                  <Form.Item name="switch" valuePropName="checked">
                    <Switch className="mx-2" onChange={handleSwitchChange} />
                  </Form.Item>
                  <span
                    className={`${
                      isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                    }`}
                  >
                    Active
                  </span>
                </div>
              </div>

              <div className="flex flex-row">
                <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                <p className="text-[12px] text-secondaryColor">
                  Changing the country status will impact the states and suburbs
                  within the country
                </p>
              </div>
            </div>

            <div className="w-full pt-12">
              <Row gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <FormSubmitButton
                      form={form}
                      errormsg={errormsg}
                      isUploading={isUploading}
                      isEditButtonEnable={false}
                      type={"ADD"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="outline-button">
                  <Button
                    size="large"
                    className="font-medium w-full "
                    onClick={onClose}
                  >
                    <p className="text-center">CANCEL</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default NewCountry;
