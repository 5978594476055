import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Switch,
  Typography,
} from "antd";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import {
  deleteAppData,
  editAppData,
} from "../../helpers/apiHelpers/appDownloadHelpers";
import AppDownloadSubmitButton from "../submitButton/AppDownloadSubmitButton";
import { PiWarningCircleThin } from "react-icons/pi";
import AppDownloadDelete from "../deleteComponents/AppDownloadDelete";
import { getLocalStoragedata } from "../../helpers/StorageHelper";

const EditAppDownload = ({ onClose, open, dataSet, reloadTable }) => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");

  const [viewUserData, setViewUserData] = useState(
    dataSet?.au_client_register == 0 &&
      dataSet?.au_tasker_register == 0 &&
      dataSet?.sl_client_register == 0 &&
      dataSet?.sl_tasker_register == 0
      ? 1
      : 0
  );

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [isEditButtonEnable, setIsEditButonEnable] = useState(true);

  const [form] = Form.useForm();

  const handleUSerCount = (e) => {
    e === true ? setViewUserData(0) : setViewUserData(1);
  };

  const deleteModalOpen = () => {
    setIsOpenDeleteModal(true);
  };
  const deleteModalClose = () => {
    setIsOpenDeleteModal(false);
  };

  const formInitialState = {
    au_ios_c: dataSet?.au_ios_client_download,
    au_ios_t: dataSet?.au_ios_tasker_download,
    au_and_c: dataSet?.au_android_client_download,
    au_and_t: dataSet?.au_android_tasker_download,
    sl_ios_c: dataSet?.sl_ios_client_download,
    sl_ios_t: dataSet?.sl_ios_tasker_download,
    sl_and_c: dataSet?.sl_android_client_download,
    sl_and_t: dataSet?.sl_android_tasker_download,
    au_c_ru: dataSet?.au_client_register,
    au_t_ru: dataSet?.au_tasker_register,
    sl_c_ru: dataSet?.sl_client_register,
    sl_t_ru: dataSet?.sl_tasker_register,
  };

  const onFieldsChange = () => {
    let formState = form.getFieldsValue();
    if (
      dataSet?.au_client_register == 0 &&
      dataSet?.au_tasker_register == 0 &&
      dataSet?.sl_client_register == 0 &&
      dataSet?.sl_tasker_register == 0
    ) {
      if (
        formInitialState.au_ios_c !== formState.au_ios_c ||
        formInitialState.au_ios_t !== formState.au_ios_t ||
        formInitialState.au_and_c !== formState.au_and_c ||
        formInitialState.au_and_t !== formState.au_and_t ||
        formInitialState.sl_ios_c !== formState.sl_ios_c ||
        formInitialState.sl_ios_t !== formState.sl_ios_t ||
        formInitialState.sl_and_c !== formState.sl_and_c ||
        formInitialState.sl_and_t !== formState.sl_and_t ||
        viewUserData === 0
      ) {
        setIsEditButonEnable(false);
      } else {
        setIsEditButonEnable(true);
      }
    } else {
      if (
        formInitialState.au_ios_c !== formState.au_ios_c ||
        formInitialState.au_ios_t !== formState.au_ios_t ||
        formInitialState.au_and_c !== formState.au_and_c ||
        formInitialState.au_and_t !== formState.au_and_t ||
        formInitialState.sl_ios_c !== formState.sl_ios_c ||
        formInitialState.sl_ios_t !== formState.sl_ios_t ||
        formInitialState.sl_and_c !== formState.sl_and_c ||
        formInitialState.sl_and_t !== formState.sl_and_t ||
        formInitialState.au_c_ru !== formState.au_c_ru ||
        formInitialState.au_t_ru !== formState.au_t_ru ||
        formInitialState.sl_c_ru !== formState.sl_c_ru ||
        formInitialState.sl_t_ru !== formState.sl_t_ru
      ) {
        setIsEditButonEnable(false);
      } else {
        setIsEditButonEnable(true);
      }
    }
  };

  const onFinish = (formData) => {
    const data = {
      day: dataSet?.day,
      day_str: dataSet?.day_str,
      user_id: userId,
      au_ios_client_download: formData?.au_ios_c,
      au_ios_tasker_download: formData?.au_ios_t,
      sl_ios_client_download: formData?.sl_ios_c,
      sl_ios_tasker_download: formData?.sl_ios_t,
      au_android_client_download: formData?.au_and_c,
      au_android_tasker_download: formData?.au_and_t,
      sl_android_client_download: formData?.sl_and_c,
      sl_android_tasker_download: formData?.sl_and_t,
      is_auto: viewUserData,
      sl_client_register: formData?.sl_c_ru,
      sl_tasker_register: formData?.sl_t_ru,
      au_client_register: formData?.au_c_ru,
      au_tasker_register: formData?.au_t_ru,
    };

    editAppData(data)
      .then((response) => {
        if (response.data.success) {
          reloadTable();
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  const deleteRecordPermanently = () => {
    const data = {
      graph_id: dataSet?.id,
      updated_by: userId,
    };

    deleteAppData(data)
      .then((response) => {
        if (response.data.success) {
          reloadTable();
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
        setIsOpenDeleteModal(false);
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  const validateInputField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please ente only numbers"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            form={form}
            name="editAppDownload"
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}
            initialValues={{
              au_ios_c:
                dataSet?.au_ios_client_download != 0
                  ? dataSet?.au_ios_client_download
                  : "",
              au_ios_t:
                dataSet?.au_ios_tasker_download != 0
                  ? dataSet?.au_ios_tasker_download
                  : "",
              au_and_c:
                dataSet?.au_android_client_download != 0
                  ? dataSet?.au_android_client_download
                  : "",
              au_and_t:
                dataSet?.au_android_tasker_download != 0
                  ? dataSet?.au_android_tasker_download
                  : "",
              sl_ios_c:
                dataSet?.sl_ios_client_download != 0
                  ? dataSet?.sl_ios_client_download
                  : "",
              sl_ios_t:
                dataSet?.sl_ios_tasker_download != 0
                  ? dataSet?.sl_ios_tasker_download
                  : "",
              sl_and_c:
                dataSet?.sl_android_client_download != 0
                  ? dataSet?.sl_android_client_download
                  : "",
              sl_and_t:
                dataSet?.sl_android_tasker_download != 0
                  ? dataSet?.sl_android_tasker_download
                  : "",
              au_c_ru:
                dataSet?.au_client_register != 0
                  ? dataSet?.au_client_register
                  : "",
              au_t_ru:
                dataSet?.au_tasker_register != 0
                  ? dataSet?.au_tasker_register
                  : "",
              sl_c_ru:
                dataSet?.sl_client_register != 0
                  ? dataSet?.sl_client_register
                  : "",
              sl_t_ru:
                dataSet?.sl_tasker_register != 0
                  ? dataSet?.sl_tasker_register
                  : "",
            }}
            onFieldsChange={onFieldsChange}
          >
            <div className="relative bg-secondaryWhite p-7">
              <div className="w-full flex flex-row justify-between pb-5">
                <div className="text-[16px] font-medium text-primaryLight">
                  Add App Download Data
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>

              <div className="flex">
                <Form.Item name="date" className="w-[50%]">
                  <Typography className="border-[2px] h-9 rounded-lg text-[16px] flex justify-center items-center w-[50%]">
                    {dataSet?.day_str}
                  </Typography>
                </Form.Item>
              </div>

              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="au_ios_c"
                  label="AU IOS client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    maxLength={20}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="au_ios_t"
                  label="AU IOS tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="au_and_c"
                  label="AU Android client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="au_and_t"
                  label="AU Android tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="sl_ios_c"
                  label="SL IOS client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="sl_ios_t"
                  label="SL IOS tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                <Form.Item
                  name="sl_and_c"
                  label="SL Android client download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="sl_and_t"
                  label="SL Android tasker download"
                  className="sm:w-[50%]"
                  rules={[{ validator: validateInputField }]}
                >
                  <Input
                    placeholder="Download count"
                    size="large"
                    maxLength={20}
                    onChange={(e) => {
                      // setPromoTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex font-medium text-base">User Count</div>

                <div className="flex text-center">
                  <Switch
                    defaultValue={
                      dataSet?.au_client_register == 0 &&
                      dataSet?.au_tasker_register == 0 &&
                      dataSet?.sl_client_register == 0 &&
                      dataSet?.sl_tasker_register == 0
                        ? 0
                        : 1
                    }
                    onChange={handleUSerCount}
                  />
                </div>
                {viewUserData == 0 ? (
                  <>
                    <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                      <Form.Item
                        name="au_c_ru"
                        label="AU client register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Download count"
                          size="large"
                          maxLength={20}
                          onChange={(e) => {
                            // setPromoTitle(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="au_t_ru"
                        label="AU tasker register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Download count"
                          size="large"
                          maxLength={20}
                          onChange={(e) => {
                            // setPromoTitle(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>

                    <Divider className="mb-4 mt-2" />

                    <div className="flex flex-col sm:flex-row gap-0 sm:gap-6">
                      <Form.Item
                        name="sl_c_ru"
                        label="SL client register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Download count"
                          size="large"
                          maxLength={20}
                          onChange={(e) => {
                            // setPromoTitle(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="sl_t_ru"
                        label="SL tasker register user"
                        className="sm:w-[50%]"
                        rules={[{ validator: validateInputField }]}
                      >
                        <Input
                          placeholder="Download count"
                          size="large"
                          maxLength={20}
                          onChange={(e) => {
                            // setPromoTitle(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <Divider />
              <div>
                <p className="font-medium text-base pb-5">
                  Delete App Download Details
                </p>

                <div className="flex flex-row pb-3">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                </div>
                <Button
                  size="large"
                  className="!border-primaryRed text-primaryRed hover:!text-secondaryWhite hover:!bg-primaryRed hover:!border-primaryRed"
                  onClick={deleteModalOpen}
                >
                  DELETE
                </Button>
              </div>

              <div className="w-full pt-0 sm:pt-12">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <AppDownloadSubmitButton
                        form={form}
                        isEditButtonEnable={isEditButtonEnable}
                        type={"SAVE"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
      {isOpenDeleteModal && (
        <AppDownloadDelete
          open={isOpenDeleteModal}
          onCancel={deleteModalClose}
          deletePermanently={deleteRecordPermanently}
        />
      )}
    </>
  );
};

export default EditAppDownload;
