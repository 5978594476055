import { Button, Col, Divider, Drawer, Form, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import FormSubmitButton from "../submitButton/FormSubmitButton";
import { CollapsedLogo } from "../../assets";
import { FaStar } from "react-icons/fa6";
import { BsChevronCompactDown } from "react-icons/bs";
import { BsChevronCompactUp } from "react-icons/bs";
import { useContentContext } from "../../providers/ContentContext";
import {
  getAllNotificationHistory,
  sendNotification,
} from "../../helpers/apiHelpers/jobsHelpers";
import { decryptSecureData } from "../../services/EncryptionService";

const TaskerNotificationForm = ({ open, onClose, tasterData, jobData }) => {
  const [showOrNotShowHistory, setShowOrNotShowHistory] = useState(false);
  let { openNotification, handleError } = useContentContext();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationHistory, setNotificationHistory] = useState();
  const [form] = Form.useForm();

  const handleNotificationMessage = (e) => {
    const data = {
      notification_type_id: 3,
      user_id: tasterData?.id,
      job_id: jobData?.id,
      title: e?.title,
      description: e?.discription,
    };
    sendNotification(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  useEffect(() => {
    getHistorysData();
  }, []);

  const getHistorysData = async () => {
    setIsLoading(true);
    await getAllNotificationHistory(jobData?.id)
      .then((response) => {
        if (response.data.success) {
          setNotificationHistory(response.data.output);
          setIsLoading(false);
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[500px]">
          <div className="relative bg-secondaryWhite p-4 mt-2">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="flex flex-col">
                <div className="text-base font-medium text-primaryLight">
                  Related Tasker Notification
                </div>
              </div>

              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div className="pb-10">
              <div className="flex flex-row justify-between items-center px-2 py-1 gap-2 bg-white m-2">
                <div className="flex w-[50%]">
                  <div className="flex flex-row">
                    <img
                      className="w-16 h-16 rounded-full"
                      src={CollapsedLogo}
                    />
                    <div className="flex flex-col gap-1">
                      <p className="break-words  font-semibold text-sm">
                        {decryptSecureData(tasterData?.trady_name)}
                      </p>
                      <div className="flex flex-row gap-1">
                        <FaStar className="text-[#E4AD1E]" />
                        <div className="text-black">{tasterData?.rate}</div>
                        <div className="text-[#8C8C8C]">
                          ({tasterData?.review_count})
                        </div>
                      </div>
                      <div className="flex flex-row gap-1">
                        <div className="text-black">
                          {tasterData?.recommended_count}
                        </div>
                        <div className="text-[#8C8C8C]">recommended</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex flex-row gap-2 justify-start items-center text-base font-medium"
              onClick={() => {
                setShowOrNotShowHistory(!showOrNotShowHistory);
              }}
            >
              <p>Notification History</p>

              {showOrNotShowHistory ? (
                <BsChevronCompactUp />
              ) : (
                <BsChevronCompactDown />
              )}
            </div>

            {showOrNotShowHistory && (
              <div className="py-5">
                {notificationHistory?.length === 0 ? (
                  <p className="text-[12px] font-medium">No History Data</p>
                ) : (
                  <>
                    {notificationHistory?.map((item) => {
                      return (
                        <div className="bg-[#F3F2F2] p-5 rounded-lg my-2">
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-sm font-medium">{item?.title}</p>
                            <p className="text-[12px] font-medium">
                              {item?.date_time}
                            </p>
                          </div>

                          <p className="pt-2 text-[12px] font-normal">
                            {item?.description}
                          </p>

                          <div className="flex justify-end">
                            <Button
                              onClick={() => {
                                handleNotificationMessage({
                                  title: item?.title,
                                  discription: item?.description,
                                });
                              }}
                            >
                              RESEND
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}

            <Divider />

            <div>
              <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                className="w-full add-form"
                onFinish={handleNotificationMessage}
              >
                <Form.Item
                  name="title"
                  className="text-[16px]"
                  label="Title"
                  rules={[{ required: true, message: "Title is required" }]}
                >
                  <Input
                    placeholder="Title"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>

                <Form.Item
                  name="discription"
                  className="text-[16px]"
                  label="Description"
                  rules={[
                    { required: true, message: "Description is required" },
                  ]}
                >
                  <TextArea
                    placeholder="Type message here"
                    className="!bg-secondaryWhite"
                    size="large"
                    maxLength={500}
                    style={{
                      height: 200,
                      resize: "none",
                    }}
                  />
                </Form.Item>

                <div className="w-full pt-12">
                  <Row gutter={8}>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <FormSubmitButton
                          form={form}
                          //   errormsg={errormsg}
                          //   isUploading={isUploading}
                          isEditButtonEnable={false}
                          type={"SEND"}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} className="outline-button">
                      <Button
                        size="large"
                        className="font-medium w-full "
                        onClick={onClose}
                      >
                        <p className="text-center">CANCEL</p>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default TaskerNotificationForm;
