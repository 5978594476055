import React, { useState } from "react";
import { Button, Form, Input, Select, Switch, Divider, Checkbox } from "antd";
import { useContentContext } from "../../../providers/ContentContext";
import { PiWarningCircleThin } from "react-icons/pi";

const DateTimeForm = ({ formType, pageTitleId, onCancel, fieldStatus }) => {
  let { pageTitleCount } = useContentContext();
  const [errorMsg, setErrorMsg] = useState(false);

  const checkField = async (e) => {
    pageTitleCount.forEach((pageTitle) => {
      if (pageTitle.page_title_id === pageTitleId) {
        pageTitle.question_data.forEach((question) => {
          if (question.title === e) {
            setErrorMsg(true);
          } else {
            setErrorMsg(false);
          }
        });
      }
    });
  };

  return (
    <div className="w-full add-form pt-3">
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please enter the Title !" }]}
      >
        <Input
          placeholder="Type the input field title"
          className="!bg-secondaryWhite"
          size="large"
          onChange={() => {
            setErrorMsg(false);
          }}
          onKeyUp={(e) => {
            checkField(e.target.value);
          }}
          maxLength={255}
        />
      </Form.Item>

      {errorMsg && (
        <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-5">
          Question field already exist.
        </p>
      )}

      <Form.Item
        name="hint"
        label="Hint"
        rules={[{ required: true, message: "Please enter the Title !" }]}
      >
        <Input
          placeholder="Type the hint for the input field"
          className="!bg-secondaryWhite"
          size="large"
          maxLength={255}
        />
      </Form.Item>

      <div className="text-[16px] font-normal  pb-3">Input Type</div>
      <Form.Item
        name="inputType"
        rules={[{ required: true, message: "Please select the input type !" }]}
      >
        <Select
          placeholder="Select a input type"
          required
          className="!bg-secondaryWhite"
          size="large"
          options={[
            { value: 1, label: "Date" },
            { value: 2, label: "Time" },
            { value: 3, label: "Date & Time" },
          ]}
        />
      </Form.Item>

      <Divider />
      <div className="job-type-question-require">
        <div className="flex flex-row gap-3">
          <Form.Item name="require" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <p className="text-[16px] font-normal">Required</p>
        </div>

        <div className="flex flex-row mt-[-15px]">
          <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
          <p className="text-[12px] text-secondaryColor">
            Select whether the question is mandatory or not
          </p>
        </div>
      </div>

      <Divider />
      <div className="flex flex-row justify-end gap-10">
        <Form.Item>
          <Button
            className={`flex justify-center flex-row ${
              errorMsg ? "!bg-[#cecdcd]" : "bg-primaryLight"
            }  font-medium min-w-[100px]`}
            type="primary"
            htmlType="submit"
            size="large"
            disabled={errorMsg ? true : false}
          >
            {formType === "update" ? (
              <p className="flex text-secondaryWhite">UPDATE</p>
            ) : (
              <p className="flex text-secondaryWhite">ADD</p>
            )}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            className="min-w-[100px] hover:!text-primaryLight hover:!border-primaryLight"
            onClick={() => {
              onCancel();
            }}
          >
            CANCEL
          </Button>
        </Form.Item>
      </div>
    </div>
  );
};

export default DateTimeForm;
