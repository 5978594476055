import { notification } from "antd";
import { createContext, useContext, useState } from "react";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import axios from "axios";
import Loading from "../components/Loading";
import { url } from "../helpers/apiHelpers/header";

const ContentContext = createContext(undefined);

export const ContentProvider = ({ children }) => {
  const token = getLocalStoragedata("token");
  const [api, contextHolder] = notification.useNotification();
  const placement = "top";

  const [isLoading, setIsLoading] = useState(true);
  const [promoSmsUserList, setPromoSmsUserList] = useState();
  const [selectedPromoList, setSelectedPromoList] = useState([]);

  const [notificationArray, setNotificationArray] = useState([]);
  // const [notificationountry, setNotificationCountry] = useState();

  const [filterValues, setFilterValues] = useState({
    selectedCountry: 1,
    location1SelectedData: null,
    location2SelectedData: null,
    selectedCategoryData: null,
    selectedJobTypeData: null,
  });

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const config = (token) => ({
    headers: {
      "Access-Control-Allow-": true,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });

  const openNotification = (type, title, description) => {
    api[type]({
      message: title,
      description,
      placement,
    });
  };
  const handleError = (error) => {
    if (error.code === "ERR_NETWORK") {
      openNotification("error", "Network Error!", error.message);
    } else if (error.response) {
      if (error.response.status === 401) {
        openNotification("error", "Error!", error.response.data.message);
        localStorage.clear();
        window.location.replace("/login");
      } else if (error.response.status === 500) {
        openNotification("error", "Internal Server Error!", error.message);
      } else {
        openNotification("error", "Error!", error.message);
      }
    }
  };
  const loadData = async (endpoint, data) => {
    try {
      const response = await axios
        .post(url + endpoint, data, config(token))
        .catch((error) => {
          handleError(error);
        });
      return response ? response.data.output : [];
    } catch (error) {
      handleError(error);
    }
  };

  //Exixting permission list
  const loadAllPermissionList = async () =>
    loadData("permission/list", { is_default: 1, status: 1 });

  const loadAdminRolePermissions = async () =>
    loadData("user/type/all", {
      is_default: 0,
      status: -1,
    });

  //Check endpoints
  const checkQuestion = async (data) => loadData("job/question/check", data);
  const checkQuestionField = async (data) =>
    loadData("question/field/check", data);

  const [pageTitleCount, setPageTitleCount] = useState([
    {
      page_title_id: 1,
      page_title: "",
      question_data: [],
      status: 1,
    },
  ]);

  const transformedPageTitleQuestionArray = pageTitleCount.map((item) => {
    return {
      id: item?.page_title_id,
      question: item?.page_title,
      status: item?.status,
      fields: item?.question_data,
    };
  });

  return (
    <ContentContext.Provider
      value={{
        isLoading,
        url,
        openNotification,
        handleError,
        loadAllPermissionList,
        loadAdminRolePermissions,
        checkQuestion,
        checkQuestionField,
        pageTitleCount,
        setPageTitleCount,
        transformedPageTitleQuestionArray,

        promoSmsUserList,
        setPromoSmsUserList,

        selectedPromoList,
        setSelectedPromoList,

        notificationArray,
        setNotificationArray,

        // notificationountry,
        // setNotificationCountry,
        filterValues,
        setFilterValues,
      }}
    >
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <>
          {contextHolder}
          {children}
        </>
      )}
    </ContentContext.Provider>
  );
};

export const useContentContext = () => useContext(ContentContext);
