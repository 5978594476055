const JobStatus = {
  1: "Placed",
  2: "Interested",
  3: "Shortlisted",
  4: "Assigned",
  5: "Completed",
  6: "Rated",
};

export function getJobStatusLabel(id) {
  return JobStatus[id];
}

export function getAllJobStatuses() {
  let data = [
    JobStatus[1],
    JobStatus[2],
    JobStatus[3],
    JobStatus[4],
    JobStatus[5],
    JobStatus[6],
  ];
  let statuses = [];
  data.forEach((status, x) => {
    let data = {
      value: x + 1,
      label: status,
    };
    statuses.push(data);
  });

  return statuses;
}

const JonUrgency = {
  1: "Urgent",
  3: "Next Few Weeks",
  5: "I'm Flexible",
};

export function getJobUrgencyLabel(id) {
  return JonUrgency[id];
}

const JobStatusColors = {
  1: "bg-[#F5B041]",
  2: "bg-[#3498DB]",
  3: "bg-[#F8C291]",
  4: "bg-[#D98880]",
  5: "bg-[#82E0AA]",
  6: "bg-[#34495E]",
};

export function getJobStatusColor(id) {
  return JobStatusColors[id];
}
