import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { useContentContext } from "../providers/ContentContext";
import { EditTerms, NewTerms, ViewTerms } from "../components";
import { getTermsAndConditions } from "../helpers";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { customStatusSorter } from "../commonFun";

const columns = [
  {
    title: "Version",
    dataIndex: "version",
    key: "0",
    sorter: (a, b) => a.version?.localeCompare(b.version),
    defaultSortOrder: "ascend",
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    key: "1",
    sorter: (a, b) => a.createdDate?.localeCompare(b.createdDate),
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "3",
    sorter: (a, b) => customStatusSorter(a, b),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];

const Terms = () => {
  const permission = getLocalStoragedata("permission");
  const { handleError, openNotification } = useContentContext();
  const [dataLoading, setDataLoading] = useState(false);
  const [tableTermsData, setTableTermsData] = useState();

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState();

  const [isOpenNewDrawer, setIsOpenNewDrawer] = useState(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);
  const [isOpenViewDrawer, setIsOpenViewDrawer] = useState(false);
  const [selectedTerms, setSelectedTerms] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    setDataLoading(true);

    await getTermsAndConditions()
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output);
          setDataForSearch(response.data.output);
        } else {
          openNotification(
            "warning",
            "Data loading failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const setData = (terms) => {
    if (terms.length !== 0) {
      var tableData = [];

      terms?.forEach((term, x) => {
        let data = {
          key: x,
          version: term?.version,
          createdDate: term?.created_at,
          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none pointer-events-none ${
                term?.is_active === 1 ? "bg-primaryColor" : "bg-primaryLight"
              } `}
            >
              {term?.is_active === 1 ? "Active" : "Inactive"}
            </Button>
          ),
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(50) && (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditDrawer(term);
                  }}
                />
              )}
              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewDrawerOpenChange(term);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableTermsData(tableData);
    } else {
      setTableTermsData([]);
    }
    setDataLoading(false);
  };

  const handleNewDrawerOpenChange = () => {
    setIsOpenNewDrawer(!isOpenNewDrawer);
  };
  const handleViewDrawerOpenChange = (terms) => {
    setIsOpenViewDrawer(!isOpenViewDrawer);
    setSelectedTerms(terms);
  };
  const openEditDrawer = (terms) => {
    setIsOpenEditDrawer(true);
    setSelectedTerms(terms);
  };
  const closeEditDrawer = () => {
    setIsOpenEditDrawer(false);
  };

  const search = (text) => {
    let filtered = dataForSearch.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );

    setData(filtered);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        TERMS & CONDITIONS
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-3">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Terms & Conditions..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(49) && (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                icon={<MdAdd className="text-2xl font-bold" />}
                onClick={handleNewDrawerOpenChange}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD TERMS & CONDITIONS
                </p>
              </Button>
            )}
          </Col>
        </Row>
        <div className="table-status-button">
          <Table
            dataSource={tableTermsData}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
            loading={dataLoading}
            scroll={{ x: "100%" }}
          />
        </div>
      </Content>

      {isOpenNewDrawer && (
        <NewTerms
          open={isOpenNewDrawer}
          onClose={handleNewDrawerOpenChange}
          reloadtableData={fetchData}
          setSearchText={setSearchText}
        />
      )}

      {isOpenEditDrawer && (
        <EditTerms
          open={isOpenEditDrawer}
          onClose={closeEditDrawer}
          reloadtableData={fetchData}
          setSearchText={setSearchText}
          selectedTerms={selectedTerms}
        />
      )}

      {isOpenViewDrawer && (
        <ViewTerms
          open={isOpenViewDrawer}
          onClose={handleViewDrawerOpenChange}
          setSearchText={setSearchText}
          selectedTerms={selectedTerms}
        />
      )}
    </>
  );
};

export default Terms;
