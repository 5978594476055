import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Switch,
  Upload,
  Row,
  Col,
  Checkbox,
  Select,
  Spin,
} from "antd";
import { MdClose } from "react-icons/md";
import { PiWarningCircleThin } from "react-icons/pi";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import CategoryDelete from "../deleteComponents/CategoryDelete";
import { IoMdCloudUpload } from "react-icons/io";
import {
  categoryCountryAdd,
  checkCategoryName,
  deleteCategory,
  editCategory,
  getAllCountries,
} from "../../helpers";
import _ from "lodash";
import CategorySubmitButton from "../submitButton/CategorySubmitButton";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }); //both primary and top image

const EditCategory = ({
  open,
  onClose,
  category,
  reloadCategoryData,
  setSearchText,
}) => {
  let { handleError, openNotification } = useContentContext();
  const userId = getLocalStoragedata("userId");
  const [form] = Form.useForm();
  const permission = getLocalStoragedata("permission");

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isEditButtonEnable, setIsEditButonEnable] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false); //both primary and top image
  const [previewImage, setPreviewImage] = useState(""); //both primary and top image
  const [previewTitle, setPreviewTitle] = useState(""); //both primary and top image
  const [fileList, setFileList] = useState(
    category?.image_path === null
      ? []
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: category?.image_path,
          },
        ]
  ); //for primary image
  const [topFileList, setTopFileList] = useState(
    category?.popular_image_path === null
      ? []
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: category?.popular_image_path,
          },
        ]
  ); // for top image

  const initialPrimaryImage =
    category?.image_path === null
      ? []
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: category?.image_path,
          },
        ];

  const initialPopularImage =
    category?.popular_image_path ===
      "https://dev-api.jobninja.com.au/images/no_image.jpg" ||
    category?.popular_image_path === null
      ? undefined
      : [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: category?.popular_image_path,
          },
        ];

  const [isSwitchOn, setIsSwitchOn] = useState(
    category?.is_active === 1 ? true : false
  );
  const [checkBoxStatus, setCheckBoxStatus] = useState(
    category?.is_popular === 1 ? true : false
  );

  const [selectorGlobalItemStatus, seSelectorGlobalItemStatus] = useState(
    category?.country_type === 1 ? false : true
  );
  const [selectorCountryItemStatus, seSelectorCountryItemStatus] = useState(
    category?.country_type === 1 ? true : false
  );
  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [initialCountries, setInitialCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [selectorGlobalItemStatus, selectorCountryItemStatus]);

  useEffect(() => {
    if (category?.country_type === 1) {
      setIsLoading(true);
      setInitialCountries([0]);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setInitialCountries(
        category?.countries.map((country) => {
          return country.country_id;
        })
      );
      setIsLoading(false);
    }
  }, []);

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
    setIsEditButonEnable(false);
  };

  const handleCancel = () => setPreviewOpen(false); //both primary and top image
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  }; //both primary and top image
  const handleChange = ({ fileList: newFileList }) => {
    setIsEditButonEnable(false);
    setFileList(newFileList);
  }; // for primary image
  const handleTopImagChange = ({ fileList: newTopFileList }) =>
    setTopFileList(newTopFileList); // for top image

  const uploadButton = (
    <div className="flex flex-col p-3 items-center text-primaryLightDisable italic text-[14px] font-normal">
      <p>Drop files here to upload</p>
      <p>OR</p>
      <IoMdCloudUpload className="text-[66px]" />
      <p className="underline">Click Here to Upload</p>
    </div>
  ); //both primary and top image

  const onFinish = async (e) => {
    setSearchText();

    //primary image required for a category
    if (fileList.length === 0) {
      openNotification("warning", "Please upload an image for a category.");
      return;
    }

    //popular image required for a category
    if (e.popular === true && topFileList.length === 0) {
      openNotification(
        "warning",
        "Please upload an popular image for a category."
      );
      return;
    }

    const data = new FormData();
    data.append("job_category_id", category?.id);
    data.append("job_category", e?.category);
    data.append(
      "file_extension",
      fileList.length === 1 ? fileList[0]?.name.split(".")[1] : null
    );
    data.append(
      "job_category_image",
      fileList.length !== 0 ? fileList[0]?.originFileObj : null
    );
    data.append("is_popular", e.popular === true ? 1 : 0);
    data.append("is_complex", 0);
    data.append("status", e.switch === false ? 0 : 1);
    data.append(
      "popular_image",
      topFileList[0] ? topFileList[0]?.originFileObj : null
    );
    data.append(
      "file_extension1",
      topFileList.length === 1 ? topFileList[0]?.name.split(".")[1] : null
    );
    data.append("updated_by", userId);

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);

      const res = await editCategory(data)
        .then((response) => {
          if (response.data.success) {
            openNotification("success", response.data.message);
            return response.data.success;
          } else {
            openNotification("error", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      if (res) {
        categoryCountryAdd({
          job_category_id: category?.id,
          country: e.country,
          status: 1,
        })
          .then((response) => {
            if (response.data.success) {
              reloadCategoryData(0);
              // openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });
      } else {
        reloadCategoryData(0);
      }

      onClose();
      setIsUploading(false);
    }
  };

  const checkCategory = (e) => {
    const data = {
      job_category_id: category?.id,
      job_category: e, // set the id
    };

    checkCategoryName(data)
      .then((response) => {
        if (response.data.success) {
          if (category?.job_category !== e) {
            setErrormsg(
              response.data.output.has_job_category === 1 ? true : false
            );
          } else {
            setErrormsg(false);
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const deleteModalOpen = () => {
    setIsOpenDeleteModal(true);
  };
  const deleteModalClose = () => {
    setIsOpenDeleteModal(false);
  };
  const deleteCategoryPermanently = () => {
    const data = {
      job_category_id: category.id,
      updated_by: userId,
    };

    deleteCategory(data)
      .then((response) => {
        if (response.data.success) {
          reloadCategoryData(0);
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
        setIsOpenDeleteModal(false);
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  const formInitialState = {
    category: category?.job_category,
    upload: fileList,
    topimageupload: topFileList,
    switch: category?.is_active === 1 ? true : false,
    popular: category?.is_popular === 1 ? true : false,
    country: initialCountries,
  };

  const onFieldsChange = () => {
    let formState = form.getFieldsValue();

    if (
      formInitialState.category !== formState.category ||
      !_.isEqual(formState.upload, initialPrimaryImage) ||
      formState.switch !== formInitialState.switch ||
      !_.isEqual(formState.topimageupload, initialPopularImage) ||
      JSON.stringify(formState.country) !==
        JSON.stringify(formInitialState.country)
    ) {
      setIsEditButonEnable(false);
    } else {
      setIsEditButonEnable(true);
    }
  };

  const fetchData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryList(response?.data.output);
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [
      { value: 0, label: "Global", disabled: selectorGlobalItemStatus },
    ];
    countries?.forEach((country) => {
      let data = {
        value: country.id,
        label: country.country,
        disabled: selectorCountryItemStatus,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const handleSelectorMode = (e) => {
    if (e === 0) {
      seSelectorCountryItemStatus(true);
      seSelectorGlobalItemStatus(false);

      setCountryData(countryList);
    } else {
      seSelectorCountryItemStatus(false);
      seSelectorGlobalItemStatus(true);

      setCountryData(countryList);
    }
  };

  const resetSelectoreMode = (e) => {
    if (e.length === 0) {
      seSelectorCountryItemStatus(false);
      seSelectorGlobalItemStatus(false);
    }
    setCountryData(countryList);
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          {isLoading ? (
            <div className="flex justify-center pt-[350px]">
              <Spin className="!text-[primaryLight]" />
            </div>
          ) : (
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              className="w-full edit-form"
              onFinish={onFinish}
              initialValues={{
                category: category?.job_category,
                switch: category?.is_active === 1 ? true : false,
                popular: category?.is_popular === 1 ? true : false,
                country: initialCountries,
              }}
              fields={[
                { name: ["upload"], value: fileList },
                { name: ["topimageupload"], value: topFileList },
              ]}
              onFieldsChange={onFieldsChange}
            >
              <div className="relative bg-secondaryWhite p-7 ">
                <div className="w-full flex flex-row  justify-between">
                  <div className="text-[16px] font-medium text-primaryLight pb-4">
                    Edit Category
                  </div>
                  <Button
                    className="border-none"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <MdClose className="text-[20px]" />
                  </Button>
                </div>

                <Row className="flex flex-row" gutter={8}>
                  <Col xs={24} md={10}>
                    <Form.Item
                      name="upload"
                      label="Primary Image"
                      rules={[{ required: true }]}
                    >
                      <div className=" flex flex-col justify-center items-center!">
                        <Upload
                          customRequest={({ onSuccess }) => onSuccess("ok")}
                          listType="picture-card"
                          className="md:col-span-5"
                          onPreview={handlePreview}
                          onChange={handleChange}
                          fileList={fileList}
                          accept=".png, .jpg, .jpeg"
                        >
                          {fileList.length === 1 ? null : uploadButton}
                        </Upload>
                        <p className="text-[12px] font-medium">
                          Primary category image. Resolution should be (1:1)
                        </p>
                        <Modal
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={14}>
                    <Form.Item
                      className="text-[16px]"
                      label="Category"
                      rules={[{ required: true }]}
                      name="category"
                    >
                      <Input
                        placeholder="Category name"
                        className="!bg-secondaryWhite"
                        size="large"
                        onKeyUp={(e) => {
                          checkCategory(e.target.value);
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </Form.Item>

                    {errormsg && (
                      <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-3">
                        Category name already exist
                      </p>
                    )}
                  </Col>
                </Row>

                {/* select country */}
                <Divider />
                <Row>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="country"
                      className="text-[16px]"
                      label="Select Country"
                      rules={[
                        { required: true, message: "Please select country" },
                      ]}
                    >
                      <Select
                        name="country"
                        placeholder="Country"
                        required
                        className="!bg-secondaryWhite"
                        size="large"
                        mode="multiple"
                        onChange={(e) => {
                          resetSelectoreMode(e);
                        }}
                        onSelect={(e) => {
                          handleSelectorMode(e);
                        }}
                        options={countrySelectorData}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col font-medium text-base">
                      Category Status
                    </div>
                    <div className="flex flex-row mt-2 text-center items-baseline">
                      <span
                        className={`${
                          isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                        }`}
                      >
                        Inactive
                      </span>
                      <Form.Item name="switch" valuePropName="checked">
                        <Switch
                          className="mx-2"
                          onChange={handleSwitchChange}
                        />
                      </Form.Item>
                      <span
                        className={`${
                          isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                        }`}
                      >
                        Active
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <PiWarningCircleThin className="text-[25px] mt-[-5px]] mr-2 text-secondaryColor" />
                    <p className="text-[12px] text-secondaryColor">
                      Changing the category status will impact the available job
                      types within the category
                    </p>
                  </div>
                </div>

                <Divider />

                <div className="w-full bg-secondaryLight p-3 rounded-[20px] add-permission">
                  <Row className="flex flex-row justify-between" gutter={8}>
                    <Col xs={24} sm={12}>
                      <div className="flex flex-row gap-3">
                        <Form.Item name="popular" valuePropName="checked">
                          <Checkbox
                            onChange={(e) => {
                              setCheckBoxStatus(e.target.checked);
                              setTopFileList([]);
                            }}
                          />
                        </Form.Item>

                        <p className="text-[16px] font-normal">
                          Add To Top Category
                        </p>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} className="text-right">
                      <p className="text-[12px] font-normal">
                        {`Total top categories (${category?.popular_count})`}
                      </p>
                    </Col>
                  </Row>

                  <div className="flex flex-row">
                    <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                    <p className="text-[12px] text-secondaryColor">
                      When enabled, this category becomes the top category in
                      its relevant fields.
                    </p>
                  </div>

                  {checkBoxStatus && (
                    <Row className="pt-5">
                      <Col xs={24} md={10}>
                        <Form.Item
                          name="topimageupload"
                          label="Top Category  Image"
                          rules={[{ required: true }]}
                        >
                          <Upload
                            customRequest={({ onSuccess }) => onSuccess("ok")}
                            listType="picture-card"
                            className="md:col-span-5"
                            onPreview={handlePreview}
                            onChange={handleTopImagChange}
                            fileList={topFileList}
                            accept=".png, .jpg, .jpeg"
                          >
                            {topFileList?.length === 1 ? null : uploadButton}
                          </Upload>

                          <Modal
                            className="z-[100000]"
                            open={previewOpen}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{
                                width: "100%",
                              }}
                              src={previewImage}
                            />
                          </Modal>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={14}>
                        <p className="text-[12px] font-medium pt-1 md:pt-20">
                          Top Category Image should differ from the category
                          image. Resolution should be (4:3)
                        </p>
                      </Col>
                    </Row>
                  )}
                </div>

                {permission.includes(23) && (
                  <>
                    <Divider />
                    <div>
                      <p className="font-medium text-base pb-5">
                        Delete Category
                      </p>

                      <div className="flex flex-row pb-3">
                        <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                        <p className="text-[12px] text-secondaryColor">
                          Before deleting a category, make sure there are no
                          active jobs or active transaction within the job
                          category
                        </p>
                      </div>
                      <Button
                        size="large"
                        className="!border-primaryRed text-primaryRed hover:!text-secondaryWhite hover:!bg-primaryRed hover:!border-primaryRed"
                        onClick={deleteModalOpen}
                      >
                        DELETE
                      </Button>
                    </div>
                  </>
                )}
              </div>

              <Divider />
              {/* button */}
              <div className="w-full pl-7 pb-5">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <CategorySubmitButton
                        form={form}
                        errormsg={errormsg}
                        isUploading={isUploading}
                        isEditButtonEnable={isEditButtonEnable}
                        type={"SAVE"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full"
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </div>
      </Drawer>
      {isOpenDeleteModal && (
        <CategoryDelete
          open={isOpenDeleteModal}
          onCancel={deleteModalClose}
          deleteData={category}
          deletePermanently={deleteCategoryPermanently}
        />
      )}
    </>
  );
};

export default EditCategory;
