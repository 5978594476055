import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  TimePicker,
  Row,
  Col,
  Checkbox,
} from "antd";
import { MdClose, MdAdd, MdOutlineAddBox } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import EditInputField from "./EditInputField";
import { PiWarningCircleThin } from "react-icons/pi";
import JobTypeDelete from "../deleteComponents/JobTypeDelete";
import {
  checkJobTypeName,
  deleteJobType,
  editJobType,
  getAllCategories,
  getAllJobTypeQuestionList,
  getAllUom,
  jobTypeQuestionAdd,
} from "../../helpers";
import _ from "lodash";
import { IoTrashOutline } from "react-icons/io5";
import AddInputField from "./AddInputField";
import JobTypeFormSubmitButton from "../submitButton/JobTypeFormSubmitButton";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PageTitleDelete from "../deleteComponents/PageTitleDelete";
import QuestionDelete from "../deleteComponents/QuestionDelete";
dayjs.extend(customParseFormat);
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const dateFormat = "YYYY/MM/DD";
const format = "HH:mm";

const EditJobType = ({
  open,
  onClose,
  jobType,
  reloadJobTypeData,
  setSearchText,
}) => {
  const userId = getLocalStoragedata("userId");

  let {
    openNotification,
    handleError,
    pageTitleCount,
    setPageTitleCount,
    transformedPageTitleQuestionArray,
  } = useContentContext();
  const [form] = Form.useForm();
  const permission = getLocalStoragedata("permission");

  const [allQuestionList, setAllQuestionsList] = useState();
  const [existCategoryId, setExistCategoryId] = useState();

  const [categorySelectorData, setCategorySelectorData] = useState();
  const [uomSelectorData, setUOMSelectorData] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isEditButtonEnable, setIsEditButonEnable] = useState(true);
  const [errormsg, setErrormsg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [isSwitchOn, setIsSwitchOn] = useState(
    jobType?.is_active === 1 ? true : false
  );

  let questionErrorCount = 0;

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
    setPageTitleCount([
      {
        page_title_id: 1,
        page_title: "",
        question_data: [],
        status: 1,
      },
    ]);
  }, []);

  const fetchData = async () => {
    try {
      await getAllCategories({ is_top: 0, status: -1 })
        .then((response) => {
          if (response?.data.success) {
            const existCateory = response?.data.output.find(
              (category) => category.id === jobType?.job_category_id
            );
            if (existCateory) {
              setExistCategoryId(jobType?.job_category_id);
            } else {
              setExistCategoryId(undefined);
            }
            setCategoryList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      await getAllUom({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setUOMList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      await getAllJobTypeQuestionList({
        job_category_id: jobType?.job_category_id,
        job_type_id: jobType?.id,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllQuestionsList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
      setIsLoading(false);
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCategoryList = (categories) => {
    var categoryObj = [];
    categories?.forEach((category) => {
      let data = {
        value: category.id,
        label: category.job_category,
      };
      categoryObj.push(data);
    });
    setCategorySelectorData(categoryObj);
  };

  const setUOMList = (uoms) => {
    var uomObj = [];
    uoms?.forEach((uom) => {
      let data = {
        value: uom.id,
        label: uom.uom,
      };
      uomObj.push(data);
    });
    setUOMSelectorData(uomObj);
  };

  const onFinish = async (e) => {
    setSearchText();

    const checkData = {
      job_category_id: e.category,
      job_type: e.jobType,
      job_type_id: jobType?.id,
    };

    const response = await checkJobTypeName(checkData)
      .then((response) => {
        if (response.data.success) {
          if (
            e.category === jobType?.job_category_id &&
            e.jobType === jobType?.job_type
          ) {
            return 0;
          } else {
            return response.data.output.has_job_type;
          }
        }
      })
      .catch((error) => {
        handleError(error);
      });

    if (response === 0) {
      const data = new FormData();
      data.append("job_type_id", jobType?.id);
      data.append("job_category_id", e.category);
      data.append("job_type", e.jobType);
      data.append("uom_id", e.uom);

      data.append("file_extension2", null);

      data.append("urgent_image", null);

      data.append("is_urgent", 0);
      data.append("updated_by", userId);
      data.append("status", e.switch === false ? 0 : 1);
      data.append("is_remote", e.remote === true ? 1 : 0);

      if (isUploading) {
        openNotification("warning", "Please wait Upload on progress !");
      } else {
        setIsUploading(true);

        editJobType(data)
          .then((response) => {
            if (response.data.success) {
              reloadJobTypeData(0);
              openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
            setIsUploading(false);
            setErrormsg(false);
          })
          .catch((error) => {
            handleError(error);
          });
      }

      if (pageTitleCount[0].question_data.length !== 0) {
        const questionData = {
          job_category_id: e.category,
          job_type_id: jobType?.id,
          question: pageTitleCount,
        };
        jobTypeQuestionAdd(questionData)
          .then((response) => {
            if (response.data.success) {
              reloadJobTypeData(0);
              openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
            setIsUploading(false);
            setErrormsg(false);
          })
          .catch((error) => {
            handleError(error);
          });
      }
      onClose();
      setPageTitleCount([
        {
          page_title_id: 1,
          page_title: "",
          question_data: [],
          status: 0,
        },
      ]);
    } else {
      setErrormsg(true);
    }
  };

  const formInitialState = {
    category: existCategoryId,
    jobType: jobType?.job_type,
    uom: jobType?.uom_id,
    switch: jobType?.is_active === 1 ? true : false,
    remote: jobType?.is_remote === 1 ? true : false,
  };

  const initialPageTitleCount = {
    page_title_id: 1,
    page_title: "",
    question_data: [],
    status: 1,
  };

  const onFieldsChange = () => {
    let formState = form.getFieldsValue();

    if (
      formInitialState.category !== formState.category ||
      formInitialState.jobType !== formState.jobType ||
      formInitialState.switch !== formState.switch ||
      formInitialState.remote !== formState.remote ||
      formInitialState.uom !== formState.uom ||
      pageTitleCount.length !== 1 ||
      !_.isEqual(initialPageTitleCount, pageTitleCount[0])
    ) {
      setIsEditButonEnable(false);
    } else {
      setIsEditButonEnable(true);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const validatePageTitleDuplicate = (_, value) => {
    let pageTitleQuestionList = allQuestionList
      ? [...allQuestionList, ...transformedPageTitleQuestionArray]
      : [...transformedPageTitleQuestionArray];

    // Count occurrences of the given question value
    const count = pageTitleQuestionList.reduce(
      (acc, item, currentIndex) => (item.question === value ? acc + 1 : acc),
      0
    );

    if (count >= 2) {
      return Promise.reject(new Error("Duplicate page title !"));
    } else {
      return Promise.resolve();
    }
  };

  const deleteModalOpen = () => {
    setIsOpenDeleteModal(true);
  };
  const deleteModalClose = () => {
    setIsOpenDeleteModal(false);
  };
  const deleteJobTypePermanently = () => {
    const data = {
      job_type_id: jobType.id,
      updated_by: userId,
    };

    deleteJobType(data)
      .then((response) => {
        if (response.data.success) {
          reloadJobTypeData(0);
          openNotification("success", response.data.message);
        } else {
          openNotification("error", response.data.message);
        }
        setIsOpenDeleteModal(false);
      })
      .catch((error) => {
        handleError(error);
      });
    onClose();
  };

  const [isOpenInputFieldAddModal, setIsOpenInputFieldAddModal] =
    useState(false);
  const [isOpenInputFieldEditModal, setIsOpenInputFieldEditModal] =
    useState(false);
  const [pageTitleId, setPageTitleId] = useState();
  const [selectedQuestionData, setSelectedQuestionData] = useState();

  const handleOpenInputFieldAddModal = (e) => {
    setIsOpenInputFieldAddModal(!isOpenInputFieldAddModal);
    setPageTitleId(e);
  };

  const handleOpenInputFieldEditModal = (
    pageTitleData,
    questionIndex,
    questionData
  ) => {
    setIsOpenInputFieldEditModal(true);
    setSelectedQuestionData({
      pageTitleData: pageTitleData,
      questionIndex: questionIndex,
      questionData: questionData,
    });
  };

  const handleCloseInputFieldEditModal = () => {
    setIsOpenInputFieldEditModal(false);
  };

  const handleRemovePageTitleData = (pageTitleIdToRemove) => {
    setPageTitleCount((prevPageTitleCount) =>
      prevPageTitleCount.filter(
        (pageTitle) => pageTitle.page_title_id !== pageTitleIdToRemove
      )
    );
  };
  const handlePageTitleChange = (index, e) => {
    const updatedPageTitleCount = [...pageTitleCount];
    updatedPageTitleCount[index].page_title = e.target.value;
    setPageTitleCount(updatedPageTitleCount);
  };

  const deleteQuestion = (pageTitleId, questionIndex) => {
    setPageTitleCount((prevPageTitleCount) => {
      const updatedPageTitleCount = prevPageTitleCount.map((pageTitle) => {
        if (pageTitle.page_title_id === pageTitleId) {
          const updatedQuestions = pageTitle.question_data.filter(
            (_, index) => index !== questionIndex
          );
          return { ...pageTitle, question_data: updatedQuestions };
        }
        return pageTitle;
      });

      return updatedPageTitleCount;
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  //Page Title delete permenently
  const [isOpenPageTitleDeleteModal, setIsOpenPageTitleDeleteModal] =
    useState(false);
  const [deletePageTitleData, setDeletePageTitleData] = useState({
    categoryId: jobType?.job_category_id,
    jobTypeId: jobType?.id,
    pageTitleId: 0,
  });

  const deletePageTitleModalOpen = (pageTitle) => {
    setIsOpenPageTitleDeleteModal(true);
    setDeletePageTitleData({
      ...deletePageTitleData,
      pageTitleId: pageTitle.id,
    });
  };
  const deletePageTitleModalClose = () => {
    setIsOpenPageTitleDeleteModal(false);
  };

  //Question delete permenently
  const [isOpenQuestioneDeleteModal, setIsOpenQuestionDeleteModal] =
    useState(false);
  const [deleteQuestionData, setDeleteQuestionData] = useState({
    categoryId: jobType?.job_category_id,
    jobTypeId: jobType?.id,
    questionId: 0,
    questionCount: 0,
  });

  const deleteQuestionModalOpen = (question, questionCount) => {
    setIsOpenQuestionDeleteModal(true);
    setDeleteQuestionData({
      ...deleteQuestionData,
      questionId: question?.id,
      questionCount: questionCount,
    });
  };
  const deleteQuestionModalClose = () => {
    setIsOpenQuestionDeleteModal(false);
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          {isLoading ? (
            <div className="flex justify-center pt-[350px]">
              <Spin className="!text-[primaryLight]" />
            </div>
          ) : (
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              className="w-full add-form"
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
              initialValues={{
                category: existCategoryId,
                jobType: jobType?.job_type,
                uom: jobType?.uom_id,
                switch: jobType?.is_active === 1 ? true : false,

                remote: jobType?.is_remote === 1 ? true : false,
              }}
            >
              <div className="relative bg-secondaryWhite p-7 ">
                <div className="w-full flex flex-row  justify-between">
                  <div className="text-[16px] font-medium text-primaryLight">
                    Edit Job Type
                  </div>
                  <Button
                    className="border-none"
                    onClick={() => {
                      onClose();
                      setPageTitleCount([
                        {
                          page_title_id: 1,
                          page_title: "",
                          question_data: [],
                          status: 1,
                        },
                      ]);
                    }}
                  >
                    <MdClose className="text-[20px]" />
                  </Button>
                </div>
                <Row className="flex flex-row" gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="category"
                      label="Select Category"
                      rules={[
                        { required: true, message: "Please select category !" },
                      ]}
                    >
                      <Select
                        name="category"
                        placeholder="Select category"
                        required
                        className="!bg-secondaryWhite"
                        size="large"
                        options={categorySelectorData}
                        filterOption={filterOption}
                        showSearch
                        onSelect={() => {
                          setErrormsg(false);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="jobType"
                      label="Job Type"
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Job type"
                        className="!bg-secondaryWhite"
                        size="large"
                        onChange={() => {
                          setErrormsg(false);
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </Form.Item>

                    {errormsg && (
                      <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px] pb-3">
                        JobType name already exist !
                      </p>
                    )}
                  </Col>
                </Row>
                <Row className="flex flex-row" gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Unit Of Measure"
                      name="uom"
                      rules={[
                        {
                          required: true,
                          message: "Please select unit of measure !",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Unit Of Measure"
                        required
                        className="!bg-secondaryWhite"
                        size="large"
                        options={uomSelectorData}
                        filterOption={filterOption}
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* remote */}
                <div className="w-full bg-secondaryLight p-3 rounded-[20px]">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-3">
                      <Form.Item name="remote" valuePropName="checked">
                        <Checkbox />
                      </Form.Item>
                      <p className="text-[16px] font-normal">Remote Job</p>
                    </div>
                  </div>
                  <div className="pt-[-2px]">
                    <p className="text-[12px] text-secondaryColor">
                      hint text - When enabled, this job type allows employees
                      to work from any location, eliminating the requirement to
                      visit a specific place
                    </p>
                  </div>
                </div>

                {/* dynamic question */}
                <Divider />
                <div className="w-full bg-secondaryLight p-3 rounded-[20px]">
                  <Row className="flex flex-row justify-between" gutter={8}>
                    <Col xs={24} sm={12}>
                      <p className="text-[16px] font-normal">
                        Dynamic Questions
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      className="flex justify-end outline-button"
                    >
                      <Button
                        className="flex flex-row gap-2"
                        onClick={() =>
                          setPageTitleCount([
                            ...pageTitleCount,
                            {
                              page_title_id: pageTitleCount.length + 1,
                              page_title: "",
                              question_data: [],
                              status: 1,
                            },
                          ])
                        }
                      >
                        <p className="text-[16px] font-normal">Add More</p>
                        <MdAdd className="text-[22px]" />
                      </Button>
                    </Col>
                  </Row>
                  <p className="text-[14px] font-normal">
                    Add dynamic questions related to the relevant job type
                  </p>

                  {pageTitleCount?.map((pageTitle, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Divider />
                        {/* page title */}
                        <div className="flex flex-row justify-between">
                          <Form.Item
                            label="Page Title"
                            name={`pageTitle${index}`}
                            className="pt-5 w-full"
                            rules={[
                              {
                                required:
                                  pageTitleCount.length === 1 &&
                                  pageTitleCount[0].question_data.length ===
                                    0 &&
                                  pageTitleCount[0].page_title === ""
                                    ? false
                                    : true,
                              },
                              { validator: validatePageTitleDuplicate },
                            ]}
                          >
                            <Input
                              placeholder="Type page title here"
                              className="!bg-secondaryWhite"
                              size="large"
                              onChange={(e) => handlePageTitleChange(index, e)}
                              maxLength={255}
                            />
                          </Form.Item>

                          {index !== 0 && (
                            <MdClose
                              className="text-[20px]"
                              onClick={() =>
                                handleRemovePageTitleData(
                                  pageTitle.page_title_id
                                )
                              }
                            />
                          )}
                        </div>

                        {/* button */}
                        <Button
                          className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                          type="primary"
                          size="large"
                          icon={
                            <MdOutlineAddBox className="text-2xl font-bold" />
                          }
                          onClick={() => {
                            handleOpenInputFieldAddModal(
                              pageTitle?.page_title_id
                            );
                          }}
                        >
                          <p className=" text-secondaryWhite text-[18px] font-medium">
                            ADD QUESTION
                          </p>
                        </Button>

                        {(pageTitleCount.length !== 1 &&
                          pageTitleCount[index].question_data.length === 0) ||
                        (index === 0 &&
                          pageTitle.page_title !== "" &&
                          pageTitle.question_data.length === 0) ? (
                          <>
                            <p className="hidden">
                              {(questionErrorCount += 1)}
                            </p>

                            <p className="text-[#ff4d4f]">
                              Please add question !
                            </p>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* questions */}
                        {pageTitle?.question_data.length !== 0 && (
                          <div className="py-5">
                            <div className="rounded-xl pb-5 bg-secondaryDarkshade">
                              {pageTitle?.question_data.map(
                                (question, index) => (
                                  <React.Fragment key={index}>
                                    <div className=" px-8 py-5">
                                      <p className="text-[16px] font-normal pb-2">
                                        {`${question?.title} ${
                                          question?.require === 1 ? "*" : ""
                                        }`}
                                      </p>
                                      <Row gutter={16}>
                                        <Col xs={24} sm={14} className="w-full">
                                          {question?.field_type === 2 &&
                                          question?.input_type === 1 ? (
                                            <Select
                                              placeholder={question?.hint}
                                              required
                                              className="font-medium text-[16px] w-full"
                                              size="large"
                                              options={question?.answer}
                                            />
                                          ) : question?.field_type === 2 &&
                                            question?.input_type === 2 ? (
                                            <Select
                                              mode="multiple"
                                              className="font-medium text-[16px] w-full"
                                              allowClear
                                              placeholder={question?.hint}
                                              options={question?.answer}
                                              size="large"
                                            />
                                          ) : question?.field_type === 3 &&
                                            question?.input_type === 1 ? (
                                            <DatePicker
                                              className="font-medium text-[16px] w-full"
                                              size="large"
                                              format={dateFormat}
                                              placeholder={question?.hint}
                                            />
                                          ) : question?.field_type === 3 &&
                                            question?.input_type === 2 ? (
                                            <TimePicker
                                              className="font-medium text-[16px] w-full"
                                              size="large"
                                              format={format}
                                              placeholder={question?.hint}
                                            />
                                          ) : question?.field_type === 3 &&
                                            question?.input_type === 3 ? (
                                            <DatePicker
                                              className="font-medium text-[16px] w-full"
                                              size="large"
                                              format="YYYY-MM-DD HH:mm:ss"
                                              disabledDate={disabledDate}
                                              disabledTime={disabledDateTime}
                                              placeholder={question?.hint}
                                              showTime={{
                                                defaultValue: dayjs(
                                                  "00:00:00",
                                                  "HH:mm:ss"
                                                ),
                                              }}
                                            />
                                          ) : (
                                            <Input
                                              className="font-medium text-[16px]"
                                              size="large"
                                              placeholder={question?.hint}
                                            />
                                          )}
                                        </Col>
                                        <Col
                                          xs={24}
                                          sm={10}
                                          className="flex flex-row w-full text-[28px] justify-end gap-3 table-status-button"
                                        >
                                          <BiEdit
                                            className="cursor-pointer"
                                            onClick={() => {
                                              handleOpenInputFieldEditModal(
                                                pageTitle,
                                                index,
                                                question
                                              );
                                            }}
                                          />
                                          <IoTrashOutline
                                            className="text-secondaryRed cursor-pointer"
                                            onClick={() => {
                                              deleteQuestion(
                                                pageTitle.page_title_id,
                                                index
                                              );
                                            }}
                                          />
                                          <p className="text-[20px]">|</p>
                                          <Button
                                            className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                                              question?.status === 1
                                                ? "bg-primaryColor"
                                                : "bg-primaryLight"
                                            } `}
                                          >
                                            <p>
                                              {question?.status === 1
                                                ? "Active"
                                                : "Inactive"}
                                            </p>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}

                  {/* exist question list */}
                  {allQuestionList?.map((questionObj, questionIndex) => (
                    <>
                      <Divider />
                      <div className="py-5">
                        <div className="rounded-xl bg-secondaryDarkshade p-5">
                          <div key={questionIndex}>
                            <div className="flex flex-row justify-between">
                              <p className="text-[16px] font-medium pb-2">
                                {questionObj.question}
                              </p>
                              <div className="flex flex-row">
                                <MdClose
                                  className="text-[24px] cursor-pointer gap-3"
                                  onClick={() => {
                                    deletePageTitleModalOpen(questionObj);
                                  }}
                                />
                              </div>
                            </div>
                            <Divider />
                            {questionObj.fields?.map(
                              (inputField, inputIndex) => {
                                return (
                                  <div key={inputIndex} className="pl-5">
                                    <p className="text-[16px] font-normal pb-2">{`${
                                      inputField.title
                                    } ${
                                      inputField.is_required === 1 ? "*" : ""
                                    }`}</p>
                                    <div className="pl-3 pb-4">
                                      <Row gutter={16}>
                                        <Col xs={24} sm={14} className="w-full">
                                          {inputField.field_type === 2 &&
                                          inputField.input_type === 1 ? (
                                            <Form.Item>
                                              <Select
                                                placeholder={inputField.hint}
                                                required
                                                className="font-medium text-[16px]"
                                                size="large"
                                                options={inputField?.answer}
                                              />
                                            </Form.Item>
                                          ) : inputField.field_type === 2 &&
                                            inputField.input_type === 2 ? (
                                            <Form.Item>
                                              <Select
                                                mode="multiple"
                                                className="font-medium text-[16px]"
                                                allowClear
                                                placeholder={inputField.hint}
                                                options={inputField?.answer}
                                                size="large"
                                              />
                                            </Form.Item>
                                          ) : inputField.field_type === 3 &&
                                            inputField.input_type === 1 ? (
                                            <Form.Item>
                                              <DatePicker
                                                className="!bg-secondaryWhite border-primaryLight font-medium text-[16px] w-full"
                                                size="large"
                                                format={dateFormat}
                                                placeholder={inputField.hint}
                                              />
                                            </Form.Item>
                                          ) : inputField.field_type === 3 &&
                                            inputField.input_type === 2 ? (
                                            <Form.Item>
                                              <TimePicker
                                                className="!bg-secondaryWhite border-primaryLight font-medium text-[16px] w-full"
                                                size="large"
                                                format={format}
                                                placeholder={inputField.hint}
                                              />
                                            </Form.Item>
                                          ) : inputField.field_type === 3 &&
                                            inputField.input_type === 3 ? (
                                            <Form.Item>
                                              <DatePicker
                                                className="!bg-secondaryWhite border-primaryLight font-medium text-[16px] w-full"
                                                size="large"
                                                format="YYYY-MM-DD HH:mm:ss"
                                                disabledDate={disabledDate}
                                                disabledTime={disabledDateTime}
                                                placeholder={inputField.hint}
                                                showTime={{
                                                  defaultValue: dayjs(
                                                    "00:00:00",
                                                    "HH:mm:ss"
                                                  ),
                                                }}
                                              />
                                            </Form.Item>
                                          ) : (
                                            <Form.Item
                                              name={
                                                inputField.field_type === 1 &&
                                                inputField.input_type === 1
                                                  ? "text"
                                                  : inputField.field_type ===
                                                      1 &&
                                                    inputField.input_type === 2
                                                  ? "IntegerNumber"
                                                  : "doubleNumber"
                                              }
                                              rules={[
                                                {
                                                  pattern:
                                                    inputField.field_type ===
                                                      1 &&
                                                    inputField.input_type === 1
                                                      ? /^[A-Za-z]+$/
                                                      : inputField.field_type ===
                                                          1 &&
                                                        inputField.input_type ===
                                                          2
                                                      ? /^[0-9]*$/
                                                      : /^[+-]?\d+(\.\d+)?$/,
                                                  message:
                                                    "please enter valid input",
                                                },
                                              ]}
                                            >
                                              <Input
                                                className="!bg-secondaryWhite border-primaryLight font-medium text-[16px]"
                                                size="large"
                                                placeholder={inputField.hint}
                                              />
                                            </Form.Item>
                                          )}
                                        </Col>
                                        <Col
                                          xs={24}
                                          sm={10}
                                          className="flex flex-row w-full justify-end gap-3"
                                        >
                                          <IoTrashOutline
                                            className="text-primaryRed cursor-pointer text-[30px]"
                                            onClick={() => {
                                              deleteQuestionModalOpen(
                                                inputField,
                                                questionObj?.fields?.length
                                              );
                                            }}
                                          />

                                          <p className="text-[20px]">|</p>
                                          <div
                                            className={`rounded-[50px] text-white !border-none py-1 px-2 min-w-[100px] max-w-[150px] h-9 ${
                                              inputField?.status === 1
                                                ? "bg-primaryColor"
                                                : "bg-primaryLight"
                                            } `}
                                          >
                                            <p className="text-center text-[16px]">
                                              {inputField?.status === 1
                                                ? "Active"
                                                : "Inactive"}
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                {/* job type status */}
                <Divider />
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col font-medium text-base text-[16px] text-primaryLight">
                      Job Type Status
                    </div>
                    <div className="flex flex-row  text-center items-baseline">
                      <span
                        className={`${
                          isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                        }`}
                      >
                        Inactive
                      </span>
                      <Form.Item name="switch" valuePropName="checked">
                        <Switch
                          className="mx-2"
                          onChange={handleSwitchChange}
                        />
                      </Form.Item>
                      <span
                        className={`${
                          isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                        }`}
                      >
                        Active
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                    <p className="text-[12px] text-secondaryColor">
                      Changing the job type status will impact the available job
                      types within the job type
                    </p>
                  </div>
                </div>
                {/* delete job type */}

                {permission?.includes(27) && (
                  <>
                    <Divider />
                    <div>
                      <p className="font-medium text-base pb-5">
                        Delete Job Type
                      </p>

                      <div className="flex flex-row pb-3">
                        <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                        <p className="text-[12px] text-secondaryColor">
                          Before deleting a job type, make sure there are no
                          active jobs or active transaction within the job type
                        </p>
                      </div>
                      <Button
                        size="large"
                        className="!border-primaryRed text-primaryRed hover:!text-secondaryWhite hover:!bg-primaryRed hover:!border-primaryRed"
                        onClick={deleteModalOpen}
                      >
                        DELETE
                      </Button>
                    </div>
                  </>
                )}

                <Divider />
                {/* button */}
                <div className="w-full pt-8">
                  <Row gutter={8}>
                    <Col xs={24} sm={12}>
                      <Form.Item>
                        <JobTypeFormSubmitButton
                          form={form}
                          errormsg={errormsg}
                          isUploading={isUploading}
                          isEditButtonEnable={isEditButtonEnable}
                          type={"SAVE"}
                          pageTitleStatus={
                            (pageTitleCount.length > 1 &&
                              pageTitleCount[0].page_title === "") ||
                            (pageTitleCount.length === 1 &&
                              pageTitleCount[0].page_title === "" &&
                              pageTitleCount[0].question_data.length > 0)
                              ? true
                              : false
                          }
                          questionStatus={questionErrorCount > 0 ? true : false}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} className="outline-button">
                      <Button
                        size="large"
                        className="font-medium w-full"
                        onClick={() => {
                          onClose();
                          setPageTitleCount([
                            {
                              page_title_id: 1,
                              page_title: "",
                              question_data: [],
                              status: 1,
                            },
                          ]);
                        }}
                      >
                        <p className="text-center">CANCEL</p>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          )}
        </div>
      </Drawer>

      {isOpenInputFieldAddModal && (
        <AddInputField
          open={isOpenInputFieldAddModal}
          onCancel={handleOpenInputFieldAddModal}
          pageTitleId={pageTitleId}
        />
      )}

      {isOpenInputFieldEditModal && (
        <EditInputField
          open={isOpenInputFieldEditModal}
          onCancel={handleCloseInputFieldEditModal}
          selectedQuestionData={selectedQuestionData}
        />
      )}

      {isOpenDeleteModal && (
        <JobTypeDelete
          open={isOpenDeleteModal}
          onCancel={deleteModalClose}
          deleteData={jobType}
          deletePermanently={deleteJobTypePermanently}
        />
      )}

      {isOpenPageTitleDeleteModal && (
        <PageTitleDelete
          open={isOpenPageTitleDeleteModal}
          onCancel={deletePageTitleModalClose}
          deletePageTitleData={deletePageTitleData}
          setAllQuestionsList={setAllQuestionsList}
        />
      )}

      {isOpenQuestioneDeleteModal && (
        <QuestionDelete
          open={isOpenQuestioneDeleteModal}
          onCancel={deleteQuestionModalClose}
          deleteQuestionData={deleteQuestionData}
          setAllQuestionsList={setAllQuestionsList}
        />
      )}
    </>
  );
};

export default EditJobType;
