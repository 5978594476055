import { Button, Col, Modal, Row } from "antd";
import React from "react";

const Confirmation = ({ title, description, open, onCancel }) => {
  return (
    <Modal open={open} onCancel={onCancel}>
      <p className="text-[24px] font-medium text-center">{title}</p>
      <p className="text-[16px] font-normal text-secondaryColor text-center pt-5">
        {description}
      </p>
      <Row className="px-10 pt-5 w-full">
        <Col className="w-full">
          <Button
            className="bg-black text-white w-full"
            size="large"
            onClick={onCancel}
          >
            OK
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default Confirmation;
