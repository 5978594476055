import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import {
  Card,
  Col,
  Row,
  Segmented,
  Popover,
  DatePicker,
  Table,
  Select,
  Input,
  Button,
} from "antd";
import { IoTrendingDownOutline, IoTrendingUpOutline } from "react-icons/io5";
import { getAllCartSummary, getAllStates, getSuburbSummary } from "../helpers";
import { useContentContext } from "../providers/ContentContext";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { TbPencil } from "react-icons/tb";
import AppSummeryUpdate from "../components/dashboard/AppSummeryUpdate";
import { formatDateWithTime } from "../helpers/apiHelpers/dateHelpers";

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [screenSize, setScreenSize] = useState("large");
  let { openNotification, handleError } = useContentContext();
  const [cartArray, setCartArray] = useState([]);
  const [appSummery, setAppSummery] = useState(0);
  const [appTotSummery, setAppTotSummery] = useState(0);
  const [appSummeryUpdatedDateTime, setAppSummeryUpdatedDateTime] =
    useState("test");
  const [androidSummery, setAndroidSummery] = useState([
    {
      app: "Client",
      count: 0,
    },
    {
      app: "Tasker",
      count: 0,
    },
  ]);
  const [IOSSummery, setIOSSummery] = useState([
    {
      app: "Client",
      count: 0,
    },
    {
      app: "Tasker",
      count: 0,
    },
  ]);
  const userId = getLocalStoragedata("userId");

  const [stateSelectorData, setStateSelectorData] = useState([]);
  const [stateSelectorValue, setStateSelectorValue] = useState();
  const [tableSuburbData, setTableSuburbData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [isOpenAppSummeryUpdateDrawer, setIsOpenAppSummeryUpdateDrawer] =
    useState(false);

  const updateScreenSize = () => {
    if (window.innerWidth < 640) {
      setScreenSize("small");
    } else {
      setScreenSize("large");
    }
  };
  useEffect(() => {
    fetchData({
      data_type: 1,
      start_date: "",
      end_date: "",
    });

    fetchState();
    fetchSuburbTableData(1, null);

    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async (data) => {
    try {
      await getAllCartSummary(data)
        .then((response) => {
          if (response?.data.success) {
            setCartArray([
              {
                name: "Total Jobs",
                count: response?.data?.output?.new?.job_count,
                presntage: response?.data?.output?.new?.job_precentage,
              },
              {
                name: "Total Clients",
                count: response?.data?.output?.new?.customer_count,
                presntage: response?.data?.output?.new?.customer_precentage,
              },
              {
                name: "Total Taskers",
                count: response?.data?.output?.new?.tradie_count,
                presntage: response?.data?.output?.new?.tradie_precentage,
              },
              {
                name: "Total Transaction",
                count: response?.data?.output?.new?.transaction_sum,
                presntage: response?.data?.output?.new?.transaction_precentage,
              },
            ]);

            setAppSummery(response?.data?.output?.app);
            setAndroidSummery([
              {
                app: "Client",
                count: response?.data?.output?.app?.client_android,
              },
              {
                app: "Tasker",
                count: response?.data?.output?.app?.tasker_android,
              },
            ]);
            setIOSSummery([
              {
                app: "Client",
                count: response?.data?.output?.app?.client_ios,
              },
              {
                app: "Tasker",
                count: response?.data?.output?.app?.tasker_ios,
              },
            ]);
            setAppTotSummery(
              response?.data?.output?.app?.client_android +
                response?.data?.output?.app?.tasker_android +
                response?.data?.output?.app?.client_ios +
                response?.data?.output?.app?.tasker_ios
            );

            setAppSummeryUpdatedDateTime(
              formatDateWithTime(response?.data?.output?.app?.updated_at)
            );
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchSuburbTableData = async (state, text) => {
    try {
      setDataLoading(true);
      await getSuburbSummary({
        user_id: userId,
        state_id: state,
        search: text,
        page: 1,
        count: 1000,
      })
        .then((response) => {
          if (response?.data?.success) {
            setData(
              response?.data?.output?.data ? response?.data?.output?.data : []
            );
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (suburbs) => {
    if (suburbs !== 0) {
      var tableData = [];

      suburbs?.forEach((suburb, x) => {
        let data = {
          key: x,
          state: suburb?.state_name,
          suburb: suburb?.suburb_name,
          posted: suburb?.posted_count,
          awarded: suburb?.award_count,
          completed: suburb?.completed_count,
        };
        tableData.push(data);
      });
      setTableSuburbData(tableData);
    } else {
      setTableSuburbData([]);
    }
    setDataLoading(false);
  };

  const fetchState = async (e) => {
    setStateSelectorValue();
    try {
      await getAllStates({ country_id: e, status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setStateData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setStateData = (states) => {
    var object = [];
    states?.forEach((state) => {
      let data = {
        value: state.id,
        label: state.state,
      };
      object.push(data);
    });
    setStateSelectorData(object);
  };

  const dashboardFilter = (segment) => {
    if (segment === "Today") {
      fetchData({
        data_type: 1,
        start_date: "",
        end_date: "",
      });
    } else if (segment === "Last 7 days") {
      fetchData({
        data_type: 2,
        start_date: "",
        end_date: "",
      });
    } else if (segment === "This Month") {
      fetchData({
        data_type: 3,
        start_date: "",
        end_date: "",
      });
    }
  };

  const content = (
    <RangePicker
      allowClear
      onChange={(date, dateString) => {
        fetchData({
          data_type: 4,
          start_date: dateString[0],
          end_date: dateString[1],
        });
      }}
    />
  );

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        DASHBOARD
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-row justify-start sm:justify-end pb-10 dashboard">
          <Segmented
            className="text-[10px] sm:text-[14px] font-medium text-black bg-secondaryLight"
            options={[
              "Today",
              "Last 7 days",
              "This Month",
              {
                label: (
                  <Popover
                    content={content}
                    title="Select Date Range"
                    trigger="click"
                    placement="bottom"
                  >
                    <p>Custom</p>
                  </Popover>
                ),
                value: "Custom",
              },
            ]}
            size={screenSize}
            onChange={(e) => {
              dashboardFilter(e);
            }}
          ></Segmented>
        </Row>

        {/* Dashboard 6 card */}
        <Row className="flex justify-between pb-3" gutter={[8, 8]}>
          <>
            {cartArray.map((cartData) => {
              return (
                <Col xs={24} sm={12} xl={4} className="pb-5 w-full h-max">
                  <Card className="bg-secondaryLight border-none rounded-[20px] shadow-md xl:max-w-[250px] h-[160px]">
                    <div className="pb-5">
                      <p className="text-[14px] font-medium">
                        {cartData?.name}
                      </p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="text-lg font-semibold">{cartData?.count}</p>

                      {cartData?.presntage > 0 ? (
                        <div className="flex justify-center items-center rounded-[40px] bg-[#13A051] w-[60px]">
                          <div className="flex flex-row text-[11px] gap-1">
                            <IoTrendingUpOutline className="text-white" />
                            <p className="text-white">
                              {cartData?.presntage + "%"}
                            </p>
                          </div>
                        </div>
                      ) : cartData?.presntage < 0 ? (
                        <div className="flex justify-center items-center rounded-[40px] bg-[#CC4C24] w-[60px]">
                          <div className="flex flex-row text-[11px] gap-1">
                            <IoTrendingDownOutline className="text-white" />
                            <p className="text-white">
                              {cartData?.presntage + "%"}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center rounded-[40px] bg-[#13A051] w-[60px]">
                          <div className="flex flex-row text-[11px] gap-1">
                            <p className="text-white">
                              {cartData?.presntage + "%"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
              );
            })}
            <Col xs={24} sm={12} xl={5} className="pb-5 w-full">
              {/* Adjusted lg={4} */}
              <Card className="border-none bg-primaryColor rounded-[20px] shadow-md xl:max-w-[300px] h-[160px]">
                <div className="pb-[5px] flex flex-row justify-between">
                  <div>
                    <p className="text-[14px] font-medium">App Summery</p>
                    <p className="text-[10px] font-normal">
                      Updated At: {appSummeryUpdatedDateTime}
                    </p>
                  </div>

                  <TbPencil
                    className="text-lg cursor-pointer"
                    onClick={() => {
                      setIsOpenAppSummeryUpdateDrawer(true);
                    }}
                  />
                </div>

                <div className="text-[12px] font-semibold">
                  <p>
                    Client:
                    <span className="pl-3">
                      {androidSummery[0]?.count + IOSSummery[0]?.count}
                    </span>
                  </p>
                  <p>
                    Tasker:
                    <span className="pl-3">
                      {androidSummery[1]?.count + IOSSummery[1]?.count}
                    </span>
                  </p>
                  <p>
                    Total: <span className="pl-3">{appTotSummery}</span>
                  </p>
                </div>

                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowMoreDetails(!showMoreDetails);
                  }}
                >
                  <p className="text-end text-xs text-primaryLight underline cursor-pointer">
                    {showMoreDetails ? "Hide... " : "More Details..."}
                  </p>
                </div>
              </Card>
            </Col>
          </>
        </Row>

        {/* installed count */}
        {showMoreDetails && (
          <div className="flex flex-col lg:flex-row w-full gap-2 mb-5">
            <div className="bg-secondaryLight border-none rounded-[20px] shadow-md p-3 w-full">
              <p className="text-base font-semibold pb-2 text-center">IOS</p>
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-around pb-2 gap-3">
                {IOSSummery.map((app, x) => (
                  <Card
                    key={x}
                    className="bg-white border-none w-full md:max-w-[500px]"
                  >
                    <div className="flex flex-row justify-between">
                      <div>
                        <p className="text-lg font-medium">{app.app}</p>
                      </div>
                      <div>
                        <p className="text-xl font-bold">{app.count}</p>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
            <div className="bg-secondaryLight border-none rounded-[20px] shadow-md p-3 w-full">
              <p className="text-base font-semibold pb-2 text-center">
                ANDROID
              </p>
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-around pb-2 gap-3">
                {androidSummery.map((app, x) => (
                  <Card
                    key={x}
                    className="bg-white border-none w-full md:max-w-[500px]"
                  >
                    <div className="flex flex-row justify-between">
                      <div>
                        <p className="text-lg font-medium">{app.app}</p>
                      </div>
                      <div>
                        <p className="text-xl font-bold">{app.count}</p>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Dashboard filters */}
        <Row className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pb-6">
          <div className="w-full">
            <p>State</p>
            <Select
              name="state"
              placeholder="State"
              className="!bg-secondaryWhite w-full"
              size="large"
              value={stateSelectorValue}
              options={stateSelectorData}
              onSelect={(e) => {
                setStateSelectorValue(e);
                fetchSuburbTableData(e, searchText);
              }}
              filterOption={filterOption}
              showSearch
            />
          </div>
          <div className="w-full">
            <p>Search</p>
            <Input
              placeholder="Search..."
              className="!bg-secondaryWhite"
              size="large"
              minLength={3}
              maxLength={50}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                fetchSuburbTableData(stateSelectorValue, e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <p>&nbsp;</p>
            <Button
              size="large"
              className="font-medium"
              onClick={() => {
                fetchSuburbTableData(1, null);
                setStateSelectorValue();
                setSearchText(null);
              }}
            >
              <p className="text-center">CLEAR</p>
            </Button>
          </div>
        </Row>

        {/* Dashboard tables */}
        <div>
          <Table
            dataSource={tableSuburbData}
            columns={columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>

      {isOpenAppSummeryUpdateDrawer && (
        <AppSummeryUpdate
          open={isOpenAppSummeryUpdateDrawer}
          onClose={() => {
            setIsOpenAppSummeryUpdateDrawer(false);
            fetchData({
              data_type: 1,
              start_date: "",
              end_date: "",
            });
          }}
          values={appSummery}
        />
      )}
    </>
  );
};

export default Dashboard;

const columns = [
  {
    title: "State",
    dataIndex: "state",
    key: "0",
  },
  {
    title: "Suburb",
    dataIndex: "suburb",
    key: "1",
  },
  {
    title: "Posted Jobs",
    dataIndex: "posted",
    key: "2",
  },
  {
    title: "Awarded Jobs",
    dataIndex: "awarded",
    key: "3",
  },
  {
    title: "Completed Jobs",
    dataIndex: "completed",
    key: "4",
  },
];
