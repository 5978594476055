import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";

const AppDownloadSubmitButton = ({ form, isEditButtonEnable, type }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then((e) => {
        setSubmittable(true);
      })
      .catch((error) => {
        if (error.errorFields.length !== 0) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      });
  }, [values]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      size="large"
      className={`font-medium w-full ${
        isEditButtonEnable || !submittable //true
          ? "!bg-secondaryDarkshade"
          : "bg-primaryLigh"
      }`}
      disabled={!submittable || isEditButtonEnable}
    >
      <p className="text-center text-secondaryWhite">{type}</p>
    </Button>
  );
};

export default AppDownloadSubmitButton;
