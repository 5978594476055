import React from "react";

const NotPermission = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center  max-h-full h-screen">
      <p className="text-[80px] font-bold">Error 401</p>
      <p className="text-[20px] text-slate-500">
        You don't have permission to access this page !
      </p>
    </div>
  );
};

export default NotPermission;
