import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { useContentContext } from "../providers/ContentContext";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  EditCountry,
  NewCountry,
  StatusChange,
  ViewCountry,
} from "../components";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { customStatusSorter } from "../commonFun";
import { getAllCountries, tableCountryStatusChange } from "../helpers";

const columns = [
  {
    title: "Country",
    dataIndex: "country",
    key: "0",
    sorter: (a, b) => a.country?.localeCompare(b.country),
    defaultSortOrder: "ascend",
  },
  {
    title: "Country Code",
    dataIndex: "countryCode",
    key: "2",
    sorter: (a, b) => a.countryCode?.localeCompare(b.countryCode),
    defaultSortOrder: "ascend",
  },
  {
    title: "Time Zone Type",
    dataIndex: "timeZoneType",
    key: "3",
    sorter: (a, b) => a.timeZoneType?.localeCompare(b.timeZoneType),
    defaultSortOrder: "ascend",
  },
  {
    title: "Time Zone",
    dataIndex: "timeZone",
    key: "4",
    sorter: (a, b) => a.timeZone?.localeCompare(b.timeZone),
    defaultSortOrder: "ascend",
  },
  {
    title: "No of states",
    dataIndex: "states",
    key: "5",
    sorter: (a, b) => a.states - b.states,
    defaultSortOrder: "ascend",
  },
  {
    title: "No of Suburbs",
    dataIndex: "suburbs",
    key: "6",
    sorter: (a, b) => a.suburbs - b.suburbs,
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "7",
    sorter: (a, b) => customStatusSorter(a, b),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "8",
  },
];

const Country = () => {
  let { handleError, openNotification } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [countries, setCountries] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [searchText, setSearchText] = useState();
  const [countryDataForSearch, setCountryDataForSearch] = useState([]);

  const [isOpenNewCountryDrawer, setIsOpenNewCountryDrawer] = useState(false);
  const [isOpenVewCountryDrawer, setIsOpenViewCountryDrawer] = useState(false);
  const [selectedViewCountryData, setSelectedViewCountryData] = useState();
  const [isOpenEditCountryDrawer, setIsOpenEditCountryDrawer] = useState(false);
  const [selectedEditCountryData, setSelectedEditCountryData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setDataLoading(true);
      await getAllCountries({ status: -1 })
        .then((response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
            setCountryDataForSearch(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (countries) => {
    if (countries.length !== 0) {
      var tableData = [];
      countries.forEach((country, x) => {
        let data = {
          key: x,
          country: country.country,
          countryCode: country.country_code,
          timeZone: country.timezone,
          states: country?.active_states,
          suburbs: country?.active_suberb,
          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                country?.is_active === 1 ? "bg-primaryColor" : "bg-primaryLight"
              } `}
              onClick={() => {
                setChangeStatus(country?.is_active);
                openChangeStatus(country);
              }}
            >
              {country?.is_active === 1 ? "Active" : "Inactive"}
            </Button>
          ),

          timeZoneType:
            country?.timezone_type === 1 ? "Country Wise" : "State Wise",
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(10) ? (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditCountryDrawer(country);
                  }}
                />
              ) : (
                <></>
              )}

              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewCountryDrawerOpenChange(country);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
        setCountries(tableData);
      });
    } else {
      setCountries([]);
    }
    setDataLoading(false);
  };

  const search = (text) => {
    let filtered = countryDataForSearch.filter((o) =>
      o.country.toLowerCase().includes(text.toLowerCase())
    );

    setData(filtered);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  const handleNewCountryDrawerOpenChange = () => {
    setIsOpenNewCountryDrawer(!isOpenNewCountryDrawer);
  };
  const handleViewCountryDrawerOpenChange = (country) => {
    setSelectedViewCountryData(country);
    setIsOpenViewCountryDrawer(!isOpenVewCountryDrawer);
  };
  const openEditCountryDrawer = (country) => {
    setIsOpenEditCountryDrawer(true);
    setSelectedEditCountryData(country);
  };
  const closeEditCountryDrawer = () => {
    setIsOpenEditCountryDrawer(false);
  };

  const openChangeStatus = (country) => {
    setIsModalOpen(true);
    setSelectedData(country);
  };
  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };
  // country status change in table
  const handleStatus = async (status) => {
    const data = {
      country_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    await tableCountryStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        COUNTRY
      </div>

      <Content className="rounded-lg mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Country..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>

          <Col>
            {permission.includes(9) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                onClick={handleNewCountryDrawerOpenChange}
                icon={<MdAdd className="text-2xl font-bold" />}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD COUNTRY
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={countries}
            columns={columns}
            showSorterTooltip={false}
            loading={dataLoading}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
            scroll={{ x: "100%" }}
          />
        </div>
      </Content>

      {isOpenNewCountryDrawer ? (
        <NewCountry
          open={isOpenNewCountryDrawer}
          onClose={handleNewCountryDrawerOpenChange}
          reloadCountryData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenVewCountryDrawer ? (
        <ViewCountry
          open={isOpenVewCountryDrawer}
          onClose={handleViewCountryDrawerOpenChange}
          country={selectedViewCountryData}
        />
      ) : (
        <></>
      )}

      {isOpenEditCountryDrawer ? (
        <EditCountry
          open={isOpenEditCountryDrawer}
          onClose={closeEditCountryDrawer}
          country={selectedEditCountryData}
          reloadCountryData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isModalOpen && (
        <StatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default Country;
