import React, { useRef, useState } from "react";
import { useContentContext } from "../../providers/ContentContext";
import { IoMdCloudUpload } from "react-icons/io";
import { CopyOutlined } from "@ant-design/icons";
import { addImage } from "../../helpers/apiHelpers/cmsHelpers";
import { Button, Form, Input, Modal, Typography, Upload } from "antd";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }); //for image

const ImageConvertor = () => {
  let { openNotification, handleError } = useContentContext();
  const [isUploading, setIsUploading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false); //for image
  const [previewImage, setPreviewImage] = useState(""); //for image
  const [previewTitle, setPreviewTitle] = useState(""); //for image
  const [fileList, setFileList] = useState([]); //for image
  const [imgUrl, setImgUrl] = useState(null);
  const inputRef = useRef(null);

  const handleCancel = () => setPreviewOpen(false); //for image
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  }; //for image
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList); // for image
  const uploadButton = (
    <div className="flex flex-col p-3 items-center text-primaryLightDisable italic text-[14px] font-normal">
      <p>Drop files here to upload</p>
      <p>OR</p>
      <IoMdCloudUpload className="text-[66px]" />
      <p className="underline">Click Here to Upload</p>
    </div>
  ); //for image

  const onFinish = async (e) => {
    const data = new FormData();
    data.append("blog_id", "1");
    data.append(
      "extension",
      fileList.length ? fileList[0]?.name.split(".")[1] : null
    );
    data.append("blog_image", fileList[0] ? fileList[0]?.originFileObj : null);

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);

      await addImage(data)
        .then((response) => {
          if (response.data.success) {
            openNotification("success", response.data.message);
            setImgUrl(response.data.output.image);
            setFileList([]);
          } else {
            openNotification("error", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      setIsUploading(false);
    }
  };

  const handleLinkCopy = () => {
    const inputValue = inputRef.current.input.value;

    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = inputValue;

    document.body.appendChild(tempTextArea);

    tempTextArea.select();

    document.execCommand("copy");

    document.body.removeChild(tempTextArea);
  };

  return (
    <div className="flex justify-center w-full mt-20 rounded-lg ">
      <div className="flex flex-col md:flex-row p-4 w-[98%] rounded-lg bg-secondaryLight ">
        <div className="flex flex-col gap-2 w-full md:w-[50%]">
          <div className="flex w-full">
            <Typography className="text-[16px] font-normal text-primaryLight">
              Upload Image
            </Typography>
          </div>
          <div className="flex flex-row w-full">
            <Typography className="text-[12px] font-normal">
              Upload image here and use the link to attach the image in your
              blog content
            </Typography>
          </div>
          <div className="flex w-full">
            <Form
              name="newBlog"
              layout="vertical"
              className="w-full add-form"
              onFinish={onFinish}
              fields={[{ name: ["upload"], value: fileList }]}
            >
              <Form.Item name="image">
                <Upload
                  customRequest={({ onSuccess }) => onSuccess("ok")}
                  listType="picture-card"
                  className="mt-2 md:col-span-5"
                  onPreview={handlePreview}
                  onChange={handleChange}
                  fileList={fileList}
                  accept=".png, .jpg, .jpeg"
                >
                  {fileList?.length ? null : uploadButton}
                </Upload>

                <Modal
                  className="z-[100000]"
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={fileList.length == 0 ? true : false}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="w-64 font-medium "
                >
                  <p className="text-center">UPLOAD</p>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="flex flex-col gap-4 items-start justify-center w-full md:w-[50%]">
          <div className="flex flex-row w-full gap-4">
            <Typography className="text-[16px] font-normal text-primaryLight">
              Image Link
            </Typography>
            <Button
              size="small"
              disabled={imgUrl === null ? true : false}
              onClick={handleLinkCopy}
            >
              <p className="text-center">
                <CopyOutlined />
              </p>
            </Button>
          </div>
          <div className="flex w-full">
            <Input
              ref={inputRef}
              placeholder="Image url"
              className="!bg-secondaryWhite w-full"
              size="large"
              value={imgUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageConvertor;
