import { Button, Form, Input, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { Confirmation } from "../components";
import { useContentContext } from "../providers/ContentContext";
import { resetPassword } from "../helpers";

const PasswordReset = () => {
  const [form] = Form.useForm();
  let { openNotification, handleError } = useContentContext();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const onReset = async (e) => {
    const data = {
      old_password: e.password,
      new_password: e.new_password,
      confirm_password: e.confirm_password,
    };

    await resetPassword(data)
      .then((response) => {
        if (response.data.success) {
          setOpenConfirmation(true);
          form.resetFields();
        } else {
          openNotification("warning", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        RESET PASSWORD
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px]  bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-row items-start pt-2 pb-0">
          Set the new password for your account so you can login and access all
          the features.
        </Row>
        <Form
          form={form}
          name="reset"
          onFinish={onReset}
          autoComplete="off"
          className="mt-6"
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Enter your current password!",
              },
            ]}
          >
            <Row className="flex flex-col items-start">
              <p>Current Password</p>
              <Input.Password
                className="password border-none bg-[#dddfe0] xl:w-1/4 lg:w-1/2 md:w-2/3 w-full mt-2 rounded-[10px] text-black font-semibold p-2"
                placeholder="Current Password"
                maxLength={20}
              />
            </Row>
          </Form.Item>
          <Form.Item
            name="new_password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Enter new password!",
              },
              { min: 8, message: "Password must have a minimum length of 8" },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
                ),
                message:
                  "Password must contain at least one lowercase letter, uppercase letter, number, and special character",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue("password") === value) {
                    return Promise.reject(
                      new Error("Cannot reuse old password!")
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            className="xl:w-1/4 lg:w-1/2 md:w-2/3 w-full"
          >
            <Row className="flex flex-col items-start">
              <p>New Password</p>
              <Input.Password
                className="password border-none bg-[#dddfe0] w-full mt-2 rounded-[10px] text-black font-semibold p-2"
                placeholder="New Password"
                maxLength={20}
              />
            </Row>
          </Form.Item>
          <Form.Item
            name="confirm_password"
            dependencies={["new_password"]}
            rules={[
              {
                required: true,
                message: "Confirm new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password didn’t match!"));
                },
              }),
            ]}
          >
            <Row className="flex flex-col items-start">
              <p>Confirm Password</p>
              <Input.Password
                className="password border-none bg-[#dddfe0] xl:w-1/4 lg:w-1/2 md:w-2/3 w-full mt-2 rounded-[10px] text-black font-semibold p-2"
                placeholder="Confirm Password"
                maxLength={20}
              />
            </Row>
          </Form.Item>
          <Form.Item>
            <Row className="flex flex-col items-start mt-8">
              <Button
                className="flex text-center justify-center xl:w-1/4 lg:w-1/2 md:w-2/3 w-full bg-[#707070] font-medium disabled:bg-[#707070]"
                size="large"
                htmlType="submit"
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0
                }
              >
                <p className="text-secondaryWhite text-[18px] font-semibold">
                  CHANGE PASSWORD
                </p>
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Content>

      {openConfirmation && (
        <Confirmation
          title="Your password changed successfully"
          description="Your password has been successfully updated."
          open={openConfirmation}
          onCancel={closeConfirmation}
        />
      )}
    </>
  );
};

export default PasswordReset;
