import { Col, Input, Row, Segmented, Table, Select, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import {
  getActiveStatesWithCountry,
  getActiveSuburbsWithState,
  getAllCountries,
  getAllLocationLevel1Data,
  getAllLocationLevel2Data,
  getAllPromoSmsCountries,
  getTradieList,
} from "../helpers";
import { useContentContext } from "../providers/ContentContext";
import moment from "moment/moment";
import { ViewVerifyTradie } from "../components";
import { decryptSecureData } from "../services/EncryptionService";

const columns = [
  {
    title: "Tasker ID",
    dataIndex: "tradieId",
    key: "0",
    sorter: (a, b) => a.tradieId - b.tradieId,
    defaultSortOrder: "ascend",
  },
  {
    title: "Tasker Name",
    dataIndex: "name",
    key: "1",
    sorter: (a, b) => a.name?.localeCompare(b.name),
    defaultSortOrder: "ascend",
  },
  {
    title: "Request ID",
    dataIndex: "requestedId",
    key: "2",
    sorter: (a, b) => a.requestedId - b.requestedId,
    defaultSortOrder: "ascend",
  },

  {
    title: "Requested Date",
    dataIndex: "date",
    key: "3",
    sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];

const VerificationCenter = () => {
  let { openNotification, handleError } = useContentContext();

  const [segment, setSegment] = useState();
  const [statusSegment, setStatusSegment] = useState();
  const [loading, setLoading] = useState(false);
  const [tradieList, setTradieList] = useState([]);
  const [tradies, setTradies] = useState([]);

  const [viewTradie, setViewTradie] = useState(false);
  const [selectedTradie, setSelectedTradie] = useState();
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [suburbList, setSuburbList] = useState();

  //filters
  const [searchText, setSearchText] = useState();
  const [dataSegment, setDataSegment] = useState(1);
  const [dataStatusSegment, setDataStatusSegment] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedSuburb, setSelectedSuburb] = useState();

  useEffect(() => {
    getTradieData(1, 1);
    getCountryList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTradieData = async (
    dateType,
    status,
    country,
    state,
    suburb,
    from,
    to
  ) => {
    setLoading(true);

    await getTradieList(dateType, status, country, state, suburb)
      .then(async (response) => {
        if (response.data.success) {
          mapToTable(response.data.output);
          const searchArray = await response.data.output.map((item) => {
            return {
              ...item,
              trady_name: decryptSecureData(item.trady_name),
            };
          });
          setTradieList(searchArray);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
    setLoading(false);
  };

  const getCountryList = async () => {
    await getAllPromoSmsCountries({ status: 1 })
      .then((response) => {
        if (response.data.success) {
          var countries = [];
          response.data.output?.forEach((country) => {
            let data = {
              value: country?.country_id,
              label: country?.country,
            };
            countries.push(data);
          });
          setCountryList(countries);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getStateList = async (e) => {
    await getAllLocationLevel1Data({ country_id: e })
      .then((response) => {
        if (response.data.success) {
          var states = [];
          response.data.output?.forEach((state) => {
            let data = {
              value: state?.level1_id,
              label: state?.level1_location,
            };
            states.push(data);
          });
          setStateList(states);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const getSuburbList = async (e) => {
    await getAllLocationLevel2Data({
      country_id: selectedCountry,
      level1_id: e,
    })
      .then((response) => {
        if (response.data.success) {
          var suburbs = [];
          response.data.output?.forEach((suburb) => {
            let data = {
              // value: suburb.id,
              // label: suburb?.suberb + " - " + suburb?.postal_code,
              value: suburb?.level2_id,
              label: suburb?.level2_location,
            };
            suburbs.push(data);
          });
          setSuburbList(suburbs);
        } else {
          openNotification(
            "warning",
            "Data Loading Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const mapToTable = (data) => {
    if (data?.length !== 0) {
      var tableData = [];

      data?.forEach((tradie, x) => {
        let data = {
          key: x,
          tradieId: tradie?.trady_id,
          name: decryptSecureData(tradie?.trady_name),
          requestedId: tradie?.request_id,
          date: tradie?.created_at,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  openTradieDetailsView(tradie);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTradies(tableData);
    } else {
      setTradies([]);
    }
    setLoading(false);
  };

  const openTradieDetailsView = (tradie) => {
    setSelectedTradie(tradie);
    setViewTradie(true);
  };

  const closeTradieDetailsView = () => {
    setViewTradie(false);
  };

  const setSegmentFilter = (segment) => {
    setSearchText();
    setSegment(segment);
    if (segment === "Today") {
      setDataSegment(1);
      getTradieData(
        1,
        dataStatusSegment,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else if (segment === "Last 7 Days") {
      setDataSegment(2);
      getTradieData(
        2,
        dataStatusSegment,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else if (segment === "This Month") {
      setDataSegment(3);
      getTradieData(
        3,
        dataStatusSegment,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else if (segment === "Last 3 Months") {
      setDataSegment(4);
      getTradieData(
        4,
        dataStatusSegment,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else {
      setDataSegment(5);
      getTradieData(
        5,
        dataStatusSegment,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    }
  };

  const setStatusSegmentFilter = (segment) => {
    setSearchText();
    setStatusSegment(segment);
    if (segment === "REQUESTS") {
      setDataStatusSegment(1);
      getTradieData(
        dataSegment,
        1,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else if (segment === "IN HOLD") {
      setDataStatusSegment(3);
      getTradieData(
        dataSegment,
        3,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else if (segment === "VERIFIED") {
      setDataStatusSegment(2);
      getTradieData(
        dataSegment,
        2,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    } else if (segment === "REJECTED") {
      setDataStatusSegment(4);
      getTradieData(
        dataSegment,
        4,
        selectedCountry,
        selectedState,
        selectedSuburb
      );
    }
  };

  const cleaFilters = () => {
    setSelectedCountry();
    setSelectedState();
    setSelectedSuburb();
    setSearchText();
    setSegment("Today");
    setStatusSegment("REQUESTS");
    getTradieData(1, 1);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };
  const search = (text) => {
    let filtered = tradieList.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );
    mapToTable(filtered);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        VERIFICATION CENTRE
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start sm:items-end lg:flex-row lg:justify-between pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 xl:mb-0"
              placeholder="Search Taksers..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            <Segmented
              options={["Today", "Last 7 Days", "This Month", "Last 3 Months"]}
              value={segment}
              onChange={(e) => {
                setSegmentFilter(e);
              }}
            />
          </Col>
        </Row>
        <Row className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pb-3">
          <div className="w-full">
            <p>Country</p>
            <Select
              name="country"
              placeholder="Country"
              className="!bg-secondaryWhite w-full"
              size="large"
              options={countryList}
              onSelect={(e) => {
                setSelectedState();
                setSelectedSuburb();
                setSelectedCountry(e);
                getStateList(e);
                getTradieData(dataSegment, dataStatusSegment, e);
              }}
              value={selectedCountry}
              filterOption={filterOption}
              showSearch
            />
          </div>
          <div className="w-full">
            <p>{selectedCountry === 2 ? "District" : "State"}</p>
            <Select
              name="state"
              placeholder={selectedCountry === 2 ? "District" : "State"}
              className="!bg-secondaryWhite w-full"
              size="large"
              options={stateList}
              onSelect={(e) => {
                setSelectedSuburb();
                setSelectedState(e);
                getSuburbList(e);
                getTradieData(
                  dataSegment,
                  dataStatusSegment,
                  selectedCountry,
                  e
                );
              }}
              disabled={selectedCountry === undefined}
              value={selectedState}
              filterOption={filterOption}
              showSearch
            />
          </div>
          <div className="w-full">
            <p>{selectedCountry === 2 ? "City" : "Suburb"}</p>
            <Select
              name="suburb"
              placeholder={selectedCountry === 2 ? "City" : "Suburb"}
              className="!bg-secondaryWhite w-full"
              size="large"
              options={suburbList}
              onSelect={(e) => {
                setSelectedSuburb(e);
                getTradieData(
                  dataSegment,
                  dataStatusSegment,
                  selectedCountry,
                  selectedState,
                  e
                );
              }}
              value={selectedSuburb}
              filterOption={filterOption}
              showSearch
              disabled={selectedState === undefined}
            />
          </div>
          <div className="w-full">
            <p>&nbsp;</p>
            <Button size="large" className="font-medium" onClick={cleaFilters}>
              <p className="text-center">CLEAR</p>
            </Button>
          </div>
        </Row>

        <Row className="flex justify-end pb-5">
          <Col>
            <Segmented
              options={["REQUESTS", "IN HOLD", "VERIFIED", "REJECTED"]}
              value={statusSegment}
              onChange={(e) => {
                setStatusSegmentFilter(e);
              }}
            />
          </Col>
        </Row>

        <Row className="flex flex-col">
          <Table
            dataSource={tradies}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
            loading={loading}
            scroll={{ x: "100%" }}
          />
        </Row>
      </Content>

      {viewTradie && (
        <ViewVerifyTradie
          onClose={closeTradieDetailsView}
          open={viewTradie}
          type={dataStatusSegment}
          tradie={selectedTradie}
          getTradieData={getTradieData}
        />
      )}
    </>
  );
};

export default VerificationCenter;
