import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, formconfig, url } from "./header";

const userId = getLocalStoragedata("userId");

//category
export async function getAllCategories(data) {
  return await axios.post(url + "job/category/data", data, config);
}

export async function addCategory(data) {
  return await axios.post(url + "job/category/add", data, formconfig);
}

export async function editCategory(data) {
  return await axios.post(url + "job/category/edit", data, formconfig);
}

export async function categoryCountryAdd(data) {
  return await axios.post(url + "job/country/add", data, config);
}

export async function deleteCategory(data) {
  return await axios.post(url + "job/category/delete", data, config);
}

export async function checkCategoryName(data) {
  return await axios.post(url + "job/category/check", data, config);
}

export async function tableCategoryStatusChange(data) {
  return await axios.post(url + "job/category/status", data, config);
}

//jobType
export async function getAllJobTypes(data) {
  return await axios.post(url + "job/type/all", data, config);
}

export async function addJobType(data) {
  return await axios.post(url + "job/type/add", data, formconfig);
}

export async function editJobType(data) {
  return await axios.post(url + "job/type/edit", data, formconfig);
}

export async function deleteJobType(data) {
  return await axios.post(url + "job/type/delete", data, config);
}

export async function checkJobTypeName(data) {
  return await axios.post(url + "job/type/check", data, config);
}

export async function tableJobTypeStatusChange(data) {
  return await axios.post(url + "job/type/status", data, config);
}

//job type question
export async function jobTypeQuestionAdd(data) {
  return await axios.post(url + "admin/question/add", data, config);
}

export async function getAllJobTypeQuestionList(data) {
  return await axios.post(url + "job/question/admin/all", data, config);
}

export async function deleteJobTypePageTitle(data) {
  return await axios.post(url + "job/question/delete", data, config);
}
export async function deleteJobTypeQuestion(data) {
  return await axios.post(url + "question/field/delete", data, config);
}

//
export async function getAllJobs(
  type,
  country_id,
  state_id,
  suburb_id,
  category_id,
  job_type_id,
  status,
  from,
  to
) {
  const data = {
    user_id: userId,
    date_type: type,
    job_category_id: category_id,
    job_type_id: job_type_id,
    job_status_id: status,
    country_id: country_id,
    state_id: state_id,
    suberb_id: suburb_id,
    from_date: from,
    to_date: to,
  };
  return await axios.post(url + "admin/job/all", data, config);
}

export async function getJobData(job_id) {
  const data = {
    user_id: userId,
    job_id: job_id,
  };
  return await axios.post(url + "admin/job/data", data, config);
}

export async function getAllNotificationHistory(job_id) {
  const data = {
    user_id: userId,
    job_id: job_id,
  };
  return await axios.post(url + "notification/job", data, config);
}

export async function sendNotification(data) {
  return await axios.post(url + "notification/send", data, config);
}
