import { Button, Col, Divider, Drawer, Row } from "antd";
import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";

const ViewAppDownload = ({ onClose, open, dataSet }) => {
  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-8">
              <div className="text-[16px] font-medium text-primaryLight">
                App Download and Register Details
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div className="flex w-full flex-col">
              <Row gutter={10} className="pb-6">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">Date</p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">{dataSet?.day_str}</p>
                </Col>
              </Row>

              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    AU IOS client download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.au_ios_client_download}
                  </p>
                </Col>
              </Row>

              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    AU IOS tasker download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.au_ios_tasker_download}
                  </p>
                </Col>
              </Row>

              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    AU Android client download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.au_android_client_download}
                  </p>
                </Col>
              </Row>

              <Row gutter={10} className="pb-6">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    AU Android tasker download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.au_android_tasker_download}
                  </p>
                </Col>
              </Row>

              <Divider className="m-0 pb-6" />

              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    SL IOS client download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.sl_ios_client_download}
                  </p>
                </Col>
              </Row>
              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    SL IOS tasker download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.sl_ios_tasker_download}
                  </p>
                </Col>
              </Row>

              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    SL Android client download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.sl_android_client_download}
                  </p>
                </Col>
              </Row>
              <Row gutter={10} className="pb-6">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    SL Android tasker download count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.sl_android_tasker_download}
                  </p>
                </Col>
              </Row>

              <Divider className="m-0 pb-6" />

              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    AU client register user count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.au_client_register}
                  </p>
                </Col>
              </Row>
              <Row gutter={10} className="pb-6">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    AU tasker register user count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.au_tasker_register}
                  </p>
                </Col>
              </Row>
              <Divider className="m-0 pb-6" />
              <Row gutter={10} className="pb-4">
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    SL client register user count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.sl_client_register}
                  </p>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    SL tasker register user count
                  </p>
                </Col>
                <Col xs={12}>
                  <p className="text-[16px] font-medium">
                    {dataSet?.sl_tasker_register}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ViewAppDownload;
