import axios from "axios";
import { getLocalStoragedata } from "../StorageHelper";
import { config, formconfig, url } from "./header";

const userId = getLocalStoragedata("userId");

//user List
export async function getAllNotificationUserList(data) {
  return await axios.post(url + "user/list", data, config);
}

export async function getAllNotificationTableData(data) {
  return await axios.post(url + "notification/admin/list", data, config);
}

export async function postNewNotification(data) {
  return await axios.post(url + "notification/array", data, config);
}

export async function getAllNotificationMoreDetails(data) {
  return await axios.post(url + "notification/data", data, config);
}

export async function getAllNotificationAudiensList(data) {
  return await axios.post(url + "notification/user/list", data, config);
}
