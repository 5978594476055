import React from "react";
import { Drawer, Form, Button, Divider, Row, Col } from "antd";
import { MdClose } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { decryptSecureData } from "../../services/EncryptionService";

const ViewCustomer = ({ open, onClose, customer }) => {
  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px]">
        <Form layout="vertical" className="w-full view-form">
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <p className="text-[18px] font-semibold">Client Details</p>

              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form.Item label="Client Name">
              <p className="text-[16px] font-medium">
                {decryptSecureData(customer?.full_name)}
              </p>
            </Form.Item>
            <Divider />

            <p className="text-[16px] font-semibold pb-3">Address</p>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="Postal Code">
                  <p className="text-[16px] font-medium">
                    {customer?.postal_code}
                  </p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Suburbs">
                  <p className="text-[16px] font-medium">{customer?.suberb}</p>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="State">
                  <p className="text-[16px] font-medium">{customer?.state}</p>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Country">
                  <p className="text-[16px] font-medium">{customer?.country}</p>
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <p className="text-[16px] font-semibold pb-3">Contact</p>
            <Row>
              <Col xs={24} md={12} className="pb-3">
                <Row className="flex flex-row">
                  <Col xs={5}>
                    <FiPhoneCall className="text-[30px] text-[primaryLight] mt-3" />
                  </Col>
                  <Col xs={18}>
                    <p className="text-[16px] font-normal">Telephone</p>
                    <p className="text-[16px] font-medium">
                      {decryptSecureData(customer?.mobile_number)}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />
            <div className="flex flex-row gap-10">
              <p className="text-[16px] font-semibold pb-3">Joined at</p>
              <p className="text-[16px] font-medium">
                - {customer?.created_at}
              </p>
            </div>

            <Divider />
            <p className="text-[16px] font-semibold pb-3"> Client Status</p>
            <p className="text-[16px] font-medium">
              {customer?.is_active === 1 ? "Active" : "Inactive"}
            </p>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewCustomer;
