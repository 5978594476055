import React, { useEffect, useRef, useState } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useContentContext } from "../providers/ContentContext";
import {
  getAllForm3GraphData,
  getAllForm4GraphData,
} from "../helpers/apiHelpers/graph";
import { Content } from "antd/es/layout/layout";
import { Button, DatePicker, Spin } from "antd";
const { RangePicker } = DatePicker;

const AppRegisterGraph = () => {
  let { openNotification, handleError } = useContentContext();

  const [graphData, setGraphData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [dateRangeDiff, setDateRangeDiff] = useState([]);

  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    fetchForm4GraphData();
  }, []);

  useEffect(() => {
    if (graphData?.length !== 0) {
      const ctx = chartRef.current.getContext("2d");

      Chart.register(
        CategoryScale,
        LinearScale,
        LineController,
        LineElement,
        PointElement,
        Title,
        Tooltip,
        Legend
      );

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      chartInstanceRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: graphData?.labels,
          datasets: [
            {
              label: "Total App Register Count",
              data: graphData?.total_app_register,
              fill: false,
              borderColor: "rgb(255, 99, 132)",
              tension: 0.1,
            },
            {
              label: "Total App Register Count - Australia",
              data: graphData?.total_au_register,
              fill: false,
              borderColor: "rgb(54, 162, 235)",
              tension: 0.1,
            },
            {
              label: "Total App Register Count - Sri Lanka",
              data: graphData?.total_sl_register,
              fill: false,
              borderColor: "rgb(255, 206, 86)",
              tension: 0.1,
            },
            {
              label: "Total Client App Register Count - Australia",
              data: graphData?.total_au_client_register,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
            {
              label: "Total Client App Register Count - Sri Lanka",
              data: graphData?.total_sl_client_register,
              fill: false,
              borderColor: "rgb(153, 102, 255)",
              tension: 0.1,
            },
            {
              label: "Total Tasker App Register Count - Australia",
              data: graphData?.total_au_tasker_register,
              fill: false,
              borderColor: "rgb(255, 159, 64)",
              tension: 0.1,
            },
            {
              label: "Total Tasker App Register Count - Sri Lanka",
              data: graphData?.total_sl_tasker_register,
              fill: false,
              borderColor: "rgb(0, 255, 0)",
              tension: 0.1,
            },
            {
              label: "Total Client App Register Count",
              data: graphData?.total_client_register,
              fill: false,
              borderColor: "rgb(165,42,42)",
              tension: 0.1,
            },
            {
              label: "Total Tasker App Register Count",
              data: graphData?.total_tasker_register,
              fill: false,
              borderColor: "rgb(0, 0, 0)",
              tension: 0.1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: "right", // Change the position to 'right'
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      return () => {
        if (chartInstanceRef?.current) {
          chartInstanceRef?.current?.destroy();
        }
      };
    }
  }, [graphData]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchForm4GraphData = async () => {
    try {
      await getAllForm4GraphData({})
        .then((response) => {
          if (response?.data.success) {
            setGraphData(response?.data?.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const fetchForm3GraphData = async () => {
    try {
      await getAllForm3GraphData({
        from_day: dateRange[0],
        to_day: dateRange[1],
      })
        .then((response) => {
          if (response?.data.success) {
            setGraphData(response?.data?.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        Graph-Application Register
      </div>
      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <div className="flex flex-row gap-2 py-5">
          <RangePicker
            size="large"
            allowClear={false}
            value={dateRangeDiff}
            onChange={(date, dateString) => {
              setDateRange(dateString);
              setDateRangeDiff(date);
            }}
          />
          <Button
            size="large"
            type="primary"
            onClick={() => {
              fetchForm3GraphData();
            }}
            disabled={dateRangeDiff?.length === 0}
          >
            GET DATA
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setDateRangeDiff([]);
              fetchForm4GraphData();
            }}
          >
            CLEAR
          </Button>
        </div>
        {graphData?.length === 0 ? (
          <div className="flex justify-center items-center pt-10">
            <Spin />
          </div>
        ) : (
          <canvas
            ref={chartRef}
            id="myChart"
            height="40vh"
            width="100"
          ></canvas>
        )}
      </Content>
    </>
  );
};

export default AppRegisterGraph;
