import React, { useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Progress,
  Switch,
  Upload,
  Row,
  Col,
} from "antd";
import { MdClose } from "react-icons/md";
import { UploadOutlined } from "@ant-design/icons";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { addNewTermsAndConditions } from "../../helpers";
import TermsConditSubmitButton from "../submitButton/TermsConditSubmitButton";

const NewTerms = ({ open, onClose, reloadtableData, setSearchText }) => {
  const token = getLocalStoragedata("token");
  let { openNotification, handleError } = useContentContext();
  const conicColors = { "0%": "#87d068", "50%": "#ffe58f", "100%": "#ffccc7" };

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [form] = Form.useForm();

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  const onFinish = async (e) => {
    setSearchText();

    // Check if an file is required for a term
    if (fileList.length === 0) {
      openNotification(
        "warning",
        "Please upload a file for terms and conditions."
      );
      setErrormsg(true);
      return;
    }

    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Origin: "devadmin.jobninja.au",
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer${token}`,
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress.toFixed(1));
      },
    };

    const data = new FormData();
    data.append("version", e.version);
    data.append(
      "file_extension",
      fileList.length === 1 ? fileList[0]?.name.split(".")[1] : null
    );
    data.append("terms_file", fileList[0]?.originFileObj);
    data.append("status", e.switch === true ? 1 : 0);

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);

      await addNewTermsAndConditions(data, config)
        .then((response) => {
          if (response.data.success) {
            reloadtableData();
            openNotification("success", response.data.message);
          } else {
            openNotification("error", response.data.message);
          }
          setIsUploading(false);
        })
        .catch((error) => {
          handleError(error);
        });
    }
    onClose();
  };

  const props = {
    onChange(info) {
      setErrormsg(false);
      if (info?.fileList.length === 1) {
        setFileList(info?.fileList);
      } else {
        setFileList([]);
      }
    },
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form terms-condition"
    >
      <div className="w-full md:w-[768px]">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          initialValues={{ switch: false }}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row justify-between">
              <div className="text-[16px] font-medium text-primaryLight">
                Add New Terms & Conditions
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  setFileList([]);
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form.Item
              name="version"
              className="text-[16px]"
              label="Version"
              rules={[{ required: true, message: "Please enter Version" }]}
            >
              <Input
                placeholder="Version"
                className="!bg-secondaryWhite"
                size="large"
                onChange={() => {
                  setErrormsg(false);
                }}
                maxLength={20}
              />
            </Form.Item>

            <Form.Item
              name="file"
              label="Terms & Condition"
              className="label-after"
              rules={[{ required: true }]}
            >
              <Upload
                {...props}
                customRequest={({ onSuccess }) => onSuccess("ok")}
                accept=".pdf"
                fileList={fileList}
              >
                <Button
                  disabled={fileList.length === 1}
                  icon={<UploadOutlined />}
                  className="text-[16px]"
                  size="large"
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form.Item>

            <div className="flex flex-col gap-2">
              <div className="flex flex-col font-medium text-base">Status</div>
              <div className="flex flex-row text-center items-baseline">
                <span
                  className={`${isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"}`}
                >
                  Inactive
                </span>
                <Form.Item name="switch" valuePropName="checked">
                  <Switch className="mx-2" onChange={handleSwitchChange} />
                </Form.Item>
                <span
                  className={`${isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"}`}
                >
                  Active
                </span>
              </div>
            </div>

            {/* button */}
            <div className="w-full pt-7">
              <Row gutter={8}>
                <Col xs={24} sm={12}>
                  <Form.Item>
                    <TermsConditSubmitButton
                      form={form}
                      errormsg={errormsg}
                      isUploading={isUploading}
                      isEditButtonEnable={false}
                      type={"ADD"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className="outline-button">
                  <Button
                    size="large"
                    className="font-medium w-full "
                    onClick={onClose}
                  >
                    <p className="text-center">CANCEL</p>
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>

      {uploadProgress !== 0 ? (
        <Progress
          className="w-full"
          percent={uploadProgress}
          strokeColor={conicColors}
        />
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default NewTerms;
