import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useContentContext } from "../providers/ContentContext";
import { MdAdd } from "react-icons/md";
import {
  EditAdminRole,
  NewAdminRole,
  StatusChange,
  ViewAdminRole,
} from "../components";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { customStatusSorter } from "../commonFun";
import { adminRoleTableStatusChange } from "../helpers";

const columns = [
  {
    title: "Admin Roles",
    dataIndex: "adminRole",
    key: "0",
    sorter: (a, b) => a.adminRole?.localeCompare(b.adminRole),
    defaultSortOrder: "ascend",
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "1",
    sorter: (a, b) => customStatusSorter(a, b),
    defaultSortOrder: "ascend",
  },

  {
    title: "Action",
    dataIndex: "action",
    key: "2",
  },
];

const AdminRoles = () => {
  let { openNotification, loadAdminRolePermissions, handleError } =
    useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState([]);
  const [selectedAdminRole, setSelectedAdminRole] = useState();

  const [isOpenNewtemDrawer, setIsOpenNewItemDrawer] = useState(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);
  const [isOpenVewDrawer, setIsOpenViewDrawer] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setDataLoading(true);
      const adminRoles = await loadAdminRolePermissions();
      setData(adminRoles);
      setDataForSearch(adminRoles);
    } catch (error) {
      openNotification("error", error);
    }
  };

  const search = (text) => {
    let filtered = dataForSearch?.filter((o) =>
      o.user_type.toLowerCase().includes(text.toLowerCase())
    );

    setData(filtered);
  };

  const setData = (adminRoles) => {
    if (adminRoles?.length !== 0) {
      var tableData = [];

      adminRoles?.forEach((adminRole, x) => {
        let data = {
          key: x,
          adminRole: adminRole?.user_type,
          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                adminRole?.is_active === 1
                  ? "bg-primaryColor"
                  : "bg-primaryLight"
              } `}
              onClick={() => {
                setChangeStatus(adminRole?.is_active);
                openChangeStatus(adminRole);
              }}
              disabled={!permission.includes(2)}
            >
              {adminRole?.is_active === 1 ? "Active" : "Inactive"}
            </Button>
          ),
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight outline-button">
              {adminRole?.id !== 1 &&
                (permission.includes(2) ? (
                  <BiEdit
                    className="cursor-pointer text-2xl text-primaryLight"
                    onClick={() => {
                      openEditDrawer(adminRole);
                    }}
                  />
                ) : (
                  <></>
                ))}

              <BsEye
                className="cursor-pointer text-2xl text-primaryLight"
                onClick={() => {
                  handleViewDrawerOpenChange(adminRole);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
    } else {
      setTableData([]);
    }
    setDataLoading(false);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  const handleNewItemDrawerOpenChange = () => {
    setIsOpenNewItemDrawer(!isOpenNewtemDrawer);
  };
  const handleViewDrawerOpenChange = (adminRole) => {
    setSelectedAdminRole(adminRole);
    setIsOpenViewDrawer(!isOpenVewDrawer);
  };

  const openEditDrawer = (adminRole) => {
    setIsOpenEditDrawer(true);
    setSelectedAdminRole(adminRole);
  };
  const closeEditDrawer = () => {
    setIsOpenEditDrawer(false);
  };

  const openChangeStatus = (state) => {
    setIsModalOpen(true);
    setSelectedData(state);
  };
  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };
  // state status change in table
  const handleStatus = async (status) => {
    const data = {
      user_type_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    await adminRoleTableStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5 pl-6 ">
        ADMIN ROLES
      </div>
      <Content className="rounded-lg mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Admin Role..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(1) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                icon={<MdAdd className="text-2xl font-bold" />}
                onClick={handleNewItemDrawerOpenChange}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD NEW ADMIN ROLE
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={tableData}
            columns={columns}
            showSorterTooltip={false}
            loading={dataLoading}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
            scroll={{ x: "100%" }}
          />
        </div>
      </Content>
      {isOpenNewtemDrawer ? (
        <NewAdminRole
          open={isOpenNewtemDrawer}
          onClose={handleNewItemDrawerOpenChange}
          reloadTabelData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenEditDrawer ? (
        <EditAdminRole
          open={isOpenEditDrawer}
          onClose={closeEditDrawer}
          setSearchText={setSearchText}
          reloadTabelData={fetchData}
          selectedAdminRole={selectedAdminRole}
        />
      ) : (
        <></>
      )}

      {isOpenVewDrawer ? (
        <ViewAdminRole
          open={isOpenVewDrawer}
          onClose={handleViewDrawerOpenChange}
          selectedAdminRole={selectedAdminRole}
        />
      ) : (
        <></>
      )}

      {isModalOpen && (
        <StatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default AdminRoles;
