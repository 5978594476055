import { Button, Col, DatePicker, Input, Row, Select, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import {
  getAllCategoryData,
  getAllCountries,
  getAllLocationLevel1Data,
  getAllPromoSmsCountries,
} from "../helpers";
import { getReportData } from "../helpers/apiHelpers/report";
import moment from "moment";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const columns = [
  {
    title: "State",
    dataIndex: "level1",
    key: "1",
    sorter: (a, b) => a.level1?.localeCompare(b.level1),
    defaultSortOrder: "ascend",
  },
  {
    title: "Suburb",
    dataIndex: "level2",
    key: "2",
    sorter: (a, b) => a.level2?.localeCompare(b.level2),
    defaultSortOrder: "ascend",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "3",
    sorter: (a, b) => a.category?.localeCompare(b.category),
    defaultSortOrder: "ascend",
  },
  {
    title: "Job Types",
    dataIndex: "jobtype",
    key: "4",
    sorter: (a, b) => a.jobtype?.localeCompare(b.jobtype),
    defaultSortOrder: "ascend",
  },

  {
    title: "Job Count",
    dataIndex: "jobCount",
    key: "5",
    sorter: (a, b) => a.jobCount - b.jobCount,
    defaultSortOrder: "ascend",
  },
];

const columnsDiff = [
  {
    title: "District",
    dataIndex: "level1",
    key: "1",
    sorter: (a, b) => a.level1?.localeCompare(b.level1),
    defaultSortOrder: "ascend",
  },
  {
    title: "City",
    dataIndex: "level2",
    key: "2",
    sorter: (a, b) => a.level2?.localeCompare(b.level2),
    defaultSortOrder: "ascend",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "3",
    sorter: (a, b) => a.category?.localeCompare(b.category),
    defaultSortOrder: "ascend",
  },
  {
    title: "Job Types",
    dataIndex: "jobtype",
    key: "4",
    sorter: (a, b) => a.jobtype?.localeCompare(b.jobtype),
    defaultSortOrder: "ascend",
  },

  {
    title: "Job Count",
    dataIndex: "jobCount",
    key: "5",
    sorter: (a, b) => a.jobCount - b.jobCount,
    defaultSortOrder: "ascend",
  },
];

const options = [];
for (let i = 10; i < 36; i++) {
  const value = i.toString(36) + i;
  options.push({
    label: `Long Label: ${value}`,
    value,
  });
}
const sharedProps = {
  mode: "multiple",
  style: {
    width: "100%",
  },
  options,
  placeholder: "Select Item...",
  maxTagCount: "responsive",
};

const UserCountReport = () => {
  let { openNotification, handleError } = useContentContext();
  const [tableFinalData, setTableFinalData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [location1SelectorData, setLocation1SelectorData] = useState([]);
  const [categorySelectorData, setCategorySelectorData] = useState([]);

  const [value, setValue] = useState(["a10", "c12", "h17", "j19", "k20"]);

  const [filterDataObject, setFilterDataObject] = useState({
    country_id: 0,
    state_id: [],
    job_category_id: [],
    from_date: "",
    to_date: "",
  });

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    fetchCountryData();
  }, []);

  //fetch country
  const fetchCountryData = async () => {
    try {
      await getAllPromoSmsCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [];
    countries?.forEach((country) => {
      let data = {
        value: country?.country_id,
        label: country?.country,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  //fetch location 1
  const fetchLocationLevel1Data = async (data) => {
    try {
      await getAllLocationLevel1Data({ country_id: data })
        .then((response) => {
          if (response?.data.success) {
            setLocationLevel1Data(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setLocationLevel1Data = (locations1) => {
    var locationLevel1Object = [];
    locations1?.forEach((location1) => {
      let data = {
        value: location1?.level1_id,
        label: location1?.level1_location,
      };
      locationLevel1Object.push(data);
    });
    setLocation1SelectorData(locationLevel1Object);
  };

  //fetch category
  const fetchCategoryData = async (data) => {
    try {
      await getAllCategoryData({
        country_id: data,
      })
        .then((response) => {
          if (response?.data.success) {
            setCategoryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCategoryData = (category) => {
    var categoryObject = [];
    category?.forEach((category) => {
      let data = {
        value: category?.job_category_id,
        label: category?.job_category,
      };
      categoryObject.push(data);
    });
    setCategorySelectorData(categoryObject);
  };

  //fetch tabel data
  const fetchTableData = async (data) => {
    setDataLoading(true);
    try {
      await getReportData(data)
        .then((response) => {
          if (response?.data.success) {
            setTableData(response.data.output ? response.data.output : []);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }

    setDataLoading(false);
  };

  const setTableData = (items) => {
    if (items?.length !== 0) {
      var tableData = [];

      items?.forEach((item, x) => {
        let data = {
          key: x,
          level1: item?.state,
          level2: item?.suburb,
          category: item?.job_category,
          jobtype: item?.job_type,
          jobCount: item?.job_count,
        };
        tableData.push(data);
      });
      setTableFinalData(tableData);
    } else {
      setTableFinalData([]);
    }
    setDataLoading(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  //report export
  const exportToCSV = async () => {
    const filterCountry = await countrySelectorData?.find(
      (item) => item?.value === filterDataObject?.country_id
    )?.label;
    const filterDate =
      filterDataObject?.from_date === "" && filterDataObject?.to_date === ""
        ? new Date().toLocaleString()
        : filterDataObject?.to_date !== ""
        ? filterDataObject?.from_date + "-" + filterDataObject?.to_date
        : filterDataObject?.from_date;

    const headers =
      filterDataObject?.country_id === 2
        ? ["District", "City", "Category", "Job Types", "Job Count"]
        : ["State", "Suburb", "Category", "Job Types", "Job Count"];
    const title = `Job Count Report - ${filterCountry} - ${filterDate}`;

    const csvRows = [title, "", headers.join(",")];

    tableFinalData.forEach((field, index) => {
      const rowValues = [
        field.level1?.replace(/,/g, " / "),
        field.level2?.replace(/,/g, " / "),
        field.category?.replace(/,/g, " / "),
        field.jobtype?.replace(/,/g, " / "),
        field.jobCount,
      ];
      csvRows.push(rowValues.join(","));
    });

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Job Count Report - ${filterCountry} - ${filterDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const selectProps = {
    value,
    onChange: setValue,
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        JOB COUNT REPORT
      </div>

      <Content className="mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <div className="flex justify-end items-end pb-5">
          <Button
            className="flex flex-row bg-primaryLight font-medium"
            type="primary"
            size="large"
            disabled={tableFinalData?.length === 0}
            onClick={() => {
              exportToCSV();
            }}
          >
            <p className="px-5">EXPORT CSV</p>
          </Button>
        </div>

        <Row gutter={8} className="pb-5">
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Select
              name="country"
              placeholder="Country"
              className="!bg-secondaryWhite w-full"
              size="large"
              options={countrySelectorData}
              value={
                filterDataObject?.country_id === 0
                  ? null
                  : filterDataObject?.country_id
              }
              onChange={(e) => {
                setTableFinalData([]);
                setLocation1SelectorData([]);
                setCategorySelectorData([]);
                fetchLocationLevel1Data(e);
                fetchCategoryData(e);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  country_id: e,
                  state_id: [],
                  job_category_id: [],
                }));
                fetchTableData({
                  ...filterDataObject,
                  country_id: e,
                  state_id: [],
                  job_category_id: [],
                });
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Select
              {...sharedProps}
              {...selectProps}
              mode="multiple"
              placeholder={
                filterDataObject?.country_id === 2 ? "District" : "State"
              }
              className="!bg-secondaryWhite w-full"
              size="large"
              options={location1SelectorData}
              value={
                filterDataObject?.state_id?.length === 0
                  ? []
                  : filterDataObject?.state_id
              }
              onChange={(e) => {
                setTableFinalData([]);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  state_id: e,
                }));
                fetchTableData({
                  ...filterDataObject,
                  state_id: e,
                });
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Select
              {...sharedProps}
              {...selectProps}
              mode="multiple"
              placeholder="Category"
              className="!bg-secondaryWhite w-full"
              size="large"
              options={categorySelectorData}
              value={
                filterDataObject?.job_category_id?.length === 0
                  ? []
                  : filterDataObject?.job_category_id
              }
              onChange={(e) => {
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  job_category_id: e,
                }));
                fetchTableData({
                  ...filterDataObject,
                  job_category_id: e,
                });
              }}
              filterOption={filterOption}
              showSearch
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <DatePicker
              size="large"
              placeholder="From Date"
              className="w-full"
              value={fromDate}
              disabledDate={(current) => {
                return current && current.isAfter(moment(), "day");
              }}
              disabled={
                filterDataObject?.country_id === 0 &&
                filterDataObject?.job_category_id?.length === 0 &&
                filterDataObject?.state_id?.length === 0
              }
              onChange={(dateValue, dateString) => {
                setFromDate(dateValue);
                setToDate();
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  from_date: dateString,
                  to_date: "",
                }));
                fetchTableData({
                  ...filterDataObject,
                  from_date: dateString,
                  to_date: "",
                });
              }}
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <DatePicker
              size="large"
              placeholder="To Date"
              className="w-full"
              value={toDate}
              disabledDate={(current) => {
                return (
                  (fromDate && current && current.isBefore(fromDate, "day")) ||
                  (current && current.isAfter(moment().endOf("day")))
                );
              }}
              disabled={
                filterDataObject?.from_date === "" ||
                (filterDataObject?.country_id === 0 &&
                  filterDataObject?.job_category_id?.length === 0 &&
                  filterDataObject?.state_id?.length === 0)
              }
              onChange={(dateValue, dateString) => {
                setToDate(dateValue);
                setFilterDataObject((prevState) => ({
                  ...prevState,
                  to_date: dateString,
                }));
                fetchTableData({
                  ...filterDataObject,
                  to_date: dateString,
                });
              }}
            />
          </Col>
          <Col className="pb-2" span={24} md={{ span: 12 }} xl={{ span: 4 }}>
            <Button
              size="large"
              className="w-full font-bold"
              onClick={() => {
                setFromDate();
                setToDate();
                setTableFinalData([]);
                setLocation1SelectorData([]);
                setCategorySelectorData([]);
                setFilterDataObject({
                  country_id: 0,
                  state_id: [],
                  job_category_id: [],
                  from_date: "",
                  to_date: "",
                });
              }}
            >
              CLEAR
            </Button>
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={tableFinalData}
            columns={filterDataObject?.country_id === 2 ? columnsDiff : columns}
            loading={dataLoading}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "100", "1000", "10000"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>
    </>
  );
};

export default UserCountReport;
