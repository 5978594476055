import React, { useState } from "react";
import "./index.css";
import { Form, Button, Statistic } from "antd";
import { InputOTP } from "antd-input-otp";
import { useContentContext } from "../../providers/ContentContext";
import { getResedOTP, verifyOTP } from "../../helpers";

const { Countdown } = Statistic;

const SetOTP = ({ setFormChanger, resetemail, isOTPsend }) => {
  const deadline = Date.now() + 1000 * 60 * 2;

  let { openNotification, handleError } = useContentContext();
  const formRef = React.useRef(null);

  const [isCountDownFinished, setIsCountDownFinished] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [countDownTime, setCountDownTime] = useState(deadline);
  const [resetButtonDisable, setResetButtonDisable] = useState(false);

  const onFinishCountDown = () => {
    setIsCountDownFinished(true);
    setResetButtonDisable(false);
  };
  const onReset = () => {
    formRef.current?.resetFields();
  };
  const resendOTP = () => {
    const data = {
      email_address: resetemail,
      should_generate: 1,
    };

    getResedOTP(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          onReset();
          setCountDownTime(Date.now() + 1000 * 60 * 2);
          setIsCountDownFinished(false);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const onSubmitOTP = (e) => {
    setIsButtonDisable(false);
    const enteredOTP = e.otp.join("");

    const data = {
      email_address: resetemail,
      otp: enteredOTP,
    };

    if (isOTPsend === true) {
      verifyOTP(data)
        .then((response) => {
          if (response.data.success) {
            openNotification("success", response.data.message);
            setFormChanger(2);
          } else {
            openNotification("error", response.data.message);
            onReset();
            setIsButtonDisable(false);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  return (
    <>
      <div className=" text-[40px] font-semibold text-center md:text-secondaryWhite">
        Reset Your Password
      </div>
      <div className="flex flex-col justify-center items-center mt-4 md:bg-[#484848] p-6 sm:px-8 px-4 max-sm:mx-2">
        <Form
          name="login"
          className="login-form flex flex-col sm:w-[400px] w-[300px] mt-2 font-normal "
          layout="vertical"
          size="large"
          autoComplete="off"
          onFinish={onSubmitOTP}
          ref={formRef}
        >
          <Form.Item name="otp">
            <InputOTP
              inputType="numeric"
              className="!text-secondaryDarkshade"
              onChange={(otpValues) => {
                if (otpValues.join("").length === 6) {
                  setIsButtonDisable(true);
                } else {
                  setIsButtonDisable(false);
                }
              }}
            />
          </Form.Item>

          {!isCountDownFinished ? (
            <Countdown
              value={countDownTime}
              onFinish={onFinishCountDown}
              format="mm:ss"
            />
          ) : (
            <button
              onClick={() => {
                setResetButtonDisable(true);
                resendOTP();
              }}
              className="text-[18px] font-semibold md:text-white text-center border-none"
              disabled={resetButtonDisable ? true : false}
            >
              RESEND
            </button>
          )}

          <Form.Item>
            <Button
              htmlType="submit"
              className={`w-full mt-7 !text-[18px] text-semibold !text-white ${
                isCountDownFinished === true || isButtonDisable === false
                  ? "!bg-primaryLightDisable !border-primaryLightDisable"
                  : "!bg-primaryLight !border-primaryLight"
              }`}
              style={{ height: "50px" }}
              disabled={
                isCountDownFinished === true || isButtonDisable === false
                  ? true
                  : false
              }
            >
              VERIFY
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SetOTP;
