import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { useContentContext } from "../providers/ContentContext";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import {
  EditJobType,
  NewJobType,
  StatusChange,
  ViewJobType,
} from "../components";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { customStatusSorterDiff } from "../commonFun";
import { getAllJobTypes, tableJobTypeStatusChange } from "../helpers";

const columns = [
  {
    title: "Category",
    dataIndex: "category",
    key: "0",
    sorter: (a, b) => a.category?.localeCompare(b.category),
    defaultSortOrder: "ascend",
  },
  {
    title: "Job Type",
    dataIndex: "jobType",
    key: "1",
    sorter: (a, b) => a.jobType?.localeCompare(b.jobType),
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "2",
    sorter: (a, b) => customStatusSorterDiff(a, b),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "3",
  },
];

const JobType = () => {
  const { openNotification, handleError } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [tableJobTypeData, setTableJobTypeData] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState();
  const [selectedData, setSelectedData] = useState();

  const [isOpenNewJobTypeDrawer, setIsOpenNewJobTypeDrawer] = useState(false);
  const [isOpenViewJobTypeDrawer, setIsOpenViewJobTypeDrawer] = useState(false);
  const [selectedViewJobTypeData, setSelectedViewJobTypeData] = useState();
  const [isOpenEditJobTypeDrawer, setIsOpenEditJobTypeDrawer] = useState(false);
  const [selectedEditJobTypeData, setSelectedEditJobTypeData] = useState();

  const [topCount, setTopCount] = useState(0);
  // const [activeTableFilterButton, setActiveTableFilteButton] = useState(0);

  useEffect(() => {
    fetchData(0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async (type) => {
    try {
      // setActiveTableFilteButton(type);
      setDataLoading(true);
      await getAllJobTypes({ job_category_id: 0, status: -1, is_urgent: type })
        .then((response) => {
          if (response?.data.success) {
            setTopCount(response?.data.output[0]?.top_type_count);
            setData(response.data.output ? response.data.output : []);
            setDataForSearch(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const openChangeStatus = (category) => {
    setIsModalOpen(true);
    setSelectedData(category);
  };

  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };

  const handleNewJobTypeDrawerOpenChange = () => {
    setIsOpenNewJobTypeDrawer(!isOpenNewJobTypeDrawer);
  };

  const handleViewJobTypeDrawerOpenChange = (jobType) => {
    setSelectedViewJobTypeData(jobType);
    setIsOpenViewJobTypeDrawer(!isOpenViewJobTypeDrawer);
  };

  const openEditJobTypeDrawerOpenChange = (jobType) => {
    setSelectedEditJobTypeData(jobType);
    setIsOpenEditJobTypeDrawer(true);
  };

  const closeEditJobTypeDrawerOpenChange = () => {
    setIsOpenEditJobTypeDrawer(false);
  };

  const setData = (jobTypes) => {
    if (jobTypes.length !== 0) {
      var tableData = [];

      jobTypes?.forEach((jobType, x) => {
        let data = {
          key: x,
          category: jobType?.job_category,
          jobType: jobType.job_type,
          status: (
            <div>
              <Button
                className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                  jobType.is_active === 1
                    ? "bg-primaryColor"
                    : "bg-primaryLight"
                } `}
                onClick={() => {
                  setChangeStatus(jobType.is_active);
                  openChangeStatus(jobType);
                }}
                disabled={!permission.includes(26)}
              >
                <p>{jobType.is_active === 1 ? "Active" : "Inactive"}</p>
              </Button>
            </div>
          ),
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(26) ? (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditJobTypeDrawerOpenChange(jobType);
                  }}
                />
              ) : (
                <></>
              )}

              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewJobTypeDrawerOpenChange(jobType);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableJobTypeData(tableData);
    } else {
      setTableJobTypeData([]);
    }
    setDataLoading(false);
  };

  const search = (text) => {
    let filtered = dataForSearch.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(text.toLowerCase())
      )
    );

    setData(filtered);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  // job type status change in table
  const handleStatus = async (status) => {
    const data = {
      job_type_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    tableJobTypeStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData(0);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        JOB TYPE
      </div>

      <Content className=" mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll rounded-lg">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Job Type..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(25) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                onClick={handleNewJobTypeDrawerOpenChange}
                icon={<MdAdd className="text-2xl font-bold" />}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD JOB TYPE
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        {/* <div className="flex items-end justify-end pb-5">
          <div className="btn-group">
            <button
              className={`font-semibold ${
                activeTableFilterButton === 0 && "bg-primaryLight text-white"
              } text-[18px] border-r-2 border-gray-300 target:!bg-primaryLight active:!bg-primaryLight focus:!bg-primaryLight  target:!text-white focus:!text-white active:!text-white px-7 py-1 rounded-l-[5px] bg-[#F3F2F2]`}
              onClick={() => {
                fetchData(0);
              }}
            >
              ALL
            </button>
            <button
              className={`font-semibold ${
                activeTableFilterButton === 1 && "bg-primaryLight text-white"
              } text-[18px] target:!bg-primaryLight active:!bg-primaryLight focus:!bg-primaryLight  target:!text-white focus:!text-white active:!text-white px-7 py-1 rounded-r-[5px] bg-[#F3F2F2]`}
              onClick={() => {
                fetchData(1);
              }}
            >
              POPULAR JOB TYEPS
            </button>
          </div>
        </div> */}

        <div className="table-status-button">
          <Table
            dataSource={tableJobTypeData}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
            loading={dataLoading}
          />
        </div>
      </Content>

      {isOpenNewJobTypeDrawer ? (
        <NewJobType
          open={isOpenNewJobTypeDrawer}
          onClose={handleNewJobTypeDrawerOpenChange}
          reloadJobTypeData={fetchData}
          setSearchText={setSearchText}
          topCount={topCount}
        />
      ) : (
        <></>
      )}

      {isOpenViewJobTypeDrawer ? (
        <ViewJobType
          open={isOpenViewJobTypeDrawer}
          onClose={handleViewJobTypeDrawerOpenChange}
          jobType={selectedViewJobTypeData}
        />
      ) : (
        <></>
      )}

      {isOpenEditJobTypeDrawer && (
        <EditJobType
          open={isOpenEditJobTypeDrawer}
          onClose={closeEditJobTypeDrawerOpenChange}
          jobType={selectedEditJobTypeData}
          reloadJobTypeData={fetchData}
          setSearchText={setSearchText}
        />
      )}

      {isModalOpen && (
        <StatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default JobType;
