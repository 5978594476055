import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { CollapsedLogo, LoginBg, LoginLogo } from "../assets";
import { useContentContext } from "../providers/ContentContext";
import { ConfigProvider } from "antd";
import {
  getLocalStoragedata,
  setLocalStorageData,
} from "../helpers/StorageHelper";
import { useNavigate } from "react-router-dom";
import { allAuthUserData, allAuthUserPermission, userLogin } from "../helpers";
import { encryptSecureData } from "../services/EncryptionService";

const Login = () => {
  let { openNotification, handleError } = useContentContext();
  const navigate = useNavigate();

  const [loginValues, setLoginValues] = useState({
    email: getLocalStoragedata("rememberedEmail") || "",
    password: getLocalStoragedata("rememberedPassword") || "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState();
  const [isButtonDisable, setIsButtonDisable] = useState(false);

  const onFinish = (values) => {
    const data = {
      identification_key: encryptSecureData(values.email),
      password: encryptSecureData(values.password),
    };

    if (rememberMe) {
      setLocalStorageData("rememberedEmail", values.email);
      setLocalStorageData("rememberedPassword", values.password);
      localStorage.setItem("rememberMe", rememberMe);
    } else {
      localStorage.clear();
    }

    userLogin(data)
      .then((response) => {
        if (response.data.success) {
          if (response.data.output.is_set_password === 0) {
            navigate("/create-new-password", { state: { loginValues } });
          } else {
            getAuthUserData(response.data.output.token);
            openNotification("success", response.data.message);
            setLocalStorageData("token", response.data.output.token);
          }
        } else {
          openNotification("error", response.data.message);
          setLoginError(true);
          setLoginErrorMsg(response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    setIsButtonDisable(true);
  };

  const getAuthUserData = (token) => {
    const data = {};
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Origin: "devadmin.jobninja.au",
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer ${token}`,
      },
    };

    allAuthUserData(data, config)
      .then((response) => {
        if (response.data.success) {
          setLocalStorageData("usertype", response.data.output.user_type_id);
          setLocalStorageData("userId", response.data.output.user_id);
          setLocalStorageData("user", response.data.output.last_name);
          getUserPermission(token, response.data.output.user_type_id);
        } else {
          openNotification(
            "warning",
            "User Authentication Failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getUserPermission = (token, id) => {
    const data = { user_type_id: id };
    const config = {
      headers: {
        "Access-Control-Allow": true,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Origin: "devadmin.jobninja.au",
        "X-Custom-Header": "random-value-1234567890",
        Authorization: `Bearer ${token}`,
      },
    };
    allAuthUserPermission(data, config).then((response) => {
      if (response.data.success) {
        setLocalStorageData("permission", response.data.output.permission);
        window.location.replace("/");
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins",
          colorPrimary: "var(--primary-light)",
        },
      }}
    >
      <Row>
        {/* small screen login */}
        <Col
          xs={24}
          md={15}
          className="login-form-mobile md:hidden flex flex-col w-full h-screen justify-center items-center"
          style={{
            backgroundImage: `url(${LoginBg})`,
            backgroundSize: "cover",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div>
            <img src={CollapsedLogo} alt="collapsedLogo" />
          </div>
          <div className=" text-[40px] font-semibold md:text-secondaryWhite">
            Admin Login
          </div>
          <div className="flex flex-col justify-center items-center mt-4 p-6 sm:px-8 px-4 max-sm:mx-2">
            <Form
              name="login"
              className="login-form flex flex-col sm:w-[400px] w-[300px] mt-2 font-normal "
              initialValues={{
                email: getLocalStoragedata("rememberedEmail") || "",
                password: getLocalStoragedata("rememberedPassword") || "",
                remember: localStorage.getItem("rememberMe") || false,
              }}
              layout="vertical"
              onFinish={onFinish}
              size="large"
              autoComplete="off"
            >
              <Form.Item
                name="email"
                label="E-Mail"
                className="mb-4 login-email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input
                  placeholder="E-Mail"
                  className="border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
                  onChange={(e) => {
                    setLoginError(false);
                    setLoginValues({ ...loginValues, email: e.target.value });
                    setIsButtonDisable(false);
                  }}
                  maxLength={100}
                />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                className="mb-4 login-password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Password!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Password"
                  className="login-page-password border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
                  onChange={(e) => {
                    setLoginError(false);
                    setLoginValues({
                      ...loginValues,
                      password: e.target.value,
                    });
                    setIsButtonDisable(false);
                  }}
                  maxLength={20}
                />
              </Form.Item>

              {loginError && (
                <p className="text-[14px] !text-[#a77802] font-normal pb-4">
                  {loginErrorMsg}
                </p>
              )}

              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox
                  onChange={(e) => {
                    setRememberMe(e.target.checked);
                  }}
                >
                  <p className="text-[18px]">Remember me</p>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  size="large"
                  className={`w-full mt-7 !text-[18px] text-semibold !text-white ${
                    loginValues.email !== "" &&
                    loginValues.password !== "" &&
                    isButtonDisable === false
                      ? "!bg-primaryLight !border-primaryLight"
                      : "!bg-primaryLightDisable !border-primaryLightDisable"
                  }`}
                  style={{ height: "50px" }}
                  disabled={
                    loginValues.email !== "" &&
                    loginValues.password !== "" &&
                    isButtonDisable === false
                      ? false
                      : true
                  }
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>

            <div className="forgot-password">
              <Button
                type="text"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                <p className="underline !text-primaryLight flex justify-center text-[18px] cursor-pointer">
                  Forgot Password
                </p>
              </Button>
            </div>
          </div>
        </Col>
        {/* Large screen login */}
        <Col
          xs={24}
          md={15}
          className="login-form-desktop hidden md:flex flex-col w-full h-screen justify-center items-center bg-[#484848]"
        >
          <div className=" text-[40px] font-semibold md:text-secondaryWhite">
            Admin Login
          </div>
          <div className="flex flex-col justify-center items-center mt-4 md:bg-[#484848] p-6 sm:px-8 px-4 max-sm:mx-2">
            <Form
              name="login"
              className="login-form flex flex-col sm:w-[400px] w-[300px] mt-2 font-normal "
              initialValues={{
                email: getLocalStoragedata("rememberedEmail") || "",
                password: getLocalStoragedata("rememberedPassword") || "",
                remember: localStorage.getItem("rememberMe") || false,
              }}
              layout="vertical"
              onFinish={onFinish}
              size="large"
              autoComplete="off"
            >
              <Form.Item
                name="email"
                label="E-Mail"
                className="mb-4 login-email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input
                  placeholder="E-Mail"
                  className="border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
                  onChange={(e) => {
                    setLoginError(false);
                    setLoginValues({ ...loginValues, email: e.target.value });
                    setIsButtonDisable(false);
                  }}
                  maxLength={100}
                />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                className="mb-4 login-password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Password!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Password"
                  className="login-page-password border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
                  onChange={(e) => {
                    setLoginError(false);
                    setLoginValues({
                      ...loginValues,
                      password: e.target.value,
                    });
                    setIsButtonDisable(false);
                  }}
                  maxLength={20}
                />
              </Form.Item>

              {loginError ? (
                <p className="text-[14px] !text-primaryColor font-normal pb-4">
                  {loginErrorMsg}
                </p>
              ) : (
                <></>
              )}

              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox
                  onChange={(e) => {
                    setRememberMe(e.target.checked);
                  }}
                >
                  <p className="text-[18px]">Remember me</p>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  size="large"
                  className={`w-full mt-7 !text-[18px] text-semibold !text-white ${
                    loginValues.email !== "" &&
                    loginValues.password !== "" &&
                    isButtonDisable === false
                      ? "!bg-primaryLight !border-primaryLight"
                      : "!bg-primaryLightDisable !border-primaryLightDisable"
                  }`}
                  style={{ height: "50px" }}
                  disabled={
                    loginValues.email !== "" &&
                    loginValues.password !== "" &&
                    isButtonDisable === false
                      ? false
                      : true
                  }
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>

            <div className="forgot-password">
              <Button
                type="text"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                <p className="underline !text-primaryLight flex justify-center text-[18px] cursor-pointer">
                  Forgot Password
                </p>
              </Button>
            </div>
          </div>
        </Col>

        {/* login page Image */}
        <Col
          md={9}
          className="hidden md:flex flex-col bg-cover bg-center items-center justify-center"
          style={{
            backgroundImage: `url(${LoginBg})`,
          }}
        >
          <div>
            <img src={LoginLogo} alt="loginLogo" width={250} />
          </div>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default Login;
