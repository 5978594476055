import React from "react";
import { Button, Divider, Drawer, Form, Tag, Row, Col } from "antd";
import { MdClose } from "react-icons/md";

const ViewCountry = ({ open, onClose, country }) => {
  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] view-country">
        <Form layout="vertical" className="w-full view-form">
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium pb-3">View Country</div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Country Name</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{country?.country}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Country Code</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {country?.country_code}
                </p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Time Zone Type</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {country?.timezone_type === 1 ? "Country Wise" : "State Wise"}
                </p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-5">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Time Zone</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{country?.timezone}</p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Currency</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{country?.currency}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-5">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Currency Label</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {country?.currency_label}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Country Status</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {country?.is_active === 0 ? "Inactive" : "Active"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{country?.created_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {country?.created_username ? country.created_username : "_"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{country?.updated_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {country?.updated_username ? country.updated_username : "_"}
                </p>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewCountry;
