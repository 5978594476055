import React, { useEffect, useState } from "react";
import { Button, Dropdown, Layout, Space } from "antd";
import { Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineArrowDropDown } from "react-icons/md";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { ConfigProvider } from "antd";
import { useContentContext } from "../providers/ContentContext";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { Outlet } from "react-router-dom";
import { userLogOut } from "../helpers";
import { decryptSecureData } from "../services/EncryptionService";

const Main = () => {
  let { openNotification, handleError } = useContentContext();
  const token = getLocalStoragedata("token");
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const username = getLocalStoragedata("user");

  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={(e) => {
            logOut();
          }}
        >
          Logout
        </a>
      ),
      icon: <PoweroffOutlined />,
    },
  ];

  const logOut = () => {
    const data = {
      token: token,
    };

    userLogOut(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          localStorage.removeItem("usertype");
          localStorage.removeItem("permission");
          localStorage.removeItem("token");
          window.location.replace("/login");
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    }

    if (!token) {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins",
          colorPrimary: "var(--primary-light)",
        },
        components: {
          Select: {
            optionSelectedBg: "var(--secondary-light-shadow)",
          },
          Switch: {
            colorPrimary: "var(--primary-color)",
            colorPrimaryHover: "var(--primary-color)",
          },
        },
      }}
    >
      <Layout className="w-full flex flex-row max-h-full h-screen">
        <Sider
          className={`
          ${
            collapsed
              ? "max-md:hidden bg-secondaryWhite"
              : "visible sider bg-secondaryWhite !max-w-[230px] !min-w-[230px] !w-[230px]"
          } sider-scroll`}
          theme="light"
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ background: "#FFFFFF" }}
        >
          <Sidebar collapsed={collapsed} />
        </Sider>
        <Layout className="bg-[#F3F2F2]">
          <Header className="p-0 bg-secondaryWhite pt-4">
            <div className="flex flex-row justify-between menu-profile-button">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              />
              <div className="flex">
                <Dropdown menu={{ items }}>
                  <Space>
                    <Button
                      icon={
                        <AiOutlineUser className="text-[#2F2F2F] text-2xl" />
                      }
                      className=" border-none flex flex-row "
                      onClick={(e) => e.preventDefault()}
                    >
                      <p className="text-[#2F2F2F] max-w-[250px] overflow-hidden">
                        Hi, {decryptSecureData(username)} !
                      </p>
                      <MdOutlineArrowDropDown className="text-2xl" />
                    </Button>
                  </Space>
                </Dropdown>
              </div>
            </div>
          </Header>

          <Outlet />

          <Footer className="text-center py-4">
            Copyright 2024 © ALL RIGHTS RESERVED. Design by Idia Corporation
            (PVT) LTD
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default Main;
