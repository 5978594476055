import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MdClose, MdUpload } from "react-icons/md";
import { useContentContext } from "../../../providers/ContentContext";
import {
  getAllMailTemplateList,
  postNewNotification,
} from "../../../helpers/apiHelpers/emailNotificationHelpers";
import { getLocalStoragedata } from "../../../helpers/StorageHelper";
import ClientEmailNotificationAudience from "./ClientEmailNotificationAudience";

const NewClientEmailNotification = ({ open, onClose }) => {
  const userId = getLocalStoragedata("userId");

  let {
    openNotification,
    handleError,
    notificationArray,
    setNotificationArray,
    filterValues,
    setFilterValues,
  } = useContentContext();

  useEffect(() => {
    fetchAllMailTemplateList();
    setNotificationArray([]);
    setFilterValues({
      selectedCountry: 1,
      location1SelectedData: null,
      location2SelectedData: null,
      selectedCategoryData: null,
      selectedJobTypeData: null,
    });
  }, []);

  const [form] = Form.useForm();

  const [openCloseAudienceDrawer, setOpenCloseAudienceDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [templateSelectorList, setTemplateSelectoreList] = useState([]);
  const [templateSelectorListAllData, setTemplateSelectoreListAllData] =
    useState([]);
  const [selectedTemplateType, setSelectedTemplateType] = useState();

  const handleOpenCloseAudienceDrawer = () => {
    setOpenCloseAudienceDrawer(false);
  };

  const fetchAllMailTemplateList = async (e) => {
    try {
      await getAllMailTemplateList({
        user_type_id: 2,
      })
        .then((response) => {
          if (response?.data.success) {
            setTemplateSelectoreListAllData(response?.data?.output);
            setTemplateData(response?.data?.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setTemplateData = (items) => {
    var itemObject = [];
    items?.forEach((item) => {
      let data = {
        value: item.template_id,
        label: item.template_name,
      };
      itemObject.push(data);
    });
    setTemplateSelectoreList(itemObject);
  };

  const onFinish = async (e) => {
    setLoading(true);
    try {
      await postNewNotification({
        notification_type_id: 1,
        user_type_id: 2,
        country_id: filterValues?.selectedCountry,
        subject: e?.subject || null,
        title: e?.title || null,
        subtitle: "ClientNotification",
        description: e?.descri || null,
        users: notificationArray,
        template_id: e?.emailtemplate,

        state_id: filterValues?.location1SelectedData,
        suburb_id: filterValues?.location2SelectedData,
        job_category_id: filterValues?.selectedCategoryData,
        job_type_id: filterValues?.selectedJobTypeData,
        created_by: userId,

        button_title: e?.button_title || null,
        button_url: e?.button_url || null,
        image_title: e?.image_title || null,
        image_url: e?.image_url || null,
      })
        .then((response) => {
          if (response?.data.success) {
            openNotification("success", response.data.message);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    onClose();
    setLoading(false);
  };

  const selectedTemplate = templateSelectorListAllData.find(
    (item) => item.template_id === selectedTemplateType
  );

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                New Notification
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              className="w-full add-form"
              onFinish={onFinish}
              initialValues={{
                type: 1,
              }}
            >
              <div>
                <Form.Item
                  name="emailtemplate"
                  className="text-[16px] w-60"
                  label="Mail Template Types"
                  rules={[{ required: true }]}
                >
                  <Select
                    name="emailtemplate"
                    placeholder="Mail Template Type"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    options={templateSelectorList}
                    onSelect={(e) => {
                      setSelectedTemplateType(e);
                    }}
                  />
                </Form.Item>
              </div>

              <div>
                <Button
                  size="large"
                  className="w-60"
                  onClick={() => {
                    setOpenCloseAudienceDrawer(true);
                  }}
                >
                  ADD AUDIENCE
                </Button>
              </div>

              <Divider />

              <div>
                <Form.Item
                  name="subject"
                  className="text-[16px]"
                  label="Subject"
                  rules={[
                    {
                      required:
                        templateSelectorListAllData?.find(
                          (item) => item?.template_id === selectedTemplateType
                        )?.can_custom === 0
                          ? false
                          : true,
                      message: "Subject is Required!",
                    },
                  ]}
                >
                  <Input
                    name="subject"
                    placeholder="Subject"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                    disabled={
                      templateSelectorListAllData?.find(
                        (item) => item?.template_id === selectedTemplateType
                      )?.can_custom === 0
                    }
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  name="title"
                  className="text-[16px]"
                  label="Title"
                  rules={[
                    {
                      required:
                        templateSelectorListAllData?.find(
                          (item) => item?.template_id === selectedTemplateType
                        )?.can_custom === 0
                          ? false
                          : true,
                      message: "Title is Required!",
                    },
                  ]}
                >
                  <Input
                    name="title"
                    placeholder="Title"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                    disabled={
                      templateSelectorListAllData?.find(
                        (item) => item?.template_id === selectedTemplateType
                      )?.can_custom === 0
                    }
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  name="descri"
                  className="text-[16px]"
                  label="Message"
                  rules={[
                    {
                      required:
                        templateSelectorListAllData?.find(
                          (item) => item?.template_id === selectedTemplateType
                        )?.can_custom === 0
                          ? false
                          : true,
                      message: "Description is Required!",
                    },
                  ]}
                >
                  <TextArea
                    name="descri"
                    placeholder="Type message here"
                    size="large"
                    rows={5}
                    disabled={
                      templateSelectorListAllData?.find(
                        (item) => item?.template_id === selectedTemplateType
                      )?.can_custom === 0
                    }
                  />
                </Form.Item>
              </div>

              <div>
                {selectedTemplate?.has_button === 1 ? (
                  <div>
                    <Form.Item
                      name="button_title"
                      label="Button Title"
                      rules={[
                        { required: true, message: "Button title is required!" },
                      ]}
                    >
                      <Input placeholder="Enter button title" />
                    </Form.Item>
                    <Form.Item
                      name="button_url"
                      label="Button URL"
                      rules={[
                        { required: true, message: "Button URL is required!" },
                      ]}
                    >
                      <Input placeholder="Enter button URL" size="large" />
                    </Form.Item>
                  </div>
                ) : null}
              </div>

              <div>
                {selectedTemplate?.has_image === 1 ? (
                  <div>
                    <Form.Item
                      name="image_title"
                      label="Image Title"
                      rules={[
                        { required: true, message: "Image title is required!" },
                      ]}
                    >
                      <Input placeholder="Enter image title" size="large" />
                    </Form.Item>

                    <Form.Item
                      name="image_url"
                      label="Image URL"
                      rules={[
                        { required: true, message: "Image URL is required!" },
                      ]}
                      className="w-full"
                    >
                      <TextArea
                        name="image_url"
                        placeholder="Enter Image Url"
                        size="large"
                        rows={5}
                      />
                    </Form.Item>
                  </div>
                ) : null}
              </div>

              {/* button */}
              <div className="w-full pt-12">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="font-medium w-full bg-primaryLigh"
                        disabled={notificationArray?.length === 0 || loading}
                      >
                        <p className="text-center text-secondaryWhite">SEND</p>
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </Drawer>

      {openCloseAudienceDrawer && (
        <ClientEmailNotificationAudience
          open={openCloseAudienceDrawer}
          onClose={handleOpenCloseAudienceDrawer}
        />
      )}
    </>
  );
};

export default NewClientEmailNotification;
