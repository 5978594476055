import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Radio } from "antd";
import { MdClose } from "react-icons/md";
import TextForm from "./inputFieldForms/TextForm";
import DropdownForm from "./inputFieldForms/DropdownForm";
import DateTimeForm from "./inputFieldForms/DateTimeForm";
import { useContentContext } from "../../providers/ContentContext";

const EditInputField = ({ open, onCancel, selectedQuestionData }) => {
  let { setPageTitleCount } = useContentContext();

  const [value, setValue] = useState(1);
  const [inputFieldValue, setInputFieldValue] = useState();

  const formType = "update";

  const radioButtonChange = (e) => {
    setValue(e.target.value);
    //empty all input field
    setInputFieldValue();
  };

  useEffect(() => {
    setValue(selectedQuestionData?.questionData?.field_type);
    setInputFieldValue(selectedQuestionData.questionData);
  }, []);

  const onFinish = async (e) => {
    const updateQuestionData = {
      title: e?.title,
      input_type: e?.inputType,
      require: e?.require === true ? 1 : 0,
      hint: e?.hint,
      answer: e?.dropdown,
      field_type: value,
      status: 1,
    };

    setPageTitleCount((prevPageTitleCount) => {
      const updatedPageTitleCount = prevPageTitleCount.map((pageTitle) => {
        if (
          pageTitle.page_title_id ===
          selectedQuestionData?.pageTitleData?.page_title_id
        ) {
          const updatedQuestions = pageTitle.question_data.map(
            (question, index) => {
              if (index === selectedQuestionData?.questionIndex) {
                return { ...question, ...updateQuestionData };
              }
              return question;
            }
          );
          return { ...pageTitle, question_data: updatedQuestions };
        }
        return pageTitle;
      });

      return updatedPageTitleCount;
    });

    onCancel();
  };

  return (
    <Modal placement="right" open={open} onCancel={onCancel} width={650}>
      <div>
        <Form
          layout="vertical"
          className="w-full add-form"
          onFinish={onFinish}
          fields={[
            {
              name: ["title"],
              value: inputFieldValue?.title,
            },
            { name: ["hint"], value: inputFieldValue?.hint },
            {
              name: ["inputType"],
              value: inputFieldValue?.input_type,
            },
            {
              name: ["dropdown"],
              value: inputFieldValue?.answer,
            },
            {
              name: ["require"],
              value: inputFieldValue?.require === 1 ? true : false,
            },
            {
              name: ["fieldStatus"],
              value: inputFieldValue?.status === 1 ? true : false,
            },
          ]}
        >
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[18px] font-medium text-primaryLight pb-3">
                Input Field
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onCancel();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div className="text-[16px] font-normal  pb-3">Input Field</div>
            <Radio.Group
              onChange={radioButtonChange}
              value={value}
              className="job-type-question"
            >
              <Radio value={1}>Text</Radio>
              <Radio value={2}>Dropdown</Radio>
              <Radio value={3}>Date&Time</Radio>
            </Radio.Group>

            {value === 1 ? (
              <TextForm
                pageTitleId={selectedQuestionData?.pageTitleData?.page_title_id}
                formType={formType}
                onCancel={onCancel}
                fieldStatus={inputFieldValue?.status}
              />
            ) : value === 2 ? (
              <DropdownForm
                pageTitleId={selectedQuestionData?.pageTitleData?.page_title_id}
                formType={formType}
                onCancel={onCancel}
                fieldStatus={inputFieldValue?.status}
              />
            ) : value === 3 ? (
              <DateTimeForm
                pageTitleId={selectedQuestionData?.pageTitleData?.page_title_id}
                formType={formType}
                onCancel={onCancel}
                fieldStatus={inputFieldValue?.status}
              />
            ) : (
              <></>
            )}
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditInputField;
