import { getLocalStoragedata } from "../StorageHelper";

const token = getLocalStoragedata("token");

export const url = process.env.REACT_APP_URL;

export const config = {
  headers: {
    "Access-Control-Allow": true,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Origin: "https://prod-api.jobninja.au",
    "X-Custom-Header": "random-value-1234567890",
    Authorization: `Bearer ${token}`,
  },
};

//when upload data in FormData()
export const formconfig = {
  headers: {
    "Access-Control-Allow": true,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    Origin: "https://prod-api.jobninja.au",
    "X-Custom-Header": "random-value-1234567890",
    Authorization: `Bearer ${token}`,
  },
};

export const authConfig = {
  headers: {
    "Access-Control-Allow": true,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Origin: "https://prod-api.jobninja.au",
    "X-Custom-Header": "random-value-1234567890",
  },
};
