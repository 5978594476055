import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";

const TermsConditSubmitButton = ({
  form,
  errormsg,
  isUploading,
  isEditButtonEnable,
  type,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then((e) => {
        if (e?.file?.fileList?.length === 0) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      })
      .catch((error) => {
        if (error.errorFields.length !== 0) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      });
  }, [values]);

  // console.log(!submittable, errormsg, isUploading, isEditButtonEnable, type);

  return (
    <Button
      type="primary"
      htmlType="submit"
      size="large"
      className={`font-medium w-full ${
        !submittable || errormsg || isUploading || isEditButtonEnable //true
          ? "!bg-secondaryDarkshade"
          : "bg-primaryLigh"
      }`}
      disabled={!submittable || errormsg || isUploading || isEditButtonEnable}
    >
      <p className="text-center text-secondaryWhite">{type}</p>
    </Button>
  );
};

export default TermsConditSubmitButton;
