import axios from "axios";
import { config, url } from "./header";

export async function getAllSupportCustomerTradieList(data) {
  return await axios.post(url + "support/all", data, config);
}

export async function getAllSupportCustomerTradieProfileDetails(data) {
  return await axios.post(url + "support/data", data, config);
}
