import { Button, Col, Divider, Drawer, Form, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import {
  getAllTopicList,
  postNewNotificationTopic,
} from "../../helpers/apiHelpers/topicHelpers";

const NewTopicNotification = ({ open, onClose }) => {
  const userId = getLocalStoragedata("userId");

  let { openNotification, handleError, setNotificationArray, setFilterValues } =
    useContentContext();

  useEffect(() => {
    setNotificationArray([]);
    setFilterValues({
      selectedCountry: 1,
      location1SelectedData: null,
      location2SelectedData: null,
      selectedCategoryData: null,
      selectedJobTypeData: null,
    });
  }, []);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [topicSelectorList, setTopicSelectorList] = useState([]);
  const [selectedTopicType, setSelectedTopicType] = useState();

  useEffect(() => {
    fetchAllTopicList();
  }, []);

  const fetchAllTopicList = async (e) => {
    try {
      await getAllTopicList({
        user_id: userId,
      })
        .then((response) => {
          if (response?.data.success) {
            setTemplateData(response?.data?.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setTemplateData = (items) => {
    var itemObject = [];
    items?.forEach((item) => {
      let data = {
        value: item.id,
        label: item?.description,
      };
      itemObject.push(data);
    });
    setTopicSelectorList(itemObject);
  };

  const onFinish = async (e) => {
    setLoading(true);
    try {
      await postNewNotificationTopic({
        topic_id: selectedTopicType,
        title: e?.title,
        description: e?.descri,
        created_by: userId,
      })
        .then((response) => {
          if (response?.data.success) {
          } else {
            openNotification("warning", response?.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
    onClose();
    setLoading(false);
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                New Notification
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              className="w-full add-form"
              onFinish={onFinish}
            >
              <div>
                <Form.Item
                  name="type"
                  className="text-[16px] w-60"
                  label="Topic List"
                  rules={[{ required: true }]}
                >
                  <Select
                    name="type"
                    placeholder="Notification Type"
                    required
                    className="!bg-secondaryWhite"
                    size="large"
                    onSelect={(e) => {
                      setSelectedTopicType(e);
                    }}
                    options={topicSelectorList}
                  />
                </Form.Item>
              </div>

              <Divider />

              <div>
                <Form.Item
                  name="title"
                  className="text-[16px]"
                  label="Title"
                  rules={[{ required: true, message: "Title is Required!" }]}
                >
                  <Input
                    name="title"
                    placeholder="Title"
                    className="!bg-secondaryWhite"
                    size="large"
                    minLength={3}
                    maxLength={50}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  name="descri"
                  className="text-[16px]"
                  label="Description"
                  rules={[{ required: true, message: "Title is Required!" }]}
                >
                  <TextArea
                    name="descri"
                    placeholder="Type message here"
                    size="large"
                    rows={5}
                  />
                </Form.Item>
              </div>

              {/* button */}
              <div className="w-full pt-12">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="font-medium w-full bg-primaryLigh"
                      >
                        <p className="text-center text-secondaryWhite">SEND</p>
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default NewTopicNotification;
