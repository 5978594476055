import { Button, Drawer } from "antd";
import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getAllTopicMoreDetails } from "../../helpers/apiHelpers/topicHelpers";
import { decryptSecureData } from "../../services/EncryptionService";

const ViewTopicNotification = ({ open, onClose, notificationID }) => {
  let { openNotification, handleError } = useContentContext();

  const [notificationMoreDetails, setNotificationMoreDetails] = useState();

  useEffect(() => {
    fetchAllNotificationMoreDetails();
  }, []);

  const fetchAllNotificationMoreDetails = async () => {
    try {
      await getAllTopicMoreDetails({ notification_id: notificationID })
        .then((response) => {
          if (response?.data.success) {
            setNotificationMoreDetails(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                Notification Topic
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <div>
              <p className="text-base font-normal">Title</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.title}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Topic</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.topic_description}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Notification Description</p>
              <p className="text-base font-medium">
                {decryptSecureData(notificationMoreDetails?.description)}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Created Date</p>
              <p className="text-base font-medium">
                {notificationMoreDetails?.date_time}
              </p>
            </div>

            <div className="pt-5">
              <p className="text-base font-normal">Created By</p>
              <p className="text-base font-medium">
                {decryptSecureData(notificationMoreDetails?.created_by)}
              </p>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ViewTopicNotification;
