import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useContentContext } from "../../providers/ContentContext";
import { resetEmail } from "../../helpers";

const ResetEmail = ({ setFormChanger, setResetEmail, setIsOTPsend }) => {
  let { openNotification, handleError } = useContentContext();
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [verificationEmail, setVerifictionEmail] = useState("");

  const formRef = React.useRef(null);

  const onReset = () => {
    formRef.current?.resetFields();
  };

  const onFinish = (e) => {
    setResetEmail(e.email);
    setIsButtonDisable(true);

    const data = {
      email_address: e.email,
      should_generate: 1,
    };

    resetEmail(data)
      .then((response) => {
        if (response.data.success) {
          setIsOTPsend(response.data.success);
          openNotification("success", response.data.message);
          setFormChanger(1);
          onReset();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <div className="text-[40px] font-semibold text-center md:text-secondaryWhite">
        Reset Your Password
      </div>
      <div className="flex flex-col justify-center items-center mt-4 md:bg-[#484848] p-6 sm:px-8 px-4 max-sm:mx-2">
        <Form
          name="login"
          ref={formRef}
          className="login-form flex flex-col sm:w-[400px] w-[300px] mt-2 font-normal "
          layout="vertical"
          size="large"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label="Enter Your E-Mail"
            className="mb-4 login-email"
            rules={[
              { type: "email", message: "Invalid user information" },
              { required: true, message: "Enter the email" },
            ]}
          >
            <Input
              placeholder="Enter your email"
              className="border-none !bg-primaryLightDisable !text-secondaryDarkshade font-normal h-[50px] text-[18px]"
              onChange={(e) => {
                setIsButtonDisable(false);
                setVerifictionEmail(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              htmlType="submit"
              className={`w-full mt-7 !text-[18px] text-semibold !text-white ${
                isButtonDisable === false && verificationEmail !== ""
                  ? "!bg-primaryLight !border-primaryLight"
                  : "!bg-primaryLightDisable !border-primaryLightDisable"
              }`}
              style={{ height: "50px" }}
              disabled={
                isButtonDisable === false && verificationEmail !== ""
                  ? false
                  : true
              }
            >
              SEND VERIFICATION CODE
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default ResetEmail;
