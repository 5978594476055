import React, { useEffect, useState } from "react";
import { Form, Input, Row, Switch, Button, Spin, Col } from "antd";
import { Content } from "antd/es/layout/layout";
import { useContentContext } from "../providers/ContentContext";
import {
  getCommonConfigurationData,
  updateCommonConfigurationData,
} from "../helpers";
import { Confirmation } from "../components";

const Configuration = () => {
  let { openNotification, handleError } = useContentContext();
  const [form] = Form.useForm();

  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [settingValue, setSettingValue] = useState();
  const [fieldChange, setFieldChange] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onFieldsChange = () => {
    setFieldChange(true);
  };

  const fetchData = async () => {
    await getCommonConfigurationData()
      .then((response) => {
        if (response.data.success) {
          setSettingValue(response.data.output);
        } else {
          openNotification(
            "warning",
            "Data loading failed!",
            response.data.message
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  const onFinish = async (e) => {
    const data = {
      expire_day_count: e?.expireDayCount,
      score_calculation: e?.switch === true ? 1 : 0,
      locked_attempt4: e?.lockedAttempt4,
      locked_attempt5: e?.lockedAttempt5,
      locked_attempt6: e?.lockedAttempt6,
      support_url: e?.supportUrl,
    };

    await updateCommonConfigurationData(data)
      .then((response) => {
        if (response.data.success) {
          fetchData();
          setOpenConfirmation(true);
          setFieldChange(false);
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const validateNumberField = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter the value"));
    }
    if (!/^\d+$/.test(value)) {
      return Promise.reject(new Error("Please enter only numbers"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        COMMON CONFIGURATION
      </div>

      <Content className="mt-[10px] m-[24px] p-[30px] bg-secondaryWhite h-full overflow-y-scroll c-configuration md:pl-[60px]  rounded-lg">
        <Row className="flex flex-col items-start pt-0 pb-3 drawer-form">
          <div className="w-full">
            {settingValue === undefined ? (
              <div className="flex justify-center">
                <Spin className="!text-[primaryLight]" />
              </div>
            ) : (
              <Form
                form={form}
                name="validateOnly"
                className="w-full add-form"
                onFinish={onFinish}
                initialValues={{
                  expireDayCount: settingValue?.expire_day_count,
                  lockedAttempt4: settingValue?.locked_attempt4,
                  lockedAttempt5: settingValue?.locked_attempt5,
                  lockedAttempt6: settingValue?.locked_attempt6,
                  supportUrl: settingValue?.support_url,
                  switch: settingValue?.score_calculation === 1 ? true : false,
                }}
                onFieldsChange={onFieldsChange}
              >
                <div className="relative">
                  <div className="flex flex-col">
                    <div className="flex flex-col font-normal text-base">
                      Score Calculation
                    </div>
                    <div className="flex flex-row mt-2 text-center items-baseline">
                      <span
                        className={`${
                          isSwitchOn
                            ? "text-[#AAB8C2]"
                            : "text-[#000] font-bold"
                        }`}
                      >
                        Inactive
                      </span>
                      <Form.Item name="switch" valuePropName="checked">
                        <Switch
                          className="mx-2"
                          onChange={handleSwitchChange}
                        />
                      </Form.Item>
                      <span
                        className={`${
                          isSwitchOn
                            ? "text-[#000] font-bold"
                            : "text-[#AAB8C2]"
                        }`}
                      >
                        Active
                      </span>
                    </div>
                  </div>
                </div>

                <Row className="flex flex-col items-start">
                  <p className="font-normal text-base">Job expire day count</p>
                  <Form.Item
                    name="expireDayCount"
                    className="text-[16px]"
                    required
                    rules={[{ validator: validateNumberField }]}
                  >
                    <Input
                      placeholder="Expire Day Count"
                      className="!bg-secondaryWhite mt-2 md:w-72 w-full"
                      size="large"
                      maxLength={3}
                    />
                  </Form.Item>
                </Row>

                <Row className="flex flex-col items-start">
                  <p className="font-normal text-base">Support Url</p>
                  <Form.Item
                    name="supportUrl"
                    className="text-[16px] w-full"
                    rules={[
                      { required: true, message: "Please enter Support Url" },
                    ]}
                  >
                    <Input
                      placeholder="Paster here"
                      className="!bg-secondaryWhite mt-2 lg:w-1/2 w-full"
                      size="large"
                    />
                  </Form.Item>
                </Row>

                <Row className="flex flex-col items-start">
                  <p className="font-normal text-base">Account lock time</p>
                  <p className="font-normal text-sm text-[#a7a7a7]">
                    Time must be in seconds(s)
                  </p>
                </Row>

                <Row className="flex flex-row w-full justify-between items-center p-4 bg-[#d4d4d4] mt-2 rounded-xl">
                  <Col className="flex flex-col xl:w-1/4 lg:w-1/2 w-full">
                    <div className="flex flex-col items-start">
                      <p className="font-normal text-base">
                        4th attempt lock countdown{" "}
                        <label className="text-red-600">*</label>
                      </p>
                      <Form.Item
                        name="lockedAttempt4"
                        className="text-[16px]"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Locked Attempt 4",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Locked Attempt 4"
                          className="!bg-secondaryWhite mt-2"
                          size="large"
                          type="number"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col className="flex flex-col xl:w-1/4 lg:w-1/2 w-full">
                    <div className="flex flex-col items-start">
                      <p className="font-normal text-base">
                        5th attempt lock countdown{" "}
                        <label className="text-red-600">*</label>
                      </p>
                      <Form.Item
                        name="lockedAttempt5"
                        className="text-[16px]"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Locked Attempt 5",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Locked Attempt 5"
                          className="!bg-secondaryWhite mt-2"
                          size="large"
                          type="number"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col className="flex flex-col xl:w-1/4 lg:w-1/2 w-full">
                    <div className="flex flex-col items-start">
                      <p className="font-normal text-base">
                        6th attempt lock countdown{" "}
                        <label className="text-red-600">*</label>
                      </p>
                      <Form.Item
                        name="lockedAttempt6"
                        className="text-[16px]"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Locked Attempt 6",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Locked Attempt 6"
                          className="!bg-secondaryWhite mt-2"
                          size="large"
                          type="number"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Form.Item className="pt-10">
                  <div className="xl:w-1/4 lg:w-1/2 md:w-2/3 w-full">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="w-full"
                      disabled={fieldChange ? false : true}
                    >
                      <p
                        className={`${
                          fieldChange ? "text-secondaryWhite" : "text-slate-400"
                        }  text-[18px] font-semibold`}
                      >
                        SAVE
                      </p>
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </div>
        </Row>
      </Content>

      {openConfirmation && (
        <Confirmation
          title="Common configuration Updated!"
          description="Common configuration has been successfully updated."
          open={openConfirmation}
          onCancel={closeConfirmation}
        />
      )}
    </>
  );
};

export default Configuration;
