import React, { useEffect, useState } from "react";
import "../assets/css/theme-override.css";
import { Button, Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { MdAdd } from "react-icons/md";
import { useContentContext } from "../providers/ContentContext";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { EditState, NewState, StatusChange, ViewState } from "../components";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { customStatusSorter } from "../commonFun";
import { getAllStates, tableStateStatusChange } from "../helpers";

const columns = [
  {
    title: "Country",
    dataIndex: "country",
    key: "0",
    sorter: (a, b) => a.country?.localeCompare(b.country),
    defaultSortOrder: "ascend",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "1",
    sorter: (a, b) => a.state?.localeCompare(b.state),
    defaultSortOrder: "ascend",
  },
  {
    title: "Time Zone",
    dataIndex: "timezone",
    key: "2",
    sorter: (a, b) => a.timezone?.localeCompare(b.timezone),
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "3",
    sorter: (a, b) => customStatusSorter(a, b),
    defaultSortOrder: "ascend",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];

const State = () => {
  let { handleError, openNotification } = useContentContext();
  const permission = getLocalStoragedata("permission");
  const userId = getLocalStoragedata("userId");

  const [tableStateData, setTableStateData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [searchText, setSearchText] = useState();
  const [stateDataForSearch, setStateDataForSearch] = useState([]);

  const [isOpenVewStateDrawer, setIsOpenViewStateDrawer] = useState(false);
  const [selectedViewStateData, setSelectedViewStateData] = useState();
  const [isOpenNewStateDrawer, setIsOpenNewStateDrawer] = useState(false);
  const [isOpenEditStateDrawer, setIsOpenEditStateDrawer] = useState(false);
  const [selectedEditStateData, setSelectedEditStateData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setDataLoading(true);

      await getAllStates({ country_id: 0, status: -1 })
        .then((response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
            setStateDataForSearch(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setData = (states) => {
    if (states.length !== 0) {
      var tableData = [];

      states?.forEach((state, x) => {
        let data = {
          key: x,
          country: state?.country,
          state: state?.state,
          status: (
            <Button
              className={`rounded-[50px] text-white min-w-[90px] !border-none ${
                state?.is_active === 1 ? "bg-primaryColor" : "bg-primaryLight"
              } `}
              onClick={() => {
                setChangeStatus(state?.is_active);
                openChangeStatus(state);
              }}
            >
              {state?.is_active === 1 ? "Active" : "Inactive"}
            </Button>
          ),
          timezone: state?.timezone,
          action: (
            <div className="flex flex-row gap-4 text-2xl text-primaryLight">
              {permission.includes(14) ? (
                <BiEdit
                  className="cursor-pointer"
                  onClick={() => {
                    openEditStateDrawer(state);
                  }}
                />
              ) : (
                <></>
              )}

              <BsEye
                className="cursor-pointer"
                onClick={() => {
                  handleViewStateDrawerOpenChange(state);
                }}
              />
            </div>
          ),
        };
        tableData.push(data);
      });
      setTableStateData(tableData);
    } else {
      setTableStateData([]);
    }
    setDataLoading(false);
  };

  const search = (text) => {
    let filtered = stateDataForSearch.filter(
      (o) =>
        o.country.toLowerCase().includes(text.toLowerCase()) ||
        o.state.toLowerCase().includes(text.toLowerCase())
    );

    setData(filtered);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  const handleViewStateDrawerOpenChange = (state) => {
    setSelectedViewStateData(state);
    setIsOpenViewStateDrawer(!isOpenVewStateDrawer);
  };
  const handleNewStateDrawerOpenChange = () => {
    setIsOpenNewStateDrawer(!isOpenNewStateDrawer);
  };
  const openEditStateDrawer = (state) => {
    setIsOpenEditStateDrawer(true);
    setSelectedEditStateData(state);
  };
  const closeEditStateDrawer = () => {
    setIsOpenEditStateDrawer(false);
  };

  const openChangeStatus = (state) => {
    setIsModalOpen(true);
    setSelectedData(state);
  };
  const onCloseChangeStatus = () => {
    setIsModalOpen(false);
  };
  // state status change in table
  const handleStatus = async (status) => {
    const data = {
      state_id: selectedData?.id,
      status: status,
      updated_by: userId,
    };

    await tableStateStatusChange(data)
      .then((response) => {
        if (response.data.success) {
          openNotification("success", response.data.message);
          fetchData();
        } else {
          openNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        handleError(error);
      });

    onCloseChangeStatus();
    setSearchText();
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5 pl-6">
        STATE
      </div>

      <Content className="rounded-lg mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start sm:items-start lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search State..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
          <Col>
            {permission.includes(13) ? (
              <Button
                className="flex flex-row bg-primaryLight font-medium !pt-2 !pb-9"
                type="primary"
                size="large"
                onClick={handleNewStateDrawerOpenChange}
                icon={<MdAdd className="text-2xl font-bold" />}
              >
                <p className=" text-secondaryWhite text-[18px] font-semibold">
                  ADD STATE
                </p>
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <div className="table-status-button">
          <Table
            dataSource={tableStateData}
            columns={columns}
            showSorterTooltip={false}
            loading={dataLoading}
            pagination={{
              size: "small",
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showQuickJumper: true,
              itemRender: CustomPagination,
            }}
          />
        </div>
      </Content>

      {isOpenNewStateDrawer ? (
        <NewState
          onClose={handleNewStateDrawerOpenChange}
          open={isOpenNewStateDrawer}
          reloadStateData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isOpenVewStateDrawer ? (
        <ViewState
          open={isOpenVewStateDrawer}
          onClose={handleViewStateDrawerOpenChange}
          state={selectedViewStateData}
        />
      ) : (
        <></>
      )}

      {isOpenEditStateDrawer ? (
        <EditState
          open={isOpenEditStateDrawer}
          onClose={closeEditStateDrawer}
          state={selectedEditStateData}
          reloadStateData={fetchData}
          setSearchText={setSearchText}
        />
      ) : (
        <></>
      )}

      {isModalOpen && (
        <StatusChange
          open={isModalOpen}
          onCancel={onCloseChangeStatus}
          changeStatus={changeStatus}
          handleStatus={handleStatus}
          setChangeStatus={setChangeStatus}
        />
      )}
    </>
  );
};

export default State;
