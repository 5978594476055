import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Form, Modal, Row, Upload } from "antd";
import { MdClose } from "react-icons/md";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ViewCategory = ({ open, onClose, category }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [topFileList, setTopFileList] = useState([]); // for top image

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  useEffect(() => {
    setFileList([
      {
        uid: "1",
        name: "image.png",
        status: "done",
        url: category?.image_path,
      },
    ]);

    setTopFileList([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: category?.popular_image_path,
      },
    ]);
  }, []);

  return (
    <Drawer open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] view-category view-drawer">
        <Form layout="vertical" className="w-full view-form ">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row  justify-between pb-5">
              <div className="text-[16px] font-medium pb-3">View Category</div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Category Image</p>
              </Col>
              <Col xs={12}>
                <Form.Item name="upload">
                  <Upload
                    customRequest={({ onSuccess }) => onSuccess("ok")}
                    listType="picture-card"
                    className="md:col-span-5"
                    onPreview={handlePreview}
                    fileList={fileList}
                  ></Upload>
                  <Modal
                    className="z-[100000]"
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{
                        width: "100%",
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Category</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {category?.job_category}
                </p>
              </Col>
            </Row>

            <Divider />

            {category?.countries?.length !== 0 ? (
              <>
                <Row gutter={10}>
                  <Col xs={12}>
                    <p className="text-[16px] font-medium">Countries</p>
                  </Col>
                  <Col xs={12}>
                    <p className="text-[16px] font-medium">
                      {category?.countries?.map((item, index) =>
                        index === category.countries.length - 1
                          ? item.country
                          : item.country + ","
                      )}
                    </p>
                  </Col>
                </Row>

                <Divider />
              </>
            ) : (
              <>
                <Row gutter={10}>
                  <Col xs={12}>
                    <p className="text-[16px] font-medium">Countries</p>
                  </Col>
                  <Col xs={12}>
                    <p className="text-[16px] font-medium">Global</p>
                  </Col>
                </Row>

                <Divider />
              </>
            )}

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Category Status</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {category?.is_active === 0 ? "Inactive" : "Active"}
                </p>
              </Col>
            </Row>

            {category?.is_popular === 1 && (
              <>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12}>
                    <p className="text-[16px] font-medium">Top Category</p>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="topimageupload">
                      <Upload
                        customRequest={({ onSuccess }) => onSuccess("ok")}
                        listType="picture-card"
                        className="md:col-span-5"
                        onPreview={handlePreview}
                        fileList={topFileList}
                      ></Upload>
                      <Modal
                        className="z-[100000]"
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {category?.created_at}
                </p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {category?.created_user ? category.created_user : "_"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {category?.updated_at}
                </p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {category?.updated_user ? category.updated_user : "_"}
                </p>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewCategory;
