import axios from "axios";
import { config, url } from "./header";

//report
export async function getReportData(data) {
  return await axios.post(
    url + "report/suburb_job_type_wise_user",
    data,
    config
  );
}

export async function getReportDataTypeWise(data) {
  return await axios.post(url + "report/type/user", data, config);
}

export async function getReportDataAreaWise(data) {
  return await axios.post(url + "report/area/user", data, config);
}
