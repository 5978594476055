import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Switch,
  Upload,
  Row,
  Col,
  Checkbox,
  Select,
  ConfigProvider,
} from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getLocalStoragedata } from "../../helpers/StorageHelper";
import { PiWarningCircleThin } from "react-icons/pi";
import { IoMdCloudUpload } from "react-icons/io";
import {
  addCategory,
  categoryCountryAdd,
  checkCategoryName,
  getAllCountries,
} from "../../helpers";
import CategorySubmitButton from "../submitButton/CategorySubmitButton";
import { commonFieldValidateField } from "../../commonFun";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  }); //both primary and top image

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}; //for primary image

const topNormFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.topFileList;
}; // for top image

const NewCategory = ({
  onClose,
  reloadCategoryData,
  open,
  setSearchText,
  topCount,
}) => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");
  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false); //both primary and top image
  const [previewImage, setPreviewImage] = useState(""); //both primary and top image
  const [previewTitle, setPreviewTitle] = useState(""); //both primary and top image
  const [fileList, setFileList] = useState([]); //for primary image
  const [topFileList, setTopFileList] = useState([]); // for top image

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  const handleCancel = () => setPreviewOpen(false); //both primary and top image
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  }; //both primary and top image
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList); // for primary image
  const handleTopImagChange = ({ fileList: newTopFileList }) =>
    setTopFileList(newTopFileList); // for top image
  const uploadButton = (
    <div className="flex flex-col p-3 items-center text-primaryLightDisable italic text-[14px] font-normal">
      <p>Drop files here to upload</p>
      <p>OR</p>
      <IoMdCloudUpload className="text-[66px]" />
      <p className="underline">Click Here to Upload</p>
    </div>
  ); //both primary and top image

  const onFinish = async (e) => {
    setSearchText();
    const data = new FormData();
    data.append("job_category", e.category);
    data.append(
      "file_extension",
      fileList.length === 1 ? fileList[0]?.name.split(".")[1] : null
    );
    data.append(
      "job_category_image",
      fileList[0] ? fileList[0]?.originFileObj : null
    );
    data.append("is_popular", e.popular === true ? 1 : 0);
    data.append("is_complex", 0);
    data.append("created_by", userId);
    data.append("status", e.switch === true ? 1 : 0);
    data.append(
      "popular_image",
      topFileList[0] ? topFileList[0]?.originFileObj : null
    );
    data.append(
      "file_extension1",
      topFileList.length === 1 ? topFileList[0]?.name.split(".")[1] : null
    );

    if (isUploading) {
      openNotification("warning", "Please wait Upload on progress !");
    } else {
      setIsUploading(true);

      const res = await addCategory(data)
        .then((response) => {
          if (response.data.success) {
            openNotification("success", response.data.message);
            return response.data.output.id;
          } else {
            openNotification("error", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      if (res !== 0) {
        categoryCountryAdd({
          job_category_id: res,
          country: e.country,
          status: 1,
        })
          .then((response) => {
            if (response.data.success) {
              reloadCategoryData(0);
              // openNotification("success", response.data.message);
            } else {
              openNotification("error", response.data.message);
            }
          })
          .catch((error) => {
            handleError(error);
          });
      } else {
        reloadCategoryData(0);
      }

      onClose();
      setIsUploading(false);
    }
  };

  const checkCategory = (e) => {
    checkCategoryName({
      job_category_id: 0,
      job_category: e,
    })
      .then((response) => {
        if (response.data.success) {
          setErrormsg(
            response.data.output.has_job_category === 1 ? true : false
          );
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const [selectorGlobalItemStatus, seSelectorGlobalItemStatus] =
    useState(false);
  const [selectorCountryItemStatus, seSelectorCountryItemStatus] =
    useState(false);
  const [countrySelectorData, setCountrySelectorData] = useState([]);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    fetchData();
  }, [selectorGlobalItemStatus, selectorCountryItemStatus]);

  const fetchData = async () => {
    try {
      await getAllCountries({ status: 1 })
        .then((response) => {
          if (response?.data.success) {
            setCountryList(response?.data.output);
            setCountryData(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const setCountryData = (countries) => {
    var countryObject = [
      { value: 0, label: "Global", disabled: selectorGlobalItemStatus },
    ];
    countries?.forEach((country) => {
      let data = {
        value: country.id,
        label: country.country,
        disabled: selectorCountryItemStatus,
      };
      countryObject.push(data);
    });
    setCountrySelectorData(countryObject);
  };

  const handleSelectorMode = (e) => {
    if (e === 0) {
      seSelectorCountryItemStatus(true);
      seSelectorGlobalItemStatus(false);

      setCountryData(countryList);
    } else {
      seSelectorCountryItemStatus(false);
      seSelectorGlobalItemStatus(true);

      setCountryData(countryList);
    }
  };

  const resetSelectoreMode = (e) => {
    if (e.length === 0) {
      seSelectorCountryItemStatus(false);
      seSelectorGlobalItemStatus(false);
    }
    setCountryData(countryList);
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            className="w-full add-form"
            onFinish={onFinish}
            fields={[
              { name: ["upload"], value: fileList },
              { name: ["topimageupload"], value: topFileList },
            ]}
            initialValues={{
              switch: false,
              popular: checkBoxStatus,
            }}
          >
            <div className="relative bg-secondaryWhite p-7">
              <div className="w-full flex flex-row justify-between pb-5">
                <div className="text-[16px] font-medium text-primaryLight">
                  Add New Category
                </div>
                <Button
                  className="border-none"
                  onClick={() => {
                    setFileList([]);
                    setTopFileList([]);
                    onClose();
                  }}
                >
                  <MdClose className="text-[20px]" />
                </Button>
              </div>
              {/* category image:require */}
              <Row className="flex flex-row" gutter={8}>
                <Col xs={24} md={10}>
                  <Form.Item
                    name="upload"
                    label="Primary Image"
                    getValueFromEvent={normFile}
                    rules={[{ required: true }]}
                  >
                    <Upload
                      customRequest={({ onSuccess }) => onSuccess("ok")}
                      listType="picture-card"
                      className="md:col-span-5"
                      onPreview={handlePreview}
                      onChange={handleChange}
                      fileList={fileList}
                      accept=".png, .jpg, .jpeg"
                    >
                      {fileList?.length === 1 ? null : uploadButton}
                    </Upload>
                    <p className="text-[12px] font-medium">
                      Primary category image. Resolution should be (1:1)
                    </p>
                    <Modal
                      className="z-[100000]"
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </Col>
                <Col xs={24} md={14}>
                  <Form.Item
                    name="category"
                    className="text-[16px]"
                    label="Category"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="Category name"
                      className="!bg-secondaryWhite"
                      size="large"
                      onKeyUp={(e) => {
                        checkCategory(e.target.value);
                      }}
                      minLength={3}
                      maxLength={50}
                    />
                  </Form.Item>

                  {errormsg === true ? (
                    <p className="text-[14px] font-normal text-[#ff4d4f] mt-[-20px]">
                      Category name already exist
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>

              {/* select country */}
              <Divider />
              <Row>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="country"
                    className="text-[16px]"
                    label="Select Country"
                    rules={[
                      { required: true, message: "Please select country" },
                    ]}
                  >
                    <Select
                      name="country"
                      placeholder="Country"
                      required
                      className="!bg-secondaryWhite"
                      size="large"
                      mode="multiple"
                      onChange={(e) => {
                        resetSelectoreMode(e);
                      }}
                      onSelect={(e) => {
                        handleSelectorMode(e);
                      }}
                      options={countrySelectorData}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* status */}
              <Divider />
              <div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col font-medium text-base">
                    Category Status
                  </div>

                  <div className="flex flex-row mt-2 text-center items-baseline">
                    <span
                      className={`${
                        isSwitchOn ? "text-[#AAB8C2]" : "text-[#000]"
                      }`}
                    >
                      Inactive
                    </span>
                    <Form.Item name="switch" valuePropName="checked">
                      <Switch className="mx-2" onChange={handleSwitchChange} />
                    </Form.Item>
                    <span
                      className={`${
                        isSwitchOn ? "text-[#000]" : "text-[#AAB8C2]"
                      }`}
                    >
                      Active
                    </span>
                  </div>
                </div>

                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    Changing the category status will impact the available job
                    types within the category
                  </p>
                </div>
              </div>

              {/* checkbox for top category image */}
              <Divider />
              <div className="w-full bg-secondaryLight p-3 rounded-[20px] add-permission">
                <Row className="flex flex-row justify-between" gutter={8}>
                  <Col xs={24} sm={12}>
                    <div className="flex flex-row gap-3">
                      <Form.Item name="popular" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            setCheckBoxStatus(e.target.checked);
                            setTopFileList([]);
                          }}
                        />
                      </Form.Item>

                      <p className="text-[16px] font-normal">
                        Add To Top Category
                      </p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} className="text-right">
                    <p className="text-[12px] font-normal">
                      {`Total top categories (${topCount})`}
                    </p>
                  </Col>
                </Row>

                <div className="flex flex-row">
                  <PiWarningCircleThin className="text-[25px] mt-[-5px] mr-2 text-secondaryColor" />
                  <p className="text-[12px] text-secondaryColor">
                    When enabled, this category becomes the top category in its
                    relevant fields.
                  </p>
                </div>

                {checkBoxStatus && (
                  <Row className="pt-5">
                    <Col xs={24} md={10}>
                      <Form.Item
                        name="topimageupload"
                        label="Top Category  Image"
                        getValueFromEvent={topNormFile}
                        rules={[{ required: true }]}
                      >
                        <Upload
                          customRequest={({ onSuccess }) => onSuccess("ok")}
                          listType="picture-card"
                          className="md:col-span-5"
                          onPreview={handlePreview}
                          onChange={handleTopImagChange}
                          fileList={topFileList}
                          accept=".png, .jpg, .jpeg"
                        >
                          {topFileList?.length === 1 ? null : uploadButton}
                        </Upload>

                        <Modal
                          className="z-[100000]"
                          open={previewOpen}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                            }}
                            src={previewImage}
                          />
                        </Modal>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={14}>
                      <p className="text-[12px] font-medium pt-1 md:pt-20">
                        Top Category Image should differ from the category
                        image. Resolution should be (4:3)
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
              {/* button */}
              <div className="w-full pt-12">
                <Row gutter={8}>
                  <Col xs={24} sm={12}>
                    <Form.Item>
                      <CategorySubmitButton
                        form={form}
                        errormsg={errormsg}
                        isUploading={isUploading}
                        isEditButtonEnable={false}
                        type={"ADD"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} className="outline-button">
                    <Button
                      size="large"
                      className="font-medium w-full "
                      onClick={onClose}
                    >
                      <p className="text-center">CANCEL</p>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default NewCategory;
