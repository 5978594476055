import React from "react";
import { Modal, Row, Col, Button } from "antd";

const StateDelete = ({ open, onCancel, deleteData, deletePermanently }) => {
  return (
    <Modal open={open} onCancel={onCancel}>
      <p className="text-[24px] font-medium text-center">
        Are you sure you want to delete this state?
      </p>

      {deleteData?.all_suberb !== 0 &&
      deleteData?.all_job !== 0 &&
      deleteData?.all_user !== 0 ? (
        <>
          <p className="text-[16px] font-normal text-secondaryRed text-center pt-5">
            {`Unable delete state with ${deleteData?.all_suberb} suburb(s), ${deleteData?.all_user} user(s), ${deleteData?.all_job} job(s). Remove all suburbs, users and jobs to proceed.`}
          </p>

          <Row className="px-10 pt-5 w-full">
            <Col className="w-full">
              <Button
                className="bg-primaryLight text-white hover:!text-white  w-full"
                size="large"
                onClick={onCancel}
              >
                <p className="text-white hover:!text-white"> OK</p>
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <p className="text-[16px] font-normal text-secondaryRed text-center pt-5">
            Deleting it will permanently remove the state.
          </p>

          <Row className="flex flex-row justify-between px-10 pt-5" gutter={64}>
            <Col xs={12} className="tradie-block-button w-full">
              <Button
                className="bg-primaryRed text-white w-full border-none"
                size="large"
                onClick={deletePermanently}
              >
                DELETE
              </Button>
            </Col>
            <Col xs={12} className="w-full outline-button">
              <Button size="large" onClick={onCancel} className="w-full">
                CANCEL
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default StateDelete;
