import React from "react";
import { Modal, Row, Col, Button } from "antd";
import { getLocalStoragedata } from "../../helpers/StorageHelper";

const StatusChangeDiff = ({ open, onCancel, changeStatus, handleStatus }) => {
  const userId = getLocalStoragedata("userId");

  return (
    <Modal open={open} onCancel={onCancel}>
      <p className="text-[24px] font-medium text-center">
        Are you sure you want to change the status?
      </p>

      {changeStatus?.id === userId ? (
        <>
          <p className="text-[16px] font-normal text-secondaryColor text-center pt-5">
            You can't change your status
          </p>
          <Row className="flex flex-row justify-center px-10 pt-5">
            <Col className="tradie-block-button">
              <Button
                className="bg-black text-white"
                size="large"
                onClick={onCancel}
              >
                <p className="px-9"> OK</p>
              </Button>
            </Col>
          </Row>
        </>
      ) : changeStatus?.user_type_id === 1 ? (
        <>
          <p className="text-[16px] font-normal text-secondaryColor text-center pt-5">
            You can't change super admin status
          </p>
          <Row className="flex flex-row justify-center px-10 pt-5">
            <Col className="tradie-block-button">
              <Button
                className="bg-black text-white"
                size="large"
                onClick={onCancel}
              >
                <p className="px-9"> OK</p>
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <p className="text-[16px] font-normal text-secondaryColor text-center pt-5">
            {`This action will ${
              changeStatus?.is_active === 1 ? "de-activate" : "activate"
            } the selected item. Confirm to
        proceed`}
          </p>

          <Row className="flex flex-row justify-between px-10 pt-5">
            <Col className="tradie-block-button">
              <Button
                onClick={() => {
                  handleStatus(changeStatus?.is_active === 1 ? 0 : 1);
                }}
                className="bg-black text-white"
                size="large"
              >
                {changeStatus?.is_active === 1 ? "INACTIVATE" : "ACTIVATE"}
              </Button>
            </Col>
            <Col className="outline-button">
              <Button size="large" onClick={onCancel}>
                CANCEL
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default StatusChangeDiff;
