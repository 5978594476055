import React, { useEffect } from "react";
import { Button, Form } from "antd";

const FormSubmitButton = ({
  form,
  errormsg,
  isUploading,
  isEditButtonEnable,
  type,
}) => {
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  // console.log(!submittable, errormsg, isUploading, isEditButtonEnable, type);
  // console.log(values);

  return (
    <Button
      type="primary"
      htmlType="submit"
      size="large"
      className={`font-medium w-full ${
        !submittable || errormsg || isUploading || isEditButtonEnable //true
          ? "!bg-secondaryDarkshade"
          : "bg-primaryLigh"
      }`}
      disabled={!submittable || errormsg || isUploading || isEditButtonEnable}
    >
      <p className="text-center text-secondaryWhite">{type}</p>
    </Button>
  );
};

export default FormSubmitButton;
