import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";

const JobTypeFormSubmitButton = ({
  form,
  errormsg,
  isUploading,
  isEditButtonEnable,
  type,
  pageTitleStatus,
  questionStatus,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then((e) => {
        setSubmittable(true);
      })
      .catch((error) => {
        if (error.errorFields.length !== 0) {
          setSubmittable(false);
        } else {
          setSubmittable(true);
        }
      });
  }, [values]);

  // console.log(
  //   !submittable,
  //   errormsg,
  //   isUploading,
  //   isEditButtonEnable,
  //   type,
  //   pageTitleStatus,
  //   questionStatus
  // );

  return (
    <Button
      type="primary"
      htmlType="submit"
      size="large"
      className={`font-medium w-full ${
        !submittable ||
        errormsg ||
        isUploading ||
        isEditButtonEnable ||
        pageTitleStatus ||
        questionStatus //true
          ? "!bg-secondaryDarkshade"
          : "bg-primaryLigh"
      }`}
      disabled={
        !submittable ||
        errormsg ||
        isUploading ||
        isEditButtonEnable ||
        pageTitleStatus ||
        questionStatus
      }
    >
      <p className="text-center text-secondaryWhite">{type}</p>
    </Button>
  );
};

export default JobTypeFormSubmitButton;
