import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { BsEye } from "react-icons/bs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useContentContext } from "../providers/ContentContext";
import { StatusChange, ViewTradie } from "../components";
import { getAllTradieUsers } from "../helpers";
import { getLocalStoragedata } from "../helpers/StorageHelper";
import { decryptSecureData } from "../services/EncryptionService";

const columns = [
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "0",
    sorter: (a, b) => a.fullName?.localeCompare(b.fullName),
    defaultSortOrder: "ascend",
  },

  {
    title: "State",
    dataIndex: "state",
    key: "1",
    sorter: (a, b) => a.state?.localeCompare(b.state),
    defaultSortOrder: "ascend",
  },
  {
    title: "Suburb",
    dataIndex: "suburb",
    key: "2",
    sorter: (a, b) => a.suburb?.localeCompare(b.suburb),
    defaultSortOrder: "ascend",
  },
  {
    title: "Postal Code",
    dataIndex: "postalCode",
    key: "3",
    sorter: (a, b) => a.postalCode?.localeCompare(b.postalCode),
    defaultSortOrder: "ascend",
  },
  {
    title: "Verify",
    dataIndex: "verify",
    key: "3",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "4",
  },
];

const Tradie = () => {
  let { openNotification, handleError } = useContentContext();
  const userId = getLocalStoragedata("userId");

  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const [searchText, setSearchText] = useState();
  const [dataForSearch, setDataForSearch] = useState([]);

  const [isOpenVewDrawer, setIsOpenViewDrawer] = useState(false);
  const [selectedViewData, setSelectedViewData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tradieStatus, setTradieStatus] = useState();

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    try {
      setDataLoading(true);

      await getAllTradieUsers({ user_id: userId, status: -1 })
        .then(async (response) => {
          if (response?.data.success) {
            setData(response.data.output ? response.data.output : []);
            const searchArray = await response.data.output.map((item) => {
              return {
                ...item,
                full_name: decryptSecureData(item.full_name),
              };
            });
            setDataForSearch(searchArray);
          } else {
            openNotification("warning", response.data.message);
          }
        })

        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const search = (text) => {
    let filtered = dataForSearch.filter(
      (o) =>
        o.full_name.toLowerCase().includes(text.toLowerCase()) ||
        o.state.toLowerCase().includes(text.toLowerCase()) ||
        o.suberb.toLowerCase().includes(text.toLowerCase()) ||
        o.postal_code.toLowerCase().includes(text.toLowerCase())
    );

    setData(filtered);
  };

  const setData = (tradies) => {
    if (tradies.length !== 0) {
      var tableData = [];

      tradies?.forEach((tradie, x) => {
        let data = {
          key: x,
          fullName: decryptSecureData(tradie?.full_name),
          state: tradie?.state,
          suburb: tradie?.suberb,
          postalCode: tradie?.postal_code,
          verify: (
            <div>
              <div
                className={`rounded-[50px] text-white min-w-[90px] max-w-[150px] !border-none py-1 px-2 ${
                  tradie?.has_verified === 0
                    ? "bg-[#aaa9a9]"
                    : tradie?.has_verified === 1
                    ? "bg-[#999ff5]"
                    : tradie?.has_verified === 2
                    ? "bg-[#79f3ca]"
                    : "bg-[#f7b6c1]"
                } `}
              >
                <p
                  className={`text-center ${
                    tradie?.has_verified === 0
                      ? "text-primaryLight"
                      : tradie?.has_verified === 1
                      ? "text-[#1e2366]"
                      : tradie?.has_verified === 2
                      ? "text-[#195541]"
                      : "text-[#70222f]"
                  } `}
                >
                  {tradie?.has_verified === 0
                    ? "Pending"
                    : tradie?.has_verified === 1
                    ? "Requested"
                    : tradie?.has_verified === 2
                    ? "Approved"
                    : "Rejected"}
                </p>
              </div>
            </div>
          ),

          action: (
            <BsEye
              className="cursor-pointer text-2xl text-primaryLight"
              onClick={() => {
                handleViewDrawerOpenChange(tradie);
              }}
            />
          ),
        };
        tableData.push(data);
      });
      setTableData(tableData);
      setDataLoading(false);
    } else {
      setTableData([]);
      setDataLoading(false);
    }
    setDataLoading(false);
  };

  const handleViewDrawerOpenChange = (tradie) => {
    setSelectedViewData(tradie);
    setIsOpenViewDrawer(!isOpenVewDrawer);
  };

  const CustomPagination = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <div className="flex flex-row">
          <GrFormPrevious className="text-2xl" />
          <span className="text-[14px] font-medium">Previous | </span>
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="flex flex-row">
          <span className="text-[14px] font-medium"> | Next</span>
          <GrFormNext className="text-2xl" />
        </div>
      );
    }
    return originalElement;
  };

  const openChangeStatus = (status) => {
    setIsModalOpen(!isModalOpen);
    setTradieStatus(status);
  };

  return (
    <>
      <div className="text-primaryLight text-[20px] sm:text-2xl font-semibold pt-5  pl-6 ">
        WORKER
      </div>
      <Content className="rounded-lg table-status-button mt-[10px] m-[24px] p-[24px] pt-4 bg-secondaryWhite h-full overflow-y-scroll">
        <Row className="flex flex-col items-start  lg:flex-row lg:justify-between pt-0 pb-6">
          <Col className="flex">
            <Input
              className="border-none bg-slate-100 w-80 p-3 rounded-[10px] mb-3 lg:mb-0"
              placeholder="Search Tasker..."
              onChange={(e) => {
                setSearchText(e.target.value);
                search(e.target.value);
              }}
              value={searchText}
              maxLength={50}
            />
          </Col>
        </Row>

        <Table
          dataSource={tableData}
          columns={columns}
          showSorterTooltip={false}
          loading={dataLoading}
          pagination={{
            size: "small",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            showQuickJumper: true,
            itemRender: CustomPagination,
          }}
        />
      </Content>

      {isModalOpen ? (
        <StatusChange
          open={isModalOpen}
          onCancel={openChangeStatus}
          status={tradieStatus}
        />
      ) : (
        <></>
      )}

      {isOpenVewDrawer ? (
        <ViewTradie
          open={isOpenVewDrawer}
          onClose={handleViewDrawerOpenChange}
          tradie={selectedViewData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Tradie;
