import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  TimePicker,
  Upload,
  Row,
  Col,
} from "antd";
import { IoTrashOutline } from "react-icons/io5";
import { getAllJobTypeQuestionList } from "../../helpers";

const dateFormat = "YYYY/MM/DD";
const format = "HH:mm";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ViewJobType = ({ open, onClose, jobType }) => {
  let { openNotification, handleError } = useContentContext();

  const [allQuestionList, setAllQuestionsList] = useState();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList([
      {
        uid: "1",
        name: "image.png",
        status: "done",
        url: jobType?.urgent_image_path,
      },
    ]);

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await getAllJobTypeQuestionList({
        job_category_id: jobType?.job_category_id,
        job_type_id: jobType?.id,
      })
        .then((response) => {
          if (response?.data.success) {
            setAllQuestionsList(response?.data.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    <Drawer placement="right" open={open} onClose={onClose} width={"large"}>
      <div className="w-full md:w-[768px] view-drawer">
        <Form layout="vertical" className="w-full add-form">
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row  justify-between">
              <div className="text-[16px] font-medium text-primaryLight">
                View Job Type
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Job Category</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {jobType?.job_category}
                </p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Job Type</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{jobType?.job_type}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Measuring Unit</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{jobType?.uom}</p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Job Type Status</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {jobType?.is_active === 0 ? "Inactive" : "Active"}
                </p>
              </Col>
            </Row>

            {jobType?.is_urgent === 1 && (
              <>
                <Divider />
                <Row gutter={10}>
                  <Col xs={12}>
                    <p className="text-[16px] font-medium">Popular Job Type</p>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="upload">
                      <Upload
                        customRequest={({ onSuccess }) => onSuccess("ok")}
                        listType="picture-card"
                        className="md:col-span-5"
                        onPreview={handlePreview}
                        fileList={fileList}
                      ></Upload>
                      <Modal
                        className="z-[100000]"
                        open={previewOpen}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{jobType?.created_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {jobType?.created_user ? jobType.created_user : "_"}
                </p>
              </Col>
            </Row>

            <Divider />

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated At</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{jobType?.updated_at}</p>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={12}>
                <p className="text-[16px] font-medium">Updated By</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {jobType?.updated_user ? jobType.updated_user : "_"}
                </p>
              </Col>
            </Row>

            <Divider />
            {/* exist question list */}
            {allQuestionList?.length !== undefined && (
              <p className="text-[16px] font-medium">Dynamic Questions</p>
            )}

            {allQuestionList?.map((questionObj, questionIndex) => (
              <>
                <div className="py-5">
                  <div className="rounded-xl bg-secondaryDarkshade p-5">
                    <div key={questionIndex}>
                      <div className="flex flex-row justify-between">
                        <p className="text-[16px] font-medium pb-2">
                          {questionObj.question}
                        </p>
                      </div>
                      <Divider />

                      {questionObj.fields?.map((inputField, inputIndex) => {
                        return (
                          <div key={inputIndex} className="pl-5">
                            <p className="text-[16px] font-normal pb-2">{`${
                              inputField.title
                            } ${inputField.is_required === 1 ? "*" : ""}`}</p>
                            <div className="pl-3 pb-4">
                              <Row gutter={16}>
                                <Col xs={24} sm={14} className="w-full">
                                  {inputField.field_type === 2 &&
                                  inputField.input_type === 1 ? (
                                    <Form.Item>
                                      <Select
                                        placeholder={inputField.hint}
                                        required
                                        className="font-medium text-[16px]"
                                        size="large"
                                        options={inputField?.answer}
                                      />
                                    </Form.Item>
                                  ) : inputField.field_type === 2 &&
                                    inputField.input_type === 2 ? (
                                    <Form.Item>
                                      <Select
                                        mode="multiple"
                                        className="font-medium text-[16px]"
                                        allowClear
                                        placeholder={inputField.hint}
                                        options={inputField?.answer}
                                        size="large"
                                      />
                                    </Form.Item>
                                  ) : inputField.field_type === 3 &&
                                    inputField.input_type === 1 ? (
                                    <Form.Item>
                                      <DatePicker
                                        className="!bg-secondaryWhite border-primaryLight font-medium text-[16px] w-full"
                                        size="large"
                                        format={dateFormat}
                                        placeholder={inputField.hint}
                                      />
                                    </Form.Item>
                                  ) : inputField.field_type === 3 &&
                                    inputField.input_type === 2 ? (
                                    <Form.Item>
                                      <TimePicker
                                        className="!bg-secondaryWhite border-primaryLight font-medium text-[16px] w-full"
                                        size="large"
                                        format={format}
                                        placeholder={inputField.hint}
                                      />
                                    </Form.Item>
                                  ) : (
                                    <Form.Item
                                      name={
                                        inputField.field_type === 1 &&
                                        inputField.input_type === 1
                                          ? "text"
                                          : inputField.field_type === 1 &&
                                            inputField.input_type === 2
                                          ? "IntegerNumber"
                                          : "doubleNumber"
                                      }
                                      rules={[
                                        {
                                          pattern:
                                            inputField.field_type === 1 &&
                                            inputField.input_type === 1
                                              ? /^[A-Za-z]+$/
                                              : inputField.field_type === 1 &&
                                                inputField.input_type === 2
                                              ? /^[0-9]*$/
                                              : /^[+-]?\d+(\.\d+)?$/,
                                          message: "please enter valid input",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="!bg-secondaryWhite border-primaryLight font-medium text-[16px]"
                                        size="large"
                                        placeholder={inputField.hint}
                                      />
                                    </Form.Item>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewJobType;
