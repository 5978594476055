import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Row } from "antd";
import { MdClose } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getAllPromoSmsDetails } from "../../helpers";
import { decryptSecureData } from "../../services/EncryptionService";
import { formatDateWithTime } from "../../helpers/apiHelpers/dateHelpers";

const ViewPromoSms = ({ open, onClose, selectedPromoSms }) => {
  let { openNotification, handleError } = useContentContext();
  const [promoSmsData, setPromoSmsData] = useState();

  useEffect(() => {
    fetchPromoSmsMoreDetails(selectedPromoSms?.promotion_id);
  }, []);

  const fetchPromoSmsMoreDetails = async (id) => {
    try {
      await getAllPromoSmsDetails({ promotion_id: id })
        .then((response) => {
          if (response?.data.success) {
            setPromoSmsData(response?.data?.output);
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } catch (error) {
      openNotification("error", error);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        open={open}
        onClose={onClose}
        width={"large"}
        className="drawer-form"
      >
        <div className="w-full md:w-[768px]">
          <div className="relative bg-secondaryWhite p-7">
            <div className="w-full flex flex-row justify-between pb-5">
              <div className="text-[16px] font-medium text-primaryLight">
                View Promo SMS
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>
          </div>

          <div className="px-7">
            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Title</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">{promoSmsData?.title}</p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Message</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {promoSmsData?.message}
                </p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Counrty</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {promoSmsData?.country}
                </p>
              </Col>
            </Row>

            <Row gutter={10} className="pb-16">
              <Col xs={12}>
                <p className="text-[16px] font-medium">Created at</p>
              </Col>
              <Col xs={12}>
                <p className="text-[16px] font-medium">
                  {promoSmsData?.created_at}
                </p>
              </Col>
            </Row>

            <div className="flex flex-col">
              <p className="text-[16px] font-medium pb-3">Audience List</p>

              {promoSmsData?.users?.map((item) => {
                return (
                  <Row className="flex flex-row text-[14px]" gutter={8}>
                    <Col span={12} className="w-full">
                      <p>{decryptSecureData(item?.full_name)}</p>
                    </Col>
                    <Col span={12}>
                      <p>{decryptSecureData(item?.mobile_number)}</p>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ViewPromoSms;
