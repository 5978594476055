import React, { useEffect, useState } from "react";
import { Button, Drawer, Form } from "antd";
import { MdClose } from "react-icons/md";

const ViewTerms = ({ open, onClose, setSearchText, selectedTerms }) => {
  const [fileList, setFileList] = useState();

  useEffect(() => {
    setFileList([selectedTerms?.term_file_path]);
  }, []);

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      width={"large"}
      className="drawer-form"
    >
      <div className="w-full md:w-[768px]">
        <Form layout="vertical" className="w-full add-form">
          <div className="relative bg-secondaryWhite p-7 ">
            <div className="w-full flex flex-row justify-between">
              <div className="w-full text-[18px] font-medium text-primaryLight">
                Terms & Conditions
              </div>
              <Button
                className="border-none"
                onClick={() => {
                  onClose();
                  setSearchText();
                }}
              >
                <MdClose className="text-[20px]" />
              </Button>
            </div>

            <Form.Item
              name="version"
              className="text-[16px] label-after mt-2"
              label="Version"
            >
              <p className="text-[16px] font-medium">
                {selectedTerms?.version}
              </p>
            </Form.Item>

            <Form.Item
              name="file"
              label="Terms & Condition"
              className="label-after"
            >
              <Button disabled={fileList === undefined} className="text-[16px]">
                <a href={fileList} target="_blank" rel="noopener noreferrer">
                  View File
                </a>
              </Button>
            </Form.Item>

            <Form.Item
              name="status"
              className="text-[16px] label-after"
              label="Status"
            >
              <p className="text-[16px] font-medium">
                {selectedTerms?.is_active === 1 ? "Active" : "Inactive"}
              </p>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default ViewTerms;
