import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Divider, Row, Col, Table, Spin } from "antd";
import { MdClose } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { useContentContext } from "../../providers/ContentContext";
import { getTradieProfileDetails } from "../../helpers";
import { decryptSecureData } from "../../services/EncryptionService";

const ViewTradie = ({ open, onClose, tradie }) => {
  let { openNotification, handleError } = useContentContext();

  const [tradieProfileData, setTradieProfileData] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  const [categoryDataSource, setCategoryDataSource] = useState([]);
  const [stateDataSource, setStateDataSource] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setDataLoading(true);

    try {
      let res = await getTradieProfileDetails({ trady_id: tradie?.id })
        .then((response) => {
          if (response?.data.success) {
            setTradieProfileData(response?.data.output);
            return response?.data.output;
          } else {
            openNotification("warning", response.data.message);
          }
        })
        .catch((error) => {
          handleError(error);
        });

      setCategoryDataSource(
        await res?.job_categories.map((categoryItem, index) => {
          return {
            key: (index + 1).toString(),
            category: categoryItem.job_category,
            jobtype: (
              <div>
                {categoryItem.job_type.map((jobTypeItem) => (
                  <p key={jobTypeItem.trady_job_type_id.toString()}>
                    {jobTypeItem.job_type}
                  </p>
                ))}
              </div>
            ),
          };
        })
      );

      setStateDataSource(
        await res?.states.map((stateItem, index) => {
          return {
            key: (index + 1).toString(),
            state: stateItem.state,
            suburb: (
              <div>
                {stateItem.suburb.map((suburbItem) => (
                  <p key={suburbItem.suburb_id.toString()}>
                    {suburbItem.suburb}
                  </p>
                ))}
              </div>
            ),
          };
        })
      );
    } catch (error) {
      openNotification("error", error);
    }
    setDataLoading(false);
  };
  return (
    <>
      <Drawer open={open} onClose={onClose} width={"large"}>
        <div className="w-full md:w-[768px]">
          {dataLoading ? (
            <div className="flex justify-center pt-[350px]">
              <Spin className="!text-[primaryLight]" />
            </div>
          ) : (
            <Form layout="vertical" className="w-full view-form">
              <div className="relative bg-secondaryWhite p-7 ">
                <div className="w-full flex flex-row  justify-between">
                  <p className="text-[18px] font-semibold">Tasker Details</p>

                  <Button
                    className="border-none"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <MdClose className="text-[20px]" />
                  </Button>
                </div>

                <Form.Item label="Your Name">
                  <p className="text-[16px] font-medium">
                    {decryptSecureData(tradieProfileData?.full_name)}
                  </p>
                </Form.Item>
                <Divider />

                <p className="text-[16px] font-semibold pb-3">Address</p>

                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Address Line 1">
                      <p className="text-[16px] font-medium">
                        {decryptSecureData(tradieProfileData?.street_address)}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Address Line 2">
                      <p className="text-[16px] font-medium">
                        {decryptSecureData(tradieProfileData?.apt)}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Suburbs">
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.suberb}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="State">
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.state}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Postal Code">
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.postal_code}
                      </p>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Country">
                      <p className="text-[16px] font-medium">
                        {tradieProfileData?.country}
                      </p>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />

                <p className="text-[16px] font-semibold pb-3">Contact</p>

                <Row>
                  <Col xs={24} md={12} className="pb-3">
                    <Row className="flex flex-row">
                      <Col xs={5}>
                        <FiPhoneCall className="text-[30px] text-[primaryLight] mt-3" />
                      </Col>
                      <Col xs={18}>
                        <p className="text-[16px] font-normal">Telephone</p>
                        <p className="text-[16px] font-medium">
                          {decryptSecureData(tradieProfileData?.mobile_number)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row className="flex flex-row">
                      <Col xs={5}>
                        <MdOutlineMailOutline className="text-[35px] text-[primaryLight] mt-2" />
                      </Col>
                      <Col xs={18}>
                        <p className="text-[16px] font-normal">Mail</p>
                        <p className="text-[16px] font-medium">
                          {decryptSecureData(tradieProfileData?.email)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider />

                <p className="text-[16px] font-semibold pb-3">
                  Service Details
                </p>
                <Table
                  className="w-full pb-10"
                  columns={detailColumns}
                  dataSource={categoryDataSource}
                  pagination={false}
                />

                <p className="text-[16px] font-semibold pb-3">
                  Service Suburbs
                </p>
                <Table
                  className="w-full"
                  columns={suburbColumns}
                  dataSource={stateDataSource}
                  pagination={false}
                />

                <Row gutter={16} className="pt-10">
                  <Col xs={24} sm={12}>
                    <p className="text-[16px] font-medium pb-3">Subscription</p>
                    <p className="text-[16px] font-normal">
                      {tradieProfileData?.has_subscribed === 1
                        ? "Subscribed"
                        : "Not subscribed"}
                    </p>
                  </Col>
                  <Col xs={24} sm={12}>
                    <p className="text-[16px] font-medium pb-3">
                      Subscription details
                    </p>
                    <p className="text-[16px] font-normal">
                      {`Subscribed date - ${tradieProfileData?.joined_at}`}
                    </p>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default ViewTradie;

const detailColumns = [
  {
    title: "Job Categories",
    dataIndex: "category",
    key: "0",
  },
  {
    title: "Job Type",
    dataIndex: "jobtype",
    key: "1",
  },
];

const suburbColumns = [
  {
    title: "State",
    dataIndex: "state",
    key: "0",
  },
  {
    title: "Suburbs",
    dataIndex: "suburb",
    key: "1",
  },
];
